import { isEmpty } from "../../forms/validators/utils";
import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

function shouldCompare(value: any, otherValue: any) {
    if (isEmpty(value) || isEmpty(otherValue)) {
        return false;
    }

    return true;
}

/**
 * Validates that the value matches the value of another field.
 * @param field
 * @param label
 */
export const equal = (field: string, label?: string): Validator => (value: any, allValues?: any) => {
    if (allValues === undefined) {
        return;
    }

    const otherValue = allValues[field];

    if (!shouldCompare(value, otherValue)) {
        return;
    }

    if (value === otherValue) {
        return;
    }

    return {
        level: Level.Invalid,
        message: messages.forms.validation.mustBeEqual({
            FIELD: label || field
        })
    };
};

/**
 * Validates that the value is not the same as the value of another field.
 * @param field
 * @param label - The label of the other field being compared.
 */
export const notEqual = (field: string, label?: string): Validator => (value: any, allValues?: any) => {
    if (allValues === undefined) {
        return;
    }

    const otherValue = allValues[field];

    if (!shouldCompare(value, otherValue)) {
        return;
    }

    if (value !== otherValue) {
        return;
    }

    return {
        level: Level.Invalid,
        message: messages.forms.validation.mustNotBeEqual({
            FIELD: label || field
        })
    };
};
