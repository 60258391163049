import { Address, AddressNew } from "../../shared/models/Address";
import { Contact, ContactNew } from "../../shared/models/Contact";
import { OrgSummary } from "../../shared/models/OrgSummary";
import { PartnerForm } from "../../shared/models/PartnerForm";
import { SiteSummary } from "../../shared/models/SiteSummary";
import { SiteSurvey } from "../../siteSurveys/models/SiteSurvey";
import { OcaReturnDetail } from "./OcaReturnDetail";
import { OcaReturnPickupPreferences } from "./OcaReturnPickupPreferences";
import { OcaReturnPickupProperties } from "./OcaReturnPickupProperties";

export const CONTACT_KEY = "shippingContact";
export const ADDRESS_KEY = "shippingAddress";

export interface OcaReturn extends PartnerForm, OcaReturnPickupProperties, OcaReturnPickupPreferences {
    [CONTACT_KEY]?: Contact | ContactNew;
    [ADDRESS_KEY]?: Address | AddressNew;
    orgSummary: OrgSummary;
    siteSummary: SiteSummary;
    /**
     * This list corresponds to the collection of issueKeys found under each
     * `OcaReturnDetail`. These are 1-1 and populated upon submit.
     */
    issueKeys: string[];
    partnerRecipients?: string;
    ocaReturnDetails: OcaReturnDetail[];
    siteSurvey: SiteSurvey;
}
