import { toNumber } from "lodash";
import React, { memo } from "react";
import Dropdown from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormField from "../../../shared/components/FormField";
import HelpIcon from "../../../shared/components/HelpIcon";
import Tooltip from "../../../shared/components/Tooltip/Tooltip";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { DatacenterFields, DATACENTER_KEYS, getLabel } from "../../../shared/models/Datacenter";
import { Option } from "../../../shared/models/Option";
import { FormPropsV2 } from "../../../shared/props/forms";

// These props are applied to individual datacenter form fields
export interface DatacenterFormFieldProps {
    options?: Option<number>[];
    required?: boolean;
    disabled?: boolean;
    /**
     * Disables the clear icon.
     */
    disableClear?: boolean;
    notes?: string;
    tooltip?: JSX.Element;
    /**
     * If set, overrides the default placeholder, which is just the input label.
     */
    placeholder?: string;
}

export interface Props extends FormPropsV2<DatacenterFields> {
    fieldProps: Record<keyof DatacenterFields, DatacenterFormFieldProps>;
}

function DatacenterForm(props: Props) {
    const { fieldProps } = props;
    const [{ values, dirty, errors }, { handleSubmit, changeHandlers, touchHandlers }] = useForm(
        { keys: DATACENTER_KEYS },
        props
    );

    return (
        <Form className="DatacenterForm" onSubmit={handleSubmit}>
            {DATACENTER_KEYS.map((key) => {
                const {
                    options = [],
                    required = true,
                    disabled = false,
                    disableClear = true,
                    tooltip,
                    notes,
                    placeholder,
                } = fieldProps[key] ?? {};
                const label = getLabel(key);
                return (
                    <FormField
                        key={key}
                        name={key}
                        required={required}
                        label={label}
                        notes={notes}
                        error={errors.get(key)}
                        labelAccessory={
                            tooltip && (
                                <Tooltip placement="right-end" content={<div>{tooltip}</div>}>
                                    <HelpIcon spacedLeft />
                                </Tooltip>
                            )
                        }
                    >
                        <Dropdown
                            disabled={disabled}
                            placeholder={placeholder ?? label}
                            error={errors.has(key)}
                            dirty={dirty.has(key)}
                            value={values[key]}
                            options={options}
                            onChange={(evt) => {
                                // evt.target.value is a stringified id, or
                                // empty string if the selection is being
                                // cleared.
                                changeHandlers[key]?.(evt.target.value ? toNumber(evt.target.value) : undefined);
                            }}
                            onBlur={() => touchHandlers[key]?.()}
                            clearable
                            disableClear={disableClear}
                        />
                    </FormField>
                );
            })}
        </Form>
    );
}

export default memo(DatacenterForm);
