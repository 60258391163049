import axios from "axios";
import { flatten, lowerCase, orderBy } from "lodash";
import BR_STATES from "../../data/brStates.json";
import US_STATES from "../../data/usStates.json";
import { OcaReturn } from "../../ocaReturns/models/OcaReturn";
import { PartOrder } from "../../partOrders/models/PartOrder";
import { PartOrderLineItem } from "../../partOrders/models/PartOrderLineItem";
import { PartOrderLineItemNew } from "../../partOrders/models/PartOrderLineItemNew";
import { PartOrderMetadata } from "../../partOrders/models/PartOrderMetadata";
import { QuantityLimits } from "../../partOrders/models/QuantityLimits";
import config from "../../shared/config";
import { messages } from "../../shared/messages";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import { AllowedParts } from "../../shared/models/AllowedParts";
import { Country } from "../../shared/models/Country";
import { CountrySubdivision } from "../../shared/models/CountrySubdivision";
import { Org } from "../../shared/models/Org";
import { PartDefinition } from "../../shared/models/PartDefinition";
import { PartnerFormKind } from "../../shared/models/PartnerFormKind";
import { NotFoundError, UnauthorizedError } from "../../shared/models/RequestError";
import { ID_KEY, Resource } from "../../shared/models/Resource";
import { NetworkConfig } from "../../siteSurveys/models/NetworkConfig";
import { SiteSurvey } from "../../siteSurveys/models/SiteSurvey";
import auth, { AuthService } from "../auth";
import { APIKeyCredentials } from "../auth/models/APIKeyCredentials";
import { RequestService } from "../RequestService";
import { AddressVerificationResult } from "./models/AddressVerificationResult";
import { APIKeyAuthResponse } from "./models/APIKeyAuthResponse";
import { TopgearHelper } from "./TopgearHelper";

/**
 * The Topgear service handles all API requests to Topgear.
 */
export class Topgear extends RequestService {
    private static BASE_URL = config.topgearApiBaseUrl;
    private static PATH_SITE_SURVEYS = "partner-site-surveys";
    private static PATH_PARTS_ORDERS = "partner-part-orders";
    private static PATH_OCA_RETURNS = "partner-oca-returns";
    private static PATH_NETWORK_CONFIGS = "network-configs";
    private static PATH_SUBMIT = "submit";
    private static PATH_METADATA = "metadata";

    constructor(auth: AuthService) {
        super(
            "Topgear",
            auth,
            axios.create({
                baseURL: Topgear.BASE_URL,
            })
        );
    }

    /**
     * Authenticates the provided auth info and retrieves corresponding site
     * surveys, org ID, and expiration date
     */
    public async authenticate(credentials: APIKeyCredentials): Promise<APIKeyAuthResponse> {
        try {
            const { data } = await this.axios.get<APIKeyAuthResponse>("/api/1.0/auth/key-auth-payload", {
                headers: AuthService.generateApiKeyAuthHeaders(credentials),
            });

            return data;
        } catch (err) {
            if (err instanceof UnauthorizedError) {
                if (/invalid/i.test(err.message)) {
                    err.message = messages.errors.login.invalidCode();
                } else if (/expired/i.test(err.message)) {
                    err.message = messages.errors.login.expiredCode();
                }
            }

            throw err;
        }
    }

    /**
     * Fetch all site surveys for an org. Optionally specify a list of site
     * survey IDs to filter results. Cancelled site surveys are **included**.
     */
    public async fetchSiteSurveys(orgId: number, siteSurveyIds?: number[]): Promise<SiteSurvey[]> {
        const siteSurveys = await this.fetchPartnerForms<SiteSurvey>(PartnerFormKind.SiteSurvey, orgId, siteSurveyIds);
        return siteSurveys.map(TopgearHelper.processSiteSurvey);
    }

    public async downloadSiteSurveyAttachment(orgId: number, siteSurveyId: number, id: number): Promise<Blob> {
        const { data, headers } = await this.axios.get<string>(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_SITE_SURVEYS}/${siteSurveyId}/attachment/${id}`,
            {
                responseType: "arraybuffer",
            }
        );

        return new Blob([data], { type: headers[lowerCase("Content-Type")] });
    }

    public async updateSiteSurvey(orgId: number, siteSurvey: Partial<SiteSurvey> & Resource): Promise<SiteSurvey> {
        const { data: updated } = await this.axios.post<SiteSurvey>(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_SITE_SURVEYS}/${siteSurvey.id}`,
            TopgearHelper.prepareSiteSurvey(siteSurvey)
        );
        return TopgearHelper.processSiteSurvey(updated);
    }

    public async submitSiteSurvey(orgId: number, siteSurveyId: number): Promise<SiteSurvey> {
        try {
            const { data: siteSurvey } = await this.axios.post<SiteSurvey>(
                `/api/1.0/orgs/${orgId}/${Topgear.PATH_SITE_SURVEYS}/${siteSurveyId}/${Topgear.PATH_SUBMIT}`
            );
            return TopgearHelper.processSiteSurvey(siteSurvey);
        } catch (err) {
            throw TopgearHelper.processSubmitError(err);
        }
    }

    public async fetchSiteSurveyShippingAddressSuggestions(orgId: number, siteSurveyId: number): Promise<Address[]> {
        try {
            const { data: addresses } = await this.axios.get<Address[]>(
                `/api/1.0/orgs/${orgId}/${Topgear.PATH_SITE_SURVEYS}/${siteSurveyId}/shipping-addresses`
            );
            return addresses;
        } catch (err) {
            if (err instanceof NotFoundError) {
                return [];
            }

            throw err;
        }
    }

    /**
     * Fetch all part orders for an org. Optionally specify a list of part
     * order IDs to filter results. Cancelled part orders are **included**
     */
    public async fetchPartOrders(orgId: number, partOrderIds?: number[]): Promise<PartOrder[]> {
        const partOrders = await this.fetchPartnerForms<PartOrder>(PartnerFormKind.PartOrder, orgId, partOrderIds);
        return partOrders.map(TopgearHelper.processPartOrder);
    }

    public async updatePartOrder(
        orgId: number,
        partOrder: Partial<PartOrder<PartOrderLineItem | PartOrderLineItemNew>> & Resource
    ): Promise<PartOrder> {
        const { data: updated } = await this.axios.post<PartOrder>(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_PARTS_ORDERS}/${partOrder.id}`,
            TopgearHelper.preparePartOrder(partOrder)
        );
        return TopgearHelper.processPartOrder(updated);
    }

    public async removePartOrderLineItem(orgId: number, partOrderId: number, lineItemId: number): Promise<void> {
        await this.axios.delete(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_PARTS_ORDERS}/${partOrderId}/line-items/${lineItemId}`
        );
    }

    public async submitPartOrder(orgId: number, partOrderId: number): Promise<PartOrder> {
        try {
            const { data: partOrder } = await this.axios.post<PartOrder>(
                `/api/1.0/orgs/${orgId}/${Topgear.PATH_PARTS_ORDERS}/${partOrderId}/${Topgear.PATH_SUBMIT}`
            );

            return TopgearHelper.processPartOrder(partOrder);
        } catch (err) {
            throw TopgearHelper.processSubmitError(err);
        }
    }

    public async fetchPartOrderShippingAddressSuggestions(orgId: number, partOrderId: number): Promise<Address[]> {
        try {
            const { data: addresses } = await this.axios.get<Address[]>(
                `/api/1.0/orgs/${orgId}/${Topgear.PATH_PARTS_ORDERS}/${partOrderId}/shipping-addresses`
            );
            return addresses;
        } catch (err) {
            if (err instanceof NotFoundError) {
                return [];
            }

            throw err;
        }
    }

    public async fetchPartOrderQuantityLimits(orgId: number, partOrderId: number): Promise<QuantityLimits> {
        const { data: metadata } = await this.axios.get<PartOrderMetadata>(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_PARTS_ORDERS}/${partOrderId}/${Topgear.PATH_METADATA}`
        );
        return metadata.quantityLimits;
    }

    public async fetchOcaReturns(orgId: number, ocaReturnIds?: number[]): Promise<OcaReturn[]> {
        const ocaReturns = await this.fetchPartnerForms<OcaReturn>(PartnerFormKind.OcaReturn, orgId, ocaReturnIds);

        return ocaReturns.map(TopgearHelper.processOcaReturn);
    }

    public async updateOcaReturn(orgId: number, ocaReturn: Partial<OcaReturn> & Resource): Promise<OcaReturn> {
        const { data: updated } = await this.axios.post<OcaReturn>(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_OCA_RETURNS}/${ocaReturn.id}`,
            TopgearHelper.prepareOcaReturn(ocaReturn)
        );
        return TopgearHelper.processOcaReturn(updated);
    }

    public async submitOcaReturn(orgId: number, ocaReturnId: number): Promise<OcaReturn> {
        try {
            const { data: ocaReturn } = await this.axios.post<OcaReturn>(
                `/api/1.0/orgs/${orgId}/${Topgear.PATH_OCA_RETURNS}/${ocaReturnId}/${Topgear.PATH_SUBMIT}`
            );
            return TopgearHelper.processOcaReturn(ocaReturn);
        } catch (err) {
            throw TopgearHelper.processSubmitError(err);
        }
    }

    public async fetchOrg(id: number): Promise<Org> {
        const { data: org } = await this.axios.get<Org>(`/api/1.0/orgs/${id}`);
        return TopgearHelper.processOrg(org);
    }

    public async fetchCountries(): Promise<Country[]> {
        const { data: countries } = await this.axios.get<Country[]>("/api/1.0/countries");
        return orderBy(countries, (c) => c.name);
    }

    public async fetchCountrySubdivisions(): Promise<Map<string, CountrySubdivision[]>> {
        return new Map([
            ["US", US_STATES],
            ["BR", BR_STATES],
        ]);
    }

    public async fetchPartDefinitions(): Promise<PartDefinition[]> {
        const { data: partDefinitions } = await this.axios.get<PartDefinition[]>("/api/1.0/part-definitions");
        return orderBy(partDefinitions, (pd) => pd[ID_KEY]);
    }

    public async fetchAllowedParts(
        orgId: number,
        siteSurveyId: number,
        siteSurvey?: SiteSurvey
    ): Promise<AllowedParts> {
        const { data } = await this.axios.post<unknown>(
            `/api/1.0/orgs/${orgId}/partner-site-surveys/${siteSurveyId}/allowed-parts`,
            // Server expects Content-Type: application/json, but axios strips
            // the Content-Type request header out when body is empty, hence the
            // empty object. See https://github.com/axios/axios/issues/86.
            siteSurvey ? TopgearHelper.getOrderParametersFromSiteSurvey(siteSurvey) : {},
            {
                params: {
                    version: 2,
                },
            }
        );
        return TopgearHelper.toAllowedParts(data);
    }

    public async updateBmcConfigs(orgId: number, siteSurveyId: number, networkConfigs: NetworkConfig[]) {
        const { data } = await this.axios.put(
            `/api/1.0/orgs/${orgId}/${Topgear.PATH_SITE_SURVEYS}/${siteSurveyId}/${Topgear.PATH_NETWORK_CONFIGS}`,
            TopgearHelper.prepareNetworkConfigs(networkConfigs)
        );
        return TopgearHelper.processNetworkConfigs(data);
    }

    public async verifyAddress(orgId: number, address: AddressFields): Promise<AddressVerificationResult[]> {
        // We send multiple requests using different combinations of the address
        // fields to minimize the chance of a false negative. For example,
        // including street_2 may lead to an unverified address, whereas
        // omitting it could lead to a verified address.
        const results = await Promise.all(
            TopgearHelper.getAddressVerificationCombinations(address).map((variation) =>
                this.axios.get<AddressVerificationResult[]>(`/api/1.0/orgs/${orgId}/verify-address`, {
                    params: variation,
                })
            )
        );
        return flatten(results.map((result) => result.data));
    }

    /**
     * Performs the actual network request to retrieve all or a specified subset
     * of a kind of partner forms belonging to an org. Note that API key users
     * must specify the list of IDs that their API key has access to, as they
     * do not have permission to fetch all forms.
     */
    private async fetchPartnerForms<T>(kind: PartnerFormKind, orgId: number, ids?: number[]) {
        const path = this.getPartnerFormPath(kind);
        if (ids) {
            const results = await Promise.all(
                ids.map((id) => this.axios.get<T>(`/api/1.0/orgs/${orgId}/${path}/${id}`))
            );
            return results.map(({ data }) => data);
        } else {
            const { data } = await this.axios.get<T[]>(`/api/1.0/orgs/${orgId}/${path}`);
            return data;
        }
    }

    private getPartnerFormPath(kind: PartnerFormKind): string {
        switch (kind) {
            case PartnerFormKind.SiteSurvey:
                return Topgear.PATH_SITE_SURVEYS;
            case PartnerFormKind.PartOrder:
                return Topgear.PATH_PARTS_ORDERS;
            case PartnerFormKind.OcaReturn:
                return Topgear.PATH_OCA_RETURNS;
            default:
                throw new Error(`Unrecognized partner form kind: ${kind}`);
        }
    }
}

export default new Topgear(auth);
