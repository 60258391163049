import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { AuthService } from "../../../services/auth";
import AdditionalRecipients from "../../../shared/components/AdditionalRecipients";
import AdditionalRecipientsEditor from "../../../shared/components/AdditionalRecipientsEditor";
import AddressEditor from "../../../shared/components/AddressEditor";
import ContactEditor from "../../../shared/components/ContactEditor";
import Contacts from "../../../shared/components/Contacts";
import Divider from "../../../shared/components/Divider";
import MenuItem from "../../../shared/components/MenuItem";
import Notes from "../../../shared/components/Notes";
import NotesEditor from "../../../shared/components/NotesEditor";
import PartnerForm, { Header, Info } from "../../../shared/components/PartnerForm";
import PartnerFormInstance from "../../../shared/components/PartnerForm/PartnerForm";
import PartnerFormActionItems from "../../../shared/components/PartnerFormActionItems";
import SaveAndSubmit from "../../../shared/components/SaveAndSubmit";
import Segment from "../../../shared/components/Segment";
import ShippingInfo from "../../../shared/components/ShippingInfo";
import Tab, { Pane } from "../../../shared/components/Tab";
import TaxpayerFields from "../../../shared/components/TaxpayerFields";
import TaxpayerFieldsEditor from "../../../shared/components/TaxpayerFieldsEditor";
import config from "../../../shared/config";
import { UserContext } from "../../../shared/contexts/UserContext";
import { CLICK_ERROR_ACTION_ITEM } from "../../../shared/events";
import logger from "../../../shared/logger";
import { messages } from "../../../shared/messages";
import { AddressFields, DEFAULT_ADDRESS_VALUES } from "../../../shared/models/AddressFields";
import { PartDefinitionWithFlags } from "../../../shared/models/PartDefinition";
import { PartnerFormKind } from "../../../shared/models/PartnerFormKind";
import { ID_KEY } from "../../../shared/models/Resource";
import { TAXPAYER_FIELDS_KEY, TAXPAYER_FIELD_METADATA_KEY } from "../../../shared/models/TaxpayerAffiliated";
import { ValidationError } from "../../../shared/models/ValidationError";
import {
    AddPartOrderLineItem,
    ChangeAddress,
    ChangeContact,
    ChangeEori,
    ChangePartOrderLineItem,
    ChangeTaxpayerFields,
    GetQuantityLimits,
    GetShippingAddressSuggestions,
    RemovePartOrderLineItem,
    SelectAddress,
    SelectContact,
} from "../../../shared/props/actions";
import { traverse } from "../../../shared/validation";
import { PartnerFormProps } from "../../../siteSurveys/models/PartnerFormProps";
import { getAllowSave, getAllowSubmit, getSaveHint } from "../../../utils/partnerForm";
import { checkIsNew, checkIsSubmitted, getLastUpdated, getSubmittedOn } from "../../../utils/partOrder";
import {
    ADDRESS_KEY,
    CONTACT_KEY,
    PartOrder as PartOrderModel,
    PARTS_ORDER_LINE_ITEMS_KEY,
} from "../../models/PartOrder";
import { PartOrderView, title } from "../../models/PartOrderView";
import PartOrderLineItems from "../PartOrderLineItems";
import PartOrderLineItemsEditor from "../PartOrderLineItemsEditor";
import EoriField from "../../../shared/components/EoriField/EoriField";
import EoriFieldEditor from "../../../shared/components/EoriFieldEditor/EoriFieldEditor";

export interface Props extends RouteComponentProps<Record<string, string>>, PartnerFormProps<PartOrderModel> {
    disallowPsuReplacements: boolean;
    partDefinitions: PartDefinitionWithFlags[];
    fetchShippingAddressSuggestions: GetShippingAddressSuggestions;
    fetchQuantityLimits: GetQuantityLimits;
    onAddPartOrderLineItem: AddPartOrderLineItem;
    onChangePartOrderLineItem: ChangePartOrderLineItem;
    onChangeAddress: ChangeAddress;
    onChangeContact: ChangeContact;
    onChangeTaxpayerFields: ChangeTaxpayerFields;
    onChangeEori: ChangeEori;
    onRemovePartOrderLineItem: RemovePartOrderLineItem;
    onSelectContact: SelectContact;
    onSelectAddress: SelectAddress;
    partOrder: PartOrderModel;
    shippingAddressSuggestions: AddressFields[];
}

class PartOrder extends PureComponent<Props> {
    private partnerForm = React.createRef<PartnerFormInstance>();

    constructor(props: Props) {
        super(props);

        this.handleClickRecoveryAction = this.handleClickRecoveryAction.bind(this);
    }

    componentDidMount() {
        const {
            clearSaveAndSubmitErrors,
            fetchShippingAddressSuggestions,
            fetchQuantityLimits,
            validate,
            partOrder: { enforceQuantityLimits },
        } = this.props;

        clearSaveAndSubmitErrors();
        fetchShippingAddressSuggestions();
        if (enforceQuantityLimits) {
            fetchQuantityLimits();
        }
        validate();
    }

    componentDidUpdate(prevProps: Props) {
        const { forceEditable, reset } = this.props;

        if (forceEditable !== prevProps.forceEditable) {
            if (!forceEditable) {
                // Reset form to its initial state. All unsaved changes are
                // discarded.
                reset();
            }
        }
    }

    protected renderLineItems(): ReactNode {
        const {
            disallowPsuReplacements,
            partOrder,
            modified,
            partDefinitions,
            onAddPartOrderLineItem,
            onRemovePartOrderLineItem,
            onChangePartOrderLineItem,
            errors,
            forceEditable,
        } = this.props;
        const orgId = partOrder.org[ID_KEY];

        return partOrder.editable || forceEditable ? (
            <UserContext.Consumer>
                {(user) => {
                    const openTicketUrl = AuthService.checkIsAdminOrSsoPartner(user)
                        ? `${config.partnerPortalHostname}/org/${orgId}/tickets?open`
                        : undefined;

                    return (
                        <PartOrderLineItemsEditor
                            initialLineItems={partOrder[PARTS_ORDER_LINE_ITEMS_KEY]}
                            lineItems={modified?.[PARTS_ORDER_LINE_ITEMS_KEY]}
                            chooseFrom={partDefinitions}
                            onAdd={onAddPartOrderLineItem}
                            onRemove={onRemovePartOrderLineItem}
                            onChange={onChangePartOrderLineItem}
                            validationErrors={errors[PARTS_ORDER_LINE_ITEMS_KEY]}
                            disallowPsuReplacements={disallowPsuReplacements}
                            openTicketUrl={openTicketUrl}
                        />
                    );
                }}
            </UserContext.Consumer>
        ) : (
            <PartOrderLineItems lineItems={partOrder[PARTS_ORDER_LINE_ITEMS_KEY]} />
        );
    }

    protected renderContact(): ReactNode {
        const {
            contacts,
            modified,
            partOrder,
            onChangeContact,
            onSelectContact,
            errors,
            forceEditable,
            orgId,
        } = this.props;

        return partOrder.editable || forceEditable ? (
            <UserContext.Consumer>
                {(user) => {
                    const isMeechumUser = AuthService.checkIsAdminOrSsoPartner(user);

                    return (
                        <ContactEditor
                            required
                            label={messages.labels.contactType.shipping()}
                            description={messages.notes.contactDescription.partOrder()}
                            orgId={orgId}
                            validationErrors={errors.contact}
                            showRoles={false}
                            chooseFrom={contacts}
                            initialContact={partOrder[CONTACT_KEY]}
                            contact={modified?.[CONTACT_KEY]}
                            isMeechumUser={isMeechumUser}
                            onSelect={onSelectContact}
                            onEdit={onChangeContact}
                        />
                    );
                }}
            </UserContext.Consumer>
        ) : (
            <Contacts
                contacts={{
                    [messages.labels.contactType.shipping()]: partOrder.contact,
                }}
            />
        );
    }

    protected renderTaxpayerId(): ReactNode {
        const { forceEditable, partOrder, modified, onChangeTaxpayerFields, errors } = this.props;

        return partOrder.editable || forceEditable ? (
            <TaxpayerFieldsEditor
                initialTaxpayerFields={partOrder[TAXPAYER_FIELDS_KEY]}
                taxpayerFields={modified?.[TAXPAYER_FIELDS_KEY]}
                taxpayerFieldMetadata={partOrder[TAXPAYER_FIELD_METADATA_KEY]}
                onChange={(taxpayerFields) => onChangeTaxpayerFields(taxpayerFields ?? {})}
                validationErrors={traverse(errors, TAXPAYER_FIELDS_KEY)}
            />
        ) : (
            <TaxpayerFields
                taxpayerFields={partOrder[TAXPAYER_FIELDS_KEY]}
                taxpayerFieldMetadata={partOrder[TAXPAYER_FIELD_METADATA_KEY]}
            />
        );
    }

    protected renderEoriId(): ReactNode {
        const { forceEditable, partOrder, modified, onChangeEori, errors } = this.props;

        return partOrder.editable || forceEditable ? (
            <EoriFieldEditor
                initialEori={partOrder.eori}
                eori={modified?.eori}
                eoriFieldMetadata={partOrder.eoriFieldMetadata}
                onChange={({ eori }) => onChangeEori(eori)}
                validationErrors={errors}
            />
        ) : (
            <EoriField eori={partOrder?.eori} eoriFieldMetadata={partOrder.eoriFieldMetadata} />
        );
    }

    protected renderAddress(): ReactNode {
        const {
            countries,
            subdivisionsByCountryCode,
            shippingAddressSuggestions,
            partOrder,
            modified,
            onChangeAddress,
            errors,
            onSelectAddress,
            forceEditable,
        } = this.props;

        return partOrder.editable || forceEditable ? (
            <AddressEditor
                emptyAddress={DEFAULT_ADDRESS_VALUES}
                initialAddress={partOrder[ADDRESS_KEY]}
                address={modified?.[ADDRESS_KEY]}
                countries={countries}
                subdivisionsByCountryCode={subdivisionsByCountryCode}
                chooseFrom={shippingAddressSuggestions}
                onEdit={onChangeAddress}
                onSelect={onSelectAddress}
                validationErrors={traverse(errors, ADDRESS_KEY)}
            />
        ) : (
            <ShippingInfo shippingAddress={partOrder[ADDRESS_KEY]} />
        );
    }

    protected renderAdditionalRecipients(): ReactNode {
        const {
            partOrder,
            additionalRecipients,
            initialAdditionalRecipients,
            onChangeAdditionalRecipients,
            forceEditable,
        } = this.props;

        return partOrder.editable || forceEditable ? (
            <AdditionalRecipientsEditor
                initialRecipients={initialAdditionalRecipients}
                recipients={additionalRecipients}
                onChange={onChangeAdditionalRecipients}
            />
        ) : (
            <AdditionalRecipients recipients={additionalRecipients} />
        );
    }

    protected renderNotes(): ReactNode {
        const { partOrder, modified, onChangeNotes, forceEditable } = this.props;

        return partOrder.editable || forceEditable ? (
            <NotesEditor initialNotes={partOrder.notes} notes={modified?.notes} onChange={onChangeNotes} />
        ) : (
            <Notes label={messages.labels.notes()} notes={partOrder.notes} />
        );
    }

    render() {
        const {
            partOrder,
            isDirty,
            saveState,
            submitState,
            onSubmit,
            isValid,
            isComplete,
            onSave,
            rolledUpErrors,
            clearSaveAndSubmitErrors,
            forceEditable = false,
            ...props
        } = this.props;
        const isSubmitted = checkIsSubmitted(partOrder);
        const allowSave = getAllowSave(partOrder.editable || forceEditable, isDirty, isValid);
        const allowSubmit = getAllowSubmit(
            partOrder.editable || forceEditable,
            isDirty,
            isComplete,
            isSubmitted,
            allowSave
        );

        return (
            <PartnerForm
                ref={this.partnerForm}
                {...props}
                hasUnsavedChanges={isDirty}
                header={
                    <Header
                        title={partOrder.name}
                        isNew={checkIsNew(partOrder)}
                        editable={partOrder.editable || forceEditable}
                        accessory={() => (
                            <SaveAndSubmit
                                allowSave={allowSave}
                                allowSubmit={allowSubmit}
                                isSubmitted={isSubmitted}
                                saveState={saveState}
                                submitState={submitState}
                                onSubmit={onSubmit}
                                onSave={onSave}
                                saveHint={getSaveHint(PartnerFormKind.PartOrder, saveState, isDirty, allowSave)}
                                onDismiss={clearSaveAndSubmitErrors}
                            />
                        )}
                    />
                }
                info={
                    <Info
                        lastUpdated={getLastUpdated(partOrder)}
                        submittedOn={getSubmittedOn(partOrder)}
                        accessory={{
                            label: messages.labels.toDo(),
                            render: () => (
                                <PartnerFormActionItems
                                    kind={PartnerFormKind.PartOrder}
                                    isComplete={isComplete}
                                    isSubmitted={isSubmitted}
                                    allowSave={allowSave}
                                    allowSubmit={allowSubmit}
                                    errors={rolledUpErrors}
                                    partnerForm={partOrder}
                                    onClickRecoveryAction={this.handleClickRecoveryAction}
                                />
                            ),
                        }}
                    />
                }
            >
                {(view, onChangeView) => <Tab panes={this.panes} activePane={view} onSwitchPane={onChangeView} />}
            </PartnerForm>
        );
    }

    private get panes(): Pane[] {
        const { rolledUpErrors } = this.props;

        return [
            {
                kind: PartOrderView.Parts,
                menuItem: <MenuItem title={title(PartOrderView.Parts)} error={rolledUpErrors[PartOrderView.Parts]} />,
                render: () => <Segment>{this.renderLineItems()}</Segment>,
            },
            {
                kind: PartOrderView.Shipping,
                menuItem: (
                    <MenuItem title={title(PartOrderView.Shipping)} error={rolledUpErrors[PartOrderView.Shipping]} />
                ),
                render: () => (
                    <Segment.Group horizontal>
                        <Segment>
                            <Segment.Group>
                                <Segment>{this.renderEoriId()}</Segment>
                                <Segment>{this.renderTaxpayerId()}</Segment>
                                <Segment>{this.renderAddress()}</Segment>
                            </Segment.Group>
                        </Segment>
                        <Segment>{this.renderContact()}</Segment>
                    </Segment.Group>
                ),
            },
            {
                kind: PartOrderView.More,
                menuItem: <MenuItem title={title(PartOrderView.More)} error={rolledUpErrors[PartOrderView.More]} />,
                render: () => (
                    <Segment.Group>
                        <Segment>{this.renderAdditionalRecipients()}</Segment>
                        <Divider />
                        <Segment>{this.renderNotes()}</Segment>
                    </Segment.Group>
                ),
            },
        ];
    }

    private handleClickRecoveryAction(view: PartOrderView, validationError: ValidationError) {
        logger.trackEvent(CLICK_ERROR_ACTION_ITEM, validationError);
        this.partnerForm.current?.goToView(view);
    }
}

export default PartOrder;
