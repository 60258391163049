import { TopgearHelper } from "../../services/topgear/TopgearHelper";
import { DatacenterOptions } from "../../siteSurveys/models/DatacenterOptions";
import { SiteSurvey } from "../../siteSurveys/models/SiteSurvey";
import {
    SHIPPING_ADDRESS_KEY,
    SiteSurveyAddresses,
    SITE_ADDRESS_KEY,
} from "../../siteSurveys/models/SiteSurveyAddresses";
import {
    ContactKey,
    getContactKeyLabel,
    SiteSurveyContacts,
    SITE_SURVEY_CONTACT_KEYS,
    getRequiredContactKeys,
} from "../../siteSurveys/models/SiteSurveyContacts";
import { SiteSurveyProperty } from "../../siteSurveys/models/SiteSurveyProperty";
import { checkIsEditable } from "../../utils/siteSurvey";
import { messages } from "../messages";
import { ContactFields } from "../models/ContactFields";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { SHIPPING_ADDRESS_KEYS } from "../models/ShippingAddressFields";
import { TAXPAYER_FIELDS_KEY, TAXPAYER_FIELD_METADATA_KEY } from "../models/TaxpayerAffiliated";
import { createValidationError, Level } from "../models/ValidationError";
import { clean } from "../validation";
import { validateAddress } from "../validation/address";
import { validateBgpConfig } from "../validation/bgpConfig";
import { validateContact } from "../validation/contact";
import { validateDatacenterInfo } from "../validation/datacenter";
import { validateNetworkConfigs } from "../validation/networkConfig";
import { validateTaxpayerFields } from "./taxpayerFields";
import { validateCfdiFields } from "./cfdiFields";
import { CFDI_FIELDS_KEY, CFDI_FIELD_METADATA_KEY } from "../models/CfdiCompliant";
import { EORI_FIELD_KEY, EORI_FIELD_METADATA_KEY } from "../models/EoriCompliant";
import { validateEoriField } from "./eori";

const CONTACT_KEYS: Set<ContactKey> = new Set(SITE_SURVEY_CONTACT_KEYS);

export function validateSiteSurveyContacts(
    contacts: SiteSurveyContacts,
    countryCode: string
): ModelValidationErrors<SiteSurveyContacts> | undefined {
    const errors: ModelValidationErrors<SiteSurveyContacts> = {};
    const requiredContactKeys = getRequiredContactKeys(countryCode);

    CONTACT_KEYS.forEach((key) => {
        const contact = contacts[key];

        if (!contact || TopgearHelper.isContactEmpty(contact)) {
            // If entire contact is missing or if all the user editable fields
            // are empty, create a root-level error if it's a required contact.
            if (requiredContactKeys.has(key)) {
                errors[key] = createValidationError(
                    Level.Incomplete,
                    messages.forms.validation.missingContact({
                        CONTACT_ROLE: getContactKeyLabel(key),
                    })
                );
            }
        } else {
            // If a contact is present and it's a required role or some fields
            // are non-empty, validate individual fields.
            if (requiredContactKeys.has(key) || !TopgearHelper.isContactEmpty(contact)) {
                errors[key] = validateContact(contact, {
                    // For NOC contacts, we hide the first & last name fields,
                    // so there is no need to validate. We will be attaching a
                    // dummy first & last name before sending to API instead.
                    ignoreFields:
                        key === ContactKey.NOC ? new Set<keyof ContactFields>(["nameFirst", "nameLast"]) : undefined,
                });
            }
        }
    });

    return clean(errors);
}

/**
 *
 * @param fixedCountryCode If provided, the country will be assumed for purposes
 * of validating other fields (such as requiring state selection for US), but
 * the country field itself will not be validated. Applies to site address only.
 */
export function validateSiteSurveyAddresses(
    { shippingAddress, siteAddress }: SiteSurveyAddresses,
    shouldValidateShippingAddress: boolean,
    shouldValidateSiteAddress: boolean,
    fixedCountryCode?: string
): ModelValidationErrors<SiteSurveyAddresses> | undefined {
    const errors: ModelValidationErrors<SiteSurveyAddresses> = {};

    // Validate site address
    if (shouldValidateSiteAddress) {
        if (!siteAddress || TopgearHelper.isAddressEmpty(siteAddress)) {
            errors[SITE_ADDRESS_KEY] = createValidationError(
                Level.Incomplete,
                messages.forms.validation.missingSiteAddress()
            );
        } else {
            errors[SITE_ADDRESS_KEY] = validateAddress(siteAddress, undefined, fixedCountryCode);
        }
    }

    // Validate shipping
    if (shouldValidateShippingAddress) {
        if (!shippingAddress || TopgearHelper.isAddressEmpty(shippingAddress, SHIPPING_ADDRESS_KEYS)) {
            errors[SHIPPING_ADDRESS_KEY] = createValidationError(
                Level.Incomplete,
                messages.forms.validation.missingShippingAddress()
            );
        } else {
            errors[SHIPPING_ADDRESS_KEY] = validateAddress(shippingAddress);
        }
    }

    return clean(errors);
}

export function validateSiteSurvey(
    siteSurvey: SiteSurvey,
    datacenterOptions?: DatacenterOptions,
    forceEditable = false
): ModelValidationErrors<SiteSurvey> {
    if (!checkIsEditable({ siteSurvey, forceEditable })) {
        return {};
    }

    const shouldValidateShippingAddress = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.ShippingAddress,
        forceEditable,
    });
    const shouldValidateSiteAddress = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.SiteAddress,
        forceEditable,
    });
    const shouldValidateTaxpayerFields = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.Taxpayer,
        forceEditable,
    });
    const shouldValidateCfdiFields = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.Cfdi,
        forceEditable,
    });
    const shouldValidatePartDefinitions = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.PartDefinitions,
        forceEditable,
    });
    const shouldValidateContacts = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.Contacts,
        forceEditable,
    });
    const shouldValidateNetworkConfigs = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.NetworkConfigs,
        forceEditable,
    });
    const shouldValidateEori = checkIsEditable({
        siteSurvey,
        key: SiteSurveyProperty.Eori,
        forceEditable,
    });

    return {
        ...validateSiteSurveyAddresses(
            siteSurvey,
            shouldValidateShippingAddress,
            shouldValidateSiteAddress,
            siteSurvey.countryCode
        ),
        ...(shouldValidatePartDefinitions ? validateDatacenterInfo(siteSurvey, datacenterOptions) : undefined),
        ...(shouldValidateContacts ? validateSiteSurveyContacts(siteSurvey, siteSurvey.countryCode) : undefined),
        ...(shouldValidateNetworkConfigs ? validateBgpConfig(siteSurvey) : undefined),
        [TAXPAYER_FIELDS_KEY]: shouldValidateTaxpayerFields
            ? validateTaxpayerFields(siteSurvey[TAXPAYER_FIELDS_KEY], siteSurvey[TAXPAYER_FIELD_METADATA_KEY])
            : undefined,
        [CFDI_FIELDS_KEY]: shouldValidateCfdiFields
            ? validateCfdiFields(siteSurvey[CFDI_FIELDS_KEY], siteSurvey[CFDI_FIELD_METADATA_KEY])
            : undefined,
        networkConfigs: shouldValidateNetworkConfigs ? validateNetworkConfigs(siteSurvey.networkConfigs) : undefined,
        ...(shouldValidateEori
            ? validateEoriField(siteSurvey[EORI_FIELD_KEY], siteSurvey[EORI_FIELD_METADATA_KEY])
            : undefined),
    };
}
