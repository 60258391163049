import React, { PureComponent, ReactNode } from "react";
import Badge from "../Badge";
import Header from "../Header";
import Icon from "../Icon";
import { RenderProp } from "../../props/render";
import BadgeGroup from "../Badge/BadgeGroup";
import { messages } from "../../messages";

interface Props {
    title: string;
    /**
     * When true, applies a badge to indicate that the Partner Form is new.
     */
    isNew?: boolean;
    /**
     * When false, applies a badge to indicate that the Partner Form is no
     * longer editable.
     */
    editable?: boolean;
    /**
     * Any additional badge(s) can be included.
     */
    badges?: ReactNode;
    accessory?: RenderProp;
}

class PartnerFormHeader extends PureComponent<Props> {
    render() {
        const { accessory, title, isNew = false, editable = false, badges } = this.props;

        return (
            <div className="PartnerFormHeader">
                <div className="PartnerFormHeader__left">
                    <BadgeGroup>
                        {isNew ? (
                            <Badge kind="attention" title={messages.notice.new()}>
                                {messages.meta.new()}
                            </Badge>
                        ) : null}
                        {editable ? null : (
                            <Badge kind="subdued" title={messages.notice.locked()}>
                                <Icon icon="lock" fixedWidth />
                            </Badge>
                        )}
                        {badges}
                    </BadgeGroup>
                    <Header fluid className="PartnerFormHeader__title" as="h2" title={title} />
                </div>
                {accessory ? <div className="PartnerFormHeader__accessory">{accessory()}</div> : null}
            </div>
        );
    }
}

export default PartnerFormHeader;
