import React, { ChangeEvent } from "react";
import Dropdown, { Props as DropdownProps } from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormField, { Props as FormFieldProps } from "../../../shared/components/FormField";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { DayOfWeek } from "../../../shared/models/DayOfWeek";
import { TimeOfDay } from "../../../shared/models/TimeOfDay";
import { FormPropsV2 } from "../../../shared/props/forms";
import { dayOfWeekAsOption, timeOfDayAsOption } from "../../../utils/options";
import {
    OcaReturnPickupPreferences,
    PREFERRED_DAY_OF_WEEK_KEY,
    PREFERRED_TIME_OF_DAY_KEY,
} from "../../models/OcaReturnPickupPreferences";

export interface Props extends FormPropsV2<OcaReturnPickupPreferences> {}

const DAY_OF_WEEK_OPTIONS = Object.values(DayOfWeek).map(dayOfWeekAsOption);
const TIME_OF_DAY_OPTIONS = Object.values(TimeOfDay).map(timeOfDayAsOption);

function OcaReturnPickupPropertiesForm(props: Props) {
    const { disabled } = props;
    const [{ dirty, errors, values }, { changeHandlers, touchHandlers }] = useForm(
        { keys: [PREFERRED_DAY_OF_WEEK_KEY, PREFERRED_TIME_OF_DAY_KEY] },
        props
    );

    function getFormFieldProps<K extends keyof OcaReturnPickupPreferences>(
        key: K
    ): Pick<FormFieldProps, "name" | "horizontal" | "required" | "error"> {
        return {
            name: key,
            horizontal: true,
            required: true,
            error: errors.get(key),
        };
    }

    function getDropdownProps<K extends keyof OcaReturnPickupPreferences>(
        key: K
    ): Pick<DropdownProps, "disabled" | "placeholder" | "value" | "error" | "dirty" | "onChange" | "onBlur"> {
        return {
            disabled,
            placeholder: messages.forms.placeholders.selectGeneric(),
            value: values[key],
            error: errors.has(key),
            dirty: dirty.has(key),
            onChange: (evt: ChangeEvent<HTMLSelectElement>) => changeHandlers[key]?.(evt.target.value as any),
            onBlur: () => touchHandlers[key]?.(),
        };
    }

    return (
        <Form>
            <FormField
                {...getFormFieldProps(PREFERRED_DAY_OF_WEEK_KEY)}
                label={messages.labels.ocaReturn.preferredDay()}
            >
                <Dropdown {...getDropdownProps(PREFERRED_DAY_OF_WEEK_KEY)} options={DAY_OF_WEEK_OPTIONS} />
            </FormField>
            <FormField
                {...getFormFieldProps(PREFERRED_TIME_OF_DAY_KEY)}
                label={messages.labels.ocaReturn.preferredTime()}
            >
                <Dropdown
                    {...getDropdownProps(PREFERRED_TIME_OF_DAY_KEY)}
                    placeholder={messages.forms.placeholders.selectGeneric()}
                    options={TIME_OF_DAY_OPTIONS}
                />
            </FormField>
        </Form>
    );
}

export default OcaReturnPickupPropertiesForm;
