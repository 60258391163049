import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { AnyAction, Reducer } from "redux";
import { initAction } from "../../shared/actions";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import { Contact } from "../../shared/models/Contact";
import { ContactFields } from "../../shared/models/ContactFields";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { Notes } from "../../shared/models/Notes";
import { ID_KEY } from "../../shared/models/Resource";
import { createOcaReturnPickupPreferencesChanges, createOcaReturnPickupPropertiesChanges } from "../../utils/ocaReturn";
import {
    createAdditionalRecipientsChanges,
    createAddressChanges,
    createAddressSelectionChanges,
    createContactChanges,
    createContactSelectionChanges,
    createNotesChanges,
} from "../../utils/partnerForm";
import { createApiRequestSlice } from "../../utils/reducers";
import { ADDRESS_KEY, CONTACT_KEY, OcaReturn } from "../models/OcaReturn";
import { OcaReturnFetchParameters } from "../models/OcaReturnFetchParameters";
import { OcaReturnPickupPreferences } from "../models/OcaReturnPickupPreferences";
import { OcaReturnPickupProperties } from "../models/OcaReturnPickupProperties";
import { OcaReturnReference } from "../models/OcaReturnReference";
import { OcaReturnResult } from "../models/OcaReturnResult";
import { OcaReturnResults } from "../models/OcaReturnResults";
import { OcaReturnsRootState, OcaReturnsState } from "../models/OcaReturnsState";

const NAMESPACE = "ocaReturns";

// Nested slices
const fetchSlice = createApiRequestSlice<OcaReturnFetchParameters, OcaReturnResults, OcaReturnFetchParameters>(
    `${NAMESPACE}/fetch`
);
const saveSlice = createApiRequestSlice<OcaReturnReference, OcaReturnResult, OcaReturnReference>(`${NAMESPACE}/save`);
const submitSlice = createApiRequestSlice<OcaReturnReference, OcaReturnResult, OcaReturnReference>(
    `${NAMESPACE}/submit`
);

// RootSlice
function initialRootState(): OcaReturnsRootState {
    return {
        byId: undefined,
        edits: {},
        ui: {},
        validations: {},
    };
}

const rootSlice = createSlice({
    name: NAMESPACE,
    initialState: initialRootState(),
    reducers: {
        reset: (state, action: PayloadAction<OcaReturnReference>) => {
            const id = action.payload.ocaReturnId;
            delete state.edits[id];
            delete state.validations[id];
        },
        changePickupProperties: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    values: Partial<OcaReturnPickupProperties>;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createOcaReturnPickupPropertiesChanges(action.payload.values),
            ];
        },
        changePickupPreferences: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    values: Partial<OcaReturnPickupPreferences>;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createOcaReturnPickupPreferencesChanges(action.payload.values),
            ];
        },
        changeAddress: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    values: Partial<AddressFields>;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createAddressChanges(action.payload.values, ADDRESS_KEY)];
        },
        selectAddress: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    address?: Address | AddressFields;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAddressSelectionChanges(action.payload.address, ADDRESS_KEY),
            ];
        },
        changeContact: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    values: Partial<ContactFields>;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createContactChanges(action.payload.values, CONTACT_KEY)];
        },
        selectContact: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    contact?: ContactFields | Contact;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createContactSelectionChanges(action.payload.contact, CONTACT_KEY),
            ];
        },
        changeNotes: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    values: Partial<Notes>;
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createNotesChanges(action.payload.values)];
        },
        changeAdditionalRecipients: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    recipients: string[];
                }
            >
        ) => {
            const id = action.payload.ocaReturnId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAdditionalRecipientsChanges(action.payload.recipients),
            ];
        },
        // Validation
        doValidate: (state, _action: PayloadAction<OcaReturnReference>) => state,
        didValidate: (
            state,
            action: PayloadAction<
                OcaReturnReference & {
                    errors: ModelValidationErrors<OcaReturn>;
                }
            >
        ) => {
            state.validations[action.payload.ocaReturnId] = action.payload.errors;
        },
    },
    extraReducers: (builder) => {
        function storeResult(state: OcaReturnsRootState, action: PayloadAction<OcaReturnResult>) {
            const id = action.payload.ocaReturn[ID_KEY];
            state.byId = {
                ...state.byId,
                [id]: action.payload.ocaReturn,
            };
            state.edits = {
                ...state.edits,
                [id]: [],
            };
        }

        // Fetch
        builder.addCase(fetchSlice.actions.did, (state, action) => {
            state.byId = keyBy(action.payload.ocaReturns, "id");
        });

        // Save
        builder.addCase(saveSlice.actions.did, storeResult);

        // Submit
        builder.addCase(submitSlice.actions.did, storeResult);
    },
});

// Combine root and nested reducers
function initialState(): OcaReturnsState {
    return {
        ...rootSlice.reducer(undefined, initAction),
        fetch: fetchSlice.reducer(undefined, initAction),
        save: saveSlice.reducer(undefined, initAction),
        submit: submitSlice.reducer(undefined, initAction),
    };
}

const ocaReturnsReducer: Reducer<OcaReturnsState, AnyAction> = (state = initialState(), action) => {
    return {
        ...rootSlice.reducer(state, action),
        fetch: fetchSlice.reducer(state.fetch, action),
        save: saveSlice.reducer(state.save, action),
        submit: submitSlice.reducer(state.submit, action),
    };
};

// Combine root and nested actions
const ocaReturnsActions = {
    ...rootSlice.actions,
    fetch: fetchSlice.actions,
    save: saveSlice.actions,
    submit: submitSlice.actions,
};

export { ocaReturnsReducer, ocaReturnsActions };
