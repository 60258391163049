import React, { memo } from "react";
import { Contact } from "../../models/Contact";
import { ContactFields } from "../../models/ContactFields";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { ValidationError } from "../../models/ValidationError";
import ChooseOrCreateContact from "../ChooseOrCreateContact";

interface Props {
    description?: string;
    validationErrors: ModelValidationErrors<ContactFields> | ValidationError | undefined;
    orgId: number;
    initialContact?: ContactFields;
    contact?: ContactFields;
    chooseFrom?: ContactFields[];
    combineFirstAndLast?: boolean;
    isMeechumUser?: boolean;
    onSelect: (contact: Contact | ContactFields | undefined) => void;
    onEdit: (contact: Partial<ContactFields>) => void;
    label?: string;
    showRoles?: boolean;
    required?: boolean;
}

function ContactEditor({
    description,
    validationErrors,
    orgId,
    initialContact,
    contact,
    chooseFrom = [],
    onSelect,
    onEdit,
    combineFirstAndLast,
    label,
    showRoles = true,
    required,
    isMeechumUser,
}: Props) {
    return (
        <ChooseOrCreateContact
            required={required}
            chooseFrom={chooseFrom}
            combineFirstAndLast={combineFirstAndLast}
            initialContact={initialContact}
            contact={contact}
            description={description}
            label={label}
            orgId={orgId}
            isMeechumUser={isMeechumUser}
            onEdit={onEdit}
            onSelect={onSelect}
            showRoles={showRoles}
            validationErrors={validationErrors}
        />
    );
}

export default memo(ContactEditor);
