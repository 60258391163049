import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

interface Props<T> {
    items: readonly T[];
    index?: number;
    onSetIndex?: (index: number) => void;
    children: (item: T, index: number, onSetIndex: (index: number) => void, label?: string) => ReactNode;
    renderEmpty?: () => ReactNode;
}

/**
 * A component that does not itself render anything. It maintains state so you
 * don't have to when working with an item from a collection of items. It also
 * supports being a "controlled" component via `index` and `onSetIndex` props,
 * if desired. Expects a render function as a child. Optionally provide an empty
 * prop to render something when there are no items. As a consumer, you never
 * need to deal with the case where index is out of bounds.
 */
function Collection<T>({ items, children, renderEmpty, onSetIndex, index: indexFromProps }: Props<T>) {
    const [index, setIndex] = useState(0);

    const handleSetActiveIndex = useCallback(
        (nextIndex: number) => {
            if (onSetIndex) {
                onSetIndex(nextIndex);
            } else {
                setIndex(nextIndex);
            }
        },
        [onSetIndex, setIndex]
    );

    const boundedIndexFromProps = useMemo(
        () => (indexFromProps === undefined ? undefined : Math.max(0, Math.min(items.length - 1, indexFromProps))),
        [items, indexFromProps]
    );

    // If controlled, update state to match index from props.
    useEffect(() => {
        if (boundedIndexFromProps !== undefined && boundedIndexFromProps !== index) {
            setIndex(boundedIndexFromProps);
        }
    }, [index, setIndex, boundedIndexFromProps]);

    if (items.length === 0) {
        // Fragment workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
        return <>{renderEmpty?.() ?? null}</>;
    }

    // Fragment workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
    return (
        <>
            {children(
                items[index],
                index,
                handleSetActiveIndex,
                items.length > 1 ? `(${index + 1}/${items.length})` : undefined // e.g: "(1/3)"
            )}
        </>
    );
}

export default Collection;
