import { Location } from "history";
import Mousetrap from "mousetrap";
import React, { Component, ReactNode } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import PartnerFormContainer from "../../../shared/components/PartnerFormContainer";
import PartnerFormContent from "../../../shared/components/PartnerFormContent";
import PartnerFormHeader from "../../../shared/components/PartnerFormHeader";
import PartnerFormInfo from "../../../shared/components/PartnerFormInfo";
import PartnerFormPlaceholder from "../../../shared/components/PartnerFormPlaceholder";
import PartnerFormPresenter from "../../../shared/components/PartnerFormPresenter";
import Segment from "../../../shared/components/Segment";
import { PARTNER_FORM_SAVE } from "../../../shared/hotkeys";
import { messages } from "../../../shared/messages";
import { QUERY_PARTNER_FORM_VIEW } from "../../../shared/routes/query";
import { getSearchValue, setSearchValue } from "../../../utils/url";
import Unsaved from "../Unsaved";

export interface Props extends RouteComponentProps<any> {
    hasUnsavedChanges?: boolean;
    header?: ReactNode;
    info?: ReactNode;
    children?: (view: string | undefined, onChangeView: (view: string) => void) => ReactNode;
    onSave?: () => void;
}

/**
 * The skeleton of a partner form. It is used mainly for structure/styling. Has
 * the following features built-in:
 *
 * - Prompts the user about "unsaved changes" when navigating away
 * - Handles persisting a query param to the URL to render specific views
 * - Use `goToView` to manually trigger a view change
 * - Binds shortcut keys (cmd+S, ctrl+S) to `onSave` prop
 */
class PartnerForm extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleNavigateAway = this.handleNavigateAway.bind(this);
    }

    componentDidMount() {
        Mousetrap.bind(PARTNER_FORM_SAVE, () => {
            const { onSave } = this.props;
            if (onSave) {
                onSave();
            }
            return false;
        });
    }

    render() {
        const { hasUnsavedChanges, header, info, children, location } = this.props;
        const view = getSearchValue(location.search, QUERY_PARTNER_FORM_VIEW);

        return (
            <div className="PartnerForm">
                <Unsaved isUnsaved={hasUnsavedChanges}>
                    <Prompt when={hasUnsavedChanges} message={this.handleNavigateAway} />
                    <Segment className="PartnerForm-head">
                        {header}
                        {info}
                    </Segment>
                    <Segment className="PartnerForm-content">
                        {children && children(view, this.handleChangeView)}
                    </Segment>
                </Unsaved>
            </div>
        );
    }

    public goToView(view: string) {
        this.handleChangeView(view);
    }

    private handleChangeView(view: string) {
        const { history, location } = this.props;
        history.push({
            search: setSearchValue(location.search, QUERY_PARTNER_FORM_VIEW, view)
        });
    }

    private handleNavigateAway(nextLocation: Location) {
        const { location } = this.props;

        if (!location || location.pathname === nextLocation.pathname) {
            return true;
        }

        return messages.notice.unsavedChanges();
    }
}

export default PartnerForm;
export {
    PartnerFormContainer as Container,
    PartnerFormPresenter as Presenter,
    PartnerFormPlaceholder as Placeholder,
    PartnerFormHeader as Header,
    PartnerFormInfo as Info,
    PartnerFormContent as Content
};
