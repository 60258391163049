import { messages, getMessage } from "../../shared/messages";

export enum OcaReturnView {
    Pickup = "pickup",
    More = "more"
}

export function title(view: OcaReturnView): string {
    return getMessage(() => messages.title.ocaReturnView[view](null), view);
}
