import { validate } from ".";
import { all, regex, required } from "../forms/validators";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { FieldMetadata } from "../models/FieldMetadata";
import { Validator } from "../models/Validator";

const makeTaxpayerFieldsValidator = (fieldMetadataList: FieldMetadata[]): ModelValidator<Record<string, string>> =>
    new Map([
        ...fieldMetadataList.map((fieldMetadata): [string, Validator] => [
            fieldMetadata.name,
            all(
                fieldMetadata.required ? required : null,
                fieldMetadata.pattern ? regex(new RegExp(fieldMetadata.pattern)) : null
            ),
        ]),
    ]);

export function validateTaxpayerFields(
    taxpayerFields: Record<string, string>,
    taxpayerFieldMetadata: FieldMetadata[]
): ModelValidationErrors<Record<string, string>> | undefined {
    return validate(taxpayerFields, makeTaxpayerFieldsValidator(taxpayerFieldMetadata));
}
