import React, { memo, useMemo } from "react";
import { useForm } from "../../forms/hooks/useForm";
import { email } from "../../forms/validators";
import { messages } from "../../messages";
import { EmailEntryFields, EMAIL_KEY } from "../../models/EmailEntryFields";
import { FormPropsV2 } from "../../props/forms";
import Button from "../Button";
import Form from "../Form";
import FormField from "../FormField";
import TextInput from "../TextInput";

export interface Props extends FormPropsV2<EmailEntryFields> {
    label?: string;
    notes?: string;
}

function EmailEntryForm(props: Props) {
    const { compact, label, notes, isSubmitting, onSubmit, submitButtonText, validationErrors } = props;
    const error = useMemo(() => email(props.values?.[EMAIL_KEY]), [props.values]);
    const [{ valid, values, pristine, dirty, errors }, { handleSubmit, touchHandlers, changeHandlers }] = useForm(
        { keys: [EMAIL_KEY] },
        {
            ...props,
            // If no validation errors provided via props, fallback to the basic
            // email address validation.
            validationErrors: validationErrors ?? {
                email: error,
            },
        }
    );

    return (
        <Form className="EmailEntryForm" onSubmit={handleSubmit} compact={compact} noValidate>
            <FormField
                name="email"
                label={label}
                notes={notes}
                accessory={
                    onSubmit && (
                        <Button disabled={pristine || !valid || isSubmitting} type="submit">
                            {submitButtonText || messages.actions.create()}
                        </Button>
                    )
                }
                error={errors.get(EMAIL_KEY)}
            >
                <TextInput
                    dirty={dirty.has(EMAIL_KEY)}
                    error={errors.has(EMAIL_KEY)}
                    type="email"
                    placeholder={messages.forms.placeholders.email()}
                    value={values[EMAIL_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[EMAIL_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[EMAIL_KEY]?.()}
                />
            </FormField>
        </Form>
    );
}

export default memo(EmailEntryForm);
