/**
 * Misc UI meta state.
 */
export interface SiteSurveyUiState {
    activeNetworkConfigIndex: number;
    didAutoPickPowerCable: boolean;
}

export const DEFAULT_SITE_SURVEY_UI_STATE = Object.freeze<SiteSurveyUiState>({
    activeNetworkConfigIndex: 0,
    didAutoPickPowerCable: false
});
