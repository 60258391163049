import classnames from "classnames";
import React, { ReactNode } from "react";
import RoundedLine from "../Placeholder/RoundedLine";

interface Props {
    className?: string;
    children?: ReactNode;
}

function Placeholder({ className, children }: Props) {
    return <div className={classnames("Placeholder", className)}>{children}</div>;
}

Placeholder.RoundedLine = RoundedLine;

export default Placeholder;
