import { ComponentType } from "../../partOrders/models/ComponentType";
import { Audited } from "./Audited";
import { CurrentType } from "./CurrentType";
import { Resource } from "./Resource";
import { Versioned } from "./Versioned";

export const TWO_POST_RAIL_KIT_CODE = "2 Post";
export const FOUR_POST_RAIL_KIT_CODE = "4 Post";

export type PartDefinitionCode = string;

export interface PartDefinition extends Resource, Audited, Versioned {
    code: PartDefinitionCode;
    name: string;
    componentType: ComponentType;
    currentType?: CurrentType;
    /**
     * Whether the part is no longer being allocated.
     */
    retired: boolean;
}

/**
 * `PartDefinition` with additional UI-only flags.
 */
export interface PartDefinitionWithFlags extends PartDefinition {
    /**
     * Whether the part is disallowed for the given order. This attribute is
     * distinct from `retired` in that it is not a global - a part can be
     * restricted for one order but not another.
     */
    restricted?: boolean;
}
