import { toInteger } from "lodash";
import memoizeOne from "memoize-one";
import React, { ChangeEvent, Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../../shared/components/Button";
import Container from "../../../shared/components/Container";
import Dropdown from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormActions from "../../../shared/components/Form/FormActions";
import FormField from "../../../shared/components/FormField";
import Header from "../../../shared/components/Header";
import Loading from "../../../shared/components/Loading";
import Message from "../../../shared/components/Message";
import Spacer from "../../../shared/components/Spacer";
import { messages } from "../../../shared/messages";
import { Option } from "../../../shared/models/Option";
import { OrgSummary } from "../../../shared/models/OrgSummary";
import { ID_KEY } from "../../../shared/models/Resource";
import { QUERY_ORG_ID } from "../../../shared/routes";
import { getSearchValue, setSearchValue } from "../../../utils/url";

export interface Props extends RouteComponentProps<Record<string, string>> {
    fetchOrgs: () => void;
    orgs?: OrgSummary[];
    error?: string | Error | null;
}

interface State {
    selectedOrgId?: number;
}

class OrgPicker extends Component<Props, State> {
    private static getOrgOptions = memoizeOne((orgs?: OrgSummary[]): Option[] => {
        return (orgs || [])
            .map((org) => ({
                value: org.id,
                text: org.nameLong.trim(),
            }))
            .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
    });

    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {};
    }

    async componentDidMount() {
        const { location, fetchOrgs } = this.props;

        const orgId = getSearchValue(location.search, QUERY_ORG_ID);
        if (orgId !== undefined) {
            this.goToOrg(toInteger(orgId));
            return;
        }

        fetchOrgs();
    }

    componentDidUpdate(prevProps: Props) {
        const { orgs } = this.props;

        if (prevProps.orgs !== orgs && orgs) {
            if (orgs.length === 1) {
                this.goToOrg(orgs[0][ID_KEY]);
            }
        }
    }

    render() {
        const { orgs, error } = this.props;
        const { selectedOrgId } = this.state;

        if (!orgs) {
            return (
                <div className="OrgPicker">
                    <div className="OrgPicker__loading">
                        <Loading size="lg" />
                    </div>
                </div>
            );
        }

        return (
            <Container className="OrgPicker" fluid>
                <div className="OrgPicker__container">
                    <Header as="h2" title={messages.title.orgPicker()} textAlign="center" justifyContent="center" />

                    <Form className="OrgPicker__form" onSubmit={this.handleSubmit}>
                        <FormField>
                            <Dropdown
                                placeholder={messages.forms.placeholders.selectOrg()}
                                disabled={!orgs}
                                value={selectedOrgId}
                                options={OrgPicker.getOrgOptions(orgs)}
                                onChange={this.handleChange}
                            />
                        </FormField>
                        <FormActions>
                            <Button disabled={selectedOrgId === undefined} type="submit" kind="action">
                                {messages.actions.submit()}
                            </Button>
                        </FormActions>
                        {error && (
                            <>
                                <Spacer size="sm" />
                                <Message status="error" textAlign="center" message={error} asBlock={false} />
                            </>
                        )}
                    </Form>
                </div>
            </Container>
        );
    }

    private goToOrg(orgId: number) {
        const { location, history } = this.props;
        history.push({
            search: setSearchValue(location.search, QUERY_ORG_ID, orgId.toString()),
        });
    }

    private handleSubmit(evt: FormEvent<HTMLFormElement>) {
        const { selectedOrgId } = this.state;

        evt.preventDefault();

        if (selectedOrgId === undefined) {
            return;
        }

        this.goToOrg(selectedOrgId);
    }

    private handleChange(evt: ChangeEvent<HTMLSelectElement>) {
        this.setState({
            selectedOrgId: toInteger(evt.target.value),
        });
    }
}

export default OrgPicker;
