import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "history";
import { combineReducers, Reducer } from "redux";
import { authReducer } from "../../auth/reducer";
import { ocaReturnsReducer } from "../../ocaReturns/reducer";
import { partOrdersReducer } from "../../partOrders/reducer";
import { initAction } from "../../shared/actions";
import { Country } from "../../shared/models/Country";
import { CountrySubdivision } from "../../shared/models/CountrySubdivision";
import { Org } from "../../shared/models/Org";
import { OrgSummary } from "../../shared/models/OrgSummary";
import { siteSurveysReducer } from "../../siteSurveys/reducer";
import { createApiRequestSlice } from "../../utils/reducers";
import { AppState } from "../models/AppState";

const NAMESPACE = "app";

// Global actions
const resetAction = createAction(`${NAMESPACE}/reset`);
const resetForNextOrgAction = createAction(`${NAMESPACE}/resetForNextOrg`);
const rerouteAction = createAction<Location>(`${NAMESPACE}/reroute`);

// Nested slices
const fetchActiveOrgSlice = createApiRequestSlice<number, Org | undefined>(`${NAMESPACE}/fetchActiveOrg`, undefined);
const fetchOrgsSlice = createApiRequestSlice<undefined, OrgSummary[] | undefined>(`${NAMESPACE}/fetchOrgs`, undefined);
const fetchCountriesSlice = createApiRequestSlice<undefined, Country[]>(`${NAMESPACE}/fetchCountries`, []);
const fetchCountrySubdivisionsSlice = createApiRequestSlice<undefined, Map<string, CountrySubdivision[]>>(
    `${NAMESPACE}/fetchCountrySubdivisions`,
    new Map()
);

const childReducers = combineReducers<AppState>({
    auth: authReducer,
    siteSurveys: siteSurveysReducer,
    partOrders: partOrdersReducer,
    ocaReturns: ocaReturnsReducer,
    activeOrg: fetchActiveOrgSlice.reducer,
    orgs: fetchOrgsSlice.reducer,
    countries: fetchCountriesSlice.reducer,
    subdivisionsByCountryCode: fetchCountrySubdivisionsSlice.reducer,
});

const globalReducer = createReducer(childReducers(undefined, initAction), (builder) => {
    builder.addCase(resetAction, (_state, action) => childReducers(undefined, action));
    builder.addCase(rerouteAction, (state, _action: PayloadAction<Location>) => state);
    builder.addCase(resetForNextOrgAction, (state, action) => {
        state.activeOrg = fetchActiveOrgSlice.reducer(undefined, action);
        state.siteSurveys.byId = undefined;
        state.partOrders.byId = undefined;
        state.ocaReturns.byId = undefined;
    });
});

const appReducer: Reducer<AppState> = (state, action): AppState => {
    return childReducers(globalReducer(state, action), action);
};

const appActions = {
    reset: resetAction,
    resetForNextOrg: resetForNextOrgAction,
    cleanUrl: rerouteAction,
    fetchActiveOrg: fetchActiveOrgSlice.actions,
    fetchOrgs: fetchOrgsSlice.actions,
    fetchCountrySubdivisions: fetchCountrySubdivisionsSlice.actions,
    fetchCountries: fetchCountriesSlice.actions,
};

export { appReducer, appActions };
