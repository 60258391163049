import React, { ChangeEvent, memo, ReactNode, useCallback, useState } from "react";
import Address from "../../../shared/components/Address";
import { addressAsOption, getAddressIdentifier } from "../../../utils/options";
import { COPY_SITE_ADDRESS } from "../../events";
import logger from "../../logger";
import { messages } from "../../messages";
import { Address as AddressModel } from "../../models/Address";
import { AddressFields, ADDRESS_KEYS, COUNTRY_KEY, getLabel } from "../../models/AddressFields";
import { Country } from "../../models/Country";
import { CountrySubdivision } from "../../models/CountrySubdivision";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { ValidationError } from "../../models/ValidationError";
import AddressForm, { Props as AddressFormProps } from "../AddressForm";
import Button from "../Button";
import Dropdown from "../Dropdown";
import FormField from "../FormField";
import Header from "../Header";
import Icon from "../Icon";
import Link from "../Link";
import Spacer from "../Spacer";

enum VisibleAddressType {
    Editable = "EDITABLE",
    ReadOnly = "READ_ONLY",
    None = "NONE",
}

export interface Props<T extends AddressFields = AddressFields> {
    title?: string;
    /**
     * emptyAddress is sent to onSelect handler when a user clicks the "new"
     * button to reflect the fact that the address has changed to this default
     */
    emptyAddress: T;
    initialAddress?: T;
    address?: T;
    /**
     * If address options are provided, the editor becomes a choose-or-create
     * component. Allowing either the selection of an existing address, or
     * entering a new address using an input form.
     */
    chooseFrom?: AddressFields[];
    countries: Country[];
    subdivisionsByCountryCode: Map<string, CountrySubdivision[]>;
    quickAddress?: {
        /**
         * Whether the quick address action link should be enabled. If `false`,
         * the link will have pointer events disabled. Defaults to `true`.
         */
        enabled?: boolean;
        label: string;
        value: AddressFields;
    };
    onEdit: (address: Partial<T>) => void;
    onSelect: (address: AddressFields | AddressModel) => void;
    validationErrors?: ValidationError | ModelValidationErrors<T>;
    /**
     * Allows rendering a form other than the default `<AddressForm/>`. This is
     * used by site surveys to render a `<ShippingAddressForm/>`.
     */
    renderAddressForm?: (props: AddressFormProps<T>) => ReactNode;
    required?: boolean;
}

/**
 * Renders a dropdown that displays a list of addresses as well as a control to
 * add a new address.
 */
function AddressEditor<T extends AddressFields = AddressFields>({
    quickAddress,
    countries,
    subdivisionsByCountryCode,
    emptyAddress,
    initialAddress,
    address,
    chooseFrom = [],
    validationErrors,
    onEdit,
    title = messages.title.address(),
    onSelect,
    renderAddressForm,
    required = false,
}: Props<T>) {
    const styles = window.getComputedStyle(document.documentElement!);

    const [visibleAddressType, setVisibleAddressType] = useState<VisibleAddressType>(
        initialAddress ? VisibleAddressType.ReadOnly : VisibleAddressType.None
    );

    const options = chooseFrom.map(addressAsOption);

    const showNewAddressForm = useCallback(() => {
        onSelect(emptyAddress);
        setVisibleAddressType(VisibleAddressType.Editable);
    }, [onSelect, setVisibleAddressType]);

    const handleUseQuickAddress = useCallback(() => {
        if (quickAddress && visibleAddressType === VisibleAddressType.Editable) {
            onSelect?.(quickAddress.value);
            logger.trackEvent(COPY_SITE_ADDRESS);
        }
    }, [quickAddress, visibleAddressType, onSelect]);

    const handleSelectAddress = useCallback(
        (evt: ChangeEvent<HTMLSelectElement>) => {
            const selectedAddress = chooseFrom.find((address) => getAddressIdentifier(address) === evt.target.value);

            if (!selectedAddress) {
                return;
            }

            if (!onSelect) {
                return;
            }

            const country = countries.find(({ isoCode }) => selectedAddress[COUNTRY_KEY] === isoCode);
            const address: AddressFields = {
                ...selectedAddress,
                country: country ? country.isoCode : "",
            };

            onSelect(address);
            setVisibleAddressType(VisibleAddressType.ReadOnly);
        },
        [chooseFrom, onSelect, setVisibleAddressType, countries]
    );

    const addressFormProps: AddressFormProps<T> = {
        countries,
        initialValues: initialAddress,
        values: address,
        subdivisionsByCountryCode,
        submitButtonText: messages.actions.add(),
        onChange: onEdit,
        validationErrors,
    };

    return (
        <div className="AddressEditor">
            <Header
                as="h3"
                title={title}
                fluid
                rightAccessory={
                    required ? (
                        <Icon
                            className="FormField-requiredIndicator"
                            color={styles.getPropertyValue("--red")}
                            size="xs"
                            icon="asterisk"
                        />
                    ) : null
                }
            />

            <FormField>
                <Dropdown
                    placeholder={messages.forms.placeholders.selectAddress()}
                    options={options}
                    onChange={handleSelectAddress}
                />
                <Button className="Button" size="small" onClick={showNewAddressForm}>
                    <Icon className="Icon" icon="plus" />
                    New
                </Button>
            </FormField>

            {options.length > 0 && <Spacer />}

            {visibleAddressType === VisibleAddressType.Editable ? (
                <>
                    {quickAddress && (
                        <>
                            <Link
                                className="AddressEditor-useQuickAddress"
                                disabled={!(quickAddress.enabled ?? true)}
                                onClick={handleUseQuickAddress}
                            >
                                {quickAddress.label}
                            </Link>
                            <Spacer size="sm" />
                        </>
                    )}
                    {renderAddressForm?.(addressFormProps) ?? <AddressForm {...addressFormProps} />}
                </>
            ) : visibleAddressType === VisibleAddressType.ReadOnly && address ? (
                <Address address={address} fields={ADDRESS_KEYS} getLabel={getLabel} />
            ) : null}
        </div>
    );
}

export default memo(AddressEditor);
