import classnames from "classnames";
import React, { memo } from "react";

interface Props {
    compact?: boolean;
    expanded?: boolean;
    horizontal?: boolean;
}

function SegmentGroup({
    className,
    compact,
    expanded,
    horizontal,
    children,
    ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={classnames("Segment", "SegmentGroup", className, { horizontal, compact, expanded })} {...props}>
            {children}
        </div>
    );
}

export default memo(SegmentGroup);
