import { ComponentType } from "../partOrders/models/ComponentType";

/**
 * HINT: When we want to default to an empty object or array when some value is
 * undefined in state, we should use these frozen values instead of array/object
 * literals. That way selectors will consider the values equal if state has not
 * changed.
 */
export const EMPTY_ARRAY: readonly any[] = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});

export const EMPTY_DATACENTER_OPTIONS = Object.freeze({
    [ComponentType.Optics]: [],
    [ComponentType.PatchCable]: [],
    [ComponentType.Psu]: [],
    [ComponentType.PowerCable]: [],
    [ComponentType.Rack]: [],
    [ComponentType.ShippingMaterials]: []
});
