import classnames from "classnames";
import React, { Component } from "react";
import Checkbox from "../Checkbox";
import Dropdown from "../Dropdown";
import FormActions from "../Form/FormActions";
import OptionBar from "../OptionBar";
import TextArea from "../TextArea";
import TextInput from "../TextInput";

interface Props {
    compact?: boolean;
    horizontal?: boolean;
}

class Form extends Component<Props & React.FormHTMLAttributes<HTMLFormElement>> {
    static Actions = FormActions;
    static TextInput = TextInput;
    static Dropdown = Dropdown;
    static TextArea = TextArea;
    static Checkbox = Checkbox;
    static OptionBar = OptionBar;

    render() {
        const { children, compact = false, horizontal = false, className, ...props } = this.props;

        return (
            <form className={classnames("Form", className, { compact, horizontal })} {...props}>
                {children}
            </form>
        );
    }
}

export default Form;
