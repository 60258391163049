import { PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

/**
 * Given an action and a list of action creators, returns `true` if any of them
 * match the action.
 * @param action
 * @param actionCreators
 */
export function matchesAny<P, Q extends P = any, T extends string = string>(
    action: AnyAction,
    actionCreators: PayloadActionCreator<Q, T>[]
): action is PayloadAction<P, T> {
    return actionCreators.some((actionCreator) => actionCreator.match(action));
}
