import { Props as IconProps } from "../components/Icon";
import { getMessage, messages } from "../messages";
import logger from "../logger";

export enum OcaClass {
    Flash = "Flash",
    Storage = "Storage",
    Global = "Global",
}

export function getIcon(ocaClass: OcaClass): IconProps["icon"] {
    switch (ocaClass) {
        case OcaClass.Flash:
            return "bolt";
        case OcaClass.Storage:
            return "database";
        case OcaClass.Global:
            return "globe";
        default: {
            const errorMessage = `Expected a StorageClass enum, received ${ocaClass} instead.`;
            logger.trackError(new Error(errorMessage), errorMessage);
            return "question";
        }
    }
}

export function getLabel(ocaClass: OcaClass): string {
    return getMessage(() => messages.labels.ocaClass[ocaClass](null), ocaClass);
}
