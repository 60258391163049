import classnames from "classnames";
import React, { AnchorHTMLAttributes, memo } from "react";
import { Link as ReactRouterLink, LinkProps, NavLink as ReactRouterNavLink, NavLinkProps } from "react-router-dom";
import Icon from "../Icon";

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    isExternal?: boolean;
    isActive?: boolean;
    disabled?: boolean;
}

type Props = NavLinkProps | LinkProps | AnchorProps;

function isLinkProps(props: Props): props is LinkProps {
    return props["to"] !== undefined;
}

function isNavLinkProps(props: Props): props is NavLinkProps {
    if (!isLinkProps(props)) {
        return false;
    }

    if (typeof props["activeClassName"] === "string") {
        return true;
    }

    if (typeof props["activeStyle"] === "object") {
        return true;
    }

    if (typeof props["exact"] === "boolean" || typeof props["strict"] === "boolean") {
        return true;
    }

    if (typeof props["isActive"] === "function") {
        return true;
    }

    if (typeof props["location"] === "object") {
        return true;
    }

    return false;
}

/**
 * Can be used as either a react-router Link, NavLink or as a simple anchor tag.
 * If the `to` prop is provided, a Link is rendered. If any of the NavLink
 * properties are provided, a NavLink is rendered. In all other cases, an <a/>.
 */
function Link(props: Props) {
    if (isNavLinkProps(props)) {
        const { className } = props;
        return <ReactRouterNavLink {...props} className={classnames("Link", className)} />;
    } else if (isLinkProps(props)) {
        const { className } = props;
        return <ReactRouterLink {...props} className={classnames("Link", className)} />;
    } else {
        const { isActive = false, className, children, isExternal, href, disabled, ...rest } = props;

        return (
            <a
                className={classnames("Link", className, { active: isActive, disabled })}
                target={isExternal ? "_blank" : undefined}
                rel={isExternal ? "noopener noreferrer" : undefined}
                href={href}
                {...rest}
            >
                {children}
                {isExternal && (
                    <>
                        <span>&nbsp;</span>
                        <Icon fixedWidth size="xs" icon="external-link-alt" />
                    </>
                )}
            </a>
        );
    }
}

export default memo(Link);
