export enum SiteSurveyStatus {
    Open = "OPEN",
    Submitted = "SUBMITTED",
    Allocated = "ALLOCATED",
    Live = "LIVE",
    Cancelled = "CANCELLED"
}

/**
 * Returns whether or not the deployment for the site survey is still ongoing.
 */
export function checkIsActive(status: SiteSurveyStatus): boolean {
    switch (status) {
        case SiteSurveyStatus.Live:
        case SiteSurveyStatus.Cancelled:
            return false;
        case SiteSurveyStatus.Open:
        case SiteSurveyStatus.Submitted:
        case SiteSurveyStatus.Allocated:
        default:
            return true;
    }
}
