export interface WithKey<K extends number | string = number> {
    key: K;
}

export interface WithError {
    error: Error | string;
}

/**
 * Used in conjunction with `createApiRequestSlice`.
 */
export interface ApiRequestState<T = void> {
    value: T;
    error: Error | string | null;
    pending: boolean;
    success: boolean;
}

/**
 * Used in conjunction with `createApiRequestCollectionSlice`.
 */
export interface ApiRequestCollectionState<T = void> {
    [key: string]: ApiRequestState<T> | undefined;
}

export function anyPending(...apiRequestStates: ApiRequestState<any>[]) {
    return apiRequestStates.some(({ pending }) => pending);
}

export function firstError(...apiRequestStates: ApiRequestState<any>[]) {
    return apiRequestStates.find(({ error }) => !!error)?.error ?? null;
}
