import classnames from "classnames";
import React from "react";
import Icon, { Props as IconProps } from "../../../shared/components/Icon";

interface Props {
    direction: "left" | "right";
    disabled?: boolean;
    onClick?: () => void;
}

function DirectionalNavigationControl({ direction, onClick, disabled = false }: Props) {
    return (
        <div
            className={classnames("ApplianceBrowser-DirectionalNavigationControl", direction, { disabled })}
            onClick={onClick}
        >
            <Icon icon={`chevron-${direction}` as IconProps["icon"]} size="lg" />
        </div>
    );
}

export default DirectionalNavigationControl;
