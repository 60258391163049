export enum SiteSurveyProperty {
    AdditionalRecipients = "additionalRecipients",
    siteSurveyHardwares = "siteSurveyHardwares",
    Notes = "notes",
    AutoLive = "autoLive",
    NetworkConfigs = "networkConfigs",
    SiteAddress = "siteAddress",
    PartDefinitions = "partDefinitions",
    EstimatedFreightCharges = "estimatedFreightCharges",
    ContentRegion = "contentRegion",
    ExpectedInitialTrafficGbps = "expectedInitialTrafficGbps",
    Name = "name",
    ShippingAddress = "shippingAddress",
    InterfaceSpeed = "interfaceSpeed",
    Contacts = "contacts",
    /**
     * @deprecated
     */
    TaxId = "taxId",
    Taxpayer = "taxpayer",
    Cfdi = "cfdi",
    Eori = "eori"
}
