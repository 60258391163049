import config from "../../shared/config";
import messages from "./messages";

/**
 * A safe way to get a localized string without possibility of exceptions. It
 * catches any errors and logs it and can use a fallback string if provided.
 * This is the recommended way to access strings. You can also provide a list of
 * getters, and it will attempt each one, returning the first success, or
 * returning the fallback if none of them were successful.
 * 
 * If you are using the array-style argument, and want to see the failed output
 * as the getters are traversed, set your browser console "Default levels" to "Verbose"
 * @param getter
 * @param fallback
 */
export function getMessage(getters: (() => string)[], fallback?: string): string;
export function getMessage(getter: () => string, fallback?: string): string;
export function getMessage(getterOrGetters: (() => string)[] | (() => string), fallback = ""): string {
    const getters = Array.isArray(getterOrGetters) ? getterOrGetters : [getterOrGetters];

    for (let i = 0; i < getters.length; i++) {
        const getter = getters[i];
        try {
            return getter();
        } catch (e) {
            if (config.env === "dev") {
                console.debug(e);
            }
        }
    }
    // We tried all the getters, and all of them failed.
    if (config.env === "dev") {
        if (fallback === undefined) {
            console.warn(
                new Error(
                    "Unable to retrieve localized string from getters, and no fallback provided. " +
                        "This is likely a mistake."
                )
            );
        } else {
            console.log(`LOG(${getMessage.name}): fallback "${fallback}" used.`);
        }
    }
    return fallback;
}

export { messages };
