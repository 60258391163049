import React, { memo } from "react";
import { messages } from "../../messages";
import { NotesFields } from "../../models/NotesFields";
import NotesForm from "../NotesForm";
import { Content } from "../PartnerForm";

interface Props {
    initialNotes: string;
    notes?: string;
    onChange?: (values: Partial<NotesFields>) => void;
}

function NotesEditor({ onChange, notes, initialNotes }: Props) {
    return (
        <Content title={messages.title.notes()} subtitle={messages.notes.siteSurveyNotes()}>
            <NotesForm initialValues={{ notes: initialNotes }} values={{ notes }} onChange={onChange} />
        </Content>
    );
}

export default memo(NotesEditor);
