import qs from "qs";
import { matchPath } from "react-router";
import { Dictionary } from "../shared/models/Dictionary";
import { PartnerFormKind } from "../shared/models/PartnerFormKind";
import { PATH_OCA_RETURNS, PATH_PART_ORDERS, PATH_SITE_SURVEYS } from "../shared/routes";
import { PARAM_ID } from "../shared/routes/params";
import { asInt } from "./strings";

function cleanSearch(search = "") {
    return search[0] === "?" ? search.substring(1) : search;
}

/**
 * Given a search string, returns an object map of the search parameters
 * @param search - A string matching Location["search"]
 */
export function getSearchValues(search?: string): Dictionary<string> {
    return qs.parse(cleanSearch(search));
}

/**
 * Given a search string, returns a new search string with the specified key
 * value pair.
 *
 * Example: "?flavor=spicy", "size", "large" -> "?flavor=spicy&size=large"
 * @param search - A string matching Location["search"]
 * @param key
 * @param value
 */
export function setSearchValue(search = "", key: string, value: string | undefined): string {
    const nextSearch = qs.stringify({
        ...qs.parse(cleanSearch(search)),
        [key]: value,
    });

    return nextSearch ? `?${nextSearch}` : "";
}

export function unsetSearchValues(search = "", keys: string[]): string {
    const parsed = qs.parse(cleanSearch(search));
    keys.forEach((key) => (parsed[key] = undefined));

    const nextSearch = qs.stringify(parsed);
    return nextSearch ? `?${nextSearch}` : "";
}

export function unsetSearchValue(search = "", key: string): string {
    return unsetSearchValues(search, [key]);
}

export function hasSearchValue(search: string, key: string): boolean {
    // Checking for the presence of the query parameter key is sufficient.
    // Parameters with no values (regardless of presence of equal sign) are
    // converted to empty strings.
    return qs.parse(search).hasOwnProperty(key);
}

/**
 * Given a search string, returns the value for the given key, or undefined
 * @param search - A string matching Location["search"]
 * @param key
 */
export function getSearchValue(search: string | undefined, key: string): string | undefined {
    return getSearchValues(search)[key];
}

export function cleanRedirectPath(path: string): string | undefined {
    // This is the default path, a redirect search param to the root path is the
    // same as no redirect.
    if (path === "/") {
        return;
    }

    if (path[0] !== "/") {
        return `/${path}`;
    }

    return path;
}

export function getPartnerFormIdFromLocation(kind: PartnerFormKind): number | undefined {
    let path: string | undefined;
    switch (kind) {
        case PartnerFormKind.SiteSurvey:
            path = PATH_SITE_SURVEYS;
            break;
        case PartnerFormKind.PartOrder:
            path = PATH_PART_ORDERS;
            break;
        case PartnerFormKind.OcaReturn:
            path = PATH_OCA_RETURNS;
            break;
        default:
            return;
    }

    const match = matchPath<Record<string, string | undefined>>(window.location.pathname, {
        path: `${path}/:${PARAM_ID}`,
    });

    const param = match?.params[PARAM_ID];
    return param ? asInt(param) : undefined;
}
