import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import { getActiveOrg, getCountries, getSubdivisionsByCountryCode } from "../../../app/reducer/selectors";
import { checkIsEditable } from "../../../utils/siteSurvey";
import { SiteSurveyProperty } from "../../models/SiteSurveyProperty";
import { siteSurveysActions } from "../../reducer";
import {
    getAdditionalRecipients,
    getAddressVerificationsState,
    getAsns,
    getContacts,
    getDownloadAttachmentRequestStates,
    getInitialAdditionalRecipients,
    getSaveSiteSurveyRequestState,
    getShippingAddresses,
    getSiteSurveyErrors,
    getSiteSurveyErrorsByView,
    getSiteSurveyIsComplete,
    getSiteSurveyIsDirty,
    getSiteSurveyIsValid,
    getSiteSurveyModified,
    getSiteSurveyUiState,
    getSubmitSiteSurveyRequestState,
} from "../../reducer/selectors";
import { Props } from "./SiteSurvey";

type StateProps = Pick<
    Props,
    | "activeNetworkConfigIndex"
    | "additionalRecipients"
    | "initialAdditionalRecipients"
    | "asns"
    | "attachmentDownloadStates"
    | "addressVerificationsState"
    | "contacts"
    | "countries"
    | "subdivisionsByCountryCode"
    | "errors"
    | "isComplete"
    | "isDirty"
    | "isValid"
    | "modified"
    | "saveState"
    | "shippingAddressSuggestions"
    | "submitState"
    | "rolledUpErrors"
>;

type DispatchProps = Pick<
    Props,
    | "clearSaveAndSubmitErrors"
    | "fetchShippingAddressSuggestions"
    | "fetchAllowedParts"
    | "onChangeAdditionalRecipients"
    | "onChangeBGPConfig"
    | "onChangeContactForRole"
    | "onChangeDatacenter"
    | "onChangeNetworkConfig"
    | "onChangeNotes"
    | "onChangeShippingAddress"
    | "onChangeSiteAddress"
    | "onChangeTaxpayerFields"
    | "onChangeCfdiFields"
    | "onChangeEori"
    | "onDownloadAttachment"
    | "onSave"
    | "onSelectContactForRole"
    | "onSelectShippingAddress"
    | "onSetActiveNetworkConfigIndex"
    | "onSubmit"
    | "validate"
    | "reset"
>;

type OwnProps = Omit<Props, keyof StateProps | keyof DispatchProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state, ownProps) => {
    const {
        siteSurvey: { id },
    } = ownProps;

    return {
        ...getSiteSurveyUiState(state, id),
        initialAdditionalRecipients: getInitialAdditionalRecipients(state, id),
        additionalRecipients: getAdditionalRecipients(state, id),
        addressVerificationsState: getAddressVerificationsState(state, id),
        attachmentDownloadStates: getDownloadAttachmentRequestStates(state),
        errors: getSiteSurveyErrors(state, id),
        rolledUpErrors: getSiteSurveyErrorsByView(state, id),
        isComplete: getSiteSurveyIsComplete(state, id),
        isDirty: getSiteSurveyIsDirty(state, id),
        isValid: getSiteSurveyIsValid(state, id),
        shippingAddressSuggestions: getShippingAddresses(state, id),
        asns: getAsns(state, id),
        contacts: getContacts(state, id),
        countries: getCountries(state),
        subdivisionsByCountryCode: getSubdivisionsByCountryCode(state),
        saveState: getSaveSiteSurveyRequestState(state),
        submitState: getSubmitSiteSurveyRequestState(state),
        modified: getSiteSurveyModified(state, id),
        org: getActiveOrg(state),
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch,
    { orgId, siteSurvey, forceEditable }
) => {
    const { id: siteSurveyId } = siteSurvey;
    return {
        clearSaveAndSubmitErrors: () => {
            dispatch(siteSurveysActions.save.reset({ orgId, siteSurveyId }));
            dispatch(siteSurveysActions.submit.reset({ orgId, siteSurveyId }));
        },
        fetchShippingAddressSuggestions: () =>
            dispatch(siteSurveysActions.fetchShippingAddressSuggestions.do({ orgId, siteSurveyId }, siteSurveyId)),
        fetchAllowedParts: () =>
            dispatch(siteSurveysActions.fetchAllowedParts.do({ orgId, siteSurveyId }, siteSurveyId)),
        onChangeSiteAddress: (values) =>
            dispatch(siteSurveysActions.changeSiteAddress({ orgId, siteSurveyId, values, forceEditable })),
        onChangeNetworkConfig: (values, at) =>
            dispatch(siteSurveysActions.changeNetworkConfig({ orgId, siteSurveyId, values, at, forceEditable })),
        onChangeBGPConfig: (values) =>
            dispatch(siteSurveysActions.changeBgpConfig({ orgId, siteSurveyId, values, forceEditable })),
        onChangeDatacenter: (values) =>
            dispatch(siteSurveysActions.changeDatacenter({ orgId, siteSurveyId, values, forceEditable })),
        onChangeNotes: (values) =>
            dispatch(siteSurveysActions.changeNotes({ orgId, siteSurveyId, values, forceEditable })),
        onChangeContactForRole: (contact, role) =>
            dispatch(siteSurveysActions.changeContact({ orgId, siteSurveyId, contact, key: role, forceEditable })),
        onChangeShippingAddress: (address) =>
            dispatch(siteSurveysActions.changeShippingAddress({ orgId, siteSurveyId, values: address, forceEditable })),
        onChangeTaxpayerFields: (taxpayerFields) =>
            dispatch(siteSurveysActions.changeTaxpayerFields({ orgId, siteSurveyId, taxpayerFields, forceEditable })),
        onChangeCfdiFields: (cfdiFields) =>
            dispatch(siteSurveysActions.changeCfdiFields({ orgId, siteSurveyId, cfdiFields, forceEditable })),
        onChangeEori: (eori) => 
            dispatch(siteSurveysActions.changeEori({ orgId, siteSurveyId, eori, forceEditable })),
        onChangeAdditionalRecipients: (recipients) =>
            dispatch(siteSurveysActions.changeAdditionalRecipients({ orgId, siteSurveyId, recipients, forceEditable })),
        onDownloadAttachment: (attachment) =>
            dispatch(siteSurveysActions.downloadAttachments.do({ orgId, siteSurveyId, attachment }, attachment.id)),
        onSave: () => dispatch(siteSurveysActions.save.do({ orgId, siteSurveyId })),
        onSubmit: () => dispatch(siteSurveysActions.submit.do({ orgId, siteSurveyId })),
        onSelectShippingAddress: (address) =>
            dispatch(siteSurveysActions.selectShippingAddress({ orgId, siteSurveyId, address, forceEditable })),
        onSelectContactForRole: (contact, role) =>
            dispatch(siteSurveysActions.selectContact({ orgId, siteSurveyId, contact, key: role, forceEditable })),
        onSetActiveNetworkConfigIndex: (index) =>
            dispatch(siteSurveysActions.setActiveNetworkConfigIndex({ orgId, siteSurveyId, index })),
        validate: () => {
            // Only verify shipping address if it is editable. Save us some $.
            const verifyShippingAddress = checkIsEditable({
                siteSurvey,
                key: SiteSurveyProperty.ShippingAddress,
                forceEditable,
            });
            dispatch(siteSurveysActions.doValidate({ orgId, siteSurveyId, forceEditable, verifyShippingAddress }));
        },
        reset: () => dispatch(siteSurveysActions.reset({ orgId, siteSurveyId })),
    };
};

export { mapStateToProps, mapDispatchToProps };
