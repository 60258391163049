export const BGP_ASN_KEY = "bgpAsn";
export const BGP_MULTIHOP_KEY = "bgpMultihop";

export const BGP_CONFIG_KEYS: (keyof BgpConfigFields)[] = [BGP_ASN_KEY, BGP_MULTIHOP_KEY];

export interface BgpConfigFields {
    [BGP_ASN_KEY]: number;
    [BGP_MULTIHOP_KEY]: number;
}

export interface BgpConfig extends BgpConfigFields {}
