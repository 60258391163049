import React, { memo } from "react";
import { useForm } from "../../forms/hooks/useForm";
import { messages } from "../../messages";
import { FieldMetadata } from "../../models/FieldMetadata";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { ValidationError } from "../../models/ValidationError";
import Dropdown from "../Dropdown";
import FormField from "../FormField";
import HelpIcon from "../HelpIcon";
import { Content } from "../PartnerForm";
import TextInput from "../TextInput";
import Tooltip from "../Tooltip";

interface Props {
    initialCfdiFields: Record<string, string>;
    cfdiFields?: Record<string, string>;
    onChange: (values: Partial<Record<string, string>>) => void;
    validationErrors?: ModelValidationErrors<Record<string, string>> | ValidationError;
    cfdiFieldMetadata: FieldMetadata[];
}

function CfdiFieldsEditor({ cfdiFieldMetadata, initialCfdiFields, cfdiFields, validationErrors, onChange }: Props) {
    const [formState, formActions] = useForm<Record<string, string | undefined>>(
        { keys: cfdiFieldMetadata.map(({ name }) => name) },
        {
            initialValues: initialCfdiFields,
            values: cfdiFields,
            validationErrors,
            onChange,
        }
    );
    const { errors, dirty, values } = formState;
    const { touchHandlers, changeHandlers } = formActions;

    if (!cfdiFieldMetadata.length) {
        return null;
    }

    return (
        <Content title={messages.title.cfdi()}>
            {cfdiFieldMetadata.map(({ name, label, description, required, enums }) => (
                <FormField
                    key={name}
                    name={name}
                    required={required}
                    label={label}
                    error={errors.get(name)}
                    labelAccessory={
                        description && (
                            <Tooltip placement="right-end" content={<div>{description}</div>}>
                                <HelpIcon spacedLeft />
                            </Tooltip>
                        )
                    }
                >
                    {enums && Object.keys(enums).length ? (
                        <Dropdown
                            placeholder={label}
                            value={values?.[name] ?? ""}
                            onChange={(evt) => changeHandlers[name]?.(evt.target.value || undefined)}
                            onBlur={() => touchHandlers[name]?.()}
                            dirty={dirty.has(name)}
                            error={errors.has(name)}
                            options={
                                Object.entries(enums).map(([code, text]) => ({
                                    code,
                                    value: code,
                                    text,
                                })) ?? []
                            }
                            clearable
                        />
                    ) : (
                        <TextInput
                            type="text"
                            autoComplete="off"
                            placeholder={label}
                            value={values?.[name] ?? ""}
                            onChange={(evt) => changeHandlers[name]?.(evt.target.value)}
                            onBlur={() => touchHandlers[name]?.()}
                            dirty={dirty.has(name)}
                            error={errors.has(name)}
                        />
                    )}
                </FormField>
            ))}
        </Content>
    );
}

export default memo(CfdiFieldsEditor);
