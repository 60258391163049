import classnames from "classnames";
import React, { Component, MouseEvent, ReactNode } from "react";
import Button from "../Button";
import Icon from "../Icon";
import { messages } from "../../messages";

export interface Props {
    className?: string;
    onRemove?: (props: Props) => void | Promise<any>;
    isPending?: boolean;
    id?: any;
    when?: boolean;
    align?: "bottom" | "center" | "top";
    fluid?: boolean;
    renderRemove?: (props: Props, handleClick: (evt: MouseEvent<any>) => void) => ReactNode;
}

/**
 * Renders a container for something that can be removed. Handles the styling
 * and logic related to the remove button.
 */
class Removable extends Component<Props> {
    private static defaultRenderRemove({ when: active = true }: Props, handleClick: (evt: MouseEvent<any>) => void) {
        return (
            <Button
                disabled={!active}
                className="Removable-defaultButton"
                title={messages.actions.remove()}
                kind="dismiss"
                onClick={handleClick}
                size="small"
            >
                <Icon icon="times" />
            </Button>
        );
    }

    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        const {
            className,
            children,
            isPending = false,
            when: active = true,
            align = "center",
            fluid = true,
            renderRemove = Removable.defaultRenderRemove
        } = this.props;
        return (
            <div className={classnames("Removable", className, { isPending, active, fluid }, `align-${align}`)}>
                <div className="Removable-content">{children}</div>
                <div className="Removable-actions">
                    {renderRemove(
                        {
                            isPending,
                            when: active,
                            fluid,
                            align
                        },
                        this.handleClick
                    )}
                </div>
            </div>
        );
    }

    private handleClick(evt: MouseEvent<any>) {
        const { onRemove, when: removable = true } = this.props;

        evt.preventDefault();

        if (removable && onRemove) {
            onRemove(this.props);
        }
    }
}

export default Removable;
