import { format, formatDistanceToNowStrict } from "date-fns";
import React, { memo, useMemo } from "react";
import { DATETIME_FULL, DATETIME_SHORT_WITH_SECONDS } from "../../settings";

interface Props {
    date: Date;
    /**
     * If `true`, the date will be displayed in relative terms (e.g. "a few
     * seconds ago"). Regardless of this setting, the tooltip will always use
     * the specified `format`.
     */
    relative?: boolean;
}

/**
 * Renders a date in the specified format.
 */
function DateDisplay({ date, relative }: Props) {
    const r = useMemo(
        () =>
            formatDistanceToNowStrict(date, {
                addSuffix: true,
                roundingMethod: "floor",
            }),
        [date]
    );
    return <span title={format(date, DATETIME_FULL)}>{relative ? r : format(date, DATETIME_SHORT_WITH_SECONDS)}</span>;
}

export default memo(DateDisplay);
