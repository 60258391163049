import React, { memo } from "react";
import { TopgearHelper } from "../../../services/topgear/TopgearHelper";
import { ContactKey, SiteSurveyContacts } from "../../../siteSurveys/models/SiteSurveyContacts";
import { messages } from "../../messages";
import Contact from "../Contact";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";
import Segment from "../Segment";
import CopyContacts from "../CopyContacts";

interface Props {
    title?: string;
    contacts: SiteSurveyContacts;
    /**
     * By default, this component only renders data for the contacts included in
     * the dictionary of components. If `roles` is provided, it will render data
     * for each role: the contact info if found in the dictionary, or an empty
     * state if missing.
     */
    roles?: {
        key: string;
        label: string;
    }[];
}

function Contacts({ contacts, roles = Object.keys(contacts).map((key) => ({ key, label: key })) }: Props) {
    return (
        <Segment.Group className="Contacts" expanded>
            <CopyContacts contacts={contacts} />
            {roles.map(({ key, label }) => {
                const contact = contacts[key as ContactKey];
                return (
                    <Segment key={key} className="Contacts-contactContainer">
                        <Content title={label}>
                            <div className="Contacts-contactInfo">
                                {contact && !TopgearHelper.isContactEmpty(contact) ? (
                                    <Contact contact={contact} />
                                ) : (
                                    <MissingInfo message={messages.notice.missingContact()} />
                                )}
                            </div>
                        </Content>
                    </Segment>
                );
            })}
        </Segment.Group>
    );
}

export default memo(Contacts);
