import React, { Component } from "react";
import classnames from "classnames";

interface Props {
    inverted?: boolean;
    basic?: boolean;
    attached?: boolean;
}

class TabPane extends Component<Props & React.HTMLAttributes<HTMLDivElement>> {
    render() {
        const { className } = this.props;
        return <div className={classnames("TabPane", className)}>{this.props.children}</div>;
    }
}

export default TabPane;
