import classnames from "classnames";
import { uniq } from "lodash";
import React, { useCallback, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { messages } from "../../messages";
import { EmailEntryFields } from "../../models/EmailEntryFields";
import EmailEntryForm from "../EmailEntryForm";
import FadeIn from "../FadeIn";
import Meta from "../Meta";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";
import Removable from "../Removable";
import Segment from "../Segment";
import SlideDown from "../SlideDown";

export interface Props {
    initialRecipients: readonly string[];
    recipients: readonly string[];
    onChange?: (recipients: string[]) => void;
}

function AdditionalRecipientsEditor({ initialRecipients, recipients, onChange }: Props) {
    const [values, setValues] = useState<Partial<EmailEntryFields>>({});

    const handleSubmit = useCallback(() => {
        if (values.email) {
            onChange?.(uniq([...recipients, values.email]));
            setValues({});
        }
    }, [onChange, recipients, values]);

    const handleRemove = useCallback(
        (target: string) => onChange?.(recipients.filter((recipient) => recipient !== target)),
        [onChange, recipients]
    );

    const isNewlyAdded = useCallback(
        (recipient: string) => {
            const set = new Set(initialRecipients);
            return !set.has(recipient);
        },
        [initialRecipients]
    );

    return (
        <Content
            className="AdditionalRecipientsEditor"
            title={messages.title.additionalRecipients()}
            subtitle={messages.notes.additionalRecipients()}
        >
            <Segment.Group horizontal>
                <Segment>
                    <EmailEntryForm
                        onSubmit={handleSubmit}
                        submitButtonText={messages.actions.add()}
                        values={values}
                        initialValues={{ email: "" }}
                        onChange={setValues}
                    />
                </Segment>
                <Segment>
                    <Meta>Recipients</Meta>
                    <FadeIn in={!recipients.length}>
                        <MissingInfo message={messages.notice.noAdditionalRecipients()} />
                    </FadeIn>
                    <ul>
                        <TransitionGroup component={null}>
                            {recipients.map((recipient, idx) => (
                                <SlideDown key={idx} approxHeight={40}>
                                    <li
                                        className={classnames("AdditionalRecipientsEditor-recipientListItem", {
                                            new: isNewlyAdded(recipient),
                                        })}
                                    >
                                        <Removable onRemove={() => handleRemove(recipient)}>
                                            <span>{recipient}</span>
                                        </Removable>
                                    </li>
                                </SlideDown>
                            ))}
                        </TransitionGroup>
                    </ul>
                </Segment>
            </Segment.Group>
        </Content>
    );
}

export default AdditionalRecipientsEditor;
