import { ADDRESS_KEY, CONTACT_KEY, PartOrder, PARTS_ORDER_LINE_ITEMS_KEY } from "../../partOrders/models/PartOrder";
import { QuantityLimits } from "../../partOrders/models/QuantityLimits";
import { TopgearHelper } from "../../services/topgear/TopgearHelper";
import { messages } from "../messages";
import { Address, AddressNew } from "../models/Address";
import { AddressFields } from "../models/AddressFields";
import { Contact, ContactNew } from "../models/Contact";
import { ContactFields } from "../models/ContactFields";
import { EORI_FIELD_KEY, EORI_FIELD_METADATA_KEY } from "../models/EoriCompliant";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { TAXPAYER_FIELDS_KEY, TAXPAYER_FIELD_METADATA_KEY } from "../models/TaxpayerAffiliated";
import { createValidationError, Level, ValidationError } from "../models/ValidationError";
import { validateAddress } from "./address";
import { validateContact } from "./contact";
import { validateEoriField } from "./eori";
import { validatePartOrderLineItems } from "./partOrderLineItem";
import { validateTaxpayerFields } from "./taxpayerFields";

export function validatePartOrderContact(
    contact: Contact | ContactNew | undefined
): ModelValidationErrors<ContactFields> | ValidationError | undefined {
    if (!contact || TopgearHelper.isContactEmpty(contact)) {
        return createValidationError(
            Level.Incomplete,
            messages.forms.validation.missingContact({ CONTACT_ROLE: messages.entity.contact() })
        );
    }

    return validateContact(contact);
}

export function validatePartOrderAddress(
    address: Address | AddressNew | undefined
): ModelValidationErrors<AddressFields> | ValidationError | undefined {
    if (!address || TopgearHelper.isAddressEmpty(address)) {
        return createValidationError(Level.Incomplete, messages.forms.validation.missingShippingAddress());
    }

    return validateAddress(address);
}

export function validatePartOrder(
    partOrder: PartOrder,
    forceEditable = false,
    quantityLimits?: QuantityLimits
): ModelValidationErrors<PartOrder> {
    if (!partOrder.editable && !forceEditable) {
        return {};
    }

    return {
        [PARTS_ORDER_LINE_ITEMS_KEY]: validatePartOrderLineItems(partOrder[PARTS_ORDER_LINE_ITEMS_KEY], quantityLimits),
        [CONTACT_KEY]: validatePartOrderContact(partOrder[CONTACT_KEY]),
        [ADDRESS_KEY]: validatePartOrderAddress(partOrder[ADDRESS_KEY]),
        [TAXPAYER_FIELDS_KEY]: validateTaxpayerFields(
            partOrder[TAXPAYER_FIELDS_KEY],
            partOrder[TAXPAYER_FIELD_METADATA_KEY]
        ),
        ...validateEoriField(partOrder[EORI_FIELD_KEY], partOrder[EORI_FIELD_METADATA_KEY]),
    };
}
