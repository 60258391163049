import "core-js/shim";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router";
import { Router } from "react-router-dom";
import App from "./app/components/App";
import { configurePfStore } from "./configurePfStore";
import "./index.css";
import auth, { AuthService } from "./services/auth";
import { checkIsAPIKeyUser, User } from "./services/auth/models/User";
import session from "./services/session";
import config from "./shared/config";
import { history } from "./shared/history";
import logger from "./shared/logger";
import { PartnerFormKind } from "./shared/models/PartnerFormKind";
import { getPartnerFormIdFromLocation } from "./utils/url";

// Configure Redux store, initialize state here.
const store = configurePfStore();

function initializeLogger(user: User | null) {
    const userId = user === null ? undefined : AuthService.getIdentity(user);
    logger.setAppUser({
        userId: userId ?? "anonymous",
        isNetflixEmployee: AuthService.checkIsAdmin(user),
        userTitle: ""
    });
}

auth.start() // Start the auth service
    .then(user => {
        // Intialize logger with current user.
        initializeLogger(user);
        // Subscribe to user changes and re-initialize logger accordingly.
        auth.subscribe(user => initializeLogger(user));

        // For API Key users, if the specified partner form is not one of the
        // authorized ones stored in local storage, clear the entire session.
        if (checkIsAPIKeyUser(user)) {
            if (
                [PartnerFormKind.SiteSurvey, PartnerFormKind.PartOrder, PartnerFormKind.OcaReturn].some(kind => {
                    const id = getPartnerFormIdFromLocation(kind);
                    return id !== undefined && !AuthService.check(user, kind, id);
                })
            ) {
                session.clear();
                auth.updateUser(null);
            }
        }

        // Render the app
        ReactDOM.render(
            <Provider store={store}>
                <Router history={history}>
                    <Route render={props => <App config={config} {...props} />} />
                </Router>
            </Provider>,
            document.getElementById("root") as HTMLElement
        );
    });
