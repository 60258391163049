import { max, min } from "date-fns";

export function noLaterThan(date: Date, at: Date): Date {
    return min([date, at]);
}

export function noLaterThanNow(date: Date) {
    return noLaterThan(date, new Date());
}

export function latestOf(dates: Date[]): Date {
    if (!dates.length) {
        throw new Error("At least one date is required!");
    }
    return max(dates);
}
