import { messages } from "../messages";

export const NAME_FIRST_KEY = "nameFirst";
export const NAME_LAST_KEY = "nameLast";
export const EMAIL_WORK_KEY = "emailWork";
export const PHONE_WORK_KEY = "phoneWork";
export const ROLES_KEY = "roles";

export const CONTACT_FIELDS_KEYS: readonly (keyof ContactFields)[] = [
    NAME_FIRST_KEY,
    NAME_LAST_KEY,
    EMAIL_WORK_KEY,
    PHONE_WORK_KEY,
];

export interface ContactFields {
    [NAME_FIRST_KEY]: string;
    [NAME_LAST_KEY]: string;
    [EMAIL_WORK_KEY]: string;
    [PHONE_WORK_KEY]?: string;
    [ROLES_KEY]?: string[];
}

export const DEFAULT_CONTACT_VALUES: ContactFields = {
    [NAME_FIRST_KEY]: "",
    [NAME_LAST_KEY]: "",
    [EMAIL_WORK_KEY]: "",
    [PHONE_WORK_KEY]: "",
    [ROLES_KEY]: [],
};

export function getLabel(key: keyof ContactFields): string {
    try {
        return messages.labels.contactField[key](null);
    } catch (e) {
        console.warn(e);
        return key;
    }
}
