import React, { Component, ComponentType } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { setSearchValue, unsetSearchValue } from "../../utils/url";
import { SudoContext } from "../contexts/SudoContext";
import { QUERY_SUDO } from "../routes";
import logger from "../logger";
import { TOGGLE_SUDO } from "../events";

export interface WithSudoProps {
    sudo: boolean;
    toggleSudo: (next: boolean) => void;
}

/**
 * An HOC provides the wrapped component with the `sudo` flag and a callback to
 * toggle it on or off.
 */
export const withSudo = <P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P & WithSudoProps>
): ComponentType<Omit<P, keyof WithSudoProps>> => {
    return (withRouter(
        class extends Component<P & RouteComponentProps<Record<string, string>>> {
            constructor(props: P & RouteComponentProps<Record<string, string>>) {
                super(props);

                this.toggleSudo = this.toggleSudo.bind(this);
            }

            render() {
                const { history, location, match, staticContext, ...props } = this.props;
                return (
                    <SudoContext.Consumer>
                        {(sudo) => <WrappedComponent sudo={sudo} toggleSudo={this.toggleSudo} {...(props as P)} />}
                    </SudoContext.Consumer>
                );
            }

            toggleSudo(next: boolean) {
                const { history, location } = this.props;
                logger.trackEvent(TOGGLE_SUDO, {
                    on: next,
                });

                history.push({
                    search: next
                        ? setSearchValue(location.search, QUERY_SUDO, "")
                        : unsetSearchValue(location.search, QUERY_SUDO),
                });
            }
        }
    ) as any) as ComponentType<Omit<P, keyof WithSudoProps>>;
};
