import React, { memo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

function BadgeGroup({ children }: Props) {
    return <div className="BadgeGroup">{children}</div>;
}

export default memo(BadgeGroup);
