import React, { Component } from "react";
import { TransitionGroup } from "react-transition-group";
import Collection from "../../../shared/components/Collection";
import Divider from "../../../shared/components/Divider";
import FadeIn from "../../../shared/components/FadeIn";
import Header from "../../../shared/components/Header";
import Meta from "../../../shared/components/Meta";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import { messages } from "../../../shared/messages";
import { NetworkConfig } from "../../models/NetworkConfig";
import Appliance from "../Appliance";
import ApplianceBrowser from "../ApplianceBrowser";

interface Props {
    bgpAsn: number;
    bgpMultihop: number;
    networkConfigs: NetworkConfig[];
    onSetActiveNetworkConfigIndex?: (index: number) => void;
    activeIndex?: number;
}

class Appliances extends Component<Props> {
    render() {
        const { bgpAsn, bgpMultihop, networkConfigs, activeIndex, onSetActiveNetworkConfigIndex } = this.props;

        return (
            <Content title={messages.title.bgpConfiguration()}>
                <Segment.Group horizontal>
                    <Segment>
                        <Meta>{messages.labels.bgpAsn()}</Meta>
                        <span className="attrValue">{bgpAsn}</span>
                    </Segment>
                    <Segment>
                        <Meta>{messages.labels.bgpMultihop()}</Meta>
                        <span className="attrValue">{bgpMultihop}</span>
                    </Segment>
                </Segment.Group>
                <Divider />
                <Segment>
                    <Collection
                        items={networkConfigs}
                        index={activeIndex}
                        onSetIndex={onSetActiveNetworkConfigIndex}
                        renderEmpty={() => (
                            <MissingInfo
                                message={messages.notice.missing({
                                    ITEMS: messages.entity.networkConfig({ COUNT: 0 })
                                })}
                            />
                        )}
                    >
                        {(networkConfig, index, onSetIndex, label) => (
                            <>
                                <Header as="h3" title={this.title(label)} fluid />
                                <ApplianceBrowser
                                    activeIndex={index}
                                    onSetActiveNetworkConfig={onSetIndex}
                                    networkConfigs={networkConfigs}
                                />
                                <TransitionGroup component={null}>
                                    <FadeIn key={networkConfig.id} crossFade>
                                        <Appliance networkConfig={networkConfig} />
                                    </FadeIn>
                                </TransitionGroup>
                            </>
                        )}
                    </Collection>
                </Segment>
            </Content>
        );
    }

    private title(label?: string) {
        let title = messages.title.applianceNetworkConfiguration();

        if (label) {
            title += ` ${label}`;
        }

        return title;
    }
}

export default Appliances;
