import { Location } from "history";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import auth, { AuthService } from "../../services/auth";
import partnerPortal from "../../services/partnerPortal";
import topgear from "../../services/topgear";
import { history } from "../../shared/history";
import RequestMiddleware from "../../shared/models/RequestMiddleware";
import { QUERY_API_KEY, QUERY_REDIRECT } from "../../shared/routes";
import { getSearchValue, unsetSearchValues } from "../../utils/url";
import { AppState } from "../models/AppState";
import { appActions } from "../reducer";
import { getOrgs } from "../reducer/selectors";

export class AppMiddleware extends RequestMiddleware<Dispatch, AppState> {
    get middleware(): Middleware<Record<string, unknown>, AppState> {
        return (api: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: AnyAction) => {
            next(action);

            if (appActions.cleanUrl.match(action)) {
                this.cleanUrl(action.payload);
            }

            if (appActions.fetchOrgs.do.match(action)) {
                this.fetchOrgs(api);
            }

            if (appActions.fetchActiveOrg.do.match(action)) {
                this.fetchActiveOrg(api, action.payload);
            }

            if (appActions.fetchCountries.do.match(action)) {
                this.fetchCountries(api);
            }

            if (appActions.fetchCountrySubdivisions.do.match(action)) {
                this.fetchCountrySubdivisions(api);
            }
        };
    }

    private fetchOrgs(api: MiddlewareAPI<Dispatch, AppState>) {
        const orgs = getOrgs(api.getState());

        // Access to multiple orgs is only applicable to Netflix and SSO
        // Partners. If the authenticated user is not one of these, don't
        // attempt to access the unauthorized resource.
        if (!AuthService.checkIsAdminOrSsoPartner(auth.user)) {
            return;
        }

        // Don't fetch again if already fetched as this request is not cheap and
        // the response rarely changes.
        if (orgs) {
            return;
        }

        this.handleRequest(api, partnerPortal.getOrgs()).then(
            (orgs) => api.dispatch(appActions.fetchOrgs.did(orgs)),
            (error) => api.dispatch(appActions.fetchOrgs.fail({ error }))
        );
    }

    private fetchActiveOrg(api: MiddlewareAPI<Dispatch, AppState>, orgId: number) {
        this.handleRequest(api, topgear.fetchOrg(orgId)).then(
            (org) => api.dispatch(appActions.fetchActiveOrg.did(org)),
            (error) => api.dispatch(appActions.fetchActiveOrg.fail({ error }))
        );
    }

    private fetchCountries(api: MiddlewareAPI<Dispatch, AppState>) {
        this.handleRequest(api, topgear.fetchCountries()).then(
            (countries) => api.dispatch(appActions.fetchCountries.did(countries)),
            (error) => api.dispatch(appActions.fetchCountries.fail({ error }))
        );
    }

    private fetchCountrySubdivisions(api: MiddlewareAPI<Dispatch, AppState>) {
        this.handleRequest(api, topgear.fetchCountrySubdivisions()).then(
            (states) => api.dispatch(appActions.fetchCountrySubdivisions.did(states)),
            (error) => api.dispatch(appActions.fetchCountrySubdivisions.fail({ error }))
        );
    }

    /**
     * Erases sensitive query params from the route by doing a replace.
     * @param location
     */
    private cleanUrl(location: Location) {
        const redirect = getSearchValue(location.search, QUERY_REDIRECT);
        const nextLocation = { ...location };
        const toUnset: string[] = [QUERY_API_KEY];

        if (redirect) {
            toUnset.push(QUERY_REDIRECT);
            nextLocation.pathname = redirect;
        }

        nextLocation.search = unsetSearchValues(location.search, toUnset);

        // Only replace if pathname or search params have changed
        if (location.pathname !== nextLocation.pathname || location.search !== nextLocation.search) {
            history.replace(nextLocation);
        }
    }
}

export default new AppMiddleware().middleware;
