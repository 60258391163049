import React, { Component } from "react";

interface Props {
    /**
     * The number of width units that this column should occupy, where the total
     * number of units available in the parent Grid is equal to the sum of all
     * units specified by immediate Grid.Column children components.
     */
    units?: number;
}

class GridColumn extends Component<Props & React.HTMLAttributes<HTMLDivElement>> {
    render() {
        const { children, units = 1 } = this.props;
        return (
            <div className="GridColumn" style={{ flexBasis: `${units * 100}%` }}>
                {children}
            </div>
        );
    }
}

export default GridColumn;
