import { MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import {
    checkIsDeterminingAllowedParts,
    getAllowedDatacenterOptions,
    getSiteSurveyOcaClass,
} from "../../../siteSurveys/reducer/selectors";
import { SiteSurvey } from "../../models/SiteSurvey";
import { Props } from "./DatacenterEditor";

type StateProps = Pick<Props<SiteSurvey>, "datacenterOptions" | "isDeterminingAllowedParts" | "ocaClass">;
type OwnProps = Omit<Props<SiteSurvey>, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state, { id }) => {
    return {
        datacenterOptions: getAllowedDatacenterOptions(state, id),
        isDeterminingAllowedParts: checkIsDeterminingAllowedParts(state, id),
        ocaClass: getSiteSurveyOcaClass(state, id),
    };
};

export { mapStateToProps };
