import React, { PureComponent } from "react";
import Placeholder from "../Placeholder";
import Divider from "../Divider";

class PartnerFormPlaceholder extends PureComponent {
    render() {
        return (
            <Placeholder className="PartnerFormPlaceholder">
                <Placeholder.RoundedLine width="90%" />
                <Placeholder.RoundedLine width="50%" />
                <Placeholder.RoundedLine width="60%" />
                <Placeholder.RoundedLine width="85%" />
                <Divider />
                <Placeholder.RoundedLine width="30%" />
                <Placeholder.RoundedLine width="45%" />
                <Placeholder.RoundedLine width="70%" />
                <Placeholder.RoundedLine width="25%" />
            </Placeholder>
        );
    }
}

export default PartnerFormPlaceholder;
