import classnames from "classnames";
import React, { Component, ReactNode } from "react";
import { ValidationError } from "../../models/ValidationError";
import { extractMessage } from "../../validation";
import Icon from "../Icon";

export interface Props extends React.HTMLProps<HTMLDivElement> {
    label?: string;
    notes?: string;
    required?: boolean;
    error?: ValidationError;
    fluid?: boolean;
    horizontal?: boolean;
    className?: string;
    hidden?: boolean;
    labelAccessory?: ReactNode;
    accessory?: ReactNode;
}

/**
 * A component that renders useful things in forms around inputs, such as
 * labels, notes, error messages, and additional styles for conformity.
 */
class FormField extends Component<Props> {
    render() {
        const {
            fluid = true,
            label,
            notes,
            required = false,
            error,
            children,
            horizontal = false,
            hidden = false,
            accessory,
            labelAccessory,
            className,
            ...rest
        } = this.props;

        const styles = window.getComputedStyle(document.documentElement!);
        const errorMessage = extractMessage(error);

        return (
            <div className={classnames("FormField", className, { fluid, horizontal, hidden })} {...rest}>
                {label !== undefined ? (
                    <label className="FormField-label">
                        {label}
                        {required ? (
                            <Icon
                                className="FormField-requiredIndicator"
                                color={styles.getPropertyValue("--red")}
                                size="xxs"
                                icon="asterisk"
                            />
                        ) : null}
                        {labelAccessory}
                    </label>
                ) : null}
                {notes && <span className="FormField-notes">{notes}</span>}
                <div className="FormField-content">
                    <div className={classnames("FormField-childContainer", { withAccessory: !!accessory })}>
                        {children}
                        {accessory}
                    </div>
                    {errorMessage ? <div className="FormField-error">{errorMessage}</div> : null}
                </div>
            </div>
        );
    }
}

export default FormField;
