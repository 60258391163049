import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { AnyAction, Reducer } from "redux";
import { initAction } from "../../shared/actions";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import { Contact } from "../../shared/models/Contact";
import { ContactFields } from "../../shared/models/ContactFields";
import { ForceEditableFlag } from "../../shared/models/ForceEditableFlag";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { Notes } from "../../shared/models/Notes";
import { PartDefinition } from "../../shared/models/PartDefinition";
import { ID_KEY } from "../../shared/models/Resource";
import {
    createAdditionalRecipientsChanges,
    createAddressChanges,
    createAddressSelectionChanges,
    createContactChanges,
    createContactSelectionChanges,
    createEoriChange,
    createNotesChanges,
    createTaxpayerFieldChanges,
} from "../../utils/partnerForm";
import { createAddLineItemChanges, createLineItemChanges, createRemoveLineItemChanges } from "../../utils/partOrder";
import { createApiRequestCollectionSlice, createApiRequestSlice } from "../../utils/reducers";
import { EMPTY_OBJECT } from "../../utils/selectors";
import { ADDRESS_KEY, CONTACT_KEY, PartOrder } from "../models/PartOrder";
import { PartOrderFetchParameters } from "../models/PartOrderFetchParameters";
import { PartOrderLineItem } from "../models/PartOrderLineItem";
import { PartOrderLineItemNew } from "../models/PartOrderLineItemNew";
import { PartOrderLineItemReference } from "../models/PartOrderLineItemReference";
import { PartOrderReference } from "../models/PartOrderReference";
import { PartOrderResult } from "../models/PartOrderResult";
import { PartOrderResults } from "../models/PartOrderResults";
import { PartOrdersRootState, PartOrdersState } from "../models/PartOrdersState";
import { QuantityLimits } from "../models/QuantityLimits";

const NAMESPACE = "partOrders";

// Nested slices
const fetchSlice = createApiRequestSlice<PartOrderFetchParameters, PartOrderResults, PartOrderFetchParameters>(
    `${NAMESPACE}/fetch`
);
const saveSlice = createApiRequestSlice<PartOrderReference, PartOrderResult, PartOrderReference>(`${NAMESPACE}/save`);
const submitSlice = createApiRequestSlice<PartOrderReference, PartOrderResult, PartOrderReference>(
    `${NAMESPACE}/submit`
);
const fetchShippingAddressSuggestionsSlice = createApiRequestCollectionSlice<
    PartOrderReference,
    Address[],
    PartOrderReference
>(`${NAMESPACE}/fetchShippingAddressSuggestions`, []);
const fetchPartDefinitionsSlice = createApiRequestSlice<undefined, PartDefinition[]>(
    `${NAMESPACE}/fetchPartDefinitions`,
    []
);
const fetchQuantityLimitsSlice = createApiRequestCollectionSlice<
    PartOrderReference,
    QuantityLimits,
    PartOrderReference
>(`${NAMESPACE}/fetchQuantityLimits`, EMPTY_OBJECT);

// Root slice
function initialRootState(): PartOrdersRootState {
    return {
        byId: undefined,
        edits: {},
        ui: {},
        validations: {},
    };
}

const rootSlice = createSlice({
    name: NAMESPACE,
    initialState: initialRootState(),
    reducers: {
        reset: (state, action: PayloadAction<PartOrderReference>) => {
            const id = action.payload.partOrderId;
            delete state.edits[id];
            delete state.validations[id];
        },
        addLineItem: (state, action: PayloadAction<PartOrderReference & ForceEditableFlag>) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createAddLineItemChanges()];
        },
        removeLineItem: (state, action: PayloadAction<PartOrderLineItemReference & ForceEditableFlag>) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createRemoveLineItemChanges(action.payload.at)];
        },
        changeLineItem: (
            state,
            action: PayloadAction<
                PartOrderLineItemReference &
                    ForceEditableFlag & {
                        values: Partial<PartOrderLineItem> | Partial<PartOrderLineItemNew>;
                    }
            >
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createLineItemChanges(action.payload.values, action.payload.at),
            ];
        },
        changeAddress: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { values: Partial<AddressFields> }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createAddressChanges(action.payload.values, ADDRESS_KEY)];
        },
        changeContact: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { values: Partial<ContactFields> }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createContactChanges(action.payload.values, CONTACT_KEY)];
        },
        selectAddress: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { address?: Address | AddressFields }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAddressSelectionChanges(action.payload.address, ADDRESS_KEY),
            ];
        },
        selectContact: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { contact?: Contact | ContactFields }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createContactSelectionChanges(action.payload.contact, CONTACT_KEY),
            ];
        },
        changeNotes: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { values: Partial<Notes> }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createNotesChanges(action.payload.values)];
        },
        changeAdditionalRecipients: (
            state,
            action: PayloadAction<
                PartOrderReference &
                    ForceEditableFlag & {
                        recipients: string[];
                    }
            >
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAdditionalRecipientsChanges(action.payload.recipients),
            ];
        },
        changeTaxpayerFields: (
            state,
            action: PayloadAction<
                PartOrderReference &
                    ForceEditableFlag & {
                        taxpayerFields: Partial<Record<string, string>>;
                    }
            >
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createTaxpayerFieldChanges(action.payload.taxpayerFields),
            ];
        },
        changeEori: (
            state,
            action: PayloadAction<PartOrderReference & ForceEditableFlag & { eori: string | undefined }>
        ) => {
            const id = action.payload.partOrderId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createEoriChange(action.payload.eori)];
        },
        // Validation
        doValidate: (state, _action: PayloadAction<PartOrderReference & ForceEditableFlag>) => state,
        didValidate: (
            state,
            action: PayloadAction<
                PartOrderReference & {
                    errors: ModelValidationErrors<PartOrder>;
                }
            >
        ) => {
            state.validations[action.payload.partOrderId] = action.payload.errors;
        },
    },
    extraReducers: (builder) => {
        function storeResult(state: PartOrdersRootState, action: PayloadAction<PartOrderResult>) {
            const id = action.payload.partOrder[ID_KEY];
            state.byId = {
                ...state.byId,
                [id]: action.payload.partOrder,
            };
            state.edits = {
                ...state.edits,
                [id]: [],
            };
        }

        // Fetch
        builder.addCase(fetchSlice.actions.did, (state, action) => {
            state.byId = keyBy(action.payload.partOrders, "id");
        });

        // Save
        builder.addCase(saveSlice.actions.did, storeResult);

        // Submit
        builder.addCase(submitSlice.actions.did, storeResult);
    },
});

// Combine root and nested reducers
function initialState(): PartOrdersState {
    return {
        ...rootSlice.reducer(undefined, initAction),
        fetch: fetchSlice.reducer(undefined, initAction),
        save: saveSlice.reducer(undefined, initAction),
        submit: submitSlice.reducer(undefined, initAction),
        fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.reducer(undefined, initAction),
        fetchPartDefinitions: fetchPartDefinitionsSlice.reducer(undefined, initAction),
        fetchQuantityLimits: fetchQuantityLimitsSlice.reducer(undefined, initAction),
    };
}

const partOrdersReducer: Reducer<PartOrdersState, AnyAction> = (state = initialState(), action) => {
    return {
        ...rootSlice.reducer(state, action),
        fetch: fetchSlice.reducer(state.fetch, action),
        save: saveSlice.reducer(state.save, action),
        submit: submitSlice.reducer(state.submit, action),
        fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.reducer(
            state.fetchShippingAddressSuggestions,
            action
        ),
        fetchPartDefinitions: fetchPartDefinitionsSlice.reducer(state.fetchPartDefinitions, action),
        fetchQuantityLimits: fetchQuantityLimitsSlice.reducer(state.fetchQuantityLimits, action),
    };
};

const partOrdersActions = {
    ...rootSlice.actions,
    fetch: fetchSlice.actions,
    save: saveSlice.actions,
    submit: submitSlice.actions,
    fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.actions,
    fetchQuantityLimits: fetchQuantityLimitsSlice.actions,
    fetchPartDefinitions: fetchPartDefinitionsSlice.actions,
};

export { partOrdersReducer, partOrdersActions };
