import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import { AuthService } from "../../../services/auth";
import { UserContext } from "../../contexts/UserContext";
import { WithSudoProps } from "../../hoc/withSudo";
import { messages } from "../../messages";
import Button from "../Button";
import Icon from "../Icon";
import Message from "../Message";

interface Props extends WithSudoProps {
    onToggle?: (next: boolean) => void;
}

/**
 * Renders nothing if the current user is not an Admin (Netflix employee).
 * Otherwise, renders a button that appends the `sudo` flag to the query params
 * of the current location.
 */
function SudoToggle({ sudo, toggleSudo, onToggle }: Props) {
    const user = useContext(UserContext);

    const handleClick = useCallback(() => {
        const next = !sudo;
        toggleSudo(next);
        onToggle?.(next);
    }, [sudo, toggleSudo, onToggle]);

    if (!AuthService.checkIsAdmin(user)) {
        return null;
    }

    return (
        <div className={classNames("SudoToggle", { active: sudo })}>
            {sudo ? (
                <Message
                    asBlock={false}
                    status="warning"
                    icon={() => <Icon size="lg" icon="id-badge" fixedWidth />}
                    message={messages.notice.disableSudo()}
                />
            ) : (
                <Message
                    asBlock={false}
                    icon={() => <Icon size="lg" icon={["far", "id-badge"]} fixedWidth />}
                    message={messages.notice.enableSudo()}
                />
            )}
            <Button kind={sudo ? "attention" : undefined} onClick={handleClick}>
                {sudo ? messages.actions.restore() : messages.actions.unlock()}
            </Button>
        </div>
    );
}

export default SudoToggle;
