import { MapDispatchToProps } from "react-redux";
import { authActions } from "../../../auth/reducer";
import { WithUser } from "../../../shared/hoc/withUser";
import { Props } from "./App";

export type DispatchProps = Pick<Props, "onClickLogOut">;
/**
 * These types are dependent on the ordering of the HOCs. We're applying the
 * redux connector last, so the OwnProps represents the props of the HOC after
 * withUser and ReactRedux connect are applied.
 */
export type OwnPropsUser = Omit<Props, keyof WithUser>;
export type OwnPropsRedux = Omit<OwnPropsUser, keyof DispatchProps>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnPropsRedux> = dispatch => {
    return {
        onClickLogOut: () => dispatch(authActions.logout())
    };
};

export { mapDispatchToProps };
