import React, { Component } from "react";

/**
 * A simple container for form actions such as Submit and Cancel. For styling.
 */
class FormActions extends Component {
    render() {
        const { children } = this.props;
        return <div className="FormActions">{children}</div>;
    }
}

export default FormActions;
