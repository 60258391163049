import classnames from "classnames";
import React, { MouseEvent, PureComponent } from "react";
import ButtonGroup from "../Button/ButtonGroup";
import Loading from "../Loading";
import ButtonAccessoryLauncher from "./ButtonAccessoryLauncher";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /**
     * The kind of the button. Applies styling. Default is "primary".
     */
    kind?: "action" | "primary" | "dismiss" | "danger" | "attention";
    /**
     * Adds a border radius to the button. Defaults to true.
     */
    rounded?: boolean;
    pill?: boolean;
    /**
     * The button will take the full width of parent. Default is false.
     */
    fluid?: boolean;
    loading?: boolean;
    size?: "small";
    accessory?: () => JSX.Element;
}

class Button extends PureComponent<Props> {
    static Launcher = ButtonAccessoryLauncher;
    static Group = ButtonGroup;

    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        const {
            kind = "primary",
            accessory,
            className,
            loading = false,
            children,
            disabled,
            rounded = true,
            fluid = false,
            pill = false,
            size,
            ...props
        } = this.props;

        return (
            <div
                className={classnames("Button", className, kind, size, {
                    loading,
                    rounded,
                    fluid,
                    withAccessory: !!accessory,
                    disabled,
                    pill,
                })}
            >
                <button type="button" {...props} disabled={disabled} onClick={this.handleClick}>
                    {loading ? <Loading size="lg" /> : children}
                </button>
                {accessory ? accessory() : null}
            </div>
        );
    }

    private handleClick(evt: MouseEvent<HTMLButtonElement>) {
        const { onClick, disabled = false, loading = false } = this.props;

        if (disabled || loading) {
            return;
        }

        if (onClick) {
            onClick(evt);
        }
    }
}

export default Button;
