import React, { PureComponent } from "react";
import { AddressFields, ADDRESS_KEYS, STREET_1_KEY, STREET_2_KEY } from "../../models/AddressFields";
import Segment from "../Segment";

import Value from "../Value";
import { messages } from "../../messages";

interface Props<T extends AddressFields = AddressFields> {
    address: Partial<T>;
    fields: Readonly<(keyof T)[]>;
    getLabel: (key: keyof T) => string;
}

class Address<T extends AddressFields = AddressFields> extends PureComponent<Props<T>> {
    static defaultProps = {
        fields: ADDRESS_KEYS
    };

    render() {
        const { address, fields, getLabel } = this.props;
        return (
            <div className="Address">
                <Segment.Group>
                    {fields.map(key => {
                        const value = address[key];
                        let label = getLabel(key);

                        // If there is no "line 2", it doesn't make sense to specify "line 1"
                        if (key === STREET_1_KEY && !address[STREET_2_KEY]) {
                            label = messages.labels.addressField.street();
                        }

                        if (!value) {
                            return null;
                        }

                        return (
                            <Segment key={`${key}`}>
                                <Value label={label}>
                                    <span>{value}</span>
                                </Value>
                            </Segment>
                        );
                    })}
                </Segment.Group>
            </div>
        );
    }
}

export default Address;
