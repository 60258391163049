import { DayOfWeek } from "../../shared/models/DayOfWeek";
import { TimeOfDay } from "../../shared/models/TimeOfDay";

export const PREFERRED_DAY_OF_WEEK_KEY = "preferredDayOfWeek";
export const PREFERRED_TIME_OF_DAY_KEY = "preferredTimeOfDay";

export const OCA_RETURN_PICKUP_PREFERENCES_KEYS: readonly (keyof OcaReturnPickupPreferences)[] = [
    PREFERRED_DAY_OF_WEEK_KEY,
    PREFERRED_TIME_OF_DAY_KEY,
];

export interface OcaReturnPickupPreferences {
    [PREFERRED_DAY_OF_WEEK_KEY]?: DayOfWeek;
    [PREFERRED_TIME_OF_DAY_KEY]?: TimeOfDay;
}
