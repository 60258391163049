import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { Container } from "../../../shared/components/PartnerForm";
import PartnerFormPresenter from "../../../shared/components/PartnerFormPresenter";
import PartnerFormsList from "../../../shared/components/PartnerFormsList";
import SudoToggle from "../../../shared/components/SudoToggle";
import { SudoContext } from "../../../shared/contexts/SudoContext";
import { messages } from "../../../shared/messages";
import { Dictionary } from "../../../shared/models/Dictionary";
import { PATH_SITE_SURVEYS } from "../../../shared/routes";
import SiteSurvey from "../../../siteSurveys/components/SiteSurvey";
import { SiteSurvey as SiteSurveyModel } from "../../../siteSurveys/models/SiteSurvey";
import {
    checkIsCancelled,
    checkIsEditable,
    checkIsNew,
    checkIsSubmitted,
    getLastUpdated,
} from "../../../utils/siteSurvey";
import { siteSurveysActions } from "../../reducer";

export interface Props extends RouteComponentProps<Record<string, string>> {
    orgId: number;
    /**
     * The IDs of the site surveys to fetch
     */
    siteSurveyIds?: number[];
    /**
     * The site surveys to present as a dictionary
     */
    byId?: Dictionary<SiteSurveyModel>;
    /**
     * The same site surveys, sorted
     */
    sorted?: SiteSurveyModel[];
    errorFetching: Error | string | null;
    isFetching: boolean;
}

function SiteSurveys({ history, errorFetching, isFetching, orgId, byId, siteSurveyIds, sorted, ...props }: Props) {
    const sudo = useContext(SudoContext);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(siteSurveysActions.fetch.do({ orgId, siteSurveyIds }));
    }, [dispatch, orgId, siteSurveyIds]);

    useEffect(() => {
        dispatch(siteSurveysActions.fetchDatacenterOptions.do());
    }, [dispatch]);

    return (
        <>
            <SudoToggle />
            <Container
                className="SiteSurveys"
                path={PATH_SITE_SURVEYS}
                master={() => (
                    <PartnerFormsList<SiteSurveyModel>
                        isFetching={isFetching}
                        entries={sorted}
                        checkIsEditable={(siteSurvey) => checkIsEditable({ siteSurvey })}
                        checkIsNew={checkIsNew}
                        checkIsSubmitted={checkIsSubmitted}
                        checkIsCancelled={checkIsCancelled}
                        lastUpdated={getLastUpdated}
                        emptyMessage={messages.notice.noMatchingSiteSurveys()}
                        history={history}
                        {...props}
                    />
                )}
                detail={() => (
                    <Route
                        render={(props) => (
                            <PartnerFormPresenter<SiteSurveyModel>
                                {...props}
                                byId={byId}
                                error={errorFetching}
                                doesNotExistMessage={messages.notice.siteSurveyDoesNotExist()}
                                makeSelectionMessage={messages.notice.selectASiteSurvey()}
                                cancelledMessage={messages.notice.siteSurveyCancelled()}
                                checkIsCancelled={checkIsCancelled}
                            >
                                {(siteSurvey) => (
                                    <SiteSurvey
                                        {...props}
                                        orgId={orgId}
                                        siteSurvey={siteSurvey}
                                        forceEditable={sudo}
                                    />
                                )}
                            </PartnerFormPresenter>
                        )}
                    />
                )}
            />
        </>
    );
}

export default SiteSurveys;
