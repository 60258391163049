import { AxiosResponse } from "axios";
import { ConstraintViolation } from "./ConstraintViolation";

export class RequestError extends Error {
    code?: number;
    url?: string;
    method?: string;
    response?: AxiosResponse;

    constructor(url?: string, method?: string, message?: string) {
        super(message || "An unknown error occurred");
        this.url = url;
        this.method = method;
    }
}

export class UnauthorizedError extends RequestError {
    code = 401;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "You are unauthorized to make this request");
    }
}

export class ForbiddenError extends RequestError {
    code = 403;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "You don't have permission for this request");
    }
}

export class BadRequestError extends RequestError {
    code = 400;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "This request is not allowed");
    }
}

export class NotFoundError extends RequestError {
    code = 404;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "This resource does not exist");
    }
}

export class ConflictError extends RequestError {
    code = 409;
    constraintViolations?: ConstraintViolation[];

    constructor(url?: string, method?: string, message?: string, constraintViolations?: ConstraintViolation[]) {
        super(url, method, message || "Request failed");
        this.constraintViolations = constraintViolations;
    }
}

export class ServerError extends RequestError {
    code = 500;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "An unknown error occurred");
    }
}

export class ServiceUnavailableError extends RequestError {
    code = 503;
    constructor(url?: string, method?: string, message?: string) {
        super(url, method, message || "The service is temporarily unavailable");
    }
}
