import React, { PureComponent } from "react";
import { messages } from "../../messages";
import { Content } from "../PartnerForm";
import MissingInfo from "../MissingInfo";

interface Props {
    recipients: readonly string[];
}

class AdditionalRecipients extends PureComponent<Props> {
    render() {
        const { recipients } = this.props;
        return (
            <Content className="AdditionalRecipients" title={messages.title.additionalRecipients()}>
                {recipients.length === 0 ? (
                    <MissingInfo message={messages.notice.missingAdditionalRecipients()} />
                ) : (
                    <ul>
                        {recipients.map((recipient, idx) => (
                            <li key={idx} className="AdditionalRecipientslist">
                                <span>{recipient}</span>
                            </li>
                        ))}
                    </ul>
                )}
            </Content>
        );
    }
}

export default AdditionalRecipients;
