import classnames from "classnames";
import React, { Component } from "react";
import Icon from "../../../shared/components/Icon";
import { getIcon, getLabel } from "../../../shared/models/OcaClass";
import { getStorageClass, NetworkConfig } from "../../models/NetworkConfig";

interface Props {
    label?: string;
    networkConfig: NetworkConfig;
    onClick?: () => void;
    className?: string;
}

class ApplianceInfo extends Component<Props> {
    render() {
        const { label, className, onClick, networkConfig } = this.props;
        const storageClass = getStorageClass(networkConfig);

        return (
            <div className={classnames("ApplianceInfo", className)} onClick={onClick}>
                {label ? <span className="ApplianceInfo-label">{label}</span> : null}
                {storageClass && (
                    <div className="ApplianceInfo-applianceTypeBadge">
                        <Icon icon={getIcon(storageClass)} />
                        <span className="ApplianceInfo-applianceTypeLabel">{getLabel(storageClass)}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default ApplianceInfo;
