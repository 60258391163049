import React, { Component } from "react";
import Meta from "../Meta";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";
import { messages } from "../../messages";

interface Props {
    notes: string;
    label?: string;
}

class Notes extends Component<Props> {
    render() {
        const { label, notes } = this.props;

        return (
            <Content title={messages.title.notes()}>
                {notes.length === 0 ? (
                    <MissingInfo message={messages.notice.missingNotes()} />
                ) : (
                    <>
                        {label && <Meta>{label}</Meta>}
                        <p className="Notes-message">{notes}</p>
                    </>
                )}
            </Content>
        );
    }
}

export default Notes;
