import classnames from "classnames";
import React, { memo, ReactNode } from "react";
import { Conditional } from "../../props/conditional";

interface Props extends Conditional {
    inline?: boolean;
    children?: ReactNode;
}

/**
 * Renders the child with the "meta" styling.
 */
function Meta({ when = true, children, inline }: Props) {
    return when ? <div className={classnames("Meta", { inline })}>{children}</div> : null;
}

export default memo(Meta);
