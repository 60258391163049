import React, { PureComponent } from "react";
import Menu from "../Menu";
import { Level, ValidationError } from "../../models/ValidationError";
import { Status } from "../../models/Status";

function mapErrorToStatus(error?: ValidationError): Status | undefined {
    if (!error) {
        return "success";
    }

    switch (error.level) {
        case Level.Incomplete:
            return "warning";
        case Level.Invalid:
            return "error";
        default:
            break;
    }

    return;
}

interface Props {
    title: string;
    error?: ValidationError;
    showStatus?: boolean;
}

class MenuItem extends PureComponent<Props> {
    render() {
        const { title, error, showStatus = true } = this.props;

        return (
            <Menu.ItemWithStatus status={showStatus ? mapErrorToStatus(error) : undefined}>{title}</Menu.ItemWithStatus>
        );
    }
}

export default MenuItem;
