import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import AdditionalRecipients from "../../../shared/components/AdditionalRecipients";
import AdditionalRecipientsEditor from "../../../shared/components/AdditionalRecipientsEditor";
import AddressEditor from "../../../shared/components/AddressEditor";
import ContactEditor from "../../../shared/components/ContactEditor";
import Contacts from "../../../shared/components/Contacts";
import Divider from "../../../shared/components/Divider";
import MenuItem from "../../../shared/components/MenuItem";
import Notes from "../../../shared/components/Notes";
import NotesEditor from "../../../shared/components/NotesEditor";
import PartnerForm from "../../../shared/components/PartnerForm";
import PartnerFormInstance, { Header, Info } from "../../../shared/components/PartnerForm/PartnerForm";
import PartnerFormActionItems from "../../../shared/components/PartnerFormActionItems";
import SaveAndSubmit from "../../../shared/components/SaveAndSubmit";
import Segment from "../../../shared/components/Segment";
import ShippingInfo from "../../../shared/components/ShippingInfo";
import Tab, { Pane } from "../../../shared/components/Tab";
import { CLICK_ERROR_ACTION_ITEM } from "../../../shared/events";
import logger from "../../../shared/logger";
import { messages } from "../../../shared/messages";
import { AuthService } from "../../../services/auth";
import { UserContext } from "../../../shared/contexts/UserContext";
import { AddressFields, DEFAULT_ADDRESS_VALUES } from "../../../shared/models/AddressFields";
import { PartnerFormKind } from "../../../shared/models/PartnerFormKind";
import { ValidationError } from "../../../shared/models/ValidationError";
import { Change, ChangeAddress, ChangeContact, SelectAddress, SelectContact } from "../../../shared/props/actions";
import { traverse } from "../../../shared/validation";
import { PartnerFormProps } from "../../../siteSurveys/models/PartnerFormProps";
import { checkIsNew, checkIsSubmitted, getLastUpdated, getSubmittedOn } from "../../../utils/ocaReturn";
import { getAllowSave, getAllowSubmit, getSaveHint } from "../../../utils/partnerForm";
import { ADDRESS_KEY, CONTACT_KEY, OcaReturn as OcaReturnModel } from "../../models/OcaReturn";
import { OcaReturnPickupPreferences as OcaReturnPickupPreferencesModel } from "../../models/OcaReturnPickupPreferences";
import { OcaReturnPickupProperties as OcaReturnPickupPropertiesModel } from "../../models/OcaReturnPickupProperties";
import { OcaReturnView, title } from "../../models/OcaReturnView";
import OcaReturnDetails from "../OcaReturnDetails";
import OcaReturnPickupPreferences from "../OcaReturnPickupPreferences";
import OcaReturnPickupPreferencesEditor from "../OcaReturnPickupPreferencesEditor";
import OcaReturnPickupProperties from "../OcaReturnPickupProperties";
import OcaReturnPickupPropertiesEditor from "../OcaReturnPickupPropertiesEditor";

export interface Props extends RouteComponentProps<Record<string, string>>, PartnerFormProps<OcaReturnModel> {
    addressSuggestions: AddressFields[];
    ocaReturn: OcaReturnModel;
    onChangeOcaReturnPickupProperties: Change<OcaReturnPickupPropertiesModel>;
    onChangeOcaReturnPickupPreferences: Change<OcaReturnPickupPreferencesModel>;
    onChangeAddress: ChangeAddress;
    onChangeContact: ChangeContact;
    onSelectAddress: SelectAddress;
    onSelectContact: SelectContact;
}

class OcaReturn extends PureComponent<Props> {
    private partnerForm = React.createRef<PartnerFormInstance>();

    constructor(props: Props) {
        super(props);

        this.handleRecoveryAction = this.handleRecoveryAction.bind(this);
        this.handleChangePickupProperties = this.handleChangePickupProperties.bind(this);
        this.handleChangePickupPreferences = this.handleChangePickupPreferences.bind(this);
    }

    componentDidMount() {
        const { clearSaveAndSubmitErrors, validate } = this.props;

        clearSaveAndSubmitErrors();
        validate();
    }

    public renderAddress(): ReactNode {
        const {
            forceEditable,
            addressSuggestions,
            countries,
            subdivisionsByCountryCode,
            ocaReturn,
            modified,
            onChangeAddress,
            errors,
            onSelectAddress,
        } = this.props;

        return ocaReturn.editable || forceEditable ? (
            <AddressEditor
                title={messages.title.pickupAddress()}
                emptyAddress={DEFAULT_ADDRESS_VALUES}
                initialAddress={ocaReturn[ADDRESS_KEY]}
                address={modified?.[ADDRESS_KEY]}
                chooseFrom={addressSuggestions}
                countries={countries}
                subdivisionsByCountryCode={subdivisionsByCountryCode}
                onEdit={onChangeAddress}
                onSelect={onSelectAddress}
                validationErrors={traverse(errors, ADDRESS_KEY)}
            />
        ) : (
            <ShippingInfo shippingAddress={ocaReturn[ADDRESS_KEY]} />
        );
    }

    public renderContact(): ReactNode {
        const {
            forceEditable,
            contacts,
            modified,
            ocaReturn,
            onChangeContact,
            onSelectContact,
            errors,
            orgId,
        } = this.props;

        return ocaReturn.editable || forceEditable ? (
            <UserContext.Consumer>
                {(user) => {
                    const isMeechumUser = AuthService.checkIsAdminOrSsoPartner(user);

                    return (
                        <ContactEditor
                            required
                            label={messages.labels.contactType.generic()}
                            description={messages.notes.contactDescription.ocaReturn()}
                            isMeechumUser={isMeechumUser}
                            orgId={orgId}
                            validationErrors={traverse(errors, CONTACT_KEY)}
                            showRoles={false}
                            chooseFrom={contacts}
                            initialContact={ocaReturn[CONTACT_KEY]}
                            contact={modified?.[CONTACT_KEY]}
                            onSelect={onSelectContact}
                            onEdit={onChangeContact}
                        />
                    );
                }}
            </UserContext.Consumer>
        ) : (
            <Contacts
                contacts={{
                    [messages.labels.contactType.generic()]: ocaReturn[CONTACT_KEY],
                }}
            />
        );
    }

    public renderPropertiesEditor(): ReactNode {
        const { forceEditable, ocaReturn, errors, modified } = this.props;
        return ocaReturn.editable || forceEditable ? (
            <OcaReturnPickupPropertiesEditor
                initialProperties={ocaReturn}
                properties={modified}
                onChange={this.handleChangePickupProperties}
                validationErrors={errors}
            />
        ) : (
            <OcaReturnPickupProperties properties={ocaReturn} />
        );
    }

    public renderPreferencesEditor(): ReactNode {
        const { forceEditable, ocaReturn, errors, modified } = this.props;
        return ocaReturn.editable || forceEditable ? (
            <OcaReturnPickupPreferencesEditor
                initialPreferences={ocaReturn}
                preferences={modified}
                onChange={this.handleChangePickupPreferences}
                validationErrors={errors}
            />
        ) : (
            <OcaReturnPickupPreferences preferences={ocaReturn} />
        );
    }

    public renderAdditionalRecipientsEditor(): ReactNode {
        const {
            forceEditable,
            ocaReturn,
            onChangeAdditionalRecipients,
            additionalRecipients,
            initialAdditionalRecipients,
        } = this.props;

        return ocaReturn.editable || forceEditable ? (
            <AdditionalRecipientsEditor
                initialRecipients={initialAdditionalRecipients}
                recipients={additionalRecipients}
                onChange={onChangeAdditionalRecipients}
            />
        ) : (
            <AdditionalRecipients recipients={additionalRecipients} />
        );
    }

    public renderNotes(): ReactNode {
        const { forceEditable, ocaReturn, modified, onChangeNotes } = this.props;

        return ocaReturn.editable || forceEditable ? (
            <NotesEditor initialNotes={ocaReturn.notes} notes={modified?.notes} onChange={onChangeNotes} />
        ) : (
            <Notes label={messages.labels.notes()} notes={ocaReturn.notes} />
        );
    }

    render() {
        const {
            isDirty,
            isValid,
            ocaReturn,
            isComplete,
            saveState,
            submitState,
            onSubmit,
            onSave,
            rolledUpErrors,
            clearSaveAndSubmitErrors,
            forceEditable = false,
            ...props
        } = this.props;
        const isSubmitted = checkIsSubmitted(ocaReturn);
        const allowSave = getAllowSave(ocaReturn.editable || forceEditable, isDirty, isValid);
        const allowSubmit = getAllowSubmit(
            ocaReturn.editable || forceEditable,
            isDirty,
            isComplete,
            isSubmitted,
            allowSave
        );

        return (
            <PartnerForm
                ref={this.partnerForm}
                {...props}
                hasUnsavedChanges={isDirty}
                header={
                    <Header
                        title={ocaReturn.name}
                        isNew={checkIsNew(ocaReturn)}
                        editable={ocaReturn.editable || forceEditable}
                        accessory={() => (
                            <SaveAndSubmit
                                allowSave={allowSave}
                                allowSubmit={allowSubmit}
                                isSubmitted={isSubmitted}
                                saveState={saveState}
                                submitState={submitState}
                                onSubmit={onSubmit}
                                onSave={onSave}
                                saveHint={getSaveHint(PartnerFormKind.OcaReturn, saveState, isDirty, allowSave)}
                                onDismiss={clearSaveAndSubmitErrors}
                            />
                        )}
                    />
                }
                info={
                    <Segment.Group>
                        <Segment>
                            <Info
                                lastUpdated={getLastUpdated(ocaReturn)}
                                submittedOn={getSubmittedOn(ocaReturn)}
                                accessory={{
                                    label: messages.labels.toDo(),
                                    render: () => (
                                        <PartnerFormActionItems
                                            kind={PartnerFormKind.OcaReturn}
                                            isComplete={isComplete}
                                            isSubmitted={isSubmitted}
                                            allowSave={allowSave}
                                            allowSubmit={allowSubmit}
                                            errors={rolledUpErrors}
                                            partnerForm={ocaReturn}
                                            onClickRecoveryAction={this.handleRecoveryAction}
                                        />
                                    ),
                                }}
                            />
                        </Segment>
                        <Segment>
                            <OcaReturnDetails ocaReturnDetails={ocaReturn.ocaReturnDetails} />
                        </Segment>
                    </Segment.Group>
                }
            >
                {(view, onChangeView) => <Tab panes={this.panes} activePane={view} onSwitchPane={onChangeView} />}
            </PartnerForm>
        );
    }

    private get panes(): Pane[] {
        const { rolledUpErrors } = this.props;

        const panes: Pane[] = [
            {
                kind: OcaReturnView.Pickup,
                menuItem: <MenuItem title={title(OcaReturnView.Pickup)} error={rolledUpErrors[OcaReturnView.Pickup]} />,
                render: () => (
                    <Segment.Group>
                        <Segment.Group horizontal>
                            <Segment>{this.renderAddress()}</Segment>
                            <Segment>{this.renderContact()}</Segment>
                        </Segment.Group>
                        <Divider />
                        <Segment>{this.renderPropertiesEditor()}</Segment>
                        <Divider />
                        <Segment>{this.renderPreferencesEditor()}</Segment>
                    </Segment.Group>
                ),
            },
            {
                kind: OcaReturnView.More,
                menuItem: <MenuItem title={title(OcaReturnView.More)} error={rolledUpErrors[OcaReturnView.More]} />,
                render: () => (
                    <Segment.Group>
                        <Segment>{this.renderAdditionalRecipientsEditor()}</Segment>
                    </Segment.Group>
                ),
            },
        ];

        return panes;
    }

    private handleRecoveryAction(view: OcaReturnView, validationError: ValidationError) {
        logger.trackEvent(CLICK_ERROR_ACTION_ITEM, validationError);
        this.partnerForm.current?.goToView(view);
    }

    private handleChangePickupProperties(values: Partial<OcaReturnPickupPropertiesModel>) {
        const { onChangeOcaReturnPickupProperties } = this.props;
        onChangeOcaReturnPickupProperties(values);
    }

    private handleChangePickupPreferences(values: Partial<OcaReturnPickupPreferencesModel>) {
        const { onChangeOcaReturnPickupPreferences } = this.props;
        onChangeOcaReturnPickupPreferences(values);
    }
}

export default OcaReturn;
