import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../models/AppState";
import { appActions } from "../../reducer";
import { getActiveOrg, getOrgs } from "../../reducer/selectors";
import { Props } from "./PartnerFormsNav";

export type DispatchProps = Pick<Props, "fetchOrg">;
export type StateProps = Pick<Props, "activeOrg" | "orgs">;
export type OwnProps = Omit<Props, keyof DispatchProps | keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = state => {
    return {
        activeOrg: getActiveOrg(state),
        orgs: getOrgs(state)
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = dispatch => {
    return {
        fetchOrg: orgId => dispatch(appActions.fetchActiveOrg.do(orgId))
    };
};

export { mapStateToProps, mapDispatchToProps };
