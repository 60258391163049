import React, { Component } from "react";
import { PartnerFormKind } from "../../models/PartnerFormKind";
import { ValidationError, Level } from "../../models/ValidationError";
import { Submittable } from "../../models/Submittable";
import { getRecoveryActions, getProgressionAction } from "../../../utils/partnerForm";
import { Editable } from "../../models/Editable";
import { Dictionary } from "../../models/Dictionary";
import Message from "../Message";
import Icon from "../Icon";

export interface Props<T extends string = string> {
    kind: PartnerFormKind;
    isEditable?: boolean;
    isComplete?: boolean;
    isSubmitted?: boolean;
    allowSave?: boolean;
    allowSubmit?: boolean;
    errors: Dictionary<ValidationError>;
    partnerForm: Editable & Submittable;
    onClickRecoveryAction?: (key: T, error: ValidationError) => void;
}

class PartnerFormActionItems<T extends string = string> extends Component<Props<T>> {
    constructor(props: Props<T>) {
        super(props);

        this.handleClickActionItemError = this.handleClickActionItemError.bind(this);
    }

    render() {
        const { kind, errors, partnerForm, isComplete, isSubmitted, allowSave, allowSubmit, isEditable } = this.props;
        const recoveryActions = getRecoveryActions(errors);

        if (recoveryActions.length) {
            return recoveryActions.map(([view, error], idx) => (
                <Message
                    key={idx}
                    asGroup
                    status={error.level === Level.Incomplete ? "warning" : "error"}
                    icon={() => <Icon icon="exclamation-circle" size="sm" />}
                    message={error.message}
                    onClick={() => this.handleClickActionItemError(view, error)}
                />
            ));
        } else {
            const progressionAction = getProgressionAction(kind, partnerForm, {
                isEditable,
                isComplete,
                allowSave,
                allowSubmit,
                isSubmitted
            });

            if (progressionAction) {
                return (
                    <Message
                        asBlock={false}
                        status={progressionAction.success ? "success" : undefined}
                        message={progressionAction.message}
                    />
                );
            }
        }

        return null;
    }

    private handleClickActionItemError(key: string, error: ValidationError) {
        const { onClickRecoveryAction } = this.props;
        if (onClickRecoveryAction) {
            onClickRecoveryAction(key as T, error);
        }
    }
}

export default PartnerFormActionItems;
