import { Props } from "./Datacenter";
import { MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import { getDatacenterOptions } from "../../../siteSurveys/reducer/selectors";

type StateProps = Pick<Props, "datacenterOptions">;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = state => {
    return {
        datacenterOptions: getDatacenterOptions(state)
    };
};

export { mapStateToProps };
