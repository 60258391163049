import classnames from "classnames";
import { lowerCase } from "lodash";
import React, { Component } from "react";
import Icon from "../../../shared/components/Icon";
import Segment from "../../../shared/components/Segment";
import Value from "../../../shared/components/Value";
import { messages } from "../../../shared/messages";
import { getIcon, getLabel } from "../../../shared/models/OcaClass";
import { OcaReturnDetail as OcaReturnDetailModel } from "../../models/OcaReturnDetail";

interface Props {
    ocaReturnDetail: OcaReturnDetailModel;
}

class OcaReturnDetail extends Component<Props> {
    render() {
        const {
            ocaReturnDetail: { hardwareIdentity, ipAddress, hostname }
        } = this.props;

        return (
            <div className={classnames("OcaReturnDetail", lowerCase(hardwareIdentity.storageClass))}>
                <Icon
                    className="OcaReturnDetail-icon"
                    icon={getIcon(hardwareIdentity.storageClass)}
                    title={getLabel(hardwareIdentity.storageClass)}
                />
                <Segment.Group>
                    <Segment>
                        <Value label={messages.labels.serialNumber()}>{hardwareIdentity.serialNumber}</Value>
                    </Segment>
                    {hostname && (
                        <Segment>
                            <Value label={messages.labels.lastReportedHostname()}>{hostname}</Value>
                        </Segment>
                    )}
                    {ipAddress && (
                        <Segment>
                            <Value label={messages.labels.lastReportedIP()}>{ipAddress}</Value>
                        </Segment>
                    )}
                    <Segment>
                        <Value label={messages.labels.uuid()}>{hardwareIdentity.uuid}</Value>
                    </Segment>
                </Segment.Group>
            </div>
        );
    }
}

export default OcaReturnDetail;
