import {
    OcaReturnPickupPreferences,
    PREFERRED_DAY_OF_WEEK_KEY,
    PREFERRED_TIME_OF_DAY_KEY
} from "../../ocaReturns/models/OcaReturnPickupPreferences";
import { required } from "../forms/validators";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

const OCA_RETURN_PICKUP_PREFERENCES_VALIDATOR: ModelValidator<OcaReturnPickupPreferences> = new Map<
    keyof OcaReturnPickupPreferences,
    Validator
>([[PREFERRED_DAY_OF_WEEK_KEY, required], [PREFERRED_TIME_OF_DAY_KEY, required]]);

export function validateOcaReturnPickupPreferences(
    preferences: OcaReturnPickupPreferences
): ModelValidationErrors<OcaReturnPickupPreferences> | undefined {
    return validate(preferences, OCA_RETURN_PICKUP_PREFERENCES_VALIDATOR);
}
