import { MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import {
    getErrorFetchingOcaReturnData,
    getIsFetchingOcaReturnData,
    getOcaReturnsById,
    getOcaReturnsSorted,
} from "../../reducer/selectors";
import { Props } from "./OcaReturns";

type StateProps = Pick<Props, "isFetching" | "errorFetching" | "byId" | "sorted">;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state) => {
    return {
        isFetching: getIsFetchingOcaReturnData(state),
        errorFetching: getErrorFetchingOcaReturnData(state),
        byId: getOcaReturnsById(state),
        sorted: getOcaReturnsSorted(state),
    };
};

export { mapStateToProps };
