import { PartDefinition } from "../../shared/models/PartDefinition";
import { Resource } from "../../shared/models/Resource";

export const QUANTITY_KEY = "partCount";
export const PART_KEY = "partDefinition";

export const PARTS_ORDER_LINE_ITEM_KEYS: (keyof PartOrderLineItemFields)[] = [QUANTITY_KEY, PART_KEY];

export interface PartOrderLineItemFields<T extends PartDefinition | Resource | number = PartDefinition | Resource> {
    [QUANTITY_KEY]: number;
    [PART_KEY]: T;
}
