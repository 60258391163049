import classnames from "classnames";
import React, { StatelessComponent } from "react";

interface Props {
    className?: string;
    width?: string;
}

const RoundedLine: StatelessComponent<Props> = ({ className, width }) => (
    <div className={classnames("RoundedLine", className)} style={{ width }} />
);

export default RoundedLine;
