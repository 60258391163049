import { Validator } from "../../models/Validator";
import { Level } from "../../models/ValidationError";

export function oneOf(options: string[], message: string): Validator {
    return (value: string) => {
        for (let i = 0; i < options.length; i++) {
            const option = options[i];

            if (value === option) {
                return;
            }
        }
        return {
            level: Level.Invalid,
            message
        };
    };
}
