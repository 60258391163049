import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

interface LengthValidatorOptions {
    min?: number;
    max?: number;
    /**
     * An optional regex for characters that should match, such as alphanumeric
     * /[a-z0-9]/gi. Only matched characters are tested against the min and max
     * parameters.
     */
    re?: RegExp;
    message?: string;
}

export const length = ({ min, max, re, message }: LengthValidatorOptions = {}): Validator => (value: string) => {
    let str = value;

    if (re !== undefined) {
        const matches = str.match(re);
        if (matches) {
            str = matches.reduce((result, str) => result + str);
        } else {
            str = "";
        }
    }

    if (min !== undefined) {
        if (str.length < min) {
            return {
                level: Level.Invalid,
                message: message || messages.forms.validation.tooShort()
            };
        }
    }

    if (max !== undefined) {
        if (str.length > max) {
            return {
                level: Level.Invalid,
                message: message || messages.forms.validation.tooLong()
            };
        }
    }

    return;
};
