import compareVersions from "compare-versions";
import { map } from "lodash";
import uuidv4 from "uuid/v4";
import { ComponentType } from "../partOrders/models/ComponentType";
import { ADDRESS_KEY, CONTACT_KEY, PartOrder, PARTS_ORDER_LINE_ITEMS_KEY } from "../partOrders/models/PartOrder";
import { PartOrderLineItem } from "../partOrders/models/PartOrderLineItem";
import { PartOrderLineItemFields, PARTS_ORDER_LINE_ITEM_KEYS } from "../partOrders/models/PartOrderLineItemFields";
import { PartOrderLineItemNew } from "../partOrders/models/PartOrderLineItemNew";
import { PartOrderStatus } from "../partOrders/models/PartOrderStatus";
import { PartOrderView } from "../partOrders/models/PartOrderView";
import { QuantityLimits } from "../partOrders/models/QuantityLimits";
import { getLastUpdated as defaultGetLastUpdated } from "../shared/models/Audited";
import { Operation } from "../shared/models/Change";
import { Changeset } from "../shared/models/Changeset";
import { EORI_FIELD_KEY } from "../shared/models/EoriCompliant";
import { OcaClass } from "../shared/models/OcaClass";
import { PartDefinition } from "../shared/models/PartDefinition";
import { ID_KEY } from "../shared/models/Resource";
import { getSubmittedOn as defaultGetSubmittedOn } from "../shared/models/Submittable";
import { TAXPAYER_FIELDS_KEY } from "../shared/models/TaxpayerAffiliated";
import { ValidationError } from "../shared/models/ValidationError";
import { getDiffs } from "./diffs";
import { isResource } from "./guards";
import { checkIsNew as defaultCheckIsNew, checkIsSubmitted as defaultCheckIsSubmitted } from "./partnerForm";
import { doesPathBeginWith } from "./paths";

/**
 * Returns whether or not the parts order is considered new.
 */
export function checkIsNew(partOrder: PartOrder): boolean {
    return defaultCheckIsNew(partOrder);
}

/**
 * Returns whether or not the parts order has been submitted
 * @param partOrder
 */
export function checkIsSubmitted(partOrder: PartOrder): boolean {
    return partOrder.jira !== undefined || defaultCheckIsSubmitted(partOrder);
}

export function checkIsCancelled(partOrder: PartOrder): boolean {
    return partOrder.partOrderStatus === PartOrderStatus.Cancelled;
}

export function getSubmittedOn(partOrder: PartOrder): Date | undefined {
    return defaultGetSubmittedOn(partOrder);
}

export function getLastUpdated(partOrder: PartOrder): Date {
    return defaultGetLastUpdated(partOrder);
}

export function getPartOrderName(partOrder: PartOrder): string {
    // eslint-disable-next-line max-len
    return `Part Order for ${partOrder.hardwareIdentity.storageClass} OCA (SN: ${partOrder.hardwareIdentity.serialNumber})`;
}

/**
 * Returns the line items that appear in lhs but not in rhs partOrder based on
 * id.
 * @param lhs
 * @param rhs
 */
export function getRemovedLineItems(
    lhs: (PartOrderLineItem | PartOrderLineItemNew)[],
    rhs: (PartOrderLineItem | PartOrderLineItemNew)[]
): PartOrderLineItem[] {
    const rhsIds = new Set(map(rhs.filter(isResource), "id"));
    return lhs.filter((lineItem): lineItem is PartOrderLineItem =>
        isResource(lineItem) ? !rhsIds.has(lineItem.id) : false
    );
}

export function createAddLineItemChanges(): Changeset {
    const emptyLineItem: PartOrderLineItemNew = {
        id: `NEW_${uuidv4()}`,
        version: 0,
    };

    return [
        {
            path: PARTS_ORDER_LINE_ITEMS_KEY,
            operation: Operation.Add,
            value: emptyLineItem,
        },
    ];
}

export function createRemoveLineItemChanges(index: number): Changeset {
    return [
        {
            path: [PARTS_ORDER_LINE_ITEMS_KEY, index],
            operation: Operation.Remove,
        },
    ];
}

export function createLineItemChanges(values: Partial<PartOrderLineItemFields>, at: number): Changeset {
    return [
        ...PARTS_ORDER_LINE_ITEM_KEYS.map((key) => ({
            path: [PARTS_ORDER_LINE_ITEMS_KEY, at, key],
            value: values[key],
        })),
    ];
}

/**
 * Does a diff of two parts order objects. Ignores diffs that are inconsequential.
 * @param original - The original parts order.
 * @param modified - The modified parts order to compare it with. Can be
 * undefined, in which case this function simply returns false.
 */
export function checkArePartOrdersDifferent(original: PartOrder | undefined, modified: PartOrder | undefined): boolean {
    if (!modified) {
        return false;
    }

    if (!original) {
        return true;
    }

    return getDiffs(original, modified).length > 0;
}

export function attributePartOrderErrorToView(error: ValidationError): PartOrderView | undefined {
    if (!error.path) {
        return;
    }

    if (doesPathBeginWith(error.path, PARTS_ORDER_LINE_ITEMS_KEY)) {
        return PartOrderView.Parts;
    }

    if (
        doesPathBeginWith(error.path, TAXPAYER_FIELDS_KEY) ||
        doesPathBeginWith(error.path, ADDRESS_KEY) ||
        doesPathBeginWith(error.path, CONTACT_KEY) ||
        doesPathBeginWith(error.path, EORI_FIELD_KEY)
    ) {
        return PartOrderView.Shipping;
    }

    return;
}

export function checkShouldDisallowPsuReplacements(ocaClass: OcaClass, versionNumber: string): boolean {
    // We no longer stock replacement PSUs for certain revisions. Check with
    // @kgabler or @ajayg for confirmation. If this logic changes, be sure to
    // notify Ops so that they can update their tooling to ensure only valid
    // part orders are created. See OCTDEPLOY-1267, OCTDEPLOY-1888.
    return ocaClass !== OcaClass.Flash && compareVersions.compare(versionNumber, "1.16", "<=");
}

/**
 * Returns whether or not a part should be restricted for the given part order.
 */
export function checkShouldRestrictPart(
    partDefinition: PartDefinition,
    partOrder: PartOrder,
    quantityLimits: QuantityLimits
): boolean {
    const ocaVersionNumber = partOrder.hardwareIdentity.versionNumber;
    const ocaClass = partOrder.hardwareIdentity.storageClass;
    // OCTDEPLOY-1267, OCTDEPLOY-1888
    if (
        partDefinition.componentType === ComponentType.Psu &&
        checkShouldDisallowPsuReplacements(ocaClass, ocaVersionNumber)
    ) {
        return true;
    }

    // Restrict if we are enforcing quantity limits and the limit is either 0 or
    // not defined.
    if (partOrder.enforceQuantityLimits && !quantityLimits[partDefinition[ID_KEY]]) {
        return true;
    }

    return false;
}
