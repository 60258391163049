import React, { memo } from "react";
import { messages } from "../../messages";
import { ApiRequestState } from "../../models/ApiRequestState";
import ActionLink from "../ActionLink";
import Button from "../Button";
import DoneIndicator from "../DoneIndicator";
import Icon from "../Icon";
import Message from "../Message";
import SlideDown from "../SlideDown";

interface Props {
    isSubmitted?: boolean;
    /**
     * Whether or not the save link should be clickable by user. Under certain
     * conditions, even when this is true, the button may be disabled or hidden.
     * For example, when the request is pending.
     */
    allowSave?: boolean;
    /** Same as `allowSave`, but for the submit button. */
    allowSubmit?: boolean;
    /** The hint related to the current save state */
    saveHint?: string | undefined;
    /**
     * The ongoing request state of the save action. Save button only reflects
     * the allowSave flag when pending = false
     */
    saveState: ApiRequestState;
    submitState: ApiRequestState;
    onSubmit: () => void;
    onSave: () => void;
    onDismiss?: () => void;
}

function SaveAndSubmit({
    allowSave = true,
    allowSubmit = false,
    saveState,
    submitState,
    onSubmit,
    onSave,
    isSubmitted,
    saveHint,
    onDismiss,
}: Props) {
    return (
        <div className="SaveAndSubmit">
            {isSubmitted ? (
                <DoneIndicator />
            ) : (
                <Button
                    kind="action"
                    type="submit"
                    loading={submitState.pending}
                    onClick={onSubmit}
                    disabled={!allowSubmit || saveState.pending}
                >
                    {messages.actions.submit()}
                </Button>
            )}
            <div className="SaveAndSubmit-saveStatus">
                <SlideDown in={!!saveHint}>
                    <span>
                        {saveHint}
                        {allowSave && !saveState.pending && (
                            <>
                                {" "}
                                <ActionLink
                                    label={saveState.error ? messages.actions.retry() : messages.actions.save()}
                                    disabled={saveState.pending}
                                    onClick={onSave}
                                    withBackground={false}
                                />
                            </>
                        )}
                    </span>
                </SlideDown>
                <Message
                    className="SaveAndSubmit-errorMessage SaveAndSubmit-saveError"
                    message={saveState.error}
                    status="error"
                    onDismiss={onDismiss}
                    highContrast
                    noWrap
                    icon={() => <Icon icon="exclamation-triangle" />}
                />
            </div>
            <div className="SaveAndSubmit-submitStatus">
                <SlideDown in={submitState.error !== null}>
                    <Message
                        status="error"
                        className="SaveAndSubmit-errorMessage SaveAndSubmit-submitError"
                        message={submitState.error}
                        onDismiss={onDismiss}
                        highContrast
                        icon={() => <Icon icon="exclamation-triangle" />}
                    />
                </SlideDown>
            </div>
        </div>
    );
}

export default memo(SaveAndSubmit);
