import { createSelector, Selector } from "reselect";
import { OcaReturnsState } from "../../ocaReturns/models/OcaReturnsState";
import { PartOrdersState } from "../../partOrders/models/PartOrdersState";
import { ApiRequestState } from "../../shared/models/ApiRequestState";
import { Country } from "../../shared/models/Country";
import { CountrySubdivision } from "../../shared/models/CountrySubdivision";
import { Org } from "../../shared/models/Org";
import { OrgSummary } from "../../shared/models/OrgSummary";
import { SiteSurveysState } from "../../siteSurveys/models/SiteSurveysState";
import { EMPTY_ARRAY } from "../../utils/selectors";
import { AppState } from "../models/AppState";

export const getFetchOrgsRequestState: Selector<AppState, ApiRequestState<OrgSummary[] | undefined>> = (state) =>
    state.orgs;
export const getOrgs: Selector<AppState, OrgSummary[] | undefined> = createSelector(
    [getFetchOrgsRequestState],
    ({ value }) => value
);
export const getFetchActiveOrgRequestState: Selector<AppState, ApiRequestState<Org | undefined>> = (state) =>
    state.activeOrg;
export const getActiveOrg: Selector<AppState, Org | undefined> = createSelector(
    [getFetchActiveOrgRequestState],
    ({ value }) => value
);
export const getOrgAsns: Selector<AppState, readonly number[]> = createSelector([getActiveOrg], (org) =>
    org?.asns ? [...org.asns].sort() : EMPTY_ARRAY
);
export const getFetchCountriesRequestState: Selector<AppState, ApiRequestState<Country[]>> = (state) => state.countries;
export const getCountries: Selector<AppState, Country[]> = createSelector(
    [getFetchCountriesRequestState],
    ({ value }) => value
);
export const getFetchCountrySubdivisionsRequestState: Selector<
    AppState,
    ApiRequestState<Map<string, CountrySubdivision[]>>
> = (state) => state.subdivisionsByCountryCode;
export const getSubdivisionsByCountryCode: Selector<AppState, Map<string, CountrySubdivision[]>> = createSelector(
    [getFetchCountrySubdivisionsRequestState],
    ({ value }) => value
);
export const getSiteSurveysState: Selector<AppState, SiteSurveysState> = (state) => state.siteSurveys;
export const getPartOrdersState: Selector<AppState, PartOrdersState> = (state) => state.partOrders;
export const getOcaReturnsState: Selector<AppState, OcaReturnsState> = (state) => state.ocaReturns;
