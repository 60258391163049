import { orderBy } from "lodash";
import { createSelector, ParametricSelector, Selector } from "reselect";
import { AppState } from "../../app/models/AppState";
import {
    getActiveOrg,
    getFetchActiveOrgRequestState,
    getFetchCountriesRequestState,
    getPartOrdersState,
} from "../../app/reducer/selectors";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import {
    anyPending,
    ApiRequestCollectionState,
    ApiRequestState,
    firstError,
} from "../../shared/models/ApiRequestState";
import { Changeset } from "../../shared/models/Changeset";
import { ContactFields } from "../../shared/models/ContactFields";
import { Dictionary } from "../../shared/models/Dictionary";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { PartDefinition, PartDefinitionWithFlags } from "../../shared/models/PartDefinition";
import { getHighestErrorLevel, Level, ValidationError } from "../../shared/models/ValidationError";
import { list } from "../../shared/validation";
import { applyChangeset } from "../../utils/change";
import { dateOrdering } from "../../utils/compares";
import {
    collectAddresses,
    collectContacts,
    extractAdditionalRecipientEmails,
    rollUpErrorsByView,
} from "../../utils/partnerForm";
import {
    attributePartOrderErrorToView,
    checkArePartOrdersDifferent,
    checkShouldRestrictPart,
} from "../../utils/partOrder";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../utils/selectors";
import { PartOrder } from "../models/PartOrder";
import { PartOrderUiState } from "../models/PartOrderUiState";
import { QuantityLimits } from "../models/QuantityLimits";

export const getFetchPartOrdersRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getPartOrdersState],
    (state) => state.fetch
);

export const getSavePartOrdersRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getPartOrdersState],
    (state) => state.save
);

export const getSubmitPartOrdersRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getPartOrdersState],
    (state) => state.submit
);

export const getPartOrdersById: Selector<AppState, Dictionary<PartOrder> | undefined> = createSelector(
    [getPartOrdersState],
    (state) => state.byId
);

export const getShippingAddressSuggestionsRequestStates: Selector<
    AppState,
    ApiRequestCollectionState<Address[]>
> = createSelector([getPartOrdersState], (state) => state.fetchShippingAddressSuggestions);

export const getQuantityLimitsRequestStates: Selector<
    AppState,
    ApiRequestCollectionState<QuantityLimits>
> = createSelector([getPartOrdersState], (state) => state.fetchQuantityLimits);

export const getPartOrdersSorted: Selector<AppState, PartOrder[] | undefined> = createSelector(
    [getPartOrdersById],
    (byId) =>
        byId &&
        Object.values(byId)
            .filter((partOrder): partOrder is PartOrder => !!partOrder)
            .sort((a, b) => dateOrdering(a.dateCreated, b.dateCreated, true))
);

export const getPartOrderEditsById: Selector<AppState, Dictionary<Changeset>> = createSelector(
    [getPartOrdersState],
    (state) => state.edits
);

export const getPartOrderUIStatesById: Selector<AppState, Dictionary<PartOrderUiState>> = createSelector(
    [getPartOrdersState],
    (state) => state.ui
);

export const getPartOrderValidationsById: Selector<
    AppState,
    Dictionary<ModelValidationErrors<PartOrder>>
> = createSelector([getPartOrdersState], (state) => state.validations);

export const getPartOrder: ParametricSelector<AppState, number, PartOrder | undefined> = (state, id) => {
    const byId = getPartOrdersById(state);
    return byId && byId[id];
};

export const getPartOrderUIState: ParametricSelector<AppState, number, PartOrderUiState | undefined> = (state, id) => {
    const byId = getPartOrderUIStatesById(state);
    return byId[id];
};

export const getPartOrderErrors: ParametricSelector<AppState, number, ModelValidationErrors<PartOrder>> = (state, id) =>
    getPartOrderValidationsById(state)[id] || EMPTY_OBJECT;

export const getPartOrderErrorsList: ParametricSelector<AppState, number, ValidationError[]> = createSelector(
    [getPartOrderErrors],
    list
);

export const getPartOrderErrorsByView: ParametricSelector<
    AppState,
    number,
    Dictionary<ValidationError>
> = createSelector([getPartOrderErrorsList], (errors) => rollUpErrorsByView(errors, attributePartOrderErrorToView));

export const getPartOrderChangeset: ParametricSelector<AppState, number, Changeset> = (state, id) =>
    getPartOrderEditsById(state)[id] || EMPTY_ARRAY;

export const getShippingAddressSuggestions: ParametricSelector<AppState, number, readonly Address[]> = (state, id) => {
    const requestState = getShippingAddressSuggestionsRequestStates(state)[id];
    return requestState?.value ?? EMPTY_ARRAY;
};

export const getIsFetchingPartOrderData: Selector<AppState, boolean> = createSelector(
    [getFetchPartOrdersRequestState, getFetchCountriesRequestState, getFetchActiveOrgRequestState],
    anyPending
);

export const getErrorFetchingPartOrderData: Selector<AppState, string | Error | null> = createSelector(
    [getFetchPartOrdersRequestState, getFetchCountriesRequestState, getFetchActiveOrgRequestState],
    firstError
);

export const getPartOrderModified: ParametricSelector<AppState, number, PartOrder | undefined> = createSelector(
    [getPartOrder, getPartOrderChangeset],
    (partOrder, changeset) => partOrder && applyChangeset(partOrder, changeset)
);

export const getPartOrderIsDirty: ParametricSelector<AppState, number, boolean> = createSelector(
    [getPartOrder, getPartOrderModified],
    (partOrder, modified) => checkArePartOrdersDifferent(partOrder, modified)
);

export const getPartOrderIsValid: ParametricSelector<AppState, number, boolean> = createSelector(
    [getPartOrderErrorsList],
    (errors) => (errors.length ? getHighestErrorLevel(errors) !== Level.Invalid : true)
);

export const getPartOrderIsComplete: ParametricSelector<AppState, number, boolean> = createSelector(
    [getPartOrderErrorsList],
    (errors) => !errors.length
);

export const getContacts: ParametricSelector<AppState, number, ContactFields[]> = createSelector(
    [getActiveOrg, getPartOrder, getPartOrderModified],
    (org, _partOrder) => collectContacts(org)
);

export const getShippingAddresses: ParametricSelector<AppState, number, AddressFields[]> = createSelector(
    [getShippingAddressSuggestions, getPartOrder, getPartOrderModified],
    (suggestions, _partOrder, _modified) => collectAddresses(...suggestions)
);

/**
 * Returns an empty object if never fetched.
 */
export const getQuantityLimits: ParametricSelector<AppState, number, QuantityLimits> = (state, id) => {
    const requestState = getQuantityLimitsRequestStates(state)[id];
    return requestState?.value ?? EMPTY_OBJECT;
};

export const getInitialAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getPartOrder],
    (partOrder) => extractAdditionalRecipientEmails(partOrder)
);

export const getAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getPartOrder, getPartOrderModified],
    (partOrder, modified) => extractAdditionalRecipientEmails(modified ?? partOrder)
);

export const getFetchPartDefinitionsState: Selector<AppState, ApiRequestState<PartDefinition[]>> = createSelector(
    [getPartOrdersState],
    (state) => state.fetchPartDefinitions
);

/**
 * Returns the list of part definitions allowed for this part order. Parts that
 * are not allowed will have `restricted` set to `true`.
 */
export const getPartDefinitions: ParametricSelector<AppState, number, PartDefinitionWithFlags[]> = createSelector(
    [getFetchPartDefinitionsState, getPartOrder, getQuantityLimits],
    ({ value }, partOrder, quantityLimits) => {
        if (!partOrder) {
            return [];
        }

        return orderBy(value, (v) => v.name).map((partDefinition) => ({
            ...partDefinition,
            restricted: checkShouldRestrictPart(partDefinition, partOrder, quantityLimits),
        }));
    }
);
