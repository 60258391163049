import classnames from "classnames";
import React, { memo, useMemo } from "react";
import { AddressVerificationResult } from "../../../services/topgear/models/AddressVerificationResult";
import { TopgearHelper } from "../../../services/topgear/TopgearHelper";
import Icon from "../../../shared/components/Icon";
import Spacer from "../../../shared/components/Spacer";
import { messages } from "../../../shared/messages";
import HelpIcon from "../HelpIcon";
import Tooltip from "../Tooltip";

interface Props {
    pending: boolean;
    results: AddressVerificationResult[];
}

function AddressVerificationStatus({ pending, results }: Props) {
    const verified = useMemo(() => TopgearHelper.isAddressVerified(results), [results]);

    return (
        <div className={classnames("AddressVerificationStatus", { pending, verified })}>
            {verified ? (
                <Icon icon="check-circle" />
            ) : (
                <Tooltip placement="left-start" content={<div>{messages.notice.addressUnverifiedDetails()}</div>}>
                    <HelpIcon solid />
                </Tooltip>
            )}
            <Spacer />
            <span>{verified ? messages.notice.addressVerified() : messages.notice.addressUnverified()}</span>
        </div>
    );
}

export default memo(AddressVerificationStatus);
