import classnames from "classnames";
import React, { Component, ErrorInfo } from "react";
import logger from "../../logger";
import { messages } from "../../messages";
import { RequestError } from "../../models/RequestError";
import Icon from "../Icon";
import Message from "../Message";

interface Props {
    padded: boolean;
}

interface State {
    error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
    static defaultProps = {
        padded: false
    };

    state: State = {
        error: null
    };

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({ error });

        // If it's a request error, it is already logged by the API service.
        if (!(error instanceof RequestError)) {
            logger.trackError(error, info.componentStack, {
                url: window.location.href
            });
        }

        ga("send", "exception", {
            exDescription: error.message,
            exFatal: true
        });
    }

    render() {
        const { children, padded } = this.props;
        const { error } = this.state;

        if (error) {
            return (
                <div className={classnames("ErrorBoundary", { padded })}>
                    <Message
                        status="error"
                        message={`${messages.errors.generic()}: ${error.message}`}
                        icon={() => <Icon icon="exclamation-triangle" size="lg" />}
                    />
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
