import React, { Component, ReactNode } from "react";
import classnames from "classnames";
import Container from "../Container";
import Master from "./PartnerFormContainerMaster";
import Detail from "./PartnerFormContainerDetail";
import { PATH_SITE_SURVEYS, PATH_OCA_RETURNS, PATH_PART_ORDERS, PARAM_ID } from "../../routes";
import { matchPath, RouteComponentProps } from "react-router";
import { messages } from "../../messages";
import Link from "../Link";
import Icon from "../Icon";

interface Props extends RouteComponentProps<Record<string, string>> {
    className?: string;
    path: typeof PATH_SITE_SURVEYS | typeof PATH_OCA_RETURNS | typeof PATH_PART_ORDERS;
    master: () => ReactNode;
    detail: () => ReactNode;
}

/**
 * A container for presenting forms. Used in conjunction with Master and Detail
 * components for styling purposes.
 */
class PartnerFormContainer extends Component<Props> {
    static Master = Master;
    static Detail = Detail;

    render() {
        const { className, master, detail, path, location } = this.props;
        const showDetail = matchPath(location.pathname, { path: `${path}/:${PARAM_ID}` }) !== null;

        return (
            <Container className={classnames("PartnerFormContainer", className, { showDetail })} fluid>
                <Master>{master()}</Master>
                <Detail>
                    <span className="PartnerFormContainer__backButton">
                        <Link
                            to={{
                                ...location,
                                pathname: path,
                            }}
                        >
                            <Icon icon="angle-left" />
                            &nbsp;{messages.actions.goBack()}
                        </Link>
                    </span>
                    {detail()}
                </Detail>
            </Container>
        );
    }
}

export default PartnerFormContainer;
