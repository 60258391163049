import { noLaterThanNow } from "../../utils/dates";

export interface Submittable {
    readonly submittedBy?: string;
    readonly submittedOn?: string;
}

export function getSubmittedOn({ submittedOn }: Submittable): Date | undefined {
    return submittedOn ? noLaterThanNow(new Date(submittedOn)) : undefined;
}
