import classnames from "classnames";
import React from "react";
import SegmentGroup from "../Segment/SegmentGroup";

interface Props {
    basic?: boolean;
    inverted?: boolean;
    fluid?: boolean;
}

function Segment({ className, children, fluid = true, ...props }: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={classnames("Segment", className, { fluid })} {...props}>
            {children}
        </div>
    );
}

Segment.Group = SegmentGroup;

export default Segment;
