import axios from "axios";
import config from "../../shared/config";
import { OrgSummary } from "../../shared/models/OrgSummary";
import { RequestService } from "../RequestService";
import { AuthUserInfo } from "./models/AuthUserInfo";
import auth, { AuthService } from "../auth";

export class PartnerPortal extends RequestService {
    private static BASE_URL = config.partnerPortalApiBaseUrl;

    constructor(auth: AuthService) {
        super(
            "PartnerPortal",
            auth,
            axios.create({
                baseURL: PartnerPortal.BASE_URL
            })
        );
    }

    public async getOrgs(): Promise<OrgSummary[]> {
        const { data } = await this.axios.get<AuthUserInfo>("/api/2.0/auth/user");

        return data.attributes.orgDetails;
    }
}

export default new PartnerPortal(auth);
