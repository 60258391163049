import React, { memo } from "react";
import { getDisplayName } from "../../../utils/contact";
import { messages } from "../../messages";
import { ContactFields } from "../../models/ContactFields";
import Meta from "../Meta";
import Segment from "../Segment";
import Value from "../Value";

interface Props {
    contact: ContactFields;
    /**
     * Adjusts spacing to save more space. Also see `inlineLabels` option.
     */
    compact?: boolean;
    /**
     * Renders the field labels inline with the values. A space-saving variant.
     */
    inlineLabels?: boolean;
    /**
     * These fields are hidden. The name field is only hidden if _both_ first
     * and last name are hidden. By default, the empty set.
     */
    omitFields?: Set<keyof ContactFields>;
}

/**
 * Shows the contact details with first and last name combined into one field.
 */
function Contact({ contact, compact, inlineLabels = false, omitFields = new Set<keyof ContactFields>() }: Props) {
    const displayName = getDisplayName(contact);

    return (
        <div className="Contact">
            <Segment.Group compact={compact}>
                {displayName && (!omitFields.has("nameFirst") || !omitFields.has("nameLast")) && (
                    <Segment>
                        <Meta inline={inlineLabels}>{messages.labels.contactField.name()}</Meta>
                        <span>{displayName}</span>
                    </Segment>
                )}
                {contact.emailWork && !omitFields.has("emailWork") && (
                    <Segment>
                        <Value inline={inlineLabels} label={messages.labels.contactField.emailWork()}>
                            <span
                                style={{
                                    wordBreak: "break-word",
                                    textTransform: "lowercase",
                                }}
                            >
                                {contact.emailWork}
                            </span>
                        </Value>
                    </Segment>
                )}
                {contact.phoneWork && !omitFields.has("phoneWork") && (
                    <Segment>
                        <Value inline={inlineLabels} label={messages.labels.contactField.phoneWork()}>
                            <span>{contact.phoneWork}</span>
                        </Value>
                    </Segment>
                )}
            </Segment.Group>
        </div>
    );
}

export default memo(Contact);
