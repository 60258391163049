import { toNumber } from "lodash";
import React from "react";
import Button from "../../../shared/components/Button";
import Dropdown from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormField from "../../../shared/components/FormField";
import Segment from "../../../shared/components/Segment";
import TextInput from "../../../shared/components/TextInput";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { FormPropsV2 } from "../../../shared/props/forms";
import { BgpConfigFields, BGP_ASN_KEY, BGP_CONFIG_KEYS, BGP_MULTIHOP_KEY } from "../../models/BgpConfig";

export interface Props extends FormPropsV2<BgpConfigFields> {
    asns: readonly number[];
}

function BgpConfigForm(props: Props) {
    const { asns, isSubmitting, onSubmit } = props;

    const [{ pristine, valid, errors, dirty, values }, { handleSubmit, changeHandlers, touchHandlers }] = useForm(
        { keys: BGP_CONFIG_KEYS },
        props
    );

    return (
        <Form className="BgpConfig" onSubmit={handleSubmit}>
            <Segment.Group horizontal>
                <Segment>
                    <FormField
                        name={BGP_ASN_KEY}
                        required
                        label={messages.labels.bgpAsn()}
                        error={errors.get(BGP_ASN_KEY)}
                    >
                        <Dropdown
                            dirty={dirty.has(BGP_ASN_KEY)}
                            error={errors.has(BGP_ASN_KEY)}
                            placeholder={messages.forms.placeholders.bgpAsn()}
                            options={asns.map((asn) => ({
                                key: asn.toString(),
                                text: asn.toString(),
                                value: asn,
                            }))}
                            value={values[BGP_ASN_KEY]}
                            onChange={(evt) => changeHandlers[BGP_ASN_KEY]?.(toNumber(evt.target.value))}
                            onBlur={() => touchHandlers[BGP_ASN_KEY]?.()}
                        />
                    </FormField>
                </Segment>
                <Segment>
                    <FormField
                        name={BGP_MULTIHOP_KEY}
                        required
                        label={messages.labels.bgpMultihop()}
                        error={errors.get(BGP_MULTIHOP_KEY)}
                    >
                        <TextInput
                            dirty={dirty.has(BGP_MULTIHOP_KEY)}
                            error={errors.has(BGP_MULTIHOP_KEY)}
                            value={values?.[BGP_MULTIHOP_KEY]}
                            onChange={(evt) => changeHandlers[BGP_MULTIHOP_KEY]?.(toNumber(evt.target.value))}
                            onBlur={() => touchHandlers[BGP_MULTIHOP_KEY]?.()}
                            type="number"
                            max="16"
                            min="0"
                            placeholder={messages.forms.placeholders.bgpMultihop()}
                        />
                    </FormField>
                </Segment>
            </Segment.Group>
            {onSubmit && (
                <Button type="submit" color="blue" loading={isSubmitting} disabled={pristine || !valid || isSubmitting}>
                    {messages.actions.save()}
                </Button>
            )}
        </Form>
    );
}

export default BgpConfigForm;
