import { Dispatch, MiddlewareAPI } from "redux";
import { authActions } from "../../auth/reducer";
import { ForbiddenError, UnauthorizedError } from "./RequestError";

/**
 * Any class that makes use of a service (e.g. topgear) to make requests should
 * extend/implement this. This class determines which response codes should be
 * treated as unauthorized requests.
 */
abstract class RequestMiddleware<D extends Dispatch, S> {
    /**
     * Catches request exceptions and performs certain side effects depending on
     * the error type.
     * @param api
     * @param request
     */
    protected async handleRequest<T>(api: MiddlewareAPI<D, S>, request: Promise<T>) {
        return request.catch((e) => {
            this.handleErrorIfNecessary(api, e);
            throw e;
        });
    }

    /**
     * Handles any errors by dispatching appropriate actions
     * e.g. 401 UNAUTHORIZED redirects to login.
     * @param api - Redux middleware API
     * @param error - Any Error
     */
    private handleErrorIfNecessary(api: MiddlewareAPI<D, S>, error: Error) {
        if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
            api.dispatch(authActions.unauthorized());
            return true;
        }

        return false;
    }
}

export default RequestMiddleware;
