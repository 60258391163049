import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import { getCountries, getSubdivisionsByCountryCode } from "../../../app/reducer/selectors";
import { ocaReturnsActions } from "../../reducer";
import {
    getInitialAdditionalRecipients,
    getAddresses,
    getContacts,
    getOcaReturnErrors,
    getOcaReturnErrorsByView,
    getOcaReturnIsComplete,
    getOcaReturnIsDirty,
    getOcaReturnIsValid,
    getOcaReturnModified,
    getOcaReturnUIState,
    getSaveOcaReturnsRequestState,
    getSubmitOcaReturnsRequestState,
    getAdditionalRecipients,
} from "../../reducer/selectors";
import { Props } from "./OcaReturn";

type StateProps = Pick<
    Props,
    | "additionalRecipients"
    | "initialAdditionalRecipients"
    | "countries"
    | "subdivisionsByCountryCode"
    | "errors"
    | "isComplete"
    | "isDirty"
    | "isValid"
    | "modified"
    | "rolledUpErrors"
    | "saveState"
    | "submitState"
    | "contacts"
    | "addressSuggestions"
>;
type DispatchProps = Pick<
    Props,
    | "clearSaveAndSubmitErrors"
    | "onChangeOcaReturnPickupProperties"
    | "onChangeOcaReturnPickupPreferences"
    | "onChangeNotes"
    | "onChangeAdditionalRecipients"
    | "onChangeAddress"
    | "onChangeContact"
    | "onSave"
    | "onSelectAddress"
    | "onSelectContact"
    | "onSubmit"
    | "validate"
    | "reset"
>;

type OwnProps = Omit<Props, keyof StateProps | keyof DispatchProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state, { ocaReturn: { id } }) => {
    return {
        ...getOcaReturnUIState(state, id),
        modified: getOcaReturnModified(state, id),
        initialAdditionalRecipients: getInitialAdditionalRecipients(state, id),
        additionalRecipients: getAdditionalRecipients(state, id),
        subdivisionsByCountryCode: getSubdivisionsByCountryCode(state),
        countries: getCountries(state),
        errors: getOcaReturnErrors(state, id),
        isComplete: getOcaReturnIsComplete(state, id),
        isDirty: getOcaReturnIsDirty(state, id),
        isValid: getOcaReturnIsValid(state, id),
        rolledUpErrors: getOcaReturnErrorsByView(state, id),
        saveState: getSaveOcaReturnsRequestState(state),
        submitState: getSubmitOcaReturnsRequestState(state),
        contacts: getContacts(state, id),
        addressSuggestions: getAddresses(state, id),
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch,
    { orgId, ocaReturn: { id: ocaReturnId } }
) => {
    return {
        clearSaveAndSubmitErrors: () => {
            dispatch(ocaReturnsActions.save.reset({ orgId, ocaReturnId }));
            dispatch(ocaReturnsActions.submit.reset({ orgId, ocaReturnId }));
        },
        onChangeOcaReturnPickupProperties: (values) =>
            dispatch(ocaReturnsActions.changePickupProperties({ orgId, ocaReturnId, values })),
        onChangeOcaReturnPickupPreferences: (values) =>
            dispatch(ocaReturnsActions.changePickupPreferences({ orgId, ocaReturnId, values })),
        onChangeNotes: (notes) => dispatch(ocaReturnsActions.changeNotes({ orgId, ocaReturnId, values: notes })),
        onChangeAdditionalRecipients: (recipients) =>
            dispatch(ocaReturnsActions.changeAdditionalRecipients({ orgId, ocaReturnId, recipients })),
        onChangeAddress: (address) =>
            dispatch(ocaReturnsActions.changeAddress({ orgId, ocaReturnId, values: address })),
        onChangeContact: (contact) =>
            dispatch(ocaReturnsActions.changeContact({ orgId, ocaReturnId, values: contact })),
        onSave: () => dispatch(ocaReturnsActions.save.do({ orgId, ocaReturnId })),
        onSelectContact: (contact) => dispatch(ocaReturnsActions.selectContact({ orgId, ocaReturnId, contact })),
        onSelectAddress: (address) => dispatch(ocaReturnsActions.selectAddress({ orgId, ocaReturnId, address })),
        onSubmit: () => dispatch(ocaReturnsActions.submit.do({ orgId, ocaReturnId })),
        validate: () => dispatch(ocaReturnsActions.doValidate({ orgId, ocaReturnId })),
        reset: () => dispatch(ocaReturnsActions.reset({ orgId, ocaReturnId })),
    };
};

export { mapStateToProps, mapDispatchToProps };
