import validator from "validator";
import {
    getPrefixLength,
    inSameSubnet,
    isBroadcastAddress,
    isIPv4MappedIPv6,
    isLoopbackAddress,
    isNetworkAddress,
    isPrefix,
    isSubnetMask,
} from "../../../utils/ip";
import { all, between, not, oneOf } from "../../forms/validators";
import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

const ip = (message: string, version?: 4 | 6): Validator => (value: string) => {
    return validator.isIP(value, version)
        ? undefined
        : {
              level: Level.Invalid,
              message,
          };
};

/**
 * Validates that the IP (v4 or v6) is not the loopback address (localhost)
 * @param message - Error message if validation fails
 * @param version - The IP version, 4 or 6
 */
export const notLocalhost: Validator = (value: string) => {
    if (isLoopbackAddress(value)) {
        return {
            level: Level.Invalid,
            message: messages.forms.validation.localhostNotAllowed(),
        };
    }

    return;
};

/**
 * Validates that an address is not the network address based on the subnet mask
 * (the form value of the specified key).
 */
export const notNetworkAddress = (subnetKey: string): Validator => (value: string, allValues: any) => {
    try {
        if (isNetworkAddress(allValues[subnetKey], value)) {
            return {
                level: Level.Invalid,
                message: messages.forms.validation.ipv4NetworkAddressNotAllowed(),
            };
        }
    } catch (e) {
        return;
    }

    return;
};

export const notBroadcastAddress = (subnetKey: string): Validator => (value: string, allValues: any) => {
    try {
        if (isBroadcastAddress(allValues[subnetKey], value)) {
            return {
                level: Level.Invalid,
                message: messages.forms.validation.ipv4BroadcastAddressNotAllowed(),
            };
        }
    } catch (e) {
        return;
    }

    return;
};

export const notIPv4Mapped: Validator = (value: string) => {
    if (isIPv4MappedIPv6(value)) {
        return {
            level: Level.Invalid,
            message: messages.forms.validation.ipv4MappedNotAllowed(),
        };
    }

    return;
};

/**
 * Validates IPv4 addresses.
 * @param allowLocalhost - Set to false to disallow localhost. Default true.
 */
export const ipv4 = (allowLocalhost = true, denylist: string[] = []): Validator =>
    all(
        ip(messages.forms.validation.ipv4Malformed(), 4),
        allowLocalhost ? null : notLocalhost,
        not(oneOf(denylist, ""), messages.forms.validation.ipv4Malformed())
    );

/**
 * Validates IPv6 addresses.
 * @param allowLocalhost - Set to false to disallow loopback (localhost).
 * Default true.
 */
export const ipv6 = (allowLocalhost = true, acceptIPv4Mapped = true, denylist: string[] = []): Validator =>
    all(
        ip(messages.forms.validation.ipv6Malformed(), 6),
        allowLocalhost ? null : notLocalhost,
        acceptIPv4Mapped ? null : notIPv4Mapped,
        not(oneOf(denylist, ""), messages.forms.validation.ipv6Malformed())
    );

/**
 *
 * @param message Validates that the string is a valid IPv4 address and a valid subnet mask.
 */
export const subnetMask: Validator = (value: string) => {
    const addressError = ip(messages.forms.validation.ipv4Malformed(), 4)(value);

    if (addressError) {
        return addressError;
    }

    if (!isSubnetMask(value)) {
        return {
            level: Level.Invalid,
            message: messages.forms.validation.ipv4InvalidSubnetMask(),
        };
    }

    return;
};

/**
 * Validates the prefix length of a subnet mask of CIDR prefix.
 * @param range - (Optional) The bit range that the prefix must be within
 */
export const prefixLength = (range: [number, number]): Validator => (value: string) => {
    try {
        return between(
            range,
            messages.forms.validation.prefixOutOfRange({
                MIN: `/${range[0]}`,
                MAX: `/${range[1]}`,
            })
        )(getPrefixLength(value));
    } catch (e) {
        return {
            level: Level.Invalid,
            message: e.message,
        };
    }
};

export const prefix: Validator = (value: string) => {
    return isPrefix(value)
        ? undefined
        : {
              level: Level.Invalid,
              message: messages.forms.validation.invalidPrefix(),
          };
};

/**
 *
 * @param otherAddressKey - The key of other address to check
 * @param subnetKey - The subnet mask or prefix length
 * @param version
 */
export const sameSubnet = (otherAddressKey: string, otherAddressLabel: string, subnetKey: string): Validator => (
    value: string,
    allValues: any
) => {
    try {
        if (!inSameSubnet(allValues[subnetKey], [value, allValues[otherAddressKey]])) {
            return {
                level: Level.Invalid,
                message: messages.forms.validation.notInSubnet({
                    OTHER_ADDRESS: otherAddressLabel,
                }),
            };
        }
    } catch (e) {
        // The subnet is invalid, or one of the addresses is invalid (perhaps
        // blank), simply ignore this validation
        return;
    }
    return;
};
