import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { AnyAction, Reducer } from "redux";
import { AddressVerificationResult } from "../../services/topgear/models/AddressVerificationResult";
import { initAction } from "../../shared/actions";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import { AllowedParts } from "../../shared/models/AllowedParts";
import { Contact } from "../../shared/models/Contact";
import { ContactFields } from "../../shared/models/ContactFields";
import { DatacenterFields } from "../../shared/models/Datacenter";
import { ForceEditableFlag } from "../../shared/models/ForceEditableFlag";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { Notes } from "../../shared/models/Notes";
import { ID_KEY } from "../../shared/models/Resource";
import { ShippingAddressFields, SHIPPING_ADDRESS_KEYS } from "../../shared/models/ShippingAddressFields";
import { VerifyShippingAddressFlag } from "../../shared/models/VerifyShippingAddressFlag";
import {
    createAdditionalRecipientsChanges,
    createAddressChanges,
    createAddressSelectionChanges,
    createCfdiFieldChanges,
    createContactChanges,
    createContactSelectionChanges,
    createEoriChange,
    createNotesChanges,
    createTaxpayerFieldChanges,
} from "../../utils/partnerForm";
import { createApiRequestCollectionSlice, createApiRequestSlice } from "../../utils/reducers";
import { createBGPConfigChanges, createDatacenterChanges, createNetworkConfigChanges } from "../../utils/siteSurvey";
import { AttachmentFile } from "../models/AttachmentFile";
import { BgpConfigFields } from "../models/BgpConfig";
import { DatacenterOptions } from "../models/DatacenterOptions";
import { NetworkConfigFields } from "../models/NetworkConfig";
import { SiteSurvey } from "../models/SiteSurvey";
import { SHIPPING_ADDRESS_KEY, SITE_ADDRESS_KEY } from "../models/SiteSurveyAddresses";
import { SiteSurveyAttachmentReference } from "../models/SiteSurveyAttachmentReference";
import { ContactKey } from "../models/SiteSurveyContacts";
import { SiteSurveyFetchParameters } from "../models/SiteSurveyFetchParameters";
import { SiteSurveyReference } from "../models/SiteSurveyReference";
import { SiteSurveyResult } from "../models/SiteSurveyResult";
import { SiteSurveyResults } from "../models/SiteSurveyResults";
import { SiteSurveysRootState, SiteSurveysState } from "../models/SiteSurveysState";
import { DEFAULT_SITE_SURVEY_UI_STATE } from "../models/SiteSurveyUiState";

const NAMESPACE = "siteSurveys";

// Nested slices
const fetchSlice = createApiRequestSlice<SiteSurveyFetchParameters, SiteSurveyResults, SiteSurveyFetchParameters>(
    `${NAMESPACE}/fetch`
);
const saveSlice = createApiRequestSlice<SiteSurveyReference, SiteSurveyResult, SiteSurveyReference>(
    `${NAMESPACE}/save`
);
const submitSlice = createApiRequestSlice<SiteSurveyReference, SiteSurveyResult, SiteSurveyReference>(
    `${NAMESPACE}/submit`
);
const downloadAttachmentsSlice = createApiRequestCollectionSlice<
    SiteSurveyAttachmentReference,
    AttachmentFile,
    SiteSurveyAttachmentReference
>(`${NAMESPACE}/downloadAttachment`);
const fetchDatacenterOptionsSlice = createApiRequestSlice<void, DatacenterOptions | undefined>(
    `${NAMESPACE}/fetchDatacenterOptions`,
    undefined
);
const fetchShippingAddressSuggestionsSlice = createApiRequestCollectionSlice<
    SiteSurveyReference,
    Address[],
    SiteSurveyReference
>(`${NAMESPACE}/fetchShippingAddressSuggestions`, []);
const fetchAllowedPartsSlice = createApiRequestCollectionSlice<
    SiteSurveyReference,
    AllowedParts | undefined,
    SiteSurveyReference
>(`${NAMESPACE}/fetchAllowedParts`, undefined);
const verifyAddressSlice = createApiRequestCollectionSlice<
    SiteSurveyReference & { address: AddressFields },
    AddressVerificationResult[] | undefined,
    SiteSurveyReference & { address: AddressFields },
    SiteSurveyReference
>(`${NAMESPACE}/verifyAddress`, undefined);

// Root slice
function initialRootState(): SiteSurveysRootState {
    return {
        byId: undefined,
        ui: {},
        edits: {},
        validations: {},
    };
}

const rootSlice = createSlice({
    name: NAMESPACE,
    initialState: initialRootState(),
    reducers: {
        reset: (state, action: PayloadAction<SiteSurveyReference>) => {
            const id = action.payload.siteSurveyId;
            delete state.edits[id];
            delete state.validations[id];
        },
        changeSiteAddress: (
            state,
            action: PayloadAction<SiteSurveyReference & ForceEditableFlag & { values: Partial<AddressFields> }>
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAddressChanges(action.payload.values, SITE_ADDRESS_KEY),
            ];
        },
        changeShippingAddress: (
            state,
            action: PayloadAction<SiteSurveyReference & ForceEditableFlag & { values: Partial<ShippingAddressFields> }>
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAddressChanges(action.payload.values, SHIPPING_ADDRESS_KEY, SHIPPING_ADDRESS_KEYS),
            ];
        },
        changeTaxpayerFields: (
            state,
            action: PayloadAction<
                SiteSurveyReference & ForceEditableFlag & { taxpayerFields: Partial<Record<string, string>> }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createTaxpayerFieldChanges(action.payload.taxpayerFields),
            ];
        },
        changeCfdiFields: (
            state,
            action: PayloadAction<
                SiteSurveyReference & ForceEditableFlag & { cfdiFields: Partial<Record<string, string | undefined>> }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createCfdiFieldChanges(action.payload.cfdiFields)];
        },
        changeEori: (
            state,
            action: PayloadAction<SiteSurveyReference & ForceEditableFlag & { eori: string | undefined }>
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createEoriChange(action.payload.eori)];
        },
        selectShippingAddress: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        address?: Address | AddressFields;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAddressSelectionChanges(action.payload.address, SHIPPING_ADDRESS_KEY),
            ];
        },
        changeContact: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        contact: Partial<ContactFields>;
                        key: ContactKey;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createContactChanges(action.payload.contact, action.payload.key),
            ];
        },
        selectContact: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        contact?: ContactFields | Contact;
                        key: ContactKey;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createContactSelectionChanges(action.payload.contact, action.payload.key),
            ];
        },
        changeBgpConfig: (
            state,
            action: PayloadAction<SiteSurveyReference & ForceEditableFlag & { values: Partial<BgpConfigFields> }>
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createBGPConfigChanges(action.payload.values)];
        },
        changeNetworkConfig: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        values: Partial<NetworkConfigFields>;
                        at: number;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createNetworkConfigChanges(action.payload.values, action.payload.at),
            ];
        },
        changeDatacenter: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        values: Partial<DatacenterFields>;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createDatacenterChanges(action.payload.values)];
        },
        changeNotes: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        values: Partial<Notes>;
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [...(state.edits[id] ?? []), ...createNotesChanges(action.payload.values)];
        },
        changeAdditionalRecipients: (
            state,
            action: PayloadAction<
                SiteSurveyReference &
                    ForceEditableFlag & {
                        recipients: string[];
                    }
            >
        ) => {
            const id = action.payload.siteSurveyId;
            state.edits[id] = [
                ...(state.edits[id] ?? []),
                ...createAdditionalRecipientsChanges(action.payload.recipients),
            ];
        },
        // UI state
        setActiveNetworkConfigIndex: (state, action: PayloadAction<SiteSurveyReference & { index: number }>) => {
            const id = action.payload.siteSurveyId;
            state.ui[id] = {
                ...DEFAULT_SITE_SURVEY_UI_STATE,
                ...state.ui[id],
                activeNetworkConfigIndex: action.payload.index,
            };
        },
        autoPickedPowerCable: (state, action: PayloadAction<SiteSurveyReference>) => {
            const id = action.payload.siteSurveyId;
            state.ui[id] = {
                ...DEFAULT_SITE_SURVEY_UI_STATE,
                ...state.ui[id],
                didAutoPickPowerCable: true,
            };
        },
        // Validation
        doValidate: (
            state,
            _action: PayloadAction<SiteSurveyReference & ForceEditableFlag & VerifyShippingAddressFlag>
        ) => state,
        didValidate: (
            state,
            action: PayloadAction<
                SiteSurveyReference & ForceEditableFlag & { errors: ModelValidationErrors<SiteSurvey> }
            >
        ) => {
            state.validations[action.payload.siteSurveyId] = action.payload.errors;
        },
    },
    extraReducers: (builder) => {
        function storeResults(state: SiteSurveysRootState, action: PayloadAction<SiteSurveyResult>) {
            const id = action.payload.siteSurvey[ID_KEY];
            state.byId = {
                ...state.byId,
                [id]: action.payload.siteSurvey,
            };
            state.edits = {
                ...state.edits,
                [id]: [],
            };
        }

        // Fetch
        builder.addCase(fetchSlice.actions.did, (state, action) => {
            const byId = keyBy(action.payload.siteSurveys, "id");

            // If it's a refresh, then keep the existing site surveys instead of
            // replacing all of them.
            state.byId = action.payload.refresh
                ? {
                      ...state.byId,
                      ...byId,
                  }
                : byId;
        });

        // Save
        builder.addCase(saveSlice.actions.did, storeResults);

        // Submit
        builder.addCase(submitSlice.actions.did, storeResults);
    },
});

// Combine root and nested reducers
function initialState(): SiteSurveysState {
    return {
        ...rootSlice.reducer(undefined, initAction),
        fetch: fetchSlice.reducer(undefined, initAction),
        save: saveSlice.reducer(undefined, initAction),
        submit: submitSlice.reducer(undefined, initAction),
        downloadAttachments: downloadAttachmentsSlice.reducer(undefined, initAction),
        fetchDatacenterOptions: fetchDatacenterOptionsSlice.reducer(undefined, initAction),
        fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.reducer(undefined, initAction),
        fetchAllowedParts: fetchAllowedPartsSlice.reducer(undefined, initAction),
        verifyAddress: verifyAddressSlice.reducer(undefined, initAction),
    };
}

const siteSurveysReducer: Reducer<SiteSurveysState, AnyAction> = (state = initialState(), action) => {
    return {
        ...rootSlice.reducer(state, action),
        fetch: fetchSlice.reducer(state.fetch, action),
        save: saveSlice.reducer(state.save, action),
        submit: submitSlice.reducer(state.submit, action),
        downloadAttachments: downloadAttachmentsSlice.reducer(state.downloadAttachments, action),
        fetchDatacenterOptions: fetchDatacenterOptionsSlice.reducer(state.fetchDatacenterOptions, action),
        fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.reducer(
            state.fetchShippingAddressSuggestions,
            action
        ),
        fetchAllowedParts: fetchAllowedPartsSlice.reducer(state.fetchAllowedParts, action),
        verifyAddress: verifyAddressSlice.reducer(state.verifyAddress, action),
    };
};

const siteSurveysActions = {
    ...rootSlice.actions,
    fetch: fetchSlice.actions,
    save: saveSlice.actions,
    submit: submitSlice.actions,
    downloadAttachments: downloadAttachmentsSlice.actions,
    fetchDatacenterOptions: fetchDatacenterOptionsSlice.actions,
    fetchShippingAddressSuggestions: fetchShippingAddressSuggestionsSlice.actions,
    fetchAllowedParts: fetchAllowedPartsSlice.actions,
    verifyAddress: verifyAddressSlice.actions,
};

export { siteSurveysReducer, siteSurveysActions };
