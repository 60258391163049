import React, { PureComponent } from "react";
import Container from "../../../shared/components/Container";
import Footer from "../../../shared/components/Footer";

interface Props {
    version: string;
}

class AppFooter extends PureComponent<Props> {
    render() {
        const { version } = this.props;

        return (
            <div className="AppFooter">
                <Container>
                    <Footer textLeft="Netflix" textRight={version} />
                </Container>
            </div>
        );
    }
}

export default AppFooter;
