import React, { Component } from "react";
import Header from "../../../shared/components/Header";
import Meta from "../../../shared/components/Meta";
import MissingInfo from "../../../shared/components/MissingInfo";
import Segment from "../../../shared/components/Segment";
import { messages } from "../../../shared/messages";
import { hasAnyBmcIpv4Fields, hasAnyBmcIpv6Fields, hasAnyIpv6Fields, NetworkConfig } from "../../models/NetworkConfig";

interface Props {
    networkConfig: NetworkConfig;
}

class Appliance extends Component<Props> {
    render() {
        const { networkConfig } = this.props;
        const {
            bmcv4Address,
            bmcv4Gateway,
            bmcv4Netmask,
            bmcv6Address,
            bmcv6Gateway,
            bmcv6PrefixLength,
            ipv4Address,
            ipv4Netmask,
            ipv4Gateway,
            ipv4BGPPeer,
            ipv6Address,
            ipv6PrefixLength,
            ipv6Gateway,
            ipv6BGPPeer,
            hostname,
        } = networkConfig;

        return (
            <div className="Appliance">
                <Segment.Group>
                    {hostname && (
                        <Segment>
                            <Meta>{messages.labels.hostname()}</Meta>
                            <span>{hostname}</span>
                        </Segment>
                    )}
                    <Segment>
                        <Header as="h3" title={messages.title.primaryNetworkConfiguration()} fluid />
                        <Segment.Group horizontal>
                            <Segment.Group>
                                <Header as="h4" title="IPv4" fluid />
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.ipv4Address()}</Meta>
                                    <span>{ipv4Address}</span>
                                </Segment>
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.ipv4SubnetMask()}</Meta>
                                    <span>{ipv4Netmask}</span>
                                </Segment>
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.ipv4Gateway()}</Meta>
                                    <span>{ipv4Gateway}</span>
                                </Segment>
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.ipv4BGPPeer()}</Meta>
                                    <span>{ipv4BGPPeer}</span>
                                </Segment>
                            </Segment.Group>
                            <Segment.Group>
                                <Header as="h4" title="IPv6" fluid />
                                {ipv6Address && (
                                    <Segment>
                                        <Meta>{messages.labels.networkConfig.ipv6Address()}</Meta>
                                        <span>{ipv6Address}</span>
                                    </Segment>
                                )}
                                {ipv6PrefixLength && (
                                    <Segment>
                                        <Meta>{messages.labels.networkConfig.ipv6Prefix()}</Meta>
                                        <span>{ipv6PrefixLength}</span>
                                    </Segment>
                                )}
                                {ipv6Gateway && (
                                    <Segment>
                                        <Meta>{messages.labels.networkConfig.ipv6Gateway()}</Meta>
                                        <span>{ipv6Gateway}</span>
                                    </Segment>
                                )}
                                {ipv6BGPPeer && (
                                    <Segment>
                                        <Meta>{messages.labels.networkConfig.ipv6BGPPeer()}</Meta>
                                        <span>{ipv6BGPPeer}</span>
                                    </Segment>
                                )}
                                {!hasAnyIpv6Fields(networkConfig) && (
                                    <Segment>
                                        <MissingInfo message={messages.notice.ipv6NotConfigured()} />
                                    </Segment>
                                )}
                            </Segment.Group>
                        </Segment.Group>
                    </Segment>
                    <Segment>
                        <Header as="h3" title={messages.title.remoteNetworkConfiguration()} fluid />
                        <Segment.Group>
                            {bmcv4Address && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv4Address()}</Meta>
                                    <span>{bmcv4Address}</span>
                                </Segment>
                            )}
                            {bmcv4Netmask && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv4Netmask()}</Meta>
                                    <span>{bmcv4Netmask}</span>
                                </Segment>
                            )}
                            {bmcv4Gateway && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv4Gateway()}</Meta>
                                    <span>{bmcv4Gateway}</span>
                                </Segment>
                            )}
                            {bmcv6Address && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv6Address()}</Meta>
                                    <span>{bmcv6Address}</span>
                                </Segment>
                            )}
                            {bmcv6PrefixLength && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv6PrefixLength()}</Meta>
                                    <span>{bmcv6PrefixLength}</span>
                                </Segment>
                            )}
                            {bmcv6Gateway && (
                                <Segment>
                                    <Meta>{messages.labels.networkConfig.bmcv6Gateway()}</Meta>
                                    <span>{bmcv6Gateway}</span>
                                </Segment>
                            )}
                            {!hasAnyBmcIpv4Fields(networkConfig) && !hasAnyBmcIpv6Fields(networkConfig) && (
                                <Segment>
                                    <MissingInfo message={messages.notice.remoteManagementNotConfigured()} />
                                </Segment>
                            )}
                        </Segment.Group>
                    </Segment>
                </Segment.Group>
            </div>
        );
    }
}

export default Appliance;
