import React, { PureComponent } from "react";
import IMG_TWO_POST_RACK from "../../../img/2-post-rack.png";
import IMG_FOUR_POST_RACK from "../../../img/4-post-rack.png";
import { Content } from "../../../shared/components/PartnerForm";
import { getMessage, messages } from "../../../shared/messages";
import {
    Datacenter,
    DatacenterFields,
    DATACENTER_KEYS,
    getComponentType,
    getNotes,
    RACK_TYPE_KEY,
} from "../../../shared/models/Datacenter";
import { ModelValidationErrors } from "../../../shared/models/ModelValidationErrors";
import { OcaClass } from "../../../shared/models/OcaClass";
import { REQUIRED_PARTS } from "../../../shared/validation/datacenter";
import { partDefinitionAsOptionInSiteSurvey } from "../../../utils/options";
import { DatacenterOptions } from "../../models/DatacenterOptions";
import DatacenterForm from "../DatacenterForm";
import { DatacenterFormFieldProps } from "../DatacenterForm/DatacenterForm";

export interface Props<T extends Datacenter> {
    id: number;
    initialDatacenter: T;
    datacenter?: T;
    datacenterOptions: DatacenterOptions;
    onChange: (values: Partial<DatacenterFields>) => void;
    validationErrors?: ModelValidationErrors<DatacenterFields>;
    isDeterminingAllowedParts?: boolean;
    ocaClass: OcaClass;
}

class DatacenterEditor<T extends Datacenter> extends PureComponent<Props<T>> {
    render() {
        const { initialDatacenter, datacenter, validationErrors, onChange } = this.props;

        return (
            <Content title={messages.title.datacenterAttributes()} subtitle={messages.notice.datacenterOptions()}>
                <DatacenterForm
                    initialValues={initialDatacenter}
                    values={datacenter}
                    onChange={onChange}
                    fieldProps={this.datacenterFieldProps}
                    validationErrors={validationErrors}
                />
            </Content>
        );
    }

    private get datacenterFieldProps(): Record<keyof DatacenterFields, DatacenterFormFieldProps> {
        const { datacenterOptions, isDeterminingAllowedParts, ocaClass } = this.props;

        const datacenterDisplayOptions = { ...datacenterOptions };
        // If non-flash, we apply special treatment to the rail kit input.
        const useAlternateRailKitInput = ocaClass !== OcaClass.Flash;

        return DATACENTER_KEYS.reduce((out, key) => {
            const componentType = getComponentType(key);
            const partDefinitions = datacenterDisplayOptions[componentType] ?? [];
            const isDisallowed = partDefinitions.filter((pd) => !pd.restricted).length === 0;
            const options = partDefinitions.map((pd) => partDefinitionAsOptionInSiteSurvey(pd, ocaClass));

            // Disable if no options are available.
            out[key] = {
                options,
                required: !REQUIRED_PARTS.has(key) ? false : options.length === 0 ? false : undefined,
                disabled: isDeterminingAllowedParts || isDisallowed || undefined,
                // Different from disabled because we want to allow clearing the
                // dropdown when the part definition type is disallowed.
                disableClear: isDeterminingAllowedParts,
                tooltip: key === RACK_TYPE_KEY && useAlternateRailKitInput ? this.rackTypeTooltip : undefined,
                placeholder: key === RACK_TYPE_KEY && useAlternateRailKitInput ? messages.labels.rackType() : undefined,
                notes: isDisallowed ? getMessage(() => messages.notes.partNotAllowed()) : getNotes(key, ocaClass),
            } as DatacenterFormFieldProps;

            return out;
        }, {}) as Record<keyof DatacenterFields, DatacenterFormFieldProps>;
    }

    private get rackTypeTooltip(): JSX.Element {
        return (
            <div>
                {messages.notice.rackType()}
                <div className="DatacenterEditor-rackTypeSamples">
                    <img src={IMG_TWO_POST_RACK} alt={messages.alt.twoPostRack()} />
                    <img src={IMG_FOUR_POST_RACK} alt={messages.alt.fourPostRack()} />
                </div>
            </div>
        );
    }
}

export default DatacenterEditor;
