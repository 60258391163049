import React, { ComponentClass, StatelessComponent, Component } from "react";
import logger from "../../shared/logger";
import { history } from "../history";

interface State {
    lastLoggedPath?: string;
}

export const withLogView = function <P>(title: string, WrappedComponent: ComponentClass<P> | StatelessComponent<P>) {
    return class extends Component<P, State> {
        private startTime: number;
        private endTime?: number;

        state: State = {
            lastLoggedPath: undefined,
        };

        constructor(props: any) {
            super(props);

            this.startTime = window.performance.now();
        }

        componentDidMount() {
            this.endTime = window.performance.now();
            this.logPageView(true);
        }

        componentDidUpdate() {
            this.logPageView();
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }

        private logPageView(logDuration = false) {
            const path = this.path();

            // The path has not changed -- abort.
            if (path === this.state.lastLoggedPath) {
                return;
            }

            this.setState({
                lastLoggedPath: path,
            });
            let durationMs = 0;

            if (logDuration && this.endTime) {
                durationMs = this.endTime - this.startTime;
            }

            logger.trackPageView(path, { durationMs });
            ga("send", "pageview", path, {
                title,
            });
        }

        private path(): string {
            const location = history.location;
            return `${location.pathname}${location.search}`;
        }
    };
};
