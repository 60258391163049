import React, { memo } from "react";
import { TopgearHelper } from "../../../services/topgear/TopgearHelper";
import { messages } from "../../messages";
import { getLabel, ShippingAddressFields, SHIPPING_ADDRESS_KEYS } from "../../models/ShippingAddressFields";
import Address from "../Address";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";

interface Props {
    shippingAddress?: ShippingAddressFields;
}

function ShippingInfo({ shippingAddress }: Props) {
    return (
        <Content title={messages.title.shippingAddress()}>
            {shippingAddress && !TopgearHelper.isAddressEmpty(shippingAddress) ? (
                <Address address={shippingAddress} fields={SHIPPING_ADDRESS_KEYS} getLabel={getLabel} />
            ) : (
                <MissingInfo message={messages.notice.missingShippingAddress()} />
            )}
        </Content>
    );
}

export default memo(ShippingInfo);
