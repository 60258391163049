import { messages } from "../../shared/messages";
import { Contact, ContactNew } from "../../shared/models/Contact";

export enum ContactKey {
    Technical = "technicalContact",
    Shipping = "shippingContact",
    NOC = "nocContact",
    CustomsBroker = "customsContact",
    Logistics = "logisticsContact",
}

const LABELS_BY_CONTACT_KEY = Object.freeze<{ [R in ContactKey]: string }>({
    [ContactKey.Shipping]: messages.labels.contactType.shipping(),
    [ContactKey.Technical]: messages.labels.contactType.technical(),
    [ContactKey.CustomsBroker]: messages.labels.contactType.customs(),
    [ContactKey.NOC]: messages.labels.contactType.noc(),
    [ContactKey.Logistics]: messages.labels.contactType.logistics(),
});

const DESCRIPTIONS_BY_CONTACT_KEY = Object.freeze<{ [R in ContactKey]: string }>({
    [ContactKey.Shipping]: messages.notes.contactDescription.shipping(),
    [ContactKey.Technical]: messages.notes.contactDescription.technical(),
    [ContactKey.CustomsBroker]: messages.notes.contactDescription.customs(),
    [ContactKey.NOC]: messages.notes.contactDescription.noc(),
    [ContactKey.Logistics]: messages.notes.contactDescription.logistics(),
});

export const SITE_SURVEY_CONTACT_KEYS: ContactKey[] = [
    ContactKey.Shipping,
    ContactKey.Technical,
    ContactKey.NOC,
    ContactKey.Logistics,
    ContactKey.CustomsBroker,
];
const SITE_SURVEY_REQUIRED_CONTACT_KEYS: ContactKey[] = [ContactKey.Shipping, ContactKey.Technical, ContactKey.NOC];

export function getRequiredContactKeys(countryCode: string) {
    const result = new Set(SITE_SURVEY_REQUIRED_CONTACT_KEYS);
    if (!COUNTRIES_THAT_DO_NOT_REQUIRE_LOGISTICS_CONTACT.includes(countryCode)) {
        result.add(ContactKey.Logistics);
    }
    return result;
}

export type SiteSurveyContacts = Partial<Record<ContactKey, Contact | ContactNew>>;

export function getContactKeyLabel(key: ContactKey): string {
    return LABELS_BY_CONTACT_KEY[key] || key;
}

export function getContactKeyDescription(key: ContactKey): string {
    return DESCRIPTIONS_BY_CONTACT_KEY[key] || "";
}

export const COUNTRIES_THAT_DO_NOT_REQUIRE_LOGISTICS_CONTACT = [
    // EU countries
    "BE",
    "BG",
    "CZ",
    "DK",
    "DE",
    "EE",
    "IE",
    "EL",
    "ES",
    "FR",
    "HR",
    "IT",
    "CY",
    "LV",
    "LT",
    "LU",
    "HU",
    "MT",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "FI",
    "SE",
    // others
    "BR",
    "CA",
    "US",
];
