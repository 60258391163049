import { pick } from "lodash";
import { Address } from "../shared/models/Address";
import { AddressFields, ADDRESS_KEYS, DEFAULT_ADDRESS_VALUES } from "../shared/models/AddressFields";
import { getLastUpdated as defaultGetLastUpdated } from "../shared/models/Audited";
import { Change } from "../shared/models/Change";
import { Changeset } from "../shared/models/Changeset";
import { Contact } from "../shared/models/Contact";
import { ContactFields, CONTACT_FIELDS_KEYS } from "../shared/models/ContactFields";
import { DatacenterFields, DATACENTER_KEYS } from "../shared/models/Datacenter";
import { EORI_FIELD_KEY } from "../shared/models/EoriCompliant";
import { getSubmittedOn as defaultGetSubmittedOn } from "../shared/models/Submittable";
import { TAXPAYER_FIELDS_KEY } from "../shared/models/TaxpayerAffiliated";
import { ValidationError } from "../shared/models/ValidationError";
import { BgpConfigFields, BGP_ASN_KEY, BGP_CONFIG_KEYS, BGP_MULTIHOP_KEY } from "../siteSurveys/models/BgpConfig";
import { NetworkConfigFields, NETWORK_CONFIG_KEYS } from "../siteSurveys/models/NetworkConfig";
import { NETWORK_CONFIGS_KEY, SiteSurvey } from "../siteSurveys/models/SiteSurvey";
import { SHIPPING_ADDRESS_KEY, SITE_ADDRESS_KEY } from "../siteSurveys/models/SiteSurveyAddresses";
import { ContactKey, SiteSurveyContacts, SITE_SURVEY_CONTACT_KEYS } from "../siteSurveys/models/SiteSurveyContacts";
import { SiteSurveyProperty } from "../siteSurveys/models/SiteSurveyProperty";
import { SiteSurveyStatus } from "../siteSurveys/models/SiteSurveyStatus";
import { SiteSurveyView } from "../siteSurveys/models/SiteSurveyView";
import { latestOf, noLaterThanNow } from "./dates";
import { getDiffs } from "./diffs";
import { isAddress, isContact } from "./guards";
import { checkIsNew as defaultCheckIsNew, checkIsSubmitted as defaultCheckIsSubmitted } from "./partnerForm";
import { doesPathBeginWith, doesPathBeginWithOneOf, isPathOneOf } from "./paths";

/**
 * Returns the last updated date of the site survey, taking into account any of
 * its related entities. Date is adjusted to be no later than now.
 * @param siteSurvey
 */
export function getLastUpdated(siteSurvey: Readonly<SiteSurvey>): Date {
    const candidates: Date[] = [defaultGetLastUpdated(siteSurvey)];

    SITE_SURVEY_CONTACT_KEYS.forEach((role) => {
        const contact = siteSurvey[role];
        if (contact && isContact(contact)) {
            candidates.push(defaultGetLastUpdated(contact));
        }
    });

    if (siteSurvey.shippingAddress && isAddress(siteSurvey.shippingAddress)) {
        candidates.push(defaultGetLastUpdated(siteSurvey.shippingAddress));
    }

    if (siteSurvey.siteAddress && isAddress(siteSurvey.siteAddress)) {
        candidates.push(defaultGetLastUpdated(siteSurvey.siteAddress));
    }

    return noLaterThanNow(latestOf(candidates));
}

/**
 * Returns whether or not the site survey has been submitted.
 * @param siteSurvey
 */
export function checkIsSubmitted(siteSurvey: Readonly<SiteSurvey>): boolean {
    return siteSurvey.jira !== undefined || defaultCheckIsSubmitted(siteSurvey);
}

/**
 * Returns whether or not the site survey is considered new.
 * @param siteSurvey
 */
export function checkIsNew(siteSurvey: Readonly<SiteSurvey>): boolean {
    return defaultCheckIsNew(siteSurvey, getLastUpdated(siteSurvey), checkIsSubmitted(siteSurvey));
}

export function checkIsCancelled(siteSurvey: Readonly<SiteSurvey>): boolean {
    return siteSurvey.siteSurveyStatus === SiteSurveyStatus.Cancelled;
}

/**
 * Returns the last submitted date, if any, adjusted to be no later than now. If
 * `guess` is true, will try to guess the submitted date based on JIRA or last
 * updated timestamp.
 * @param siteSurvey
 */
export function getSubmittedOn(siteSurvey: Readonly<SiteSurvey>, guess = false): Date | undefined {
    if (!checkIsSubmitted(siteSurvey)) {
        return;
    }

    if (siteSurvey.submittedOn) {
        return defaultGetSubmittedOn(siteSurvey);
    }

    if (guess) {
        if (siteSurvey.jira) {
            return noLaterThanNow(new Date(siteSurvey.jira.dateCreated));
        }

        const lastUpdated = getLastUpdated(siteSurvey);
        if (lastUpdated) {
            return noLaterThanNow(lastUpdated);
        }
    }

    return;
}

export function createAddressChanges(
    address: Partial<AddressFields>,
    at: typeof SHIPPING_ADDRESS_KEY | typeof SITE_ADDRESS_KEY
): Changeset {
    return [
        ...ADDRESS_KEYS.map((key) => ({
            path: [at, key],
            value: address[key] || DEFAULT_ADDRESS_VALUES[key] || "",
        })),
    ];
}

export function createContactChanges(contact: Partial<ContactFields>, key: ContactKey): Changeset {
    return [
        ...CONTACT_FIELDS_KEYS.map((field) => ({
            path: [key, field],
            value: contact[field] || "",
        })),
    ];
}

export function createBGPConfigChanges(values: Partial<BgpConfigFields>): Changeset {
    const bgpConfig = pick(values, ...BGP_CONFIG_KEYS);

    return [
        ...BGP_CONFIG_KEYS.map((key) => ({
            path: key,
            value: bgpConfig[key],
        })),
    ];
}

export function createDatacenterChanges(values: Partial<DatacenterFields>): Changeset {
    const datacenter = pick(values, ...DATACENTER_KEYS);

    return [
        ...DATACENTER_KEYS.filter((key) => datacenter.hasOwnProperty(key)).map((key) => ({
            path: key,
            value: datacenter[key],
        })),
    ];
}

export function createNetworkConfigChanges(values: Partial<NetworkConfigFields>, at: number): Changeset {
    const networkConfig = pick(values, ...NETWORK_CONFIG_KEYS);

    return [
        ...NETWORK_CONFIG_KEYS.map((key) => ({
            path: [NETWORK_CONFIGS_KEY, at, key],
            value: networkConfig[key],
        })),
    ];
}

export function createContactSelectionChanges(
    contact: ContactFields | Contact | undefined,
    key: ContactKey
): Changeset {
    const changes: Change[] = [];

    if (!contact) {
        changes.push({
            path: key,
            value: undefined,
        });
    } else {
        changes.push(
            ...CONTACT_FIELDS_KEYS.map((field) => ({
                path: [key, field],
                value: contact[field] || "",
            }))
        );
    }

    return changes;
}

export function createAddressSelectionChanges(
    address: AddressFields | Address | undefined,
    at: typeof SHIPPING_ADDRESS_KEY | typeof SITE_ADDRESS_KEY
) {
    const changes: Change[] = [];

    if (!address) {
        changes.push({
            path: at,
            value: undefined,
        });
    } else {
        changes.push(
            ...ADDRESS_KEYS.map((key) => ({
                path: [at, key],
                value: address[key] || "",
            }))
        );
    }

    return changes;
}

/**
 * Does a diff of two site survey objects. Ignores diffs that are inconsequential.
 * @param original - The origin site survey.
 * @param modified - The modified site survey to compare it with. Can be
 * undefined, in which case this function simply returns false.
 */
export function checkAreSiteSurveysDifferent(
    original: SiteSurvey | undefined,
    modified: SiteSurvey | undefined
): boolean {
    if (!modified) {
        return false;
    }

    if (!original) {
        return true;
    }

    return getDiffs(original, modified, ["org", "jira", "siteSurveyHardwares"]).length > 0;
}

/**
 * Returns true if any of the partner-facing properties are editable. Or can be
 * used to check if a specific site survey property is editable by providing a
 * `key`.
 *
 * @param siteSurvey The site survey to check.
 * @param key If provided, returns whether or not the particular property is
 * editable.
 * @param forceEditable If `true`, overrides the check to return `true`.
 */
export function checkIsEditable({
    siteSurvey,
    key,
    forceEditable = false,
}: {
    siteSurvey: SiteSurvey;
    key?: SiteSurveyProperty;
    forceEditable?: boolean;
}): boolean {
    if (forceEditable) {
        return true;
    }

    if (key === undefined) {
        const partnerFacingProperties = [
            SiteSurveyProperty.AdditionalRecipients,
            SiteSurveyProperty.Notes,
            SiteSurveyProperty.NetworkConfigs,
            SiteSurveyProperty.SiteAddress,
            SiteSurveyProperty.PartDefinitions,
            SiteSurveyProperty.ShippingAddress,
            SiteSurveyProperty.Contacts,
        ];

        return partnerFacingProperties.some((property) => siteSurvey.editableProperties[property]);
    } else {
        return siteSurvey.editableProperties[key] ?? false;
    }
}

/**
 * Returns the site survey view that the error is attributed to, if applicable.
 * @param error
 */
export function attributeSiteSurveyErrorToView(error: ValidationError): SiteSurveyView | undefined {
    if (!error.path) {
        return;
    }

    if (doesPathBeginWith(error.path, SITE_ADDRESS_KEY) || isPathOneOf(error.path, DATACENTER_KEYS)) {
        return SiteSurveyView.Site;
    }

    if (
        doesPathBeginWith(error.path, TAXPAYER_FIELDS_KEY) ||
        doesPathBeginWith(error.path, EORI_FIELD_KEY) ||
        doesPathBeginWith(error.path, SHIPPING_ADDRESS_KEY) ||
        doesPathBeginWithOneOf(error.path, SITE_SURVEY_CONTACT_KEYS)
    ) {
        return SiteSurveyView.Shipping;
    }

    if (
        doesPathBeginWith(error.path, NETWORK_CONFIGS_KEY) ||
        isPathOneOf(error.path, [BGP_ASN_KEY, BGP_MULTIHOP_KEY])
    ) {
        return SiteSurveyView.Appliances;
    }

    return;
}

/**
 * Extracts the contacts from a site survey. Empty contacts (those with empty
 * strings as values) are retained. This is important because when passing the
 * set of contacts to forms, they are initialized with these values.
 */
export function extractContactsFromSiteSurvey(siteSurvey: SiteSurvey): SiteSurveyContacts {
    return pick(siteSurvey, ...SITE_SURVEY_CONTACT_KEYS);
}
