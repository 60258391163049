import classnames from "classnames";
import React, { PureComponent } from "react";
import { Option } from "../../models/Option";
import { FieldProps } from "../../props/forms";

export interface Props<T> extends FieldProps {
    value?: T;
    onChange?: (value: T) => void;
    options: readonly Option<T>[];
    dirty?: boolean;
}

/**
 * A bar of options...something like: ( Option1 | Option2 | Option3 )
 */
class OptionBar<T = any> extends PureComponent<Props<T>> {
    constructor(props: Props<T>) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        const { options, value, dirty, error } = this.props;
        const activeOption = options.find((option) => option.value === value);
        return (
            <div className={classnames("OptionBar", { dirty, error })}>
                <div className="OptionBar-group">
                    {options.map((option, idx) => (
                        <div
                            key={idx}
                            className={classnames("OptionBar-option", { active: option === activeOption })}
                            onClick={() => this.handleClick(option.value)}
                        >
                            {option.text}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private handleClick(value: T) {
        const { onChange } = this.props;
        onChange?.(value);
    }
}

export default OptionBar;
