import classnames from "classnames";
import { format, formatDistanceToNowStrict } from "date-fns";
import { LocationDescriptor } from "history";
import React from "react";
import { useRouteMatch } from "react-router";
import {
    checkIsNew as defaultCheckIsNew,
    checkIsSubmitted as defaultCheckIsSubmitted,
} from "../../../utils/partnerForm";
import { messages } from "../../messages";
import { getLastUpdated as defaultGetLastUpdated } from "../../models/Audited";
import { PartnerForm } from "../../models/PartnerForm";
import { DATETIME_FULL } from "../../settings";
import Badge from "../Badge";
import Icon from "../Icon";
import Link from "../Link";

interface Props<T extends PartnerForm> {
    entry: Readonly<T>;
    to: LocationDescriptor;
    checkIsEditable?: (entry: T) => boolean;
    checkIsNew?: (entry: T) => boolean;
    checkIsSubmitted?: (entry: T) => boolean;
    getLastUpdated?: (entry: T) => Date;
}

function PartnerFormsListItem<T extends PartnerForm>({
    entry,
    to,
    getLastUpdated = defaultGetLastUpdated,
    checkIsEditable,
    checkIsNew,
    checkIsSubmitted = defaultCheckIsSubmitted,
}: Props<T>) {
    const lastUpdated = getLastUpdated(entry);
    const isEditable = checkIsEditable ? checkIsEditable(entry) : entry.editable;
    const isNew = checkIsNew ? checkIsNew(entry) : defaultCheckIsNew(entry, lastUpdated, checkIsSubmitted(entry));
    const pathname = typeof to === "string" ? to : to.pathname ?? "";
    const match = useRouteMatch(pathname);

    return (
        <li
            className={classnames("PartnerFormsListItem", {
                active: match,
            })}
        >
            <Link to={to} title={entry.name} className="PartnerFormsListItem-link" activeClassName="active">
                <div className="PartnerFormsListItem-header">
                    <Icon icon={isEditable ? "lock-open" : "lock"} />
                    <span className="PartnerFormsListItem-name">{entry.name}</span>
                </div>
            </Link>
            {isNew ? (
                <Badge className="PartnerFormsListItem-accessory" title="New">
                    {messages.meta.new()}
                </Badge>
            ) : (
                <span
                    className={classnames("PartnerFormsListItem-accessory PartnerFormsListItem-timestamp", {
                        active: match,
                    })}
                    title={format(lastUpdated, DATETIME_FULL)}
                >
                    {formatDistanceToNowStrict(lastUpdated, {
                        addSuffix: true,
                        roundingMethod: "floor",
                    })}
                </span>
            )}
        </li>
    );
}

export default PartnerFormsListItem;
