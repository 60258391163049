import { ContactFields, EMAIL_WORK_KEY, NAME_FIRST_KEY, NAME_LAST_KEY } from "../shared/models/ContactFields";
import { Dictionary } from "../shared/models/Dictionary";
import { OptionGroup } from "../shared/models/OptionGroup";
import { contactAsOption } from "./options";
import { TopgearHelper } from "../services/topgear/TopgearHelper";

/**
 * Certain contact names are placeholders that are generated by our services.
 * This mapper maps them to display names that look more consistent/less dumb.
 */
const CONTACT_NAME_MAPPER = new Map([
    [`${TopgearHelper.NOC_LABEL} ${TopgearHelper.NOC_LABEL}`, "NOC"],
    [`${TopgearHelper.NOC_FIRST_NAME} ${TopgearHelper.NOC_LAST_NAME}`, "NOC"]
]);

const NETFLIX_EMAIL_RE = /@netflix\.com$/i;

/**
 * Returns a new contact object with first and last name combined into the first
 * name field. The last name field is replaced with an empty string.
 * @param contactFields
 */
export function combineFirstAndLastName<T extends ContactFields>(contactFields: T): T {
    const result: T = { ...(contactFields as any) };

    result[NAME_FIRST_KEY] = getFullName(contactFields);
    result[NAME_LAST_KEY] = "";

    return result;
}

/**
 * Get contact name in the format of "FIRST LAST".
 */
export function getFullName(contact: ContactFields): string {
    return [contact[NAME_FIRST_KEY], contact[NAME_LAST_KEY]].join(" ").trim();
}

/**
 * Get the display name of the contact. This replaces placeholder names with
 * names that are more user-friendly.
 */
export function getDisplayName(contact: ContactFields): string {
    const name = getFullName(contact);
    let displayName = name;

    if (CONTACT_NAME_MAPPER.has(name)) {
        displayName = CONTACT_NAME_MAPPER.get(name)!;
    }

    return displayName;
}

export function groupByRole(contacts: ContactFields[], unassignedLabel: string): Dictionary<ContactFields[]> {
    const groups: { [label: string]: ContactFields[] } = {
        [unassignedLabel]: []
    };

    contacts.forEach(contact => {
        if (contact.roles) {
            contact.roles.forEach(role => {
                groups[role] = groups[role] || [];
                groups[role] = [...groups[role], contact];
            });
        } else {
            groups[unassignedLabel] = [...groups[unassignedLabel], contact];
        }
    });

    return groups;
}

export function createOptionGroupsByRole(contacts: ContactFields[], unassignedLabel: string): OptionGroup[] {
    const byRole = groupByRole(contacts, unassignedLabel);

    return Object.entries(byRole)
        .map(([label, contacts]) => ({
            label,
            options: (contacts || []).map(contact => contactAsOption(contact))
        }))
        .sort((a, b) => {
            // Sort the unassigned group last.
            if (a.label === unassignedLabel) {
                return 1;
            }
            if (b.label === unassignedLabel) {
                return -1;
            }
            return a.label.localeCompare(b.label);
        });
}

export function isNetflixEmail(email: string): boolean {
    return NETFLIX_EMAIL_RE.test(email);
}

export function isNetflixContact(contact: ContactFields): boolean {
    return isNetflixEmail(contact[EMAIL_WORK_KEY]);
}
