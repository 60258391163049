import React, { memo } from "react";
import { messages } from "../../messages";
import { RECIPIENT_KEY, ShippingAddressFields, SHIPPING_ADDRESS_KEYS } from "../../models/ShippingAddressFields";
import AddressForm, { Props as AddressFormProps } from "../AddressForm";
import FormField from "../FormField";
import TextInput from "../TextInput";

interface Props extends AddressFormProps<ShippingAddressFields> {}

/**
 * An extension of `AddressForm` that supports `ShippingAddress`. If using this
 * with the `AddressEditor`, make sure to provide this component via the
 * `renderAddressForm` prop.
 */
function ShippingAddressForm(props: Props) {
    return (
        <AddressForm
            {...props}
            keys={SHIPPING_ADDRESS_KEYS}
            additionalFieldsPlacement="start"
            additionalFields={({ dirty, errors, values }, { touchHandlers, changeHandlers }) => (
                <FormField
                    name={RECIPIENT_KEY}
                    label={messages.labels.addressField.recipient()}
                    error={errors.get(RECIPIENT_KEY)}
                >
                    <TextInput
                        dirty={dirty.has(RECIPIENT_KEY)}
                        error={errors.has(RECIPIENT_KEY)}
                        placeholder={messages.forms.placeholders.recipient()}
                        disabled={props.disabled}
                        value={values[RECIPIENT_KEY]}
                        onChange={(evt) => changeHandlers[RECIPIENT_KEY]?.(evt.target.value)}
                        onBlur={() => touchHandlers[RECIPIENT_KEY]?.()}
                    />
                </FormField>
            )}
        />
    );
}

export default memo(ShippingAddressForm);
