import React, { CSSProperties, PropsWithChildren } from "react";
import { Transition } from "react-transition-group";
import { ENTERED, ENTERING, EXITED, EXITING, UNMOUNTED } from "react-transition-group/Transition";

interface Props {
    in?: boolean;
    /** ms */
    duration?: number;
    /**
     * If true, will position entering elements absolutely. Use this if you are
     * trying to achieve a "cross-fade" effect in a transition group.
     */
    crossFade?: boolean;
    style?: CSSProperties;
}

function defaultStyles(duration: number) {
    return {
        transition: `all ${duration}ms cubic-bezier(.62,0,.23,.99)`,
    };
}

function transitionStyles(crossFade: boolean) {
    return {
        [ENTERING]: {
            ...(crossFade
                ? {
                      position: "absolute",
                      top: 0,
                  }
                : undefined),
            opacity: 0.01,
        },
        [ENTERED]: {
            opacity: 1,
        },
        [EXITING]: {
            opacity: 0.01,
        },
        [EXITED]: {
            opacity: 0,
        },
        [UNMOUNTED]: {
            opacity: 0,
        },
    };
}

/**
 * A transition wrapper that animates the element fading in when mounting.
 */
function FadeIn({ duration = 80, in: inProp, children, crossFade = true, style }: PropsWithChildren<Props>) {
    return (
        <Transition in={inProp} timeout={duration} unmountOnExit>
            {(state: string) => (
                <div style={{ ...defaultStyles(duration), ...transitionStyles(crossFade)[state], ...style }}>
                    {children}
                </div>
            )}
        </Transition>
    );
}

export default FadeIn;
