import React, { FC, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import Login from "../../../auth/components/Login";
import { AuthService } from "../../../services/auth";
import { AppConfig } from "../../../shared/config";
import { SudoContext } from "../../../shared/contexts/SudoContext";
import { UserContext } from "../../../shared/contexts/UserContext";
import { WithUser } from "../../../shared/hoc/withUser";
import { PATH_LOGIN, QUERY_ORG_ID, QUERY_SUDO, RoutesHelper } from "../../../shared/routes";
import { asInt } from "../../../utils/strings";
import { getSearchValue } from "../../../utils/url";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";
import Authenticated from "../Authenticated";

export interface Props extends WithUser {
    config: AppConfig;
    onClickLogOut: () => void;
}

const App: FC<Props> = (props) => {
    const { config, onClickLogOut, user } = props;
    const location = useLocation();
    const orgId = asInt(getSearchValue(location.search, QUERY_ORG_ID) || "");
    const isSudoFlagEnabled = getSearchValue(location.search, QUERY_SUDO) === "";
    const authorization = useMemo(() => AuthService.getAuthorization(user, orgId), [user, orgId]);
    const isAdmin = AuthService.checkIsAdmin(user);

    return (
        <UserContext.Provider value={user}>
            <SudoContext.Provider value={isAdmin && isSudoFlagEnabled}>
                <div className="App">
                    <AppHeader user={user} onClickLogOut={onClickLogOut} env={config.env} />
                    {/* All routes should render a component wrapper with <Body/> */}
                    <Switch>
                        {/* Authenticated */}
                        {authorization && <Authenticated authorization={authorization} />}

                        {/* Not authenticated */}
                        <Route path={PATH_LOGIN} component={Login} />
                        <Redirect
                            to={{
                                ...location,
                                pathname: PATH_LOGIN,
                                search: RoutesHelper.generateRedirectSearchParamsFromPath(
                                    location.pathname,
                                    location.search
                                )
                            }}
                        />
                    </Switch>
                    <AppFooter version={config.version} />
                </div>
            </SudoContext.Provider>
        </UserContext.Provider>
    );
};

export default App;
