import React, { memo } from "react";
import { Content } from "../../../shared/components/PartnerForm";
import { messages } from "../../../shared/messages";
import { FormPropsV2 } from "../../../shared/props/forms";
import { OcaReturnPickupPreferences } from "../../models/OcaReturnPickupPreferences";
import OcaReturnPickupPreferencesForm from "../OcaReturnPickupPreferencesForm";

export interface Props extends FormPropsV2<OcaReturnPickupPreferences> {
    initialPreferences: OcaReturnPickupPreferences;
    preferences?: OcaReturnPickupPreferences;
}

function OcaReturnPickupPreferencesEditor({ preferences, initialPreferences, validationErrors, onChange }: Props) {
    return (
        <Content title={messages.title.pickupPreferences()}>
            <OcaReturnPickupPreferencesForm
                initialValues={initialPreferences}
                values={preferences}
                validationErrors={validationErrors}
                onChange={onChange}
            />
        </Content>
    );
}

export default memo(OcaReturnPickupPreferencesEditor);
