import React, { MouseEvent, useCallback } from "react";
import { TransitionGroup } from "react-transition-group";
import Button from "../../../shared/components/Button";
import Icon from "../../../shared/components/Icon";
import Markdown from "../../../shared/components/Markdown";
import Message from "../../../shared/components/Message";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Removable, { Props as RemovableProps } from "../../../shared/components/Removable";
import SlideDown from "../../../shared/components/SlideDown";
import Spacer from "../../../shared/components/Spacer";
import { messages } from "../../../shared/messages";
import { ModelValidationErrors } from "../../../shared/models/ModelValidationErrors";
import { PartDefinitionWithFlags } from "../../../shared/models/PartDefinition";
import { ID_KEY, Resource } from "../../../shared/models/Resource";
import { ValidationError } from "../../../shared/models/ValidationError";
import { extractValidationError } from "../../../shared/validation";
import { PartOrderLineItem } from "../../models/PartOrderLineItem";
import { PartOrderLineItemFields, PART_KEY, QUANTITY_KEY } from "../../models/PartOrderLineItemFields";
import { PartOrderLineItemNew } from "../../models/PartOrderLineItemNew";
import PartOrderLineItemForm from "../PartOrderLineItemForm";

interface Props {
    disallowPsuReplacements: boolean;
    /**
     * A list of line items as they were retrieved from the API.
     */
    initialLineItems: PartOrderLineItem[];
    /**
     * A list of line items that include unsaved user edits, or entirely new
     * line items that have not yet been persisted.
     */
    lineItems?: (PartOrderLineItem | PartOrderLineItemNew)[];
    chooseFrom: PartDefinitionWithFlags[];
    onRemove: (at: number) => void;
    onAdd: () => void;
    onChange: (values: Partial<PartOrderLineItemFields<Resource>>, at: number) => void;
    validationErrors?: ValidationError | (ModelValidationErrors<PartOrderLineItemFields> | undefined)[];
    openTicketUrl?: string;
}

const MAX_ITEMS = 10;
const TRANSITION_DURATION_MS = 80;
function validationErrorsList(
    validationErrors: ValidationError | (ModelValidationErrors<PartOrderLineItemFields> | undefined)[] | undefined
) {
    return Array.isArray(validationErrors) ? validationErrors : [];
}

function convertError(
    error: ModelValidationErrors<PartOrderLineItemFields<Resource>> | undefined
): ModelValidationErrors<PartOrderLineItemFields<number>> | undefined {
    if (!error) {
        return;
    }

    return {
        ...error,
        [PART_KEY]: extractValidationError(error, PART_KEY),
    };
}

/**
 * Translates a Part Order line item into form values.
 */
function convertToFormFields(
    lineItem: PartOrderLineItemNew | PartOrderLineItem
): Partial<PartOrderLineItemFields<number>> {
    const values: Partial<PartOrderLineItemFields<number>> = {};

    const quantity = lineItem[QUANTITY_KEY];
    if (quantity !== undefined) {
        values[QUANTITY_KEY] = quantity;
    }

    const part = lineItem[PART_KEY];
    if (part !== undefined) {
        values[PART_KEY] = part[ID_KEY];
    }

    return {
        [QUANTITY_KEY]: "",
        [PART_KEY]: "",
        ...values,
    } as any;
}

function PartOrderLineItemsEditor(props: Props) {
    const {
        chooseFrom: partDefinitions,
        initialLineItems,
        lineItems,
        validationErrors,
        disallowPsuReplacements,
        onAdd,
        onRemove,
        onChange,
        openTicketUrl,
    } = props;
    const errors = validationErrorsList(validationErrors);

    /**
     * Translates form values into a well-formed Part Order line item. Replaces empty string values with `undefined`.
     * See `convertToFormFields` for this method's counterpart.
     */
    const convertFromFormFields = useCallback(
        (values: Partial<PartOrderLineItemFields<number>>): Partial<PartOrderLineItemFields> => {
            const selectedPartDefinition = partDefinitions.find(({ id }) => id === values[PART_KEY]);
            return {
                ...values,
                [PART_KEY]: selectedPartDefinition,
                [QUANTITY_KEY]: typeof values[QUANTITY_KEY] === "number" ? values[QUANTITY_KEY] : undefined,
            };
        },
        [partDefinitions]
    );

    const getLineItemIndexFromId = useCallback(
        (target: number | string) => {
            const at = lineItems?.findIndex(({ id }) => id === target) ?? -1;
            return at === -1 ? undefined : at;
        },
        [lineItems]
    );

    const getInitialLineItemFromId = useCallback(
        (target: number | string): PartOrderLineItem | PartOrderLineItemNew =>
            typeof target === "number"
                ? initialLineItems.find(({ id }) => id === target)!
                : {
                      id: target,
                      version: 0,
                  },
        [initialLineItems]
    );

    const handleClickAdd = useCallback(
        (evt: MouseEvent<HTMLButtonElement>) => {
            evt.preventDefault();
            onAdd();
        },
        [onAdd]
    );

    const handleClickRemove = useCallback(
        ({ id }: RemovableProps) => {
            const at = getLineItemIndexFromId(id);
            if (at !== undefined) {
                onRemove(at);
            }
        },
        [onRemove, getLineItemIndexFromId]
    );

    const handleChange = useCallback(
        (values: Partial<PartOrderLineItemFields<number>>, id: number | string) => {
            const at = getLineItemIndexFromId(id);
            if (at !== undefined) {
                onChange(convertFromFormFields(values), at);
            }
        },
        [onChange, getLineItemIndexFromId, convertFromFormFields]
    );
    const effectiveLineItems = lineItems ?? initialLineItems;

    return (
        <Content title={messages.title.parts()}>
            {disallowPsuReplacements && (
                <>
                    <Message
                        icon={() => <Icon fixedWidth icon="exclamation-triangle" />}
                        message={() => (
                            <span>
                                {messages.notice.partsPossiblyUnavailable()}{" "}
                                {openTicketUrl ? (
                                    <Markdown
                                        source={messages.notice.remediationOpenATicket({
                                            URL: openTicketUrl,
                                        })}
                                    />
                                ) : (
                                    messages.notice.remediationAnonymous()
                                )}
                            </span>
                        )}
                    />
                    <Spacer size="lg" />
                </>
            )}
            <TransitionGroup component={null}>
                {effectiveLineItems.map((lineItem, idx) => {
                    const initialLineItem = getInitialLineItemFromId(lineItem.id);
                    return (
                        <SlideDown key={lineItem.id} approxHeight={80} duration={TRANSITION_DURATION_MS}>
                            <Removable
                                className="PartOrderLineItemsEditor-lineItem"
                                key={lineItem.id}
                                id={lineItem.id}
                                when={effectiveLineItems.length > 1}
                                onRemove={handleClickRemove}
                            >
                                <PartOrderLineItemForm
                                    chooseFrom={partDefinitions}
                                    initialValues={convertToFormFields(initialLineItem)}
                                    values={convertToFormFields(lineItem)}
                                    onChange={(values) => handleChange(values, lineItem.id)}
                                    validationErrors={convertError(errors[idx])}
                                />
                            </Removable>
                        </SlideDown>
                    );
                })}
            </TransitionGroup>
            <TransitionGroup>
                <SlideDown key="empty" duration={TRANSITION_DURATION_MS}>
                    {effectiveLineItems.length === 0 && <MissingInfo message={messages.notice.missingParts()} />}
                </SlideDown>
            </TransitionGroup>
            <div className="PartOrderLineItemsEditor-actions">
                <Button fluid onClick={handleClickAdd} disabled={effectiveLineItems.length >= MAX_ITEMS}>
                    {effectiveLineItems.length === 0 ? messages.actions.add() : messages.actions.addAnother()}
                </Button>
            </div>
        </Content>
    );
}

export default PartOrderLineItemsEditor;
