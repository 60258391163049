import React from "react";
import { Content } from "../../../shared/components/PartnerForm";
import { messages } from "../../../shared/messages";
import { FormPropsV2 } from "../../../shared/props/forms";
import { OcaReturnPickupProperties } from "../../models/OcaReturnPickupProperties";
import OcaReturnPickupPropertiesForm from "../OcaReturnPickupPropertiesForm";

export interface Props extends FormPropsV2<OcaReturnPickupProperties> {
    properties?: OcaReturnPickupProperties;
    initialProperties: OcaReturnPickupProperties;
    onChange?: (values: Partial<OcaReturnPickupProperties>) => void;
}

function OcaReturnPickupPropertiesEditor({ initialProperties, validationErrors, properties, onChange }: Props) {
    return (
        <Content title={messages.title.pickupProperties()}>
            <OcaReturnPickupPropertiesForm
                initialValues={initialProperties}
                values={properties}
                validationErrors={validationErrors}
                onChange={onChange}
            />
        </Content>
    );
}

export default OcaReturnPickupPropertiesEditor;
