import { all, between, integer, required } from "../forms/validators";
import { ModelValidator } from "../models/ModelValidator";
import { Validator } from "../models/Validator";
import { validate } from "../validation";
import { BgpConfigFields, BGP_ASN_KEY, BGP_MULTIHOP_KEY } from "../../siteSurveys/models/BgpConfig";

const BGP_MIN_HOPS = 0;
const BGP_MAX_HOPS = 16;

export const BGP_CONFIG_VALIDATOR: ModelValidator<BgpConfigFields> = new Map<keyof BgpConfigFields, Validator>([
    [BGP_ASN_KEY, required],
    [BGP_MULTIHOP_KEY, all(required, integer, between([BGP_MIN_HOPS, BGP_MAX_HOPS]))]
]);

export function validateBgpConfig(bgpConfig: BgpConfigFields) {
    return validate(bgpConfig, BGP_CONFIG_VALIDATOR);
}
