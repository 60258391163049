import { zipWith } from "lodash";
import {
    getLabel,
    hasAnyIpv6Fields,
    IPV4_ADDRESS_KEY,
    IPV4_BGP_PEER_KEY,
    IPV4_GATEWAY_KEY,
    IPV4_SUBNET_MASK_KEY,
    IPV6_ADDRESS_KEY,
    IPV6_BGP_PEER_KEY,
    IPV6_GATEWAY_KEY,
    IPV6_PREFIX_LENGTH_KEY,
    NetworkConfigFields,
} from "../../siteSurveys/models/NetworkConfig";
import { inSameSubnet, isIpv4, isSubnetMaskOrPrefix } from "../../utils/ip";
import {
    all,
    conditional,
    either,
    ipv4,
    ipv6,
    notBroadcastAddress,
    notNetworkAddress,
    prefix,
    prefixLength,
    required,
    sameSubnet,
    subnetMask,
} from "../forms/validators";
import { notEqual } from "../forms/validators/compare";
import { messages } from "../messages";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { Level } from "../models/ValidationError";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

const IPV4_PREFIX_MIN_BITS = 1;
const IPV4_PREFIX_MAX_BITS = 31;

const IPV6_PREFIX_MIN_BITS = 1;
const IPV6_PREFIX_MAX_BITS = 127;

const IPV4_DENYLIST = ["0.0.0.0", "255.255.255.255"];
const IPV6_DENYLIST = [":", "::"];

const UNIQUE_FIELDS: (keyof NetworkConfigFields)[] = [IPV4_ADDRESS_KEY, IPV6_ADDRESS_KEY];

function hasEnteredAnyIpv6(value: string, allValues?: NetworkConfigFields): boolean {
    return allValues ? hasAnyIpv6Fields(allValues) : false;
}

/**
 * @returns whether the IP address for the given field is in the same subnet as
 * the IPv4 address or IPv4 gateway, using the subnet mask (or prefix length).
 */
function inSameSubnetAsAddressOrGateway(value: string, allValues?: NetworkConfigFields): boolean {
    if (!allValues) {
        return false;
    }

    if (!isIpv4(value)) {
        return false;
    }

    const ipv4Address = allValues[IPV4_ADDRESS_KEY];
    const ipv4Gateway = allValues[IPV4_GATEWAY_KEY];
    const subnetMask = allValues[IPV4_SUBNET_MASK_KEY];

    if (!subnetMask || !isSubnetMaskOrPrefix(subnetMask)) {
        return false;
    }

    if (ipv4Address && isIpv4(ipv4Address)) {
        return inSameSubnet(subnetMask, [ipv4Address, value]);
    } else if (ipv4Gateway && isIpv4(ipv4Gateway)) {
        return inSameSubnet(subnetMask, [ipv4Gateway, value]);
    } else {
        return false;
    }
}

/**
 * Validates that a list of fields are unique among the set of network configs.
 * Returns an array of errors (matching the indices of the network configs) for
 * network configs where values are found to not be unique. Uniqueness is a
 * simple === check.
 * @param networkConfigs - An array of network configs
 * @param fields - The fields that should be unique among the network configs
 */
function validateUniqueness(networkConfigs: NetworkConfigFields[], fields: (keyof NetworkConfigFields)[]) {
    const errors: ModelValidationErrors<NetworkConfigFields>[] = networkConfigs.map(() => ({}));

    fields.forEach((field) => {
        // A map of the value to the last network config index that this value was found
        const tracker = new Map<string, number>();

        networkConfigs.forEach((networkConfig, idx) => {
            const value = networkConfig[field];

            // Ignore empty strings or undefined
            if (value === "" || value === undefined) {
                return;
            }

            const conflictIndex = tracker.get(value);

            // A conflict is found, set ValidationError for each network config
            if (conflictIndex !== undefined) {
                errors[idx][field] = {
                    level: Level.Invalid,
                    message: messages.forms.validation.mustBeUnique({
                        FIELD: getLabel(field),
                        LOCATION: `${messages.entity.oca({ COUNT: 1 })} #${conflictIndex + 1}`,
                    }),
                };

                // We don't want to overwrite a previously set ValidationError
                if (!errors[conflictIndex][field]) {
                    errors[conflictIndex][field] = {
                        level: Level.Invalid,
                        message: messages.forms.validation.mustBeUnique({
                            FIELD: getLabel(field),
                            LOCATION: `${messages.entity.oca({ COUNT: 1 })} #${idx + 1}`,
                        }),
                    };
                }
            }

            tracker.set(value, idx);
        });
    });

    return errors;
}

export const NETWORK_CONFIG_VALIDATOR: ModelValidator<NetworkConfigFields> = new Map<
    keyof NetworkConfigFields,
    Validator
>([
    [
        IPV4_ADDRESS_KEY,
        all(
            required,
            ipv4(false, IPV4_DENYLIST),
            notNetworkAddress(IPV4_SUBNET_MASK_KEY),
            notBroadcastAddress(IPV4_SUBNET_MASK_KEY),
            notEqual(IPV4_GATEWAY_KEY, messages.forms.validation.networkConfig.ipv4Gateway()),
            notEqual(IPV4_BGP_PEER_KEY, messages.forms.validation.networkConfig.ipv4BGPPeer()),
            sameSubnet(IPV4_GATEWAY_KEY, messages.forms.validation.networkConfig.ipv4Gateway(), IPV4_SUBNET_MASK_KEY)
        ),
    ],
    [
        IPV4_SUBNET_MASK_KEY,
        all(
            required,
            // Subnet mask (i.e. 255.255.255.0) or CIDR prefix (e.g. /24)
            either(
                all(ipv4(false, IPV4_DENYLIST), subnetMask),
                all(prefix, prefixLength([IPV4_PREFIX_MIN_BITS, IPV4_PREFIX_MAX_BITS]))
            )
        ),
    ],
    [
        IPV4_GATEWAY_KEY,
        all(
            required,
            ipv4(false, IPV4_DENYLIST),
            notNetworkAddress(IPV4_SUBNET_MASK_KEY),
            notBroadcastAddress(IPV4_SUBNET_MASK_KEY),
            notEqual(IPV4_ADDRESS_KEY, messages.forms.validation.networkConfig.ipv4Address()),
            sameSubnet(IPV4_ADDRESS_KEY, messages.forms.validation.networkConfig.ipv4Address(), IPV4_SUBNET_MASK_KEY)
        ),
    ],
    [
        IPV4_BGP_PEER_KEY,
        all(
            required,
            ipv4(false, IPV4_DENYLIST),
            conditional(inSameSubnetAsAddressOrGateway, notNetworkAddress(IPV4_SUBNET_MASK_KEY)),
            conditional(inSameSubnetAsAddressOrGateway, notBroadcastAddress(IPV4_SUBNET_MASK_KEY)),
            notEqual(IPV4_ADDRESS_KEY, messages.forms.validation.networkConfig.ipv4Address())
        ),
    ],
    [
        IPV6_ADDRESS_KEY,
        conditional(
            hasEnteredAnyIpv6,
            all(
                required,
                ipv6(false, false, IPV6_DENYLIST),
                notEqual(IPV6_GATEWAY_KEY, messages.forms.validation.networkConfig.ipv6Gateway()),
                notEqual(IPV6_BGP_PEER_KEY, messages.forms.validation.networkConfig.ipv6BGPPeer()),
                sameSubnet(
                    IPV6_GATEWAY_KEY,
                    messages.forms.validation.networkConfig.ipv6Gateway(),
                    IPV6_PREFIX_LENGTH_KEY
                )
            )
        ),
    ],
    [
        IPV6_PREFIX_LENGTH_KEY,
        conditional(
            hasEnteredAnyIpv6,
            all(required, prefix, prefixLength([IPV6_PREFIX_MIN_BITS, IPV6_PREFIX_MAX_BITS]))
        ),
    ],
    [
        IPV6_GATEWAY_KEY,
        conditional(
            hasEnteredAnyIpv6,
            all(
                required,
                ipv6(false, false, IPV6_DENYLIST),
                notEqual(IPV6_ADDRESS_KEY, messages.forms.validation.networkConfig.ipv6Address()),
                sameSubnet(
                    IPV6_ADDRESS_KEY,
                    messages.forms.validation.networkConfig.ipv6Address(),
                    IPV6_PREFIX_LENGTH_KEY
                )
            )
        ),
    ],
    [
        IPV6_BGP_PEER_KEY,
        conditional(
            hasEnteredAnyIpv6,
            all(
                required,
                ipv6(false, false, IPV6_DENYLIST),
                notEqual(IPV6_ADDRESS_KEY, messages.forms.validation.networkConfig.ipv6Address())
            )
        ),
    ],
]);

export function validateNetworkConfig(
    networkConfig: NetworkConfigFields
): ModelValidationErrors<NetworkConfigFields> | undefined {
    return validate(networkConfig, NETWORK_CONFIG_VALIDATOR);
}

export function validateNetworkConfigs(
    networkConfigs: NetworkConfigFields[]
): ModelValidationErrors<NetworkConfigFields>[] {
    // These are both arrays with length = number of network configs.
    const individualErrors = networkConfigs.map(validateNetworkConfig);
    const intersectionalErrors = validateUniqueness(networkConfigs, UNIQUE_FIELDS);
    return zipWith(individualErrors, intersectionalErrors, (e1, e2) => ({ ...e1, ...e2 }));
}
