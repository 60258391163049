import { Path } from "./Path";

export enum Operation {
    /**
     * The default operation. Replaces the value at the specified path.
     */
    Replace,
    /**
     * Use this to push some value to an array at the specified path.
     */
    Add,
    /**
     * Use this to remove the item from an array at the specified path. The last
     * indexor of the path is the index of the item to remove.
     */
    Remove,
    /**
     * Use this to insert an item to an array at the specified path. The last
     * indexor of the path is the index at which to insert the item.
     */
    Insert,
}

export interface Change {
    path: Path;
    value?: any;
    operation?: Operation;
}
