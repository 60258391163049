import { usePrevious } from "@octools/hooks";
import { Location } from "history";
import React, { memo, useEffect } from "react";
import { useLocation } from "react-router";
import { AuthService } from "../../../services/auth";
import Link from "../../../shared/components/Link";
import { UserContext } from "../../../shared/contexts/UserContext";
import { Org } from "../../../shared/models/Org";
import { OrgSummary } from "../../../shared/models/OrgSummary";
import {
    PATH_OCA_RETURNS,
    PATH_PART_ORDERS,
    PATH_PICK_ORG,
    PATH_SITE_SURVEYS,
    QUERY_PARTNER_FORM_VIEW
} from "../../../shared/routes";
import { unsetSearchValue } from "../../../utils/url";
import Nav from "../Nav";

export interface Props {
    orgs?: OrgSummary[];
    orgId?: number;
    activeOrg?: Org;
    fetchOrg: (orgId: number) => void;
}

function PartnerFormsNav(props: Props) {
    const { orgId, fetchOrg, activeOrg, orgs } = props;
    const prevOrgId = usePrevious(orgId);
    const location = useLocation();

    useEffect(() => {
        if (orgId !== undefined && orgId !== prevOrgId) {
            fetchOrg(orgId);
        }
    }, [orgId, prevOrgId, fetchOrg]);

    function getLinks(): { name: string; to: Location }[] {
        const to = {
            ...location,
            search: unsetSearchValue(location.search, QUERY_PARTNER_FORM_VIEW)
        };
        return [
            {
                name: "Site Surveys",
                to: {
                    ...to,
                    pathname: PATH_SITE_SURVEYS
                }
            },
            {
                name: "Part Orders",
                to: {
                    ...to,
                    pathname: PATH_PART_ORDERS
                }
            },
            {
                name: "OCA Returns",
                to: {
                    ...to,
                    pathname: PATH_OCA_RETURNS
                }
            }
        ];
    }

    return (
        <UserContext.Consumer>
            {(user) => {
                const showNav = AuthService.checkIsAdminOrSsoPartner(user);
                const hasMoreOrgs = orgs && orgs.length > 1;

                if (!showNav) {
                    return;
                }

                if (orgId === undefined) {
                    return;
                }

                return (
                    <Nav
                        className="PartnerFormsNav"
                        title={activeOrg ? activeOrg.nameLong : "-"}
                        links={getLinks()}
                        accessory={hasMoreOrgs && <Link to={PATH_PICK_ORG}>View a different org</Link>}
                    />
                );
            }}
        </UserContext.Consumer>
    );
}

export default memo(PartnerFormsNav);
