import React, { Component } from "react";
import Address from "../../../shared/components/Address";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import { messages } from "../../../shared/messages";
import { AddressFields, getLabel } from "../../../shared/models/AddressFields";
import { TopgearHelper } from "../../../services/topgear/TopgearHelper";

interface Props {
    siteAddress?: AddressFields;
}

class SiteInfo extends Component<Props> {
    render() {
        const { siteAddress } = this.props;

        return (
            <Content title={messages.title.address()}>
                <Segment.Group horizontal>
                    <Segment>
                        {siteAddress && !TopgearHelper.isAddressEmpty(siteAddress) ? (
                            <Address address={siteAddress} getLabel={getLabel} />
                        ) : (
                            <MissingInfo message={messages.notice.missingSiteAddress()} />
                        )}
                    </Segment>
                </Segment.Group>
            </Content>
        );
    }
}

export default SiteInfo;
