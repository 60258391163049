import React, { memo, useCallback } from "react";
import AddressForm from "../../../shared/components/AddressForm";
import { Content } from "../../../shared/components/PartnerForm";
import { messages } from "../../../shared/messages";
import { AddressFields } from "../../../shared/models/AddressFields";
import { Country } from "../../../shared/models/Country";
import { CountrySubdivision } from "../../../shared/models/CountrySubdivision";
import { ModelValidationErrors } from "../../../shared/models/ModelValidationErrors";
import { ValidationError } from "../../../shared/models/ValidationError";

interface Props {
    siteAddress?: Partial<AddressFields>;
    initialSiteAddress?: Partial<AddressFields>;
    /**
     * A country ISO code. If non-null, the country field of the address form is
     * fixed to the country matching this code and cannot be edited.
     */
    fixedCountryCode?: string;
    countries: Country[];
    subdivisionsByCountryCode: Map<string, CountrySubdivision[]>;
    onChangeSiteAddress: (values: Partial<AddressFields>) => void;
    validationErrors?: ModelValidationErrors<AddressFields> | ValidationError;
}

function SiteInfoEditor({
    initialSiteAddress,
    siteAddress,
    countries,
    subdivisionsByCountryCode,
    validationErrors,
    fixedCountryCode,
    onChangeSiteAddress,
}: Props) {
    const handleChangeSiteAddress = useCallback((values: Partial<AddressFields>) => onChangeSiteAddress(values), [
        onChangeSiteAddress,
    ]);

    return (
        <Content title={messages.title.address()}>
            <AddressForm
                initialValues={initialSiteAddress}
                values={siteAddress}
                countries={countries}
                subdivisionsByCountryCode={subdivisionsByCountryCode}
                onChange={handleChangeSiteAddress}
                validationErrors={validationErrors}
                fixedCountryCode={fixedCountryCode}
            />
        </Content>
    );
}

export default memo(SiteInfoEditor);
