import React, { memo } from "react";

interface Props {
    label?: string;
}

function Divider({ label }: Props) {
    return <div className="Divider">{label && <span className="Divider-text">{label}</span>}</div>;
}

export default memo(Divider);
