import React, { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { combineFirstAndLastName, createOptionGroupsByRole } from "../../../utils/contact";
import { getContactIdentifier } from "../../../utils/options";
import { messages } from "../../messages";
import { Contact as ContactModel } from "../../models/Contact";
import { ContactFields, DEFAULT_CONTACT_VALUES, EMAIL_WORK_KEY } from "../../models/ContactFields";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { ValidationError } from "../../models/ValidationError";
import Button from "../Button";
import Contact from "../Contact";
import ContactForm from "../ContactForm";
import Dropdown from "../Dropdown";
import FormField from "../FormField";
import Header from "../Header";
import Icon from "../Icon";
import Link from "../Link";
import Markdown from "../Markdown";
import MissingInfo from "../MissingInfo";
import Spacer from "../Spacer";

enum VisibleContactType {
    Editable = "EDITABLE",
    ReadOnly = "READ_ONLY",
    None = "NONE",
}

export interface Props {
    chooseFrom: (ContactFields | ContactModel)[];
    className?: string;
    combineFirstAndLast?: boolean;
    initialContact?: ContactFields | ContactModel;
    contact?: ContactFields | ContactModel;
    isMeechumUser?: boolean;
    label?: string;
    description?: string;
    orgId: number;
    onEdit: (contact: Partial<ContactFields>) => void;
    onSelect: (contact: ContactFields | ContactModel | undefined) => void;
    omitFields?: Set<keyof ContactFields>;
    required?: boolean;
    role?: string;
    showRoles?: boolean;
    validationErrors?: ValidationError | ModelValidationErrors<ContactFields>;
}

/**
 * Renders a dropdown that displays the list of contacts to choose from and a
 * form to create a new contact.
 *
 * Users are encouraged to select an existing contact rather than create a new one
 */
function ChooseOrCreateContact({
    combineFirstAndLast = false,
    label,
    chooseFrom,
    description,
    isMeechumUser,
    validationErrors,
    onEdit,
    omitFields,
    orgId,
    initialContact,
    contact,
    onSelect,
    required = false,
}: Props) {
    const styles = window.getComputedStyle(document.documentElement!);

    const [visibleContactType, setVisibleContactType] = useState<VisibleContactType>(
        initialContact ? VisibleContactType.ReadOnly : VisibleContactType.None
    );

    const showNewContactForm = useCallback(() => {
        onSelect(DEFAULT_CONTACT_VALUES);
        setVisibleContactType(VisibleContactType.Editable);
    }, [onSelect, setVisibleContactType]);

    const contactOptionGroups = useMemo(() => createOptionGroupsByRole(chooseFrom, messages.labels.noRoles()), [
        chooseFrom,
    ]);

    const handleSelectContact = useCallback(
        (evt: ChangeEvent<HTMLSelectElement>) => {
            const selectedContact = chooseFrom.find((contact) => getContactIdentifier(contact) === evt.target.value);

            if (selectedContact) {
                let contactFields = {
                    ...selectedContact,
                };

                if (combineFirstAndLast) {
                    contactFields = combineFirstAndLastName(contactFields);
                }

                onSelect(contactFields);
                setVisibleContactType(VisibleContactType.ReadOnly);
            }
        },
        [onSelect, setVisibleContactType, chooseFrom, combineFirstAndLast]
    );

    const isPortalLinkVisible = contact?.[EMAIL_WORK_KEY] && isMeechumUser;
    const portalLinkHref = `https://my.oc.netflix.com/org/${orgId}/users-management`;

    const selectedContactIdentifier = useMemo(() => (contact ? getContactIdentifier(contact) : undefined), [contact]);

    return (
        <div className="ChooseOrCreateContact">
            {label && (
                <Header
                    as="h3"
                    title={label}
                    fluid
                    rightAccessory={
                        required ? (
                            <Icon
                                className="FormField-requiredIndicator"
                                color={styles.getPropertyValue("--red")}
                                size="xs"
                                icon="asterisk"
                            />
                        ) : null
                    }
                >
                    {description && (
                        <>
                            <div className="ChooseOrCreateContact-description">
                                <Markdown source={description} />
                            </div>
                            {isPortalLinkVisible && (
                                <div className="ChooseOrCreateContact-portal-link">
                                    <Link href={portalLinkHref} isExternal={true}>
                                        {messages.title.editContactInPartnerPortal()}
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
                </Header>
            )}

            <FormField>
                <Dropdown
                    value={selectedContactIdentifier}
                    placeholder={messages.forms.placeholders.selectContact()}
                    options={contactOptionGroups}
                    onChange={handleSelectContact}
                />
                <Button className="Button" size="small" onClick={showNewContactForm}>
                    <Icon className="Icon" icon="plus" />
                    New
                </Button>
            </FormField>

            {contactOptionGroups.length > 0 && <Spacer />}

            {visibleContactType === VisibleContactType.Editable ? (
                <ContactForm
                    combineFirstAndLast={combineFirstAndLast}
                    onChange={onEdit}
                    initialValues={initialContact}
                    values={contact}
                    omitFields={omitFields}
                    validationErrors={validationErrors}
                    required={required}
                />
            ) : visibleContactType === VisibleContactType.ReadOnly && contact ? (
                <Contact contact={contact} omitFields={omitFields} />
            ) : (
                <MissingInfo message={messages.notice.missingContact()} />
            )}
        </div>
    );
}

export default memo(ChooseOrCreateContact);
