import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { Container } from "../../../shared/components/PartnerForm";
import PartnerFormPresenter from "../../../shared/components/PartnerFormPresenter";
import PartnerFormsList from "../../../shared/components/PartnerFormsList";
import { messages } from "../../../shared/messages";
import { Dictionary } from "../../../shared/models/Dictionary";
import { PATH_OCA_RETURNS } from "../../../shared/routes";
import { OcaReturn as OcaReturnModel } from "../../models/OcaReturn";
import { ocaReturnsActions } from "../../reducer";
import OcaReturn from "../OcaReturn";

export interface Props extends RouteComponentProps<any> {
    orgId: number;
    ocaReturnIds?: number[];
    isFetching: boolean;
    errorFetching: Error | string | null;
    byId?: Dictionary<OcaReturnModel>;
    sorted?: OcaReturnModel[];
}

/**
 * NOTE: OCA returns cannot be sudo-toggled because there is a check on the
 * server side that prevents partial updates post-submit. The `OcaReturn`
 * component supports the `forceEditable` flag however, we just don't set it to
 * the `sudo` context here like we do the other forms.
 */
function OcaReturns({ errorFetching, isFetching, orgId, byId, sorted, ocaReturnIds, ...props }: Props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ocaReturnsActions.fetch.do({ orgId, ocaReturnIds }));
    }, [dispatch, orgId, ocaReturnIds]);

    return (
        <Container
            className="OcaReturns"
            path={PATH_OCA_RETURNS}
            master={() => (
                <PartnerFormsList<OcaReturnModel>
                    isFetching={isFetching}
                    entries={sorted}
                    emptyMessage={messages.notice.noMatchingOcaReturns()}
                    {...props}
                />
            )}
            detail={() => (
                <Route
                    render={(props) => (
                        <PartnerFormPresenter<OcaReturnModel>
                            {...props}
                            byId={byId}
                            error={errorFetching}
                            doesNotExistMessage={messages.notice.ocaReturnDoesNotExist()}
                            makeSelectionMessage={messages.notice.selectAnOcaReturn()}
                        >
                            {(ocaReturn) => <OcaReturn {...props} orgId={orgId} ocaReturn={ocaReturn} />}
                        </PartnerFormPresenter>
                    )}
                />
            )}
        />
    );
}

export default OcaReturns;
