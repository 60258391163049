import classnames from "classnames";
import React, { memo } from "react";

interface Props {
    fluid?: boolean;
}

function Container({ fluid = false, className, children, ...props }: Props & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={classnames("Container", className, { fluid })} {...props}>
            {children}
        </div>
    );
}

export default memo(Container);
