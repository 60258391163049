import Tippy, { TippyProps } from "@tippyjs/react/headless";
import React from "react";

interface Props extends Pick<TippyProps, "content" | "placement"> {
    children: JSX.Element;
}

function Tooltip({ children, content, ...props }: Props) {
    return (
        // Wrapper div for accessibility: https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity
        <div>
            <Tippy
                render={(attrs) => (
                    <div className="Tooltip__content" tabIndex={-1} {...attrs}>
                        {content}
                    </div>
                )}
                interactive
                {...props}
            >
                <div className="Tooltip__target">{children}</div>
            </Tippy>
        </div>
    );
}

export default Tooltip;
