import React, { useCallback, useMemo, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import Body from "../../../shared/components/Body";
import Button from "../../../shared/components/Button";
import Divider from "../../../shared/components/Divider";
import Header from "../../../shared/components/Header";
import Icon from "../../../shared/components/Icon";
import Message from "../../../shared/components/Message";
import Spacer from "../../../shared/components/Spacer";
import { LOGIN_WITH_CODE, LOGIN_WITH_SSO } from "../../../shared/events";
import logger from "../../../shared/logger";
import { messages } from "../../../shared/messages";
import { LoginHandler } from "../../../shared/props/actions";
import { RoutesHelper, QUERY_API_KEY, QUERY_EMAIL } from "../../../shared/routes";
import { validate } from "../../../shared/validation";
import { loginValidations } from "../../../shared/validation/login";
import { CODE_KEY, EMAIL_KEY, LoginFields } from "../../models/LoginFields";
import LoginForm from "../LoginForm";
import { getSearchValue } from "../../../utils/url";

export interface Props extends RouteComponentProps<Record<string, string>> {
    login: LoginHandler;
    isSubmittingLogin?: boolean;
    error: Error | string | null;
}

function Login({ error, isSubmittingLogin, login, location }: Props) {
    const [values, setValues] = useState<Partial<LoginFields>>({});
    const validationErrors = useMemo(() => validate(values, loginValidations), [values]);
    const [showForm, setShowForm] = useState<boolean>(Boolean(getSearchValue(location.search, QUERY_API_KEY)));

    const handleChange = useCallback((nextValues: Partial<LoginFields>) => {
        setValues((values) => {
            return {
                ...values,
                ...nextValues,
            };
        });
    }, []);

    const handleShowFormClick = useCallback(() => setShowForm(true), [setShowForm]);

    const handleSubmitSignIn = useCallback(() => {
        // Assume form validation has ensured completeness
        logger.trackEvent(LOGIN_WITH_CODE);
        login(values[EMAIL_KEY] ?? "", values[CODE_KEY] ?? "");
    }, [login, values]);

    const handleClickSSO = useCallback(() => {
        logger.trackEvent(LOGIN_WITH_SSO);
        RoutesHelper.redirectToSSO();
    }, []);

    useEffect(() => {
        const code = getSearchValue(location.search, QUERY_API_KEY);
        const email = getSearchValue(location.search, QUERY_EMAIL);

        if (code || email) {
            setValues({
                [EMAIL_KEY]: email,
                [CODE_KEY]: code,
            });
        }
    }, [location.search]);

    return (
        <Body>
            <div className="Login">
                <div className="Login__container">
                    <Header as="h2" title={messages.title.login()} textAlign="center" justifyContent="center" />
                    <div className="Login__sso">
                        <Header
                            as="h3"
                            title={messages.title.singleSignOn()}
                            subtitle={messages.title.singleSignOnSubtitle()}
                        />
                        <Spacer size="md" />
                        <Button kind="action" onClick={handleClickSSO}>
                            <Icon icon="lock" fixedWidth />
                            &nbsp;{messages.actions.singleSignOn()}
                        </Button>
                    </div>
                    <Divider label="OR" />
                    <div className="Login__code">
                        <Header
                            as="h3"
                            title={messages.title.enterEmailAndCode()}
                            subtitle={messages.title.enterEmailAndCodeSubtitle()}
                        />
                        <Spacer size="md" />
                        {showForm ? (
                            <LoginForm
                                isSubmitting={isSubmittingLogin}
                                values={values}
                                onChange={handleChange}
                                onSubmit={handleSubmitSignIn}
                                validationErrors={validationErrors}
                            />
                        ) : (
                            <Button kind="action" onClick={handleShowFormClick}>
                                {messages.actions.tokenSignOn()}
                            </Button>
                        )}
                        {error && (
                            <>
                                <Spacer size="sm" />
                                <Message status="error" textAlign="center" message={error} asBlock={false} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Body>
    );
}

export default Login;
