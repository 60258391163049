import { length } from "../../forms/validators/length";
import { Validator } from "../../models/Validator";
import { messages } from "../../messages";

/**
 * Validates that the string contains at least 6 digits. The string is allowed
 * to contain any other characters as long as it meets this minimum requirement.
 */
export const phone: Validator = (value?: string) => {
    return length({
        min: 6,
        re: /[\d]/gi,
        message: messages.forms.validation.invalidPhoneNumber()
    })(value);
};
