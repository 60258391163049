import clsx from "classnames";
import React, { memo } from "react";
import Icon from "../Icon";

interface Props {
    className?: string;
    /**
     * Defaults to `false`. If `true`, uses the solid icon instead of the
     * regular one.
     */
    solid?: boolean;
    /**
     * Defaults to `false`. If `true`, applies spacing to the left.
     */
    spacedLeft?: boolean;
}

function HelpIcon({ className, solid, spacedLeft }: Props) {
    return (
        <div
            className={clsx("HelpIcon", className, {
                "spaced-left": spacedLeft,
            })}
        >
            <Icon icon={solid ? "question-circle" : ["far", "question-circle"]} />
        </div>
    );
}

export default memo(HelpIcon);
