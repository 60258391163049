import React from "react";
import { useForm } from "../../forms/hooks/useForm";
import { messages } from "../../messages";
import { NotesFields, NOTES_KEY } from "../../models/NotesFields";
import { FormPropsV2 } from "../../props/forms";
import Form from "../Form";
import FormField from "../FormField";
import TextArea from "../TextArea";

export interface Props extends FormPropsV2<NotesFields> {
    label?: string;
    notes?: string;
}

function NotesForm(props: Props) {
    const { label, notes, compact } = props;
    const [{ errors, dirty, values }, { handleSubmit, touchHandlers, changeHandlers }] = useForm(
        { keys: [NOTES_KEY] },
        props
    );
    return (
        <Form className="NotesForm" onSubmit={handleSubmit} compact={compact}>
            <FormField name="notes" label={label} notes={notes} error={errors.get(NOTES_KEY)}>
                <TextArea
                    dirty={dirty.has(NOTES_KEY)}
                    error={errors.has(NOTES_KEY)}
                    placeholder={messages.forms.placeholders.notes()}
                    rows={10}
                    value={values[NOTES_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[NOTES_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[NOTES_KEY]?.()}
                />
            </FormField>
        </Form>
    );
}

export default NotesForm;
