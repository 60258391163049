import { all, email, not, phone, regex, required } from "../forms/validators";
import { messages } from "../messages";
import { ContactFields, EMAIL_WORK_KEY, NAME_FIRST_KEY, NAME_LAST_KEY, PHONE_WORK_KEY } from "../models/ContactFields";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

const CONTACT_VALIDATOR: ModelValidator<ContactFields> = new Map<keyof ContactFields, Validator>([
    [NAME_FIRST_KEY, required],
    [NAME_LAST_KEY, required],
    [
        EMAIL_WORK_KEY,
        all(required, email, not(regex(/@netflix\.com/i), messages.forms.validation.netflixEmailNotAllowed())),
    ],
    [PHONE_WORK_KEY, all(required, phone)],
]);

interface ValidateContactOptions {
    strict: boolean;
    /**
     * Skips validation of any fields in this set.
     */
    ignoreFields: Set<keyof ContactFields>;
}

export function validateContact(
    contact: ContactFields,
    { strict = true, ignoreFields = new Set<keyof ContactFields>() }: Partial<ValidateContactOptions> = {}
): ModelValidationErrors<ContactFields> | undefined {
    const contactValidator = new Map([...CONTACT_VALIDATOR.entries()].filter(([key]) => !ignoreFields.has(key)));
    return validate(contact, contactValidator, strict);
}
