import { getMessage, messages } from "../../shared/messages";

export enum ComponentType {
    Optics = "optics",
    PatchCable = "patchCable",
    Rack = "rack",
    ShippingMaterials = "shippingMaterials",
    Psu = "powerSupply",
    PowerCable = "cable"
}

export const COMPONENT_TYPES: ComponentType[] = [
    ComponentType.Optics,
    ComponentType.PatchCable,
    ComponentType.Rack,
    ComponentType.ShippingMaterials,
    ComponentType.Psu,
    ComponentType.PowerCable
];

export function getLabel(componentType: ComponentType): string {
    return getMessage(() => messages.labels.partOrder[componentType](null), componentType);
}
