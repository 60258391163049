import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

export const regex = (re: RegExp, message?: string): Validator => (value: string) => {
    const isMatch = re.test(value);

    if (!isMatch) {
        return {
            level: Level.Invalid,
            message: message || messages.forms.validation.doesNotMatchPattern(),
        };
    }

    return;
};
