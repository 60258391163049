import { pick } from "lodash";
import hash from "object-hash";
import { ComponentType } from "../partOrders/models/ComponentType";
import { messages } from "../shared/messages";
import { Address } from "../shared/models/Address";
import { AddressFields, ADDRESS_KEYS, DEFAULT_ADDRESS_VALUES, displayLabel } from "../shared/models/AddressFields";
import { Contact } from "../shared/models/Contact";
import { ContactFields, CONTACT_FIELDS_KEYS, EMAIL_WORK_KEY } from "../shared/models/ContactFields";
import { Country } from "../shared/models/Country";
import { CountrySubdivision } from "../shared/models/CountrySubdivision";
import { DayOfWeek, getLabel as getDayOfWeekLabel } from "../shared/models/DayOfWeek";
import { OcaClass } from "../shared/models/OcaClass";
import { Option } from "../shared/models/Option";
import {
    FOUR_POST_RAIL_KIT_CODE,
    PartDefinitionWithFlags,
    TWO_POST_RAIL_KIT_CODE,
} from "../shared/models/PartDefinition";
import { getLabel as getShipperLabel, Shipper } from "../shared/models/Shipper";
import { getLabel as getTimeOfDayLabel, TimeOfDay } from "../shared/models/TimeOfDay";
import { getDisplayName } from "./contact";

function computeIdentifier(data: Record<string, unknown>) {
    // Exclude empty strings to avoid different hash results
    for (const key in data) {
        if (!data[key]) {
            delete data[key];
        }
    }

    return hash(data);
}

/**
 * Returns the contact id or a hash of the contact values, if id is undefined
 * @param value
 */
export function getIdentifier(value: Record<string, unknown>) {
    return value.hasOwnProperty("id") ? value["id"] : hash(value);
}

export function getContactIdentifier(contact: ContactFields, ignoreRoles = true): string {
    const data = pick(contact, ...CONTACT_FIELDS_KEYS);
    if (ignoreRoles) {
        delete data.roles;
    }

    return computeIdentifier(data);
}

export function getAddressIdentifier(address: AddressFields): string {
    const data = {
        ...DEFAULT_ADDRESS_VALUES,
        ...pick(address, ...ADDRESS_KEYS),
    };

    return computeIdentifier(data);
}

export function contactAsOption(contact: Contact | ContactFields): Option {
    const key = getContactIdentifier(contact);
    const text = `${getDisplayName(contact)} (${contact[EMAIL_WORK_KEY].toLowerCase()})`;

    return {
        key,
        value: getContactIdentifier(contact),
        text,
    };
}

export function addressAsOption(address: Address | AddressFields): Option {
    const key = getAddressIdentifier(address);

    return {
        key,
        value: getAddressIdentifier(address),
        text: displayLabel(address),
    };
}

export function subdivisionAsOption({ name, code }: CountrySubdivision): Option {
    return {
        key: code,
        value: code,
        text: name,
    };
}

export function countryAsOption({ isoCode, name }: Country): Option {
    return {
        key: isoCode,
        text: name,
        value: isoCode,
    };
}

export function partDefinitionAsOption({ id, name, retired, restricted }: PartDefinitionWithFlags): Option<number> {
    return {
        key: id,
        text: name,
        value: id,
        disabled: retired || restricted,
    };
}

export function partDefinitionAsOptionInSiteSurvey(
    { id, name, code, retired, restricted, componentType }: PartDefinitionWithFlags,
    ocaClass: OcaClass
): Option<number> {
    let text = name;

    // For non-Flash site surveys, we frame the question differently, where we
    // ask the type of rack that they have, as opposed to the kit they require.
    // There is no choice (4-post rail kit only) for Flash OCAs.
    if (ocaClass !== OcaClass.Flash && componentType === ComponentType.Rack) {
        if (code === TWO_POST_RAIL_KIT_CODE) {
            text = messages.entity.twoPostRack();
        } else if (code === FOUR_POST_RAIL_KIT_CODE) {
            text = messages.entity.fourPostRack();
        }
    }

    return {
        key: id,
        text,
        value: id,
        disabled: retired || restricted,
    };
}

export function shipperAsOption(shipper: Shipper): Option {
    return {
        key: shipper,
        text: getShipperLabel(shipper),
        value: shipper,
    };
}

export function dayOfWeekAsOption(dayOfWeek: DayOfWeek): Option {
    return {
        key: dayOfWeek,
        text: getDayOfWeekLabel(dayOfWeek),
        value: dayOfWeek,
    };
}

export function timeOfDayAsOption(timeOfDay: TimeOfDay): Option {
    return {
        key: timeOfDay,
        text: getTimeOfDayLabel(timeOfDay),
        value: timeOfDay,
    };
}
