import { messages } from "../../shared/messages";

/**
 * NOTE: These enum values must match the server enum.
 */
export enum Shipper {
    All = "ALL",
    UPS = "UPS",
    Fedex = "FEDEX",
    None = "NONE"
}

export function getLabel(shipper: Shipper): string {
    switch (shipper) {
        case Shipper.All:
            return messages.choices.both();
        case Shipper.None:
            return messages.choices.neither();
        case Shipper.UPS:
            return "UPS";
        case Shipper.Fedex:
            return "Fedex";
        default:
            return shipper;
    }
}
