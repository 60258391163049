import React, { memo, useMemo } from "react";
import { useForm } from "../../forms/hooks/useForm";
import { messages } from "../../messages";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { FieldMetadata } from "../../models/FieldMetadata";
import { ValidationError } from "../../models/ValidationError";
import FormField from "../FormField";
import HelpIcon from "../HelpIcon";
import { Content } from "../PartnerForm";
import TextInput from "../TextInput";
import Tooltip from "../Tooltip";
import { TAX_ID_KEY } from "../../models/TaxpayerAffiliated";

/**
 * We want to allow, but not require, the tax id field regardless of country. So
 * if the metadata does not include the tax id field, add this manually.
 */
const TAX_ID_METADATA: FieldMetadata = {
    name: TAX_ID_KEY,
    label: "Tax ID",
    required: false,
};

interface Props {
    initialTaxpayerFields: Record<string, string>;
    taxpayerFields?: Record<string, string>;
    onChange: (values: Partial<Record<string, string>>) => void;
    validationErrors?: ModelValidationErrors<Record<string, string>> | ValidationError;
    taxpayerFieldMetadata: FieldMetadata[];
}

function TaxpayerFieldsEditor({
    taxpayerFieldMetadata,
    initialTaxpayerFields,
    taxpayerFields,
    validationErrors,
    onChange,
}: Props) {
    // Add tax id field metadata if not provided.
    const effectiveFieldMetadata = useMemo(() => {
        const copy = [...taxpayerFieldMetadata];
        if (!taxpayerFieldMetadata.find(({ name }) => name === TAX_ID_KEY)) {
            copy.unshift(TAX_ID_METADATA);
        }
        return copy;
    }, [taxpayerFieldMetadata]);

    const [formState, formActions] = useForm<Record<string, string>>(
        { keys: effectiveFieldMetadata.map(({ name }) => name) },
        {
            initialValues: initialTaxpayerFields,
            values: taxpayerFields,
            validationErrors,
            onChange,
        }
    );
    const { errors, dirty, values } = formState;
    const { touchHandlers, changeHandlers } = formActions;

    return (
        <Content title={messages.title.taxInfo()}>
            {effectiveFieldMetadata.map(({ name, label, description, required }) => (
                <FormField
                    key={name}
                    name={name}
                    required={required}
                    label={label}
                    error={errors.get(name)}
                    labelAccessory={
                        description && (
                            <Tooltip placement="right-end" content={<div>{description}</div>}>
                                <HelpIcon spacedLeft />
                            </Tooltip>
                        )
                    }
                >
                    <TextInput
                        placeholder={label}
                        value={values?.[name] ?? ""}
                        onChange={(evt) => changeHandlers[name]?.(evt.target.value)}
                        onBlur={() => touchHandlers[name]?.()}
                        dirty={dirty.has(name)}
                        error={errors.has(name)}
                    />
                </FormField>
            ))}
        </Content>
    );
}

export default memo(TaxpayerFieldsEditor);
