import { usePrevious } from "@octools/hooks";
import { Location } from "history";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import OcaReturns from "../../../ocaReturns/components/OcaReturns";
import PartOrders from "../../../partOrders/components/PartOrders";
import { Authorization } from "../../../services/auth/models/Authorization";
import Body from "../../../shared/components/Body";
import {
    PATH_OCA_RETURNS,
    PATH_PART_ORDERS,
    PATH_PICK_ORG,
    PATH_SITE_SURVEYS,
    PATH_SSO,
    QUERY_ORG_ID
} from "../../../shared/routes";
import SiteSurveys from "../../../siteSurveys/components/SiteSurveys";
import { setSearchValue } from "../../../utils/url";
import OrgPicker from "../OrgPicker";
import PartnerFormsNav from "../PartnerFormsNav";

export interface Props {
    cleanUrl: (location: Location) => void;
    resetForNextOrg: () => void;
    authorization: Authorization;
    fetchOrgs: () => void;
    fetchCountries: () => void;
    fetchUsStates: () => void;
}

function getDefaultRoute(authorization: Authorization): string | undefined {
    if (authorization.siteSurveyIds) {
        return PATH_SITE_SURVEYS;
    }

    if (authorization.partOrderIds) {
        return PATH_PART_ORDERS;
    }

    if (authorization.ocaReturnIds) {
        return PATH_OCA_RETURNS;
    }

    return PATH_SITE_SURVEYS;
}

function Authenticated(props: Props) {
    const { authorization, fetchOrgs, fetchCountries, fetchUsStates, resetForNextOrg, cleanUrl } = props;
    const { orgId } = authorization;
    const prevAuthorization = usePrevious(authorization);
    const location = useLocation();

    useEffect(fetchOrgs, [fetchOrgs]);
    useEffect(fetchCountries, [fetchCountries]);
    useEffect(fetchUsStates, [fetchUsStates]);

    useEffect(() => {
        if (authorization.orgId !== prevAuthorization?.orgId) {
            resetForNextOrg();
        }
    }, [authorization, prevAuthorization, resetForNextOrg]);

    useEffect(() => {
        if (location.pathname !== PATH_SSO) {
            cleanUrl(location);
        }
    }, [location, cleanUrl]);

    return (
        <>
            <PartnerFormsNav orgId={orgId} />
            <Body>
                {orgId === undefined ? (
                    <Switch>
                        <Route
                            path={PATH_PICK_ORG}
                            render={(props) => {
                                return <OrgPicker {...props} />;
                            }}
                        />
                        <Redirect
                            to={{
                                ...location,
                                pathname: PATH_PICK_ORG
                            }}
                        />
                    </Switch>
                ) : (
                    <Switch>
                        <Route
                            path={PATH_SITE_SURVEYS}
                            render={(props) => <SiteSurveys {...props} {...authorization} orgId={orgId} />}
                        />
                        <Route
                            path={PATH_PART_ORDERS}
                            render={(props) => <PartOrders {...props} {...authorization} orgId={orgId} />}
                        />
                        <Route
                            path={PATH_OCA_RETURNS}
                            render={(props) => <OcaReturns {...props} {...authorization} orgId={orgId} />}
                        />
                        <Redirect
                            to={{
                                ...location,
                                pathname: getDefaultRoute(authorization),
                                search: setSearchValue(location.search, QUERY_ORG_ID, orgId.toString())
                            }}
                        />
                    </Switch>
                )}
            </Body>
        </>
    );
}

export default Authenticated;
