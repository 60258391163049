import Publisher from "../../shared/models/Publisher";

/**
 * NOTE: These local storage keys are deprecated, but we keep them around to
 * ensure that we do not introduce conflicting keys in the future.
 */
export const SESSION_ORG_ID = "session.orgId";
export const SESSION_SITE_SURVEY_IDS = "session.siteSurveyIds";

type Key = typeof SESSION_ORG_ID | typeof SESSION_SITE_SURVEY_IDS;

interface ValueChange {
    key: string;
    value: any;
}

/**
 * A key-value pubsub backed by local storage.
 */
export class SessionService extends Publisher<ValueChange> {
    private storage = window.localStorage;

    get(key: typeof SESSION_ORG_ID): number | undefined;
    get(key: typeof SESSION_SITE_SURVEY_IDS): number[] | undefined;
    get(key: string): any | undefined;
    get(key: string) {
        const value = this.storage.getItem(key);

        if (value === null) {
            return;
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    /**
     * Set a session value. Publishes update to all subscribers.
     * @param key
     * @param value
     */
    set(key: typeof SESSION_ORG_ID, orgId: number | undefined): void;
    set(key: typeof SESSION_SITE_SURVEY_IDS, siteSurveyIds: number[]): void;
    set(key: string, value: any): void;
    set(key: string, value: any) {
        this._set(key, value);
        this.publish({ key, value });
    }

    /**
     * Remove a session value. Publishes update to all subscribers.
     * @param key
     */
    remove(key: string) {
        this._remove(key);
        this.publish({ key, value: undefined });
    }

    /**
     * Clears everything in storage. Does not publish to subscribers. This is
     * usually used to reset the app.
     */
    clear() {
        this.storage.clear();
    }

    /**
     * Initializes the session by clearing it and setting initial values. Does
     * not publish to subscribers.
     * @param values - A hash of each key-value pair
     */

    initialize(values: { [key: string]: any }) {
        this.clear();
        Object.entries(values).forEach(([key, value]) => this._set(key as Key, value));
    }

    private _set(key: string, value: any) {
        if (value === null || value === undefined) {
            this._remove(key);
        } else {
            this.storage.setItem(key, JSON.stringify(value));
        }
    }

    private _remove(key: string) {
        this.storage.removeItem(key);
    }
}

export default new SessionService();
