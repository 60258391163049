import { usePrevious } from "@octools/hooks";
import React, { FormEvent, memo, ReactNode, useCallback, useEffect } from "react";
import { countryAsOption, subdivisionAsOption } from "../../../utils/options";
import { FormActions, FormState, useForm } from "../../forms/hooks/useForm";
import { messages } from "../../messages";
import {
    AddressFields,
    ADDRESS_KEYS,
    CITY_KEY,
    COUNTRY_KEY,
    POSTAL_KEY,
    STATE_KEY,
    STREET_1_KEY,
    STREET_2_KEY,
} from "../../models/AddressFields";
import { Country } from "../../models/Country";
import { CountrySubdivision } from "../../models/CountrySubdivision";
import { FormPropsV2 } from "../../props/forms";
import Button from "../Button";
import Dropdown from "../Dropdown";
import Form from "../Form";
import FormField from "../FormField";
import HelpIcon from "../HelpIcon";
import TextInput from "../TextInput";
import Tooltip from "../Tooltip";

export interface Props<T extends AddressFields> extends FormPropsV2<T> {
    compact?: boolean;
    /**
     * An ISO 2-letter country code. If provided, the country input is locked to
     * this country and cannot be edited. Note that this does not invoke an
     * `onChange` to update underlying values - you must handle that yourself.
     */
    fixedCountryCode?: string;
    countries: Country[];
    subdivisionsByCountryCode: Map<string, CountrySubdivision[]>;
    withRecipient?: boolean;
    keys?: readonly (keyof T)[];
    /**
     * If rendering an object of type `T extends AddressFields` that requires
     * additional fields, use this prop to render them.
     */
    additionalFields?: (formState: FormState<T>, formActions: FormActions<T>) => ReactNode;
    additionalFieldsPlacement?: "start" | "end";
}

/**
 * A address form for completing `AddressFields`. If the country is US, then the
 * State/Province/Region field becomes a select with US states as options.
 */
function AddressForm<T extends AddressFields = AddressFields>(props: Props<T>) {
    const {
        onSubmit,
        onCancel,
        compact,
        disabled,
        isSubmitting,
        submitButtonText,
        cancelButtonText,
        countries,
        subdivisionsByCountryCode,
        fixedCountryCode,
        keys = ADDRESS_KEYS,
        additionalFields,
        additionalFieldsPlacement = "start",
    } = props;
    const handleSubmit = useCallback(
        (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );
    const [formState, formActions] = useForm({ keys, atomic: true }, props);
    const { pristine, valid, errors, dirty, values } = formState;
    const { touchHandlers, changeHandlers } = formActions;

    const state = values[STATE_KEY];
    const countryCode = fixedCountryCode ?? values[COUNTRY_KEY];
    const prevCountryCode = usePrevious(countryCode);
    const handleChangeState = changeHandlers[STATE_KEY];

    // Reset the state input based on selected country.
    useEffect(() => {
        // If a state value is provided and the country is being changed...
        if (state && prevCountryCode !== countryCode) {
            if (prevCountryCode && subdivisionsByCountryCode.has(prevCountryCode)) {
                // When changing from US to another country, reset state value
                handleChangeState?.("");
            } else if (countryCode && subdivisionsByCountryCode.has(countryCode)) {
                const subdivisions = subdivisionsByCountryCode.get(countryCode)!;
                // When changing from a country to to one with known
                // subdivisions, reset state value if it does not correspond to
                // a subdivision (by code).
                if (!subdivisions.find(({ code }) => code === state)) {
                    handleChangeState?.("");
                }
            }
        }
    }, [state, countryCode, prevCountryCode, handleChangeState, subdivisionsByCountryCode]);

    return (
        <Form className="AddressForm" onSubmit={handleSubmit} compact={compact}>
            {additionalFieldsPlacement === "start" && additionalFields?.(formState, formActions)}
            <FormField
                name={COUNTRY_KEY}
                required={!fixedCountryCode}
                label={messages.labels.addressField.country()}
                error={errors.get(COUNTRY_KEY)}
                labelAccessory={
                    fixedCountryCode && (
                        <Tooltip placement="right-end" content={<div>{messages.notice.fixedCountry()}</div>}>
                            <HelpIcon spacedLeft />
                        </Tooltip>
                    )
                }
            >
                <Dropdown
                    placeholder={messages.forms.placeholders.selectCountry()}
                    error={errors.has(COUNTRY_KEY)}
                    dirty={dirty.has(COUNTRY_KEY)}
                    disabled={disabled || fixedCountryCode !== undefined}
                    value={countryCode}
                    options={countries.map(countryAsOption)}
                    onChange={(evt) => changeHandlers[COUNTRY_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[COUNTRY_KEY]?.()}
                />
            </FormField>
            <FormField
                name={STREET_1_KEY}
                required
                label={messages.labels.addressField.street1()}
                error={errors.get(STREET_1_KEY)}
            >
                <TextInput
                    autoComplete="on"
                    placeholder={messages.forms.placeholders.street1()}
                    dirty={dirty.has(STREET_1_KEY)}
                    error={errors.has(STREET_1_KEY)}
                    disabled={disabled}
                    value={values?.[STREET_1_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[STREET_1_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[STREET_1_KEY]?.()}
                />
            </FormField>
            <FormField
                name={STREET_2_KEY}
                label={messages.labels.addressField.street2()}
                error={errors.get(STREET_2_KEY)}
            >
                <TextInput
                    autoComplete="on"
                    placeholder={messages.forms.placeholders.street2()}
                    dirty={dirty.has(STREET_2_KEY)}
                    error={errors.has(STREET_2_KEY)}
                    disabled={disabled}
                    value={values?.[STREET_2_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[STREET_2_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[STREET_2_KEY]?.()}
                />
            </FormField>
            <FormField
                name={CITY_KEY}
                required
                label={messages.labels.addressField.city()}
                error={errors.get(CITY_KEY)}
            >
                <TextInput
                    autoComplete="on"
                    placeholder={messages.forms.placeholders.city()}
                    dirty={dirty.has(CITY_KEY)}
                    error={errors.has(CITY_KEY)}
                    disabled={disabled}
                    value={values?.[CITY_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[CITY_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[CITY_KEY]?.()}
                />
            </FormField>
            {countryCode && subdivisionsByCountryCode.has(countryCode) ? (
                <FormField
                    name={STATE_KEY}
                    required
                    label={messages.labels.addressField.state()}
                    error={errors.get(STATE_KEY)}
                >
                    <Dropdown
                        placeholder={messages.forms.placeholders.selectState()}
                        error={errors.has(STATE_KEY)}
                        dirty={dirty.has(STATE_KEY)}
                        disabled={disabled}
                        value={values?.[STATE_KEY]}
                        options={subdivisionsByCountryCode.get(countryCode)?.map(subdivisionAsOption) ?? []}
                        onChange={(evt) => changeHandlers[STATE_KEY]?.(evt.target.value)}
                        onBlur={() => touchHandlers[STATE_KEY]?.()}
                    />
                </FormField>
            ) : (
                <FormField name={STATE_KEY} label={messages.labels.addressField.state()} error={errors.get(STATE_KEY)}>
                    <TextInput
                        autoComplete="on"
                        placeholder={messages.forms.placeholders.state()}
                        dirty={dirty.has(STATE_KEY)}
                        error={errors.has(STATE_KEY)}
                        disabled={disabled}
                        value={values?.[STATE_KEY] ?? ""}
                        onChange={(evt) => changeHandlers[STATE_KEY]?.(evt.target.value)}
                        onBlur={() => touchHandlers[STATE_KEY]?.()}
                    />
                </FormField>
            )}
            <FormField name={POSTAL_KEY} label={messages.labels.addressField.postal()} error={errors.get(POSTAL_KEY)}>
                <TextInput
                    placeholder={messages.forms.placeholders.postal()}
                    autoComplete="on"
                    dirty={dirty.has(POSTAL_KEY)}
                    error={errors.has(POSTAL_KEY)}
                    disabled={disabled}
                    value={values?.[POSTAL_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[POSTAL_KEY]?.(evt.target.value)}
                    onBlur={() => touchHandlers[POSTAL_KEY]?.()}
                />
            </FormField>
            {additionalFieldsPlacement === "end" && additionalFields?.(formState, formActions)}

            <Form.Actions>
                {onSubmit && (
                    <Button type="submit" loading={isSubmitting} disabled={pristine || !valid || isSubmitting}>
                        {submitButtonText || messages.actions.create()}
                    </Button>
                )}
                {onCancel && (
                    <Button kind="dismiss" type="button" onClick={onCancel}>
                        {cancelButtonText || messages.actions.cancel()}
                    </Button>
                )}
            </Form.Actions>
        </Form>
    );
}

export default memo(AddressForm) as typeof AddressForm;
