import classnames from "classnames";
import React, { memo } from "react";

interface Props {
    message: string;
    size?: "sm" | "md" | "lg";
    filled?: boolean;
    padded?: boolean;
    align?: "center";
    fluid?: boolean;
}

function MissingInfo({ message, size = "md", filled = false, align, padded, fluid = false }: Props) {
    return <div className={classnames("MissingInfo", size, align, { filled, padded, fluid })}>{message}</div>;
}

export default memo(MissingInfo);
