import { FormEvent, useCallback, useMemo, useState } from "react";
import { checkIsDirty } from "../../../utils/forms";
import { isValidationError } from "../../../utils/guards";
import { ValidationError } from "../../models/ValidationError";
import { FormPropsV2 } from "../../props/forms";
import { extractValidationError } from "../../validation";

type ChangeHandlers<T> = {
    [K in keyof T]: (value: T[K]) => void;
};

type TouchHandlers<T> = {
    [K in keyof T]: (touched?: boolean) => void;
};

interface FormOptions<T> {
    keys: readonly (keyof T)[];
    /**
     * When `true`, errors are presented for all fields as soon as one of them
     * becomes touched or dirty. This exists because some forms in this app can
     * be partiall filled out and submitted (i.e. saved to be completed during a
     * later session), but certain forms, such as those for addresses or
     * contacts cannot be saved unless fully filled out. Defaults to `false`.
     */
    atomic?: boolean;
}

export interface FormState<T> {
    /**
     * The current values of the form, or if not set, the `initialValues`. These
     * are likely the values you want to use on your controlled form inputs.
     */
    values: Partial<T>;
    /**
     * `true` when `dirty` does not have any entries.
     */
    pristine: boolean;
    /**
     * `true` when the following are all true:
     * - `validationErrors` is not an instance of `ValidationError`.
     * - `errors` does not have any entries.
     *
     * Note that it is possible for `validationErrors` to refer to an error at
     * the root level (`ValidationError`) as opposed to a collection of errors
     * at the field level (`ModelValidationErrors`).
     */
    valid: boolean;
    /**
     * The set of fields where the current value is not equal to the initial
     * value.
     */
    dirty: Set<keyof T>;
    /**
     * The set of fields that the user has interacted with - namely, the blur
     * event was triggered.
     */
    touched: Set<keyof T>;
    /**
     * Map of keys to an error if the following are all true:
     * - The input has been touched or is dirty.
     * - There is a validation error.
     */
    errors: Map<keyof T, ValidationError>;
}

export interface FormActions<T> {
    /**
     * This is just a passthrough to the `onSubmit` prop provided via
     * `FormPropsV2`, but invokes `preventDefault` on the form event.
     */
    handleSubmit: (evt: FormEvent<HTMLFormElement>) => void;
    changeHandlers: ChangeHandlers<T>;
    touchHandlers: TouchHandlers<T>;
    handleChange: <K extends keyof T>(key: K, value: T[K]) => void;
    handleTouched: <K extends keyof T>(key: K, toggle?: boolean) => void;
    /**
     * Reset form state tracked by this hook, such as touched indicators.
     */
    reset: () => void;
}

export function useForm<T>(
    { keys, atomic = false }: FormOptions<T>,
    {
        validationErrors,
        values,
        initialValues,
        onChange,
        onSubmit,
    }: Pick<FormPropsV2<T>, "validationErrors" | "values" | "initialValues" | "onChange" | "onSubmit">
): [FormState<T>, FormActions<T>] {
    const [touched, setTouched] = useState<Set<keyof T>>(() => new Set());

    const handleSubmit = useCallback(
        (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );

    const handleTouched = useCallback((key: keyof T, toggle = true) => {
        setTouched((touched) => {
            const nextTouched = new Set([...touched?.values()]);
            toggle ? nextTouched.add(key) : nextTouched.delete(key);
            return nextTouched;
        });
    }, []);

    const handleChange = useCallback(
        <K extends keyof T>(key: K, value: T[K]) => {
            onChange?.({
                ...values,
                [key]: value,
            });
        },
        [onChange, values]
    );

    const reset = useCallback(() => {
        setTouched(new Set());
    }, []);

    const dirty = useMemo(
        (): Set<keyof T> => new Set(keys.filter((key) => checkIsDirty(initialValues?.[key], values?.[key]))),
        [values, initialValues, keys]
    );

    const pristine = useMemo(() => dirty.size === 0, [dirty]);

    const errors = useMemo(
        (): Map<keyof T, ValidationError> =>
            new Map(
                keys
                    .filter((key) => (atomic ? touched.size > 0 || dirty.size > 0 : touched.has(key) || dirty.has(key)))
                    .map((key): [keyof T, ValidationError | undefined] => [
                        key,
                        extractValidationError(validationErrors, key),
                    ])
                    .filter((entry): entry is [keyof T, ValidationError] => !!entry[1])
            ),
        [validationErrors, touched, keys, dirty, atomic]
    );

    const valid = useMemo(() => !isValidationError(validationErrors) && errors.size === 0, [errors, validationErrors]);

    const changeHandlers = useMemo(
        () =>
            keys.reduce(<K extends keyof T>(handlers: ChangeHandlers<T>, key: K) => {
                handlers[key] = (value: T[K]) => handleChange(key, value);
                return handlers;
            }, {} as ChangeHandlers<T>),
        [handleChange, keys]
    );

    const touchHandlers = useMemo(
        () =>
            keys.reduce(<K extends keyof T>(handlers: TouchHandlers<T>, key: K) => {
                handlers[key] = (touched = true) => handleTouched(key, touched);
                return handlers;
            }, {} as TouchHandlers<T>),
        [handleTouched, keys]
    );

    const _values = useMemo(
        () =>
            keys.reduce(<K extends keyof T>(out: Partial<T>, key: K) => {
                // Trying to be smart here when a field has an initial value,
                // but is now being unset. We need to distinguish this from the
                // absence of a new value, where we fallback to the initial
                // value. So, we check if the property has been explicitly set
                // to `undefined` by checking `hasOwnProperty`.
                out[key] = values?.hasOwnProperty(key)
                    ? values?.[key] === undefined
                        ? undefined
                        : values?.[key]
                    : initialValues?.[key];
                return out;
            }, {} as Partial<T>),
        [values, initialValues, keys]
    );

    return [
        {
            values: _values,
            touched,
            pristine,
            dirty,
            valid,
            errors,
        },
        {
            handleSubmit,
            changeHandlers,
            touchHandlers,
            handleChange,
            handleTouched,
            reset,
        },
    ];
}
