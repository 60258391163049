import { CaseReducer, createSlice, PayloadAction, PrepareAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";
import { ApiRequestCollectionState, ApiRequestState, WithError, WithKey } from "../shared/models/ApiRequestState";

function initialAPIRequestState(): ApiRequestState;
function initialAPIRequestState<T>(value: T): ApiRequestState<T>;
function initialAPIRequestState<T = undefined>(value?: T): ApiRequestState<T> {
    return {
        value,
        error: null,
        pending: false,
        success: false,
    } as any;
}

/* =============================================================================
 * NOTE(wtsai): Redux Toolkit (RTK) relies a lot on type inference to generate
 * the return type of `createSlice`. I am creating overloaded custom slice
 * creators here, and TS requires that overloads have a return type defined, so
 * we have to handcraft the return type instead of relying on the inference of
 * `createSlice`. In trying to define types by hand that match what RTK
 * produces, I'm finding that many of their exported types lack the proper
 * signature to fully define the return type with full typesafety. As a
 * workaround, I've opted to define custom replacement types (prefixed with
 * `Pf`) where necessary.
 * =============================================================================
 */

/**
 * The `CaseReducerWithPrepare` type from redux toolkit is broken and
 * insufficient. `Action` extends `PayloadAction` with default generics (where
 * payload defaults to `void`), making it impossible to define a
 * `CaseReducerWithPrepare` with an action type that includes a payload. It's
 * also missing a generic to type the prepare action in such a way that its
 * parameters are exposed on the action creators in a typesafe way.
 */
type PfCaseReducerWithPrepare<
    State,
    Action extends PayloadAction<any, string, any>,
    PA = PrepareAction<Action["payload"]>
> = {
    reducer: CaseReducer<State, Action>;
    prepare: PA;
};

interface CollectionPrepareAction<P> {
    (payload: P, key: number): { payload: P; meta: WithKey };
}

interface ApiRequestCaseReducers<Do, Did, Fail, Reset, T = Did> extends SliceCaseReducers<ApiRequestState<T>> {
    do: CaseReducer<ApiRequestState<T>, PayloadAction<Do>>;
    did: CaseReducer<ApiRequestState<T>, PayloadAction<Did>>;
    fail: CaseReducer<ApiRequestState<T>, PayloadAction<Fail>>;
    reset: CaseReducer<ApiRequestState<T>, PayloadAction<Reset>>;
}

interface ApiRequestCollectionCaseReducers<Do, Did, Fail, Reset, T = Did>
    extends SliceCaseReducers<ApiRequestCollectionState<T>> {
    do: PfCaseReducerWithPrepare<
        ApiRequestCollectionState<T>,
        PayloadAction<Do, string, WithKey>,
        CollectionPrepareAction<Do>
    >;
    did: PfCaseReducerWithPrepare<
        ApiRequestCollectionState<T>,
        PayloadAction<Did, string, WithKey>,
        CollectionPrepareAction<Did>
    >;
    fail: PfCaseReducerWithPrepare<
        ApiRequestCollectionState<T>,
        PayloadAction<Fail, string, WithKey>,
        CollectionPrepareAction<Fail>
    >;
    reset: PfCaseReducerWithPrepare<
        ApiRequestCollectionState<T>,
        PayloadAction<Reset, string, WithKey>,
        CollectionPrepareAction<Reset>
    >;
}

/**
 * Creates a slice that handles an `ApiRequestState`.
 * @param name The slice's name. Used to namespace the generated action types.
 * @param initialValue If provided, the reducer will initialize and store the
 * value in state in response to the `did` action. Otherwise, it only tracks the
 * state of the request (pending/success/error).
 */
export function createApiRequestSlice<Do = void, Did = void, Fail = Record<string, unknown>, Reset = Do>(
    name: string
): Slice<ApiRequestState, ApiRequestCaseReducers<Do, Did, Fail & WithError, Reset, void>, string>;
export function createApiRequestSlice<Do = void, Did = void, Fail = Record<string, unknown>, Reset = Do>(
    name: string,
    initialValue: Did
): Slice<ApiRequestState<Did>, ApiRequestCaseReducers<Do, Did, Fail & WithError, Reset>, string>;
export function createApiRequestSlice<Do = void, Did = void, Fail = Record<string, unknown>, Reset = Do>(
    name: string,
    initialValue?: Did
): Slice<ApiRequestState, ApiRequestCaseReducers<Do, Did, Fail & WithError, Reset, void>, string> {
    const store = arguments.length > 1;
    // Problem with immer's `Draft` type with generics.
    const initialState = initialAPIRequestState(initialValue) as ApiRequestState<any>;

    return createSlice({
        name,
        initialState,
        reducers: {
            do: (state, _action: PayloadAction<Do>) => {
                state.pending = true;
                state.error = null;
                state.success = false;
            },
            did: (state, action: PayloadAction<Did>) => {
                state.pending = false;
                state.error = null;
                state.success = true;

                if (store) {
                    // Same problem with immer's `Draft` type with generics.
                    state.value = action.payload as any;
                }
            },
            fail: (state, action: PayloadAction<Fail & WithError>) => {
                state.pending = false;
                state.error = action.payload.error || null;
                state.success = false;
            },
            reset: (_state, _action: PayloadAction<Reset>) => initialState,
        },
    });
}

/**
 * Creates a slice that handles an `ApiRequestCollectionState`.
 * @param name The slice's name. Used to namespace the generated action types.
 * @param initialValue If provided, the reducer will initialize and store the
 * value in state in response to the `did` action. Otherwise, it only tracks the
 * state of the request (pending/success/error).
 */
export function createApiRequestCollectionSlice<
    Do = undefined,
    Did = undefined,
    Fail = Record<string, unknown>,
    Reset = Do
>(
    name: string
): Slice<ApiRequestCollectionState, ApiRequestCollectionCaseReducers<Do, Did, Fail & WithError, Reset, void>, string>;
export function createApiRequestCollectionSlice<
    Do = undefined,
    Did = undefined,
    Fail = Record<string, unknown>,
    Reset = Do
>(
    name: string,
    initialValue: Did
): Slice<ApiRequestCollectionState<Did>, ApiRequestCollectionCaseReducers<Do, Did, Fail & WithError, Reset>, string>;
export function createApiRequestCollectionSlice<
    Do = undefined,
    Did = undefined,
    Fail = Record<string, unknown>,
    Reset = Do
>(name: string, initialValue?: Did) {
    const apiRequestSlice =
        arguments.length === 1
            ? createApiRequestSlice<Do, Did, Fail, Reset>(name)
            : createApiRequestSlice<Do, Did, Fail, Reset>(name, initialValue!);
    const initialState: ApiRequestCollectionState<any> = {};

    // Must use reducer/prepare callback syntax to support meta.
    const slice = createSlice({
        name,
        initialState,
        reducers: {
            do: {
                reducer: (state, action: PayloadAction<Do, string, WithKey>) => {
                    const key = action.meta.key;
                    state[key] = apiRequestSlice.reducer(state[key], action);
                },
                prepare: (payload: Do, key: number) => {
                    return {
                        payload,
                        meta: {
                            key,
                        },
                    };
                },
            },
            did: {
                reducer: (state, action: PayloadAction<Did, string, WithKey>) => {
                    const key = action.meta.key;
                    state[key] = apiRequestSlice.reducer(state[key], action);
                },
                prepare: (payload: Did, key: number) => {
                    return {
                        payload,
                        meta: {
                            key,
                        },
                    };
                },
            },
            fail: {
                reducer: (state, action: PayloadAction<Fail & WithError, string, WithKey>) => {
                    const key = action.meta.key;
                    state[key] = apiRequestSlice.reducer(state[key], action);
                },
                prepare: (payload: Fail & WithError, key: number) => {
                    return {
                        payload,
                        meta: {
                            key,
                        },
                    };
                },
            },
            reset: {
                reducer: (state, action: PayloadAction<Reset, string, WithKey>) => {
                    const key = action.meta.key;
                    state[key] = apiRequestSlice.reducer(state[key], action);
                },
                prepare: (payload: Reset, key: number) => {
                    return {
                        payload,
                        meta: {
                            key,
                        },
                    };
                },
            },
        },
    });

    return slice;
}
