import { groupBy } from "lodash";
import React, { memo, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { checkIsSubmitted as defaultCheckIsSubmitted } from "../../../utils/partnerForm";
import { getMessage, messages } from "../../messages";
import { PartnerForm } from "../../models/PartnerForm";
import Icon, { Props as IconProps } from "../Icon";
import PartnerFormsListItem from "./PartnerFormsListItem";
import PartnerFormsListSection from "./PartnerFormsListSection";

enum PartnerFormGroup {
    InProgress = "InProgress",
    Submitted = "Submitted",
}

interface Props<T extends PartnerForm> extends RouteComponentProps<any> {
    isFetching?: boolean;
    entries?: T[];
    checkIsEditable?: (entry: T) => boolean;
    checkIsSubmitted?: (entry: T) => boolean;
    checkIsNew?: (entry: T) => boolean;
    /**
     * If provided, hides cancelled forms from the list.
     */
    checkIsCancelled?: (entry: T) => boolean;
    lastUpdated?: (entry: T) => Date;
    emptyMessage: string;
}

function PartnerFormsList<T extends PartnerForm>({
    entries,
    match,
    location,
    isFetching,
    checkIsEditable,
    checkIsSubmitted = defaultCheckIsSubmitted,
    checkIsCancelled,
    lastUpdated,
    checkIsNew,
    emptyMessage,
}: Props<T>) {
    function getIcon(group: PartnerFormGroup): IconProps["icon"] {
        switch (group) {
            case PartnerFormGroup.InProgress:
                return ["far", "clipboard"];
            case PartnerFormGroup.Submitted:
                return "clipboard-check";
            default:
                return "clipboard-list";
        }
    }

    const grouped = useMemo(
        () =>
            groupBy(
                entries?.filter((entry) => !(checkIsCancelled?.(entry) ?? false)),
                (entry) => {
                    if (checkIsSubmitted(entry)) {
                        return PartnerFormGroup.Submitted;
                    } else {
                        return PartnerFormGroup.InProgress;
                    }
                }
            ),
        [entries, checkIsCancelled, checkIsSubmitted]
    );

    return (
        <div className="PartnerFormsList">
            <div className="PartnerFormsList-header">
                <span className="PartnerFormsList-lastUpdatedLabel">Last Updated</span>
            </div>
            {[PartnerFormGroup.InProgress, PartnerFormGroup.Submitted].map((group) => (
                <PartnerFormsListSection
                    key={group}
                    icon={() => <Icon icon={getIcon(group)} />}
                    className={group}
                    // We check if siteSurveys is undefined so that we don't
                    // show a loading state when we are only refreshing data
                    showLoading={!entries && isFetching}
                    label={getMessage(() => messages.meta[group](null), group)}
                    emptyMessage={emptyMessage}
                >
                    {grouped[group]
                        ? grouped[group].map((entry) => (
                              <PartnerFormsListItem
                                  key={entry.id}
                                  entry={entry}
                                  to={{
                                      ...location,
                                      pathname: `${match.url}/${entry.id}`,
                                  }}
                                  checkIsEditable={checkIsEditable}
                                  checkIsSubmitted={checkIsSubmitted}
                                  checkIsNew={checkIsNew}
                                  getLastUpdated={lastUpdated}
                              />
                          ))
                        : null}
                </PartnerFormsListSection>
            ))}
        </div>
    );
}

export default memo(PartnerFormsList) as typeof PartnerFormsList;
