import { flatten, isEqual } from "lodash";
import { Path, Indexor } from "../shared/models/Path";
import { asArray } from "./arrays";

export function isValidIndexor(idxr: Indexor) {
    return (typeof idxr === "number" && idxr < 0) || idxr === "" ? false : true;
}

export function stringifyIndexor(idxr: Indexor) {
    return typeof idxr === "number" ? `[${idxr}]` : `.${idxr.toString()}`;
}

export function stringifyPath(path: Path): string {
    return Array.isArray(path) ? path.map(stringifyIndexor).join("") : stringifyIndexor(path);
}

export function concatenatePaths(...paths: Path[]) {
    return flatten(paths.map(asArray));
}

export function isPath(subject: Path, test: Path) {
    return isEqual(asArray(subject), asArray(test));
}

export function isPathOneOf(subject: Path, tests: Path[]) {
    return tests.some((test) => isPath(subject, test));
}

/**
 * Returns true if test is a prefix or exact match for the path described by subject
 * @param path - The path to test
 * @param prefix - The prefix to test for
 */
export function doesPathBeginWith(path: Path | undefined, prefix: Path): boolean {
    if (!path) {
        return false;
    }

    return asArray(prefix).every((indexor, idx) => indexor === asArray(path)[idx]);
}

export function doesPathEndWith(path: Path | undefined, suffix: Path): boolean {
    if (!path) {
        return false;
    }

    return doesPathBeginWith(asArray(path).reverse(), asArray(suffix).reverse());
}

export function doesPathBeginWithOneOf(path: Path | undefined, prefixes: Path[]): boolean {
    return prefixes.some((prefix) => doesPathBeginWith(path, prefix));
}

/**
 * If the prefix is a prefix of the path, then returns a new path with the
 * prefix portion stripped. If the path is not a match with the prefix, then the path is simply returned.
 * @param path - The path to strip from
 * @param prefix - The prefix to strip
 */
export function stripPathBeginning(path: undefined, prefix: Path): undefined;
export function stripPathBeginning(path: Path, prefix: Path): Path;
export function stripPathBeginning(path: Path | undefined, prefix: Path): Path | undefined {
    if (path === undefined) {
        return;
    }

    if (doesPathBeginWith(path, prefix)) {
        return asArray(path).slice(asArray(prefix).length);
    }

    return asArray(path);
}

export function stripPathEnding(path: undefined, suffix: Path): undefined;
export function stripPathEnding(path: Path, suffix: Path): Path;
export function stripPathEnding(path: Path | undefined, suffix: Path): Path | undefined {
    if (path === undefined) {
        return;
    }

    return asArray(stripPathBeginning(asArray(path).reverse(), asArray(suffix).reverse())).reverse();
}
