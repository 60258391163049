import { PartOrderLineItem } from "../../partOrders/models/PartOrderLineItem";
import { PartOrderLineItemNew } from "../../partOrders/models/PartOrderLineItemNew";
import { Address, AddressNew } from "../../shared/models/Address";
import { Attachment } from "../../shared/models/Attachment";
import { Contact, ContactNew } from "../../shared/models/Contact";
import { EoriCompliant } from "../../shared/models/EoriCompliant";
import { HardwareIdentity } from "../../shared/models/HardwareIdentity";
import { JiraAssociable } from "../../shared/models/JiraAssociable";
import { Org } from "../../shared/models/Org";
import { PartnerForm } from "../../shared/models/PartnerForm";
import { Site } from "../../shared/models/Site";
import { TaxpayerAffiliated } from "../../shared/models/TaxpayerAffiliated";
import { PartOrderLineItemPayload } from "./PartOrderLineItemPayload";
import { PartOrderStatus } from "./PartOrderStatus";

export const PARTS_ORDER_LINE_ITEMS_KEY = "partLineItems";
export const ADDRESS_KEY = "shippingAddress";
export const CONTACT_KEY = "contact";

/**
 * A modified version of the PartOrder entity from the server...it represents
 * the data differently in the following ways to be more consistent with
 * site surveys:
 *
 * - Nests the contact and address one level deep under `contact` and `address`,
 *   respectively.
 * - Populates `contact.nameFirst` with `contactName` and `contact.nameLast`
 *   with an empty string.
 */
export interface PartOrder<
    T extends PartOrderLineItem | PartOrderLineItemNew | PartOrderLineItemPayload = PartOrderLineItem
> extends PartnerForm,
        JiraAssociable,
        EoriCompliant,
        TaxpayerAffiliated {
    hardwareIdentity: HardwareIdentity;
    partOrderStatus: PartOrderStatus;
    attachments: Attachment[];
    complete: boolean;
    org: Org;
    site: Site;
    shortHostname: string;
    [PARTS_ORDER_LINE_ITEMS_KEY]: T[];
    [ADDRESS_KEY]?: Address | AddressNew;
    [CONTACT_KEY]?: Contact | ContactNew;
    enforceQuantityLimits: boolean;
}
