import { zipWith } from "lodash";
import { validate } from ".";
import { PartOrderLineItemFields, PART_KEY, QUANTITY_KEY } from "../../partOrders/models/PartOrderLineItemFields";
import { QuantityLimits } from "../../partOrders/models/QuantityLimits";
import { all, between, integer, makeStrict, required } from "../forms/validators";
import { messages } from "../messages";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { ID_KEY } from "../models/Resource";
import { Level, ValidationError } from "../models/ValidationError";
import { Validator } from "../models/Validator";

const QUANTITY_DEFAULT_MAX = 99;
const QUANTITY_DEFAULT_MIN = 1;

function makePartOrderLineItemValidator(
    quantityMax: number = QUANTITY_DEFAULT_MAX
): ModelValidator<PartOrderLineItemFields> {
    return new Map<keyof PartOrderLineItemFields, Validator>([
        [PART_KEY, makeStrict(required)],
        [
            QUANTITY_KEY,
            makeStrict(
                all(
                    required,
                    integer,
                    between(
                        [QUANTITY_DEFAULT_MIN, quantityMax],
                        messages.forms.validation.greaterThanMax({
                            MAX: quantityMax,
                        })
                    )
                )
            ),
        ],
    ]);
}

/**
 * Validate that each order line references a different part definition.
 */
function validateUniquePartDefinition(
    lineItems: Partial<PartOrderLineItemFields>[]
): ModelValidationErrors<PartOrderLineItemFields>[] {
    const errors: ModelValidationErrors<PartOrderLineItemFields>[] = lineItems.map(() => ({}));
    const partDefinitionIdToIndicesMap: Map<number, number[]> = new Map();
    lineItems.forEach((lineItem, idx) => {
        const partDefinitionId = lineItem[PART_KEY]?.[ID_KEY];

        if (!partDefinitionId) {
            return;
        }

        const indices = partDefinitionIdToIndicesMap.get(partDefinitionId) ?? [];
        indices.push(idx);
        partDefinitionIdToIndicesMap.set(partDefinitionId, indices);
    });

    [...partDefinitionIdToIndicesMap.values()].forEach((indices) => {
        if (indices.length > 1) {
            indices.forEach((jdx) => {
                errors[jdx][PART_KEY] = {
                    level: Level.Invalid,
                    message: "This is a duplicate. Please select a different part",
                };
            });
        }
    });

    return errors;
}

function getMaxQuantity(
    quantityLimits: QuantityLimits | undefined,
    partDefinitionId: number | undefined
): number | undefined {
    if (partDefinitionId) {
        const count = quantityLimits?.[partDefinitionId];
        return count === -1 ? undefined : count;
    } else {
        return;
    }
}

/**
 *
 * @param lineItem
 * @param quantityLimits If provided, will enforce quantity limits.
 */
export function validatePartOrderLineItem(
    lineItem: Partial<PartOrderLineItemFields>,
    quantityLimits?: QuantityLimits
): ModelValidationErrors<PartOrderLineItemFields> | undefined {
    const partDefinitionId = lineItem[PART_KEY]?.[ID_KEY];
    return validate(lineItem, makePartOrderLineItemValidator(getMaxQuantity(quantityLimits, partDefinitionId)));
}

/**
 *
 * @param lineItems
 * @param quantityLimits If provided, will enforce quantity limits.
 */
export function validatePartOrderLineItems(
    lineItems: Partial<PartOrderLineItemFields>[],
    quantityLimits?: QuantityLimits
): (ModelValidationErrors<PartOrderLineItemFields> | undefined)[] | ValidationError {
    if (lineItems.length === 0) {
        return {
            level: Level.Incomplete,
            message: "At least one part is required",
        };
    }
    // These are both arrays with length = number of line items.
    const individualErrors = lineItems.map((lineItem) => validatePartOrderLineItem(lineItem, quantityLimits));
    const intersectionalErrors = validateUniquePartDefinition(lineItems);
    return zipWith(individualErrors, intersectionalErrors, (e1, e2) => ({ ...e1, ...e2 }));
}
