import React, { memo } from "react";
import { messages } from "../../messages";
import { FieldMetadata } from "../../models/FieldMetadata";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";
import Value from "../Value";

interface Props {
    eoriFieldMetadata?: FieldMetadata;
    eori?: string;
}

function EoriField({ eoriFieldMetadata, eori }: Props) {
    if (!eoriFieldMetadata) {
        return null;
    }
    return (
        <Content title={messages.title.eori()}>
            {eori ? (
                <Value label={eoriFieldMetadata.label}>
                    <span>{eori}</span>
                </Value>
            ) : (
                <MissingInfo
                    message={messages.notice.missingValue({
                        VALUE_NAME: eoriFieldMetadata.label,
                    })}
                />
            )}
        </Content>
    );
}

export default memo(EoriField);
