import { isUUID } from "validator";
import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

export const uuid: Validator = (value?: string) => {
    return !value || isUUID(value)
        ? undefined
        : {
              level: Level.Invalid,
              message: messages.forms.validation.invalidUuid(),
          };
};
