import classnames from "classnames";
import React, { ChangeEvent } from "react";
import Button from "../../../shared/components/Button";
import Form from "../../../shared/components/Form";
import FormField, { Props as FormFieldProps } from "../../../shared/components/FormField";
import Header from "../../../shared/components/Header";
import Segment from "../../../shared/components/Segment";
import TextInput, { Props as TextInputProps } from "../../../shared/components/TextInput";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { FormPropsV2 } from "../../../shared/props/forms";
import {
    IPV4_ADDRESS_KEY,
    IPV4_BGP_PEER_KEY,
    IPV4_GATEWAY_KEY,
    IPV4_SUBNET_MASK_KEY,
    IPV6_ADDRESS_KEY,
    IPV6_BGP_PEER_KEY,
    IPV6_GATEWAY_KEY,
    IPV6_PREFIX_LENGTH_KEY,
    NetworkConfigFields,
    NETWORK_CONFIG_KEYS,
} from "../../../siteSurveys/models/NetworkConfig";
import "./NetworkConfigForm.css";

export interface Props extends FormPropsV2<NetworkConfigFields> {
    className?: string;
    onToggleBmcConfigType: (values: Partial<NetworkConfigFields>) => void;
}

function NetworkConfigForm(props: Props) {
    const { className, onSubmit, isSubmitting, compact } = props;

    const [{ values, pristine, valid, dirty, errors }, { handleSubmit, changeHandlers, touchHandlers }] = useForm(
        { keys: NETWORK_CONFIG_KEYS },
        props
    );

    function getFormFieldProps<K extends keyof NetworkConfigFields>(key: K): Pick<FormFieldProps, "name" | "error"> {
        return {
            name: key,
            error: errors.get(key),
        };
    }

    function getInputProps<K extends keyof NetworkConfigFields>(
        key: K
    ): Pick<TextInputProps, "error" | "dirty" | "value" | "onBlur" | "onChange"> {
        return {
            error: errors.has(key),
            dirty: dirty.has(key),
            value: values[key] ?? "",
            onBlur: () => touchHandlers[key]?.(),
            onChange: (evt: ChangeEvent<HTMLInputElement>) => changeHandlers[key]?.(evt.target.value),
        };
    }

    return (
        <Form className={classnames("NetworkConfigForm", className)} onSubmit={handleSubmit} compact={compact}>
            <Header as="h3" title={messages.title.primaryNetworkConfiguration()} fluid />
            <div className="description">{messages.title.primaryNetworkConfigurationDescription()}</div>
            <Segment.Group horizontal>
                <Segment>
                    <Header as="h4" title="IPv4" fluid />
                    <FormField
                        {...getFormFieldProps(IPV4_ADDRESS_KEY)}
                        required
                        label={messages.labels.networkConfig.ipv4Address()}
                    >
                        <TextInput
                            {...getInputProps(IPV4_ADDRESS_KEY)}
                            placeholder={messages.forms.placeholders.ipv4Address()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV4_SUBNET_MASK_KEY)}
                        required
                        label={messages.labels.networkConfig.ipv4SubnetMask()}
                    >
                        <TextInput
                            {...getInputProps(IPV4_SUBNET_MASK_KEY)}
                            placeholder={messages.forms.placeholders.ipv4SubnetMask()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV4_GATEWAY_KEY)}
                        required
                        label={messages.labels.networkConfig.ipv4Gateway()}
                    >
                        <TextInput
                            {...getInputProps(IPV4_GATEWAY_KEY)}
                            placeholder={messages.forms.placeholders.ipv4Gateway()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV4_BGP_PEER_KEY)}
                        required
                        label={messages.labels.networkConfig.ipv4BGPPeer()}
                    >
                        <TextInput
                            {...getInputProps(IPV4_BGP_PEER_KEY)}
                            placeholder={messages.forms.placeholders.ipv4BGPPeer()}
                        />
                    </FormField>
                </Segment>
                <Segment>
                    <Header as="h4" title="IPv6" fluid />
                    <FormField
                        {...getFormFieldProps(IPV6_ADDRESS_KEY)}
                        label={messages.labels.networkConfig.ipv6Address()}
                    >
                        <TextInput
                            {...getInputProps(IPV6_ADDRESS_KEY)}
                            placeholder={messages.forms.placeholders.ipv6Address()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV6_PREFIX_LENGTH_KEY)}
                        label={messages.labels.networkConfig.ipv6Prefix()}
                    >
                        <TextInput
                            {...getInputProps(IPV6_PREFIX_LENGTH_KEY)}
                            placeholder={messages.forms.placeholders.ipv6Prefix()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV6_GATEWAY_KEY)}
                        label={messages.labels.networkConfig.ipv6Gateway()}
                    >
                        <TextInput
                            {...getInputProps(IPV6_GATEWAY_KEY)}
                            placeholder={messages.forms.placeholders.ipv6Gateway()}
                        />
                    </FormField>
                    <FormField
                        {...getFormFieldProps(IPV6_BGP_PEER_KEY)}
                        label={messages.labels.networkConfig.ipv6BGPPeer()}
                    >
                        <TextInput
                            {...getInputProps(IPV6_BGP_PEER_KEY)}
                            placeholder={messages.forms.placeholders.ipv6BGPPeer()}
                        />
                    </FormField>
                </Segment>
            </Segment.Group>
            {onSubmit && (
                <Button type="submit" loading={isSubmitting} disabled={pristine || !valid || isSubmitting}>
                    Save
                </Button>
            )}
        </Form>
    );
}

export default NetworkConfigForm;
