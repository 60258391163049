import {
    BOXED_KEY,
    FREQUENT_SHIPPER_KEY,
    LIFT_GATE_REQUIRED_KEY,
    OcaReturnPickupProperties
} from "../../ocaReturns/models/OcaReturnPickupProperties";
import { required } from "../forms/validators";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

const OCA_RETURN_PICKUP_PROPERTIES_VALIDATOR: ModelValidator<OcaReturnPickupProperties> = new Map<
    keyof OcaReturnPickupProperties,
    Validator
>([[BOXED_KEY, required], [LIFT_GATE_REQUIRED_KEY, required], [FREQUENT_SHIPPER_KEY, required]]);

export function validateOcaReturnPickupProperties(
    properties: OcaReturnPickupProperties
): ModelValidationErrors<OcaReturnPickupProperties> | undefined {
    return validate(properties, OCA_RETURN_PICKUP_PROPERTIES_VALIDATOR);
}
