import { createSelector, ParametricSelector, Selector } from "reselect";
import { AppState } from "../../app/models/AppState";
import {
    getFetchCountriesRequestState,
    getFetchActiveOrgRequestState,
    getOcaReturnsState,
    getActiveOrg,
} from "../../app/reducer/selectors";
import { AddressFields } from "../../shared/models/AddressFields";
import { anyPending, ApiRequestState, firstError } from "../../shared/models/ApiRequestState";
import { Changeset } from "../../shared/models/Changeset";
import { ContactFields } from "../../shared/models/ContactFields";
import { Dictionary } from "../../shared/models/Dictionary";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { getHighestErrorLevel, Level, ValidationError } from "../../shared/models/ValidationError";
import { list } from "../../shared/validation";
import { applyChangeset } from "../../utils/change";
import { dateOrdering } from "../../utils/compares";
import { checkAreOcaReturnsDifferent, attributeOcaReturnErrorToView } from "../../utils/ocaReturn";
import { collectContacts, extractAdditionalRecipientEmails, rollUpErrorsByView } from "../../utils/partnerForm";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../utils/selectors";
import { OcaReturn } from "../models/OcaReturn";
import { OcaReturnUiState } from "../models/OcaReturnUiState";

export const getFetchOcaReturnsRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getOcaReturnsState],
    (state) => state.fetch
);

export const getSaveOcaReturnsRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getOcaReturnsState],
    (state) => state.save
);

export const getSubmitOcaReturnsRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getOcaReturnsState],
    (state) => state.submit
);

export const getOcaReturnsById: Selector<AppState, Dictionary<OcaReturn> | undefined> = createSelector(
    [getOcaReturnsState],
    (state) => state.byId
);

export const getOcaReturnsSorted: Selector<AppState, OcaReturn[] | undefined> = createSelector(
    [getOcaReturnsById],
    (byId) =>
        byId &&
        Object.values(byId)
            .filter((ocaReturn): ocaReturn is OcaReturn => !!ocaReturn)
            .sort((a, b) => dateOrdering(a.dateCreated, b.dateCreated, true))
);

export const getOcaReturnEditsById: Selector<AppState, Dictionary<Changeset>> = createSelector(
    [getOcaReturnsState],
    (state) => state.edits
);

export const getOcaReturnUIStatesById: Selector<AppState, Dictionary<OcaReturnUiState>> = createSelector(
    [getOcaReturnsState],
    (state) => state.ui
);

export const getOcaReturnValidationsById: Selector<
    AppState,
    Dictionary<ModelValidationErrors<OcaReturn>>
> = createSelector([getOcaReturnsState], (state) => state.validations);

export const getOcaReturn: ParametricSelector<AppState, number, OcaReturn | undefined> = (state, id) => {
    const byId = getOcaReturnsById(state);
    return byId && byId[id];
};

export const getOcaReturnUIState: ParametricSelector<AppState, number, OcaReturnUiState | undefined> = (state, id) => {
    const byId = getOcaReturnUIStatesById(state);
    return byId[id];
};

export const getOcaReturnErrors: ParametricSelector<AppState, number, ModelValidationErrors<OcaReturn>> = (state, id) =>
    getOcaReturnValidationsById(state)[id] || EMPTY_OBJECT;

export const getOcaReturnErrorsList: ParametricSelector<AppState, number, ValidationError[]> = createSelector(
    [getOcaReturnErrors],
    list
);

export const getOcaReturnErrorsByView: ParametricSelector<
    AppState,
    number,
    Dictionary<ValidationError>
> = createSelector([getOcaReturnErrorsList], (errors) => rollUpErrorsByView(errors, attributeOcaReturnErrorToView));

export const getOcaReturnChangeset: ParametricSelector<AppState, number, Changeset> = (state, id) =>
    getOcaReturnEditsById(state)[id] || EMPTY_ARRAY;

export const getIsFetchingOcaReturnData: Selector<AppState, boolean> = createSelector(
    [getFetchOcaReturnsRequestState, getFetchCountriesRequestState, getFetchActiveOrgRequestState],
    anyPending
);

export const getErrorFetchingOcaReturnData: Selector<AppState, string | Error | null> = createSelector(
    [getFetchOcaReturnsRequestState, getFetchCountriesRequestState, getFetchActiveOrgRequestState],
    firstError
);

export const getOcaReturnModified: ParametricSelector<AppState, number, OcaReturn | undefined> = createSelector(
    [getOcaReturn, getOcaReturnChangeset],
    (ocaReturn, changeset) => ocaReturn && applyChangeset(ocaReturn, changeset)
);

export const getOcaReturnIsDirty: ParametricSelector<AppState, number, boolean> = createSelector(
    [getOcaReturn, getOcaReturnModified],
    (ocaReturn, modified) => checkAreOcaReturnsDifferent(ocaReturn, modified)
);

export const getOcaReturnIsValid: ParametricSelector<AppState, number, boolean> = createSelector(
    [getOcaReturnErrorsList],
    (errors) => (errors.length ? getHighestErrorLevel(errors) !== Level.Invalid : true)
);

export const getOcaReturnIsComplete: ParametricSelector<AppState, number, boolean> = createSelector(
    [getOcaReturnErrorsList],
    (errors) => !errors.length
);

export const getContacts: ParametricSelector<AppState, number, ContactFields[]> = createSelector(
    [getActiveOrg, getOcaReturn, getOcaReturnModified],
    (org) => collectContacts(org)
);

/**
 * We don't offer any address suggestions for OCA Returns.
 */
export const getAddresses: ParametricSelector<AppState, number, AddressFields[]> = createSelector(
    [getOcaReturn, getOcaReturnModified],
    () => []
);

export const getInitialAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getOcaReturn],
    (ocaReturn) => extractAdditionalRecipientEmails(ocaReturn)
);

export const getAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getOcaReturn, getOcaReturnModified],
    (ocaReturn, modified) => extractAdditionalRecipientEmails(modified ?? ocaReturn)
);
