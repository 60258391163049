import { MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import {
    getErrorFetchingSiteSurveyData,
    getIsFetchingSiteSurveyData,
    getSiteSurveysById,
    getSiteSurveysSorted,
} from "../../reducer/selectors";
import { Props } from "./SiteSurveys";

export type StateProps = Pick<Props, "errorFetching" | "isFetching" | "byId" | "sorted">;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = function (
    state: AppState,
    _ownProps: OwnProps
) {
    return {
        isFetching: getIsFetchingSiteSurveyData(state),
        errorFetching: getErrorFetchingSiteSurveyData(state),
        byId: getSiteSurveysById(state),
        sorted: getSiteSurveysSorted(state),
    };
};

export { mapStateToProps };
