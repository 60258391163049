import React, { Component } from "react";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
}

class Checkbox extends Component<Props> {
    render() {
        const { ...props } = this.props;
        return <input type="checkbox" {...props} />;
    }
}

export default Checkbox;
