import classnames from "classnames";
import React, { PureComponent } from "react";
import MissingInfo from "../MissingInfo";
import { messages } from "../../messages";
import { RenderProp } from "../../props/render";

interface Props {
    className?: string;
    showLoading?: boolean;
    icon?: RenderProp;
    label: string;
    emptyMessage: string;
}

class PartnerFormsListSection extends PureComponent<Props> {
    render() {
        const { className, showLoading = false, icon, label, children, emptyMessage } = this.props;

        return (
            <section className={classnames("PartnerFormsListSection", className)}>
                <div className="PartnerFormsListSection-header">
                    {icon ? icon() : null}
                    <span className="PartnerFormsListSection-name">{label}</span>
                </div>
                <ul className="PartnerFormsListSection-content">
                    {showLoading ? (
                        <li className="PartnerFormsListSection-loading">{messages.notice.loading()}</li>
                    ) : !children || (Array.isArray(children) && children.length === 0) ? (
                        <li>
                            <MissingInfo message={emptyMessage} />
                        </li>
                    ) : (
                        children
                    )}
                </ul>
            </section>
        );
    }
}

export default PartnerFormsListSection;
