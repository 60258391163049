import classnames from "classnames";
import React, { ReactNode } from "react";
import BadgeGroup from "../Badge/BadgeGroup";

interface Props {
    className?: string;
    title?: string;
    kind?: "standard" | "unique" | "subdued" | "attention";
    children?: ReactNode;
}

function Badge({ children, className, title, kind = "standard" }: Props) {
    return (
        <div className={classnames("Badge", className, kind)} title={title}>
            {children}
        </div>
    );
}

Badge.Group = BadgeGroup;

export default Badge;
