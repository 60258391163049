import React, { memo } from "react";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import Value from "../../../shared/components/Value";
import { messages } from "../../../shared/messages";
import { getLabel as getDayOfWeekLabel } from "../../../shared/models/DayOfWeek";
import { getLabel as getTimeOfDayLabel } from "../../../shared/models/TimeOfDay";
import {
    OcaReturnPickupPreferences as OcaReturnPickupPreferencesModel,
    PREFERRED_DAY_OF_WEEK_KEY,
    PREFERRED_TIME_OF_DAY_KEY,
} from "../../models/OcaReturnPickupPreferences";

interface Props {
    preferences: OcaReturnPickupPreferencesModel;
}

function OcaReturnPickupPreferences({ preferences }: Props) {
    return (
        <Content title={messages.title.pickupPreferences()}>
            <Segment.Group>
                <Segment>
                    <Value inline label={messages.labels.ocaReturn.preferredDay()}>
                        {preferences[PREFERRED_DAY_OF_WEEK_KEY] !== undefined ? (
                            getDayOfWeekLabel(preferences[PREFERRED_DAY_OF_WEEK_KEY]!)
                        ) : (
                            <MissingInfo message={messages.notice.notSpecified()} />
                        )}
                    </Value>
                </Segment>
                <Segment>
                    <Value inline label={messages.labels.ocaReturn.preferredTime()}>
                        {preferences[PREFERRED_TIME_OF_DAY_KEY] !== undefined ? (
                            getTimeOfDayLabel(preferences[PREFERRED_TIME_OF_DAY_KEY]!)
                        ) : (
                            <MissingInfo message={messages.notice.notSpecified()} />
                        )}
                    </Value>
                </Segment>
            </Segment.Group>
        </Content>
    );
}

export default memo(OcaReturnPickupPreferences);
