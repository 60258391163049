import { Validator } from "../../models/Validator";
import { asInt } from "../../../utils/strings";
import { Level, createValidationError } from "../../models/ValidationError";
import { messages } from "../../messages";

export const integer: Validator = (value?: string) => {
    if (!value) {
        return;
    }
    const int = asInt(value);

    if (Number(value) === int) {
        return;
    }

    return int !== undefined && Number(value) === int
        ? undefined
        : createValidationError(Level.Invalid, messages.forms.validation.invalidInteger());
};
