import classnames from "classnames";
import React, { Component } from "react";
import ActionLink from "../../../shared/components/ActionLink";
import If from "../../../shared/components/If";
import { NETWORK_CONFIG_COPY, NETWORK_CONFIG_NAVIGATE, NETWORK_CONFIG_PASTE } from "../../../shared/events";
import logger from "../../../shared/logger";
import { messages } from "../../../shared/messages";
import { NetworkConfig } from "../../models/NetworkConfig";
import ApplianceInfo from "../ApplianceInfo";
import DirectionalNavigationControl from "./DirectionalNavigationControl";

interface Props {
    onSetActiveNetworkConfig?: (index: number) => void;
    activeIndex?: number;
    networkConfigs: readonly NetworkConfig[];
    copyableNetworkConfigIds?: Set<number>;
    onClickCopyNetworkConfig?: (id: number) => void;
}

interface State {
    clipboard?: number;
}

class ApplianceBrowser extends Component<Props, State> {
    state: State = {
        clipboard: undefined,
    };

    constructor(props: Props) {
        super(props);

        this.handleClickCopy = this.handleClickCopy.bind(this);
        this.handleClickPaste = this.handleClickPaste.bind(this);
        this.handleSetActiveNetworkConfig = this.handleSetActiveNetworkConfig.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.setState({
                clipboard: undefined,
            });
        }
    }

    render() {
        const {
            activeIndex = 0,
            networkConfigs,
            onClickCopyNetworkConfig,
            copyableNetworkConfigIds = new Set(),
        } = this.props;
        const { clipboard } = this.state;
        const showCopyPasteActions = networkConfigs.length > 1 && onClickCopyNetworkConfig !== undefined;

        return (
            <div className={classnames("ApplianceBrowser", { many: networkConfigs.length > 1 })}>
                <If condition={networkConfigs.length > 1}>
                    {() => (
                        <DirectionalNavigationControl
                            direction="left"
                            onClick={() => this.handleSetActiveNetworkConfig(Math.max(0, activeIndex - 1))}
                            disabled={activeIndex === 0}
                        />
                    )}
                </If>
                <div className={classnames("ApplianceBrowser-list", { many: networkConfigs.length > 1 })}>
                    {networkConfigs.map((networkConfig, idx) => {
                        const active = activeIndex === idx;
                        return (
                            <div className={classnames("ApplianceBrowser-listItem", { active })} key={networkConfig.id}>
                                <ApplianceInfo
                                    label={`${messages.entity.oca({ COUNT: 1 })} #${idx + 1}`}
                                    networkConfig={networkConfig}
                                    onClick={() => this.handleSetActiveNetworkConfig(idx)}
                                />
                                {showCopyPasteActions ? (
                                    active ? (
                                        <ActionLink
                                            className="ApplianceBrowser-actionLink"
                                            icon="paste"
                                            disabled={!clipboard}
                                            onClick={this.handleClickPaste}
                                            label={messages.actions.paste()}
                                        />
                                    ) : (
                                        <ActionLink
                                            className="ApplianceBrowser-actionLink"
                                            icon={["far", "copy"]}
                                            disabled={!copyableNetworkConfigIds.has(networkConfig.id)}
                                            onClick={() => this.handleClickCopy(networkConfig.id)}
                                            label={messages.actions.copy()}
                                        />
                                    )
                                ) : null}
                            </div>
                        );
                    })}
                </div>
                <If condition={networkConfigs.length > 1}>
                    {() => (
                        <DirectionalNavigationControl
                            direction="right"
                            onClick={() =>
                                this.handleSetActiveNetworkConfig(Math.min(networkConfigs.length - 1, activeIndex + 1))
                            }
                            disabled={activeIndex >= networkConfigs.length - 1}
                        />
                    )}
                </If>
            </div>
        );
    }

    private handleSetActiveNetworkConfig(nextIndex: number) {
        const { onSetActiveNetworkConfig, activeIndex } = this.props;
        logger.trackEvent(NETWORK_CONFIG_NAVIGATE, {
            currentIndex: activeIndex,
            nextIndex,
        });
        if (onSetActiveNetworkConfig) {
            onSetActiveNetworkConfig(nextIndex);
        }
    }

    private handleClickCopy(id: number) {
        logger.trackEvent(NETWORK_CONFIG_COPY);
        this.setState({
            clipboard: id,
        });
    }

    private handleClickPaste() {
        const { onClickCopyNetworkConfig } = this.props;
        const { clipboard } = this.state;

        if (onClickCopyNetworkConfig && clipboard !== undefined) {
            logger.trackEvent(NETWORK_CONFIG_PASTE);
            onClickCopyNetworkConfig(clipboard);
            this.setState({
                clipboard: undefined,
            });
        }
    }
}

export default ApplianceBrowser;
