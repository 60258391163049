import React, { memo, useCallback } from "react";
import { Contact, ContactNew } from "../../models/Contact";
import { ContactFields } from "../../models/ContactFields";
import { ContactRoleDescriptor } from "../../models/ContactRoleDescriptor";
import { Contacts } from "../../models/Contacts";
import { Dictionary } from "../../models/Dictionary";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { traverse } from "../../validation";
import ChooseOrCreateContact from "../ChooseOrCreateContact";
import CopyContacts from "../CopyContacts";

interface Props {
    orgId: number;
    chooseFrom: (Contact | ContactFields)[];
    roleDescriptors: ContactRoleDescriptor[];
    required: Set<string>;
    initialContacts: Contacts;
    contacts?: Contacts;
    isMeechumUser?: boolean;
    onSelectContactForRole: (contact: ContactFields | undefined, role: string) => void;
    onChangeContactForRole: (contact: Partial<ContactFields>, role: string) => void;
    validationErrors?: ModelValidationErrors<Dictionary<ContactFields>>;
    /**
     * Use this to set the value of certain fields of a contact form for a
     * specific role and hide those fields from the user.
     */
    omitFields: Partial<Record<string, Set<keyof ContactFields>>>;
}

function ContactsEditor(props: Props) {
    const {
        chooseFrom,
        initialContacts,
        contacts,
        isMeechumUser,
        validationErrors,
        orgId,
        roleDescriptors,
        required,
        omitFields = {},
        onChangeContactForRole,
        onSelectContactForRole,
    } = props;

    const handleChangeContactForRole = useCallback(
        (contact: Partial<ContactFields>, role: string) => {
            onChangeContactForRole(contact, role);
        },
        [onChangeContactForRole]
    );

    const handleSelectContactForRole = useCallback(
        (contact: ContactFields | undefined, role: string) => {
            onSelectContactForRole(contact, role);
        },
        [onSelectContactForRole]
    );

    return (
        <div className="ContactsEditor-contactList">
            <CopyContacts contacts={contacts} />
            {roleDescriptors.map(({ key, label, description }) => {
                const contact: ContactNew | Contact | undefined = initialContacts[key];

                return (
                    <ChooseOrCreateContact
                        key={key}
                        required={required.has(key)}
                        label={label}
                        description={description}
                        orgId={orgId}
                        showRoles={false}
                        role={key}
                        chooseFrom={chooseFrom}
                        initialContact={contact}
                        contact={contacts?.[key]}
                        isMeechumUser={isMeechumUser}
                        onSelect={(contact) => handleSelectContactForRole(contact, key)}
                        onEdit={(contact) => handleChangeContactForRole(contact, key)}
                        validationErrors={traverse(validationErrors, key)}
                        omitFields={omitFields[key]}
                    />
                );
            })}
        </div>
    );
}

export default memo(ContactsEditor);
