import React, { Component, ComponentType, FunctionComponent } from "react";
import auth from "../../services/auth";
import { User } from "../../services/auth/models/User";

export interface WithUser {
    user: User | null;
}

export const withUser = <P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P & WithUser> | FunctionComponent<P & WithUser>
): ComponentType<P> => {
    return class extends Component<P> {
        unsubscribe?: () => void;

        componentDidMount() {
            this.unsubscribe = auth.subscribe(() => this.forceUpdate());
        }

        componentWillUnmount() {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
        }

        render() {
            return <WrappedComponent user={auth.user} {...this.props} />;
        }
    };
};
