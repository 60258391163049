import React, { Fragment, memo } from "react";
import ReactMarkdown from "react-markdown";
import Link from "../Link";

interface Props {
    source: string;
    /**
     * Whether or not links are "external". External links open in new tabs and
     * are displayed differently. Defaults to `true`.
     */
    isExternal: boolean;
}

/**
 * Renders markdown. All anchor tags are rendered as `Link`s.
 */
function Markdown({ source, isExternal }: Props) {
    return (
        <ReactMarkdown
            source={source}
            escapeHtml={false}
            linkTarget={isExternal ? "_blank" : undefined}
            renderers={{
                // Get rid of the wrapping paragraph tag. This renders all
                // paragraphs as fragments though...not sure what alternative
                // workaround exists.
                paragraph: (props: any) => <Fragment {...props} />,
                link: (props: any) => <Link isExternal={isExternal} {...props} />,
            }}
        />
    );
}

Markdown.defaultProps = {
    isExternal: true,
};

export default memo(Markdown);
