import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppState } from "../../app/models/AppState";
import auth from "../../services/auth";
import { APIKeyCredentials } from "../../services/auth/models/APIKeyCredentials";
import topgear from "../../services/topgear";
import { matchesAny } from "../../utils/actions";
import { authActions } from "../reducer";

export class AuthMiddleware {
    get middleware(): Middleware<Record<string, unknown>, AppState> {
        return (api: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: AnyAction) => {
            next(action);

            if (authActions.login.do.match(action)) {
                this.login(api, action.payload.email, action.payload.code);
            }

            if (authActions.login.fail.match(action)) {
                this.clearUser(false);
            }

            if (matchesAny(action, [authActions.unauthorized, authActions.logout])) {
                this.clearUser(true);
            }
        };
    }

    private async login(api: MiddlewareAPI<Dispatch, AppState>, email: string, apiKey: string) {
        const credentials: APIKeyCredentials = {
            email,
            apiKey,
        };

        try {
            const info = await topgear.authenticate(credentials);
            auth.updateApiKeyUser(credentials, info);
        } catch (error) {
            api.dispatch(authActions.login.fail({ error }));
        }
    }

    /**
     * Set the user to null and clear any session data. Optionally sign out and
     * redirecting to login, thereby refreshing the page and clearing all Redux
     * state.
     */
    private clearUser(signOut: boolean) {
        if (signOut) {
            auth.signOut();
        } else {
            auth.updateUser(null);
        }
    }
}

export default new AuthMiddleware().middleware;
