import React, { FormEvent, useCallback } from "react";
import Button from "../../../shared/components/Button";
import Form from "../../../shared/components/Form";
import FormActions from "../../../shared/components/Form/FormActions";
import FormField from "../../../shared/components/FormField";
import TextInput from "../../../shared/components/TextInput";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { FormPropsV2 } from "../../../shared/props/forms";
import { CODE_KEY, EMAIL_KEY, LoginFields, LOGIN_KEYS } from "../../models/LoginFields";

export interface Props extends FormPropsV2<LoginFields> {}

function LoginForm(props: Props) {
    const { onSubmit, isSubmitting, compact = false } = props;
    const [{ pristine, valid, errors, values }, { touchHandlers, changeHandlers }] = useForm(
        { keys: LOGIN_KEYS, atomic: true },
        props
    );

    const handleSubmit = useCallback(
        (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );

    return (
        <Form className="LoginForm" onSubmit={handleSubmit} compact={compact}>
            <FormField
                name={EMAIL_KEY}
                required
                type="email"
                label={messages.labels.login.email()}
                error={errors.get(EMAIL_KEY)}
            >
                <TextInput
                    placeholder={messages.forms.placeholders.email()}
                    value={values[EMAIL_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[EMAIL_KEY]?.(evt.target.value)}
                    error={errors.has(EMAIL_KEY)}
                    onBlur={() => touchHandlers[EMAIL_KEY]?.()}
                />
            </FormField>
            <FormField name={CODE_KEY} required label={messages.labels.login.code()} error={errors.get(CODE_KEY)}>
                <TextInput
                    placeholder={messages.forms.placeholders.code()}
                    maxLength={36}
                    value={values[CODE_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[CODE_KEY]?.(evt.target.value)}
                    error={errors.has(CODE_KEY)}
                    onBlur={() => touchHandlers[CODE_KEY]?.()}
                />
            </FormField>
            <FormActions>
                <Button
                    type="submit"
                    kind="action"
                    loading={isSubmitting}
                    disabled={!valid || pristine || isSubmitting}
                >
                    {messages.actions.signIn()}
                </Button>
            </FormActions>
        </Form>
    );
}

export default LoginForm;
