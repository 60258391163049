import { Authorization } from "./Authorization";
import { APIKeyCredentials } from "../../auth/models/APIKeyCredentials";

export const ApiKeyUserKind = "api";
export const MeechumUserKind = "meechum";
export type UserKind = typeof ApiKeyUserKind | typeof MeechumUserKind;

/**
 * NOTE: This is persisted in local storage, so breaking changes to the
 * interface should not be made.
 */
export interface ApiKeyUser extends APIKeyCredentials, Authorization {
    kind: typeof ApiKeyUserKind;
}

export interface MeechumUser {
    kind: typeof MeechumUserKind;
    accessToken: string;
    userInfo?: {
        email?: string;
    };
}

export type User = MeechumUser | ApiKeyUser;

export function checkIsUser(user: User | APIKeyCredentials): user is User {
    return user.hasOwnProperty("kind");
}

export function checkIsAPIKeyUser(user: User | null): user is ApiKeyUser {
    return user?.kind === ApiKeyUserKind;
}

export function checkIsMeechumUser(user: User | null): user is MeechumUser {
    return user?.kind === MeechumUserKind;
}
