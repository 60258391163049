import classnames from "classnames";
import React, { memo, useCallback, ReactNode } from "react";
import Icon, { Props as IconProps } from "../Icon";
import Link from "../Link";

interface Props {
    icon?: IconProps["icon"];
    className?: string;
    onClick: () => void;
    label?: string;
    disabled?: boolean;
    withBackground?: boolean;
    children?: ReactNode;
    isActive?: boolean;
}

/**
 * An action link is an anchor tag that performs some effect other than
 * navigation when interacted with.
 */
function ActionLink({
    icon,
    label,
    disabled = false,
    className,
    onClick,
    withBackground = true,
    children,
    isActive = false
}: Props) {
    const handleOnClick = useCallback(() => {
        if (disabled) {
            return;
        }

        onClick();
    }, [disabled, onClick]);

    return (
        <Link
            className={classnames("ActionLink", className, { disabled, withBackground })}
            onClick={handleOnClick}
            isActive={isActive}
        >
            {icon && <Icon icon={icon} />}
            {label && <span className="ActionLink-text">{label}</span>}
            {children}
        </Link>
    );
}

export default memo(ActionLink);
