import { MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import {
    getErrorFetchingPartOrderData,
    getIsFetchingPartOrderData,
    getPartOrdersById,
    getPartOrdersSorted
} from "../../reducer/selectors";
import { Props } from "./PartOrders";

export type StateProps = Pick<Props, "isFetching" | "errorFetching" | "byId">;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state) => {
    return {
        isFetching: getIsFetchingPartOrderData(state),
        errorFetching: getErrorFetchingPartOrderData(state),
        byId: getPartOrdersById(state),
        sorted: getPartOrdersSorted(state)
    };
};

export { mapStateToProps };
