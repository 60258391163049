import React, { useCallback, useState } from "react";
import { AuthService } from "../../../services/auth";
import { UserContext } from "../../contexts/UserContext";
import { EMAIL_WORK_KEY } from "../../models/ContactFields";
import { Contacts } from "../../models/Contacts";
import Button from "../Button";
import Icon from "../Icon";

// NOTE: This will never show when running locally.
// Browsers do not add `clipboard` to the navigator unless serving over HTTPS
const CopyContacts = ({ contacts }: { contacts: Contacts | undefined }) => {
    const [copied, setCopied] = useState(false);
    const allContacts = Object.values(contacts ?? {})
        .map((c) => c?.[EMAIL_WORK_KEY])
        .filter((e) => !!e)
        .join(", ");

    const doCopy = useCallback(() => {
        navigator.clipboard.writeText(allContacts).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        });
    }, []);

    return (
        <UserContext.Consumer>
            {(user) => {
                const isAdmin = AuthService.checkIsAdmin(user);
                if (!isAdmin || !contacts || !navigator.clipboard) {
                    return null;
                }

                return (
                    <div style={{ marginBottom: 8, textAlign: "right" }}>
                        {copied && (
                            <Button size="small">
                                <Icon icon="check" size="1x" />
                                <span style={{ marginLeft: 8 }}> Copied!</span>
                            </Button>
                        )}
                        {!copied && (
                            <Button size="small" onClick={doCopy}>
                                <Icon icon="clipboard-check" size="1x" />
                                <span style={{ marginLeft: 8 }}> Copy contacts to clipboard</span>
                            </Button>
                        )}
                    </div>
                );
            }}
        </UserContext.Consumer>
    );
};

export default CopyContacts;
