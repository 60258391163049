import { ComponentType } from "../../partOrders/models/ComponentType";
import { getMessage, messages } from "../messages";
import { OcaClass } from "./OcaClass";

export const OPTICS_TYPE_KEY = "opticsTypePartDefinition";
export const PATCH_CABLE_TYPE_KEY = "patchCableTypePartDefinition";
export const RACK_TYPE_KEY = "rackTypePartDefinition";
export const PSU_TYPE_KEY = "powerTypePartDefinition";
export const POWER_CABLE_TYPE_KEY = "cableTypePartDefinition";

const DATACENTER_KEY_TO_COMPONENT_TYPE: Map<keyof DatacenterFields, ComponentType> = new Map([
    [OPTICS_TYPE_KEY, ComponentType.Optics],
    [PATCH_CABLE_TYPE_KEY, ComponentType.PatchCable],
    [RACK_TYPE_KEY, ComponentType.Rack],
    [PSU_TYPE_KEY, ComponentType.Psu],
    [POWER_CABLE_TYPE_KEY, ComponentType.PowerCable],
]);

const COMPONENT_TYPE_TO_DATACENTER_KEY: Map<ComponentType, keyof DatacenterFields> = new Map(
    [...DATACENTER_KEY_TO_COMPONENT_TYPE.entries()].map(
        ([key, value]) => [value, key] as [ComponentType, keyof DatacenterFields]
    )
);

export const DATACENTER_KEYS: (keyof Datacenter)[] = [
    OPTICS_TYPE_KEY,
    PATCH_CABLE_TYPE_KEY,
    RACK_TYPE_KEY,
    PSU_TYPE_KEY,
    POWER_CABLE_TYPE_KEY,
];

/**
 * To facilitate working with this model using forms, we only store the ID
 * instead of the entire PartDefinition.
 */
export interface DatacenterFields {
    [OPTICS_TYPE_KEY]?: number;
    [PATCH_CABLE_TYPE_KEY]?: number;
    [RACK_TYPE_KEY]?: number;
    [PSU_TYPE_KEY]?: number;
    [POWER_CABLE_TYPE_KEY]?: number;
}

export interface Datacenter extends DatacenterFields {}

export function getLabel(key: keyof DatacenterFields): string {
    return getMessage(() => messages.labels.site[key](null), key);
}

/**
 * Get OCA class specific notes, or if not present, get generic notes, for the
 * given datacenter field.
 * @param key
 * @param ocaClass
 */
export function getNotes(key: keyof DatacenterFields, ocaClass: OcaClass): string {
    return getMessage([() => messages.notes.site[`${key}.${ocaClass}`](), () => messages.notes.site[key]()], "");
}

export function getComponentType(key: keyof DatacenterFields): ComponentType {
    return DATACENTER_KEY_TO_COMPONENT_TYPE.get(key)!;
}

export function getKey(componentType: ComponentType): keyof DatacenterFields {
    return COMPONENT_TYPE_TO_DATACENTER_KEY.get(componentType)!;
}
