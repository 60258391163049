import React, { memo, ReactNode, useCallback, useEffect } from "react";
import { messages } from "../../messages";

interface Props {
    isUnsaved?: boolean;
    children?: ReactNode;
}

/**
 * This component does not render anything. It warns user of unsaved changes
 * when the document is being unloaded (e.g. as a result of closing tab,
 * reloading page, etc). Use `isUnsaved` to control if this check should be
 * performed, otherwise does nothing.
 */
function Unsaved({ children, isUnsaved }: Props) {
    const handleWarnUnsavedChanges = useCallback(
        (evt: BeforeUnloadEvent): any => {
            if (isUnsaved) {
                evt.preventDefault();
                // NOTE: Not all browsers respect this message. Many show a
                // generic message along the lines of "your changes may not be
                // saved".
                // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
                const warningMessage = messages.notice.unsavedChanges();
                // This contract varies by browser. Some require setting
                // `returnValue` to prompt the user for confirmation. Others
                // check for a return value.
                evt.returnValue = warningMessage;
                return warningMessage;
            }
        },
        [isUnsaved]
    );

    useEffect(() => {
        window.addEventListener("beforeunload", handleWarnUnsavedChanges);
        return () => window.removeEventListener("beforeunload", handleWarnUnsavedChanges);
    }, [handleWarnUnsavedChanges]);

    // Fragment workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
    return <>{children}</>;
}

export default memo(Unsaved);
