import classnames from "classnames";
import React from "react";
import { FieldProps } from "../../props/forms";

export interface Props extends FieldProps, React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    inverted?: boolean;
}

function TextArea({ inverted, className, error, dirty, ...props }: Props) {
    return (
        <textarea
            className={classnames("TextArea", className, {
                error,
                dirty,
            })}
            {...props}
        />
    );
}

export default TextArea;
