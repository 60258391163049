import { messages, getMessage } from "../../shared/messages";

export enum PartOrderView {
    Parts = "parts",
    Shipping = "shipping",
    More = "more"
}

export function title(view: PartOrderView) {
    return getMessage(() => messages.title.partOrderView[view](null), view);
}
