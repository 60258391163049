import React, { memo } from "react";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import Value from "../../../shared/components/Value";
import { messages } from "../../../shared/messages";
import { getLabel } from "../../../shared/models/Shipper";
import {
    BOXED_KEY,
    FREQUENT_SHIPPER_KEY,
    LIFT_GATE_REQUIRED_KEY,
    OcaReturnPickupProperties as OcaReturnPickupPropertiesModel,
} from "../../models/OcaReturnPickupProperties";

interface Props {
    properties: OcaReturnPickupPropertiesModel;
}

function OcaReturnPickupProperties({ properties }: Props) {
    return (
        <Content title={messages.title.pickupProperties()}>
            <Segment.Group>
                <Segment>
                    <Value inline label={messages.labels.ocaReturn.boxed()}>
                        {properties[BOXED_KEY] !== undefined ? (
                            properties[BOXED_KEY] ? (
                                "Yes"
                            ) : (
                                "No"
                            )
                        ) : (
                            <MissingInfo message={messages.notice.notSpecified()} />
                        )}
                    </Value>
                </Segment>
                <Segment>
                    <Value inline label={messages.labels.ocaReturn.liftGate()}>
                        {properties[LIFT_GATE_REQUIRED_KEY] !== undefined ? (
                            properties[LIFT_GATE_REQUIRED_KEY] ? (
                                "Yes"
                            ) : (
                                "No"
                            )
                        ) : (
                            <MissingInfo message={messages.notice.notSpecified()} />
                        )}
                    </Value>
                </Segment>
                <Segment>
                    <Value inline label={messages.labels.ocaReturn.frequentShipper()}>
                        {properties[FREQUENT_SHIPPER_KEY] ? (
                            getLabel(properties[FREQUENT_SHIPPER_KEY]!)
                        ) : (
                            <MissingInfo message={messages.notice.notSpecified()} />
                        )}
                    </Value>
                </Segment>
            </Segment.Group>
        </Content>
    );
}

export default memo(OcaReturnPickupProperties);
