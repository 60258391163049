import React, { PureComponent } from "react";
import { messages } from "../../messages";
import { RenderProp } from "../../props/render";
import DateDisplay from "../DateDisplay";
import Grid from "../Grid";
import Meta from "../Meta";
import Segment from "../Segment";

export interface Props {
    lastUpdated?: Date;
    submittedOn?: Date;
    additionalInfo?: {
        label: string;
        render: RenderProp;
    };
    accessory?: {
        label: string;
        render: RenderProp;
    };
}

/**
 * Displays some relevant information about a partner form.
 */
class PartnerFormInfo extends PureComponent<Props> {
    render() {
        const { additionalInfo, accessory } = this.props;

        return (
            <Grid className="PartnerFormInfo" stackable>
                <Grid.Column units={2}>
                    <Segment.Group horizontal={!accessory}>
                        {this.renderLastUpdated()}
                        {this.renderSubmittedOn()}
                        {additionalInfo && (
                            <Segment>
                                <Meta>{additionalInfo.label}</Meta>
                                {additionalInfo.render()}
                            </Segment>
                        )}
                    </Segment.Group>
                </Grid.Column>
                {accessory ? (
                    <Grid.Column units={5}>
                        <Meta>{accessory.label}</Meta>
                        <div className="PartnerFormInfo-accessory">{accessory.render()}</div>
                    </Grid.Column>
                ) : null}
            </Grid>
        );
    }

    private renderLastUpdated() {
        const { lastUpdated } = this.props;
        return (
            lastUpdated !== undefined && (
                <Segment className="lastUpdated">
                    <Meta>{messages.labels.lastUpdated()}</Meta>
                    <DateDisplay relative date={lastUpdated} />
                </Segment>
            )
        );
    }

    private renderSubmittedOn() {
        const { submittedOn } = this.props;
        return (
            submittedOn !== undefined && (
                <Segment className="submittedOn">
                    <Meta>{messages.labels.submitted()}</Meta>
                    <DateDisplay relative date={submittedOn} />
                </Segment>
            )
        );
    }
}

export default PartnerFormInfo;
