import { AxiosError } from "axios";
import {
    UnauthorizedError,
    BadRequestError,
    ServerError,
    RequestError,
    NotFoundError,
    ConflictError,
    ForbiddenError,
    ServiceUnavailableError
} from "../shared/models/RequestError";
import { isAxiosError } from "./guards";

/**
 * Extracts the list of errors from an AxiosError.
 * @param error
 */
export function getResponseErrors(error: Error): string[] | undefined {
    if (isAxiosError(error)) {
        if (error.response && error.response.data && Array.isArray(error.response.data.errors)) {
            return error.response.data.errors;
        }
    }

    return;
}

/**
 * Given that an AxiosError includes a list of errors in the response, returns
 * them as a newline-delimited string.
 * @param error
 */
export function getResponseErrorsAsMessage(error: Error): string | undefined {
    const responseErrors = getResponseErrors(error);
    if (responseErrors && responseErrors.length) {
        return responseErrors.join("\n");
    }

    return;
}

export function makeRequestError(error: AxiosError): RequestError {
    let requestError: RequestError = new RequestError();

    if (error.response) {
        const { url, method } = error.config;
        const message = getResponseErrorsAsMessage(error);

        switch (error.response.status) {
            case 400:
                requestError = new BadRequestError(url, method, message);
                break;
            case 401:
                requestError = new UnauthorizedError(url, method, message);
                break;
            case 403:
                requestError = new ForbiddenError(url, method, message);
                break;
            case 404:
                requestError = new NotFoundError(url, method, message);
                break;
            case 409: {
                const conflictError = new ConflictError(url, method, message);
                if (Array.isArray(error.response.data?.errors)) {
                    conflictError.constraintViolations = error.response.data?.errors;
                }
                requestError = conflictError;
                break;
            }
            case 500:
                requestError = new ServerError(url, method, message);
                break;
            case 503:
                requestError = new ServiceUnavailableError(url, method, message);
                break;
            default:
                break;
        }

        requestError.response = error.response;
    }

    return requestError;
}
