import { uniq } from "lodash";
import { createSelector, ParametricSelector, Selector } from "reselect";
import { AppState } from "../../app/models/AppState";
import {
    getActiveOrg,
    getFetchActiveOrgRequestState,
    getFetchCountriesRequestState,
    getOrgAsns,
    getSiteSurveysState,
} from "../../app/reducer/selectors";
import { AddressVerificationResult } from "../../services/topgear/models/AddressVerificationResult";
import { Address } from "../../shared/models/Address";
import { AddressFields } from "../../shared/models/AddressFields";
import { AllowedParts } from "../../shared/models/AllowedParts";
import {
    anyPending,
    ApiRequestCollectionState,
    ApiRequestState,
    firstError,
} from "../../shared/models/ApiRequestState";
import { Changeset } from "../../shared/models/Changeset";
import { ContactFields } from "../../shared/models/ContactFields";
import { Dictionary } from "../../shared/models/Dictionary";
import { ModelValidationErrors } from "../../shared/models/ModelValidationErrors";
import { OcaClass } from "../../shared/models/OcaClass";
import { getHighestErrorLevel, Level, ValidationError } from "../../shared/models/ValidationError";
import { list } from "../../shared/validation";
import { SiteSurvey } from "../../siteSurveys/models/SiteSurvey";
import { SiteSurveyView } from "../../siteSurveys/models/SiteSurveyView";
import { applyChangeset } from "../../utils/change";
import { dateOrdering } from "../../utils/compares";
import { applyPartsRestrictions } from "../../utils/partDefinition";
import {
    collectAddresses,
    collectContacts,
    extractAdditionalRecipientEmails,
    rollUpErrorsByView,
} from "../../utils/partnerForm";
import { EMPTY_ARRAY, EMPTY_DATACENTER_OPTIONS, EMPTY_OBJECT } from "../../utils/selectors";
import { attributeSiteSurveyErrorToView, checkAreSiteSurveysDifferent, checkIsSubmitted } from "../../utils/siteSurvey";
import { BGP_ASN_KEY } from "../models/BgpConfig";
import { DatacenterOptions } from "../models/DatacenterOptions";
import { SiteSurveyUiState } from "../models/SiteSurveyUiState";

export const getFetchSiteSurveysRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getSiteSurveysState],
    (state) => state.fetch
);

export const getSaveSiteSurveyRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getSiteSurveysState],
    (state) => state.save
);

export const getSubmitSiteSurveyRequestState: Selector<AppState, ApiRequestState> = createSelector(
    [getSiteSurveysState],
    (state) => state.submit
);

export const getDownloadAttachmentRequestStates: Selector<AppState, ApiRequestCollectionState> = createSelector(
    [getSiteSurveysState],
    (state) => state.downloadAttachments
);

export const getFetchAllowedPartsRequestStates: Selector<
    AppState,
    ApiRequestCollectionState<AllowedParts | undefined>
> = createSelector([getSiteSurveysState], (state) => state.fetchAllowedParts);

export const getShippingAddressSuggestionsRequestStates: Selector<
    AppState,
    ApiRequestCollectionState<Address[]>
> = createSelector([getSiteSurveysState], (state) => state.fetchShippingAddressSuggestions);

export const getAddressVerificationsStates: Selector<
    AppState,
    ApiRequestCollectionState<AddressVerificationResult[] | undefined>
> = createSelector([getSiteSurveysState], (state) => state.verifyAddress);

export const getFetchDatacenterOptionsRequestState: Selector<
    AppState,
    ApiRequestState<DatacenterOptions | undefined>
> = createSelector([getSiteSurveysState], (state) => state.fetchDatacenterOptions);

export const getDatacenterOptions: Selector<AppState, DatacenterOptions | undefined> = createSelector(
    [getFetchDatacenterOptionsRequestState],
    ({ value }) => value
);

export const getSiteSurveysById: Selector<AppState, Dictionary<SiteSurvey> | undefined> = createSelector(
    [getSiteSurveysState],
    (state) => state.byId
);

export const getSiteSurveyEditsById: Selector<AppState, Dictionary<Changeset>> = createSelector(
    [getSiteSurveysState],
    (state) => state.edits
);

export const getSiteSurveyUIStatesById: Selector<AppState, Dictionary<SiteSurveyUiState>> = createSelector(
    [getSiteSurveysState],
    (state) => state.ui
);

export const getSiteSurveyValidationsById: Selector<
    AppState,
    Dictionary<ModelValidationErrors<SiteSurvey>>
> = createSelector([getSiteSurveysState], (state) => state.validations);

export const getSiteSurvey: ParametricSelector<AppState, number, SiteSurvey | undefined> = (state, id) => {
    const byId = getSiteSurveysById(state);
    return byId && byId[id];
};

export const getSiteSurveyUiState: ParametricSelector<AppState, number, SiteSurveyUiState | undefined> = (
    state,
    id
) => {
    const byId = getSiteSurveyUIStatesById(state);
    return byId[id];
};

export const getSiteSurveyErrors: ParametricSelector<AppState, number, ModelValidationErrors<SiteSurvey>> = (
    state,
    id
) => getSiteSurveyValidationsById(state)[id] || EMPTY_OBJECT;

export const getSiteSurveyErrorsList: ParametricSelector<AppState, number, ValidationError[]> = createSelector(
    [getSiteSurveyErrors],
    list
);

export const getSiteSurveyErrorsByView: ParametricSelector<
    AppState,
    number,
    { [view in SiteSurveyView]?: ValidationError }
> = createSelector([getSiteSurveyErrorsList], (errors) => rollUpErrorsByView(errors, attributeSiteSurveyErrorToView));

export const getSiteSurveyChangeset: ParametricSelector<AppState, number, Changeset> = (state, id) =>
    getSiteSurveyEditsById(state)[id] ?? EMPTY_ARRAY;

export const getShippingAddressSuggestions: ParametricSelector<AppState, number, readonly Address[]> = (state, id) =>
    getShippingAddressSuggestionsRequestStates(state)[id]?.value ?? EMPTY_ARRAY;

export const getAddressVerificationsState: ParametricSelector<
    AppState,
    number,
    ApiRequestState<AddressVerificationResult[] | undefined> | undefined
> = (state, id) => getAddressVerificationsStates(state)[id];

export const getIsFetchingSiteSurveyData: Selector<AppState, boolean> = createSelector(
    [
        getFetchSiteSurveysRequestState,
        getFetchCountriesRequestState,
        getFetchActiveOrgRequestState,
        getFetchDatacenterOptionsRequestState,
    ],
    anyPending
);

export const getErrorFetchingSiteSurveyData: Selector<AppState, string | Error | null> = createSelector(
    [
        getFetchSiteSurveysRequestState,
        getFetchCountriesRequestState,
        getFetchActiveOrgRequestState,
        getFetchDatacenterOptionsRequestState,
    ],
    firstError
);

export const getSiteSurveysSorted: Selector<AppState, SiteSurvey[] | undefined> = createSelector(
    [getSiteSurveysById],
    (byId) =>
        byId &&
        Object.values(byId)
            .filter((siteSurvey): siteSurvey is SiteSurvey => !!siteSurvey)
            .sort((a, b) => dateOrdering(a.dateCreated, b.dateCreated, true))
);

export const getSiteSurveyIds: Selector<AppState, number[] | undefined> = createSelector(
    [getSiteSurveysSorted],
    (siteSurveys) => siteSurveys && siteSurveys.map(({ id }) => id)
);

export const getOpenSiteSurveyIds: Selector<AppState, number[] | undefined> = createSelector(
    [getSiteSurveysSorted],
    (siteSurveys) =>
        siteSurveys && siteSurveys.filter((siteSurvey) => !checkIsSubmitted(siteSurvey)).map(({ id }) => id)
);

export const getAsns: ParametricSelector<AppState, number, readonly number[]> = createSelector(
    [getOrgAsns, getSiteSurvey],
    (asns, siteSurvey) => {
        if (siteSurvey && siteSurvey[BGP_ASN_KEY]) {
            return uniq([...asns, siteSurvey[BGP_ASN_KEY]]).sort((a, b) => a - b);
        }
        return asns;
    }
);

export const getSiteSurveyModified: ParametricSelector<AppState, number, SiteSurvey | undefined> = createSelector(
    [getSiteSurvey, getSiteSurveyChangeset],
    (siteSurvey, changeset) => {
        if (siteSurvey) {
            return applyChangeset(siteSurvey, changeset);
        }
        return;
    }
);

export const getSiteSurveyIsDirty: ParametricSelector<AppState, number, boolean> = createSelector(
    [getSiteSurvey, getSiteSurveyModified],
    (siteSurvey, modified) => checkAreSiteSurveysDifferent(siteSurvey, modified)
);

export const getSiteSurveyIsValid: ParametricSelector<AppState, number, boolean> = createSelector(
    [getSiteSurveyErrorsList],
    (errors) => (errors.length ? getHighestErrorLevel(errors) !== Level.Invalid : true)
);

export const getSiteSurveyIsComplete: ParametricSelector<AppState, number, boolean> = createSelector(
    [getSiteSurveyErrorsList],
    (errors) => !errors.length
);

export const getContacts: ParametricSelector<AppState, number, ContactFields[]> = createSelector(
    [getActiveOrg, getSiteSurvey, getSiteSurveyModified],
    (org, _siteSurvey) => collectContacts(org ?? undefined)
);

export const getShippingAddresses: ParametricSelector<AppState, number, AddressFields[]> = createSelector(
    [getShippingAddressSuggestions, getSiteSurvey, getSiteSurveyModified],
    (suggestions, _siteSurvey, _modified) => collectAddresses(...suggestions)
);

export const getInitialAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getSiteSurvey],
    (siteSurvey) => extractAdditionalRecipientEmails(siteSurvey)
);

export const getAdditionalRecipients: ParametricSelector<AppState, number, readonly string[]> = createSelector(
    [getSiteSurvey, getSiteSurveyModified],
    (siteSurvey, modified) => extractAdditionalRecipientEmails(modified ?? siteSurvey)
);

export const getAllowedParts: ParametricSelector<AppState, number, AllowedParts | undefined> = (state, id) => {
    const allowedPartsRequestStates = getFetchAllowedPartsRequestStates(state);
    return allowedPartsRequestStates?.[id]?.value;
};

export const checkIsDeterminingAllowedParts: ParametricSelector<AppState, number, boolean> = (state, id) => {
    const allowedPartsRequestStates = getFetchAllowedPartsRequestStates(state);
    return allowedPartsRequestStates?.[id]?.pending ?? false;
};

/**
 * Returns datacenter options with component rules applied.
 */
export const getAllowedDatacenterOptions: ParametricSelector<AppState, number, DatacenterOptions> = (state, id) => {
    const datacenterOptions = getDatacenterOptions(state);
    const allowedParts = getAllowedParts(state, id);

    if (!datacenterOptions) {
        return EMPTY_DATACENTER_OPTIONS;
    }

    return applyPartsRestrictions(datacenterOptions, allowedParts);
};

export const getSiteSurveyOcaClass: ParametricSelector<AppState, number, OcaClass> = createSelector(
    [getSiteSurvey],
    // We assume that there is exactly one OCA class per site survey.
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    (siteSurvey) => siteSurvey?.siteSurveyHardwares?.map((ssh) => ssh.hardwareCategory.ocaClass)?.[0]!
);
