import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../models/AppState";
import { appActions } from "../../reducer";
import { getFetchOrgsRequestState, getOrgs } from "../../reducer/selectors";
import { Props } from "./OrgPicker";

type StateProps = Pick<Props, "orgs" | "error">;
type DispatchProps = Pick<Props, "fetchOrgs">;
type OwnProps = Omit<Props, keyof StateProps | keyof DispatchProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = state => {
    return {
        orgs: getOrgs(state),
        error: getFetchOrgsRequestState(state).error
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = dispatch => {
    return {
        fetchOrgs: () => dispatch(appActions.fetchOrgs.do())
    };
};

export { mapStateToProps, mapDispatchToProps };
