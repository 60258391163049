import { debounce } from "lodash";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppState } from "../../app/models/AppState";
import topgear from "../../services/topgear";
import RequestMiddleware from "../../shared/models/RequestMiddleware";
import { ID_KEY } from "../../shared/models/Resource";
import { VALIDATION_DEBOUNCE_DURATION_MS } from "../../shared/settings";
import { validateOcaReturn } from "../../shared/validation/ocaReturn";
import { matchesAny } from "../../utils/actions";
import { OcaReturnReference } from "../models/OcaReturnReference";
import { ocaReturnsActions } from "../reducer";
import { getOcaReturn, getOcaReturnIsDirty, getOcaReturnModified } from "../reducer/selectors";

export class OcaReturnsMiddleware extends RequestMiddleware<Dispatch, AppState> {
    constructor() {
        super();

        this.validateOcaReturn = debounce(this.validateOcaReturn, VALIDATION_DEBOUNCE_DURATION_MS, {
            leading: true,
        });
    }

    get middleware(): Middleware<Record<string, unknown>, AppState> {
        return (api: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: AnyAction) => {
            next(action);

            if (
                matchesAny<OcaReturnReference>(action, [
                    ocaReturnsActions.changePickupProperties,
                    ocaReturnsActions.changePickupPreferences,
                    ocaReturnsActions.changeAddress,
                    ocaReturnsActions.changeContact,
                    ocaReturnsActions.changeNotes,
                    ocaReturnsActions.changeAdditionalRecipients,
                    ocaReturnsActions.selectAddress,
                    ocaReturnsActions.selectContact,
                ])
            ) {
                api.dispatch(ocaReturnsActions.save.reset(action.payload));
                api.dispatch(ocaReturnsActions.submit.reset(action.payload));
                api.dispatch(ocaReturnsActions.doValidate(action.payload));
            }

            if (ocaReturnsActions.doValidate.match(action)) {
                this.validateOcaReturn(api, action.payload.orgId, action.payload.ocaReturnId);
            }

            if (ocaReturnsActions.fetch.do.match(action)) {
                this.fetchOcaReturns(api, action.payload.orgId, action.payload.ocaReturnIds);
            }

            if (ocaReturnsActions.save.do.match(action)) {
                this.saveOcaReturn(api, action.payload.orgId, action.payload.ocaReturnId);
            }

            if (ocaReturnsActions.save.did.match(action)) {
                api.dispatch(
                    ocaReturnsActions.doValidate({
                        orgId: action.payload.orgId,
                        ocaReturnId: action.payload.ocaReturn[ID_KEY],
                    })
                );
            }

            if (ocaReturnsActions.submit.do.match(action)) {
                this.submitOcaReturn(api, action.payload.orgId, action.payload.ocaReturnId);
            }
        };
    }

    private validateOcaReturn(api: MiddlewareAPI<Dispatch, AppState>, orgId: number, ocaReturnId: number) {
        const state = api.getState();
        const original = getOcaReturn(state, ocaReturnId);
        const modified = getOcaReturnModified(state, ocaReturnId);

        if (!original) {
            return;
        }

        api.dispatch(
            ocaReturnsActions.didValidate({
                orgId,
                ocaReturnId,
                errors: validateOcaReturn(modified || original),
            })
        );
    }

    private fetchOcaReturns(api: MiddlewareAPI<Dispatch, AppState>, orgId: number, ocaReturnIds?: number[]) {
        this.handleRequest(api, topgear.fetchOcaReturns(orgId, ocaReturnIds)).then(
            (ocaReturns) => api.dispatch(ocaReturnsActions.fetch.did({ orgId, ocaReturns })),
            (error: Error) => api.dispatch(ocaReturnsActions.fetch.fail({ orgId, ocaReturnIds, error }))
        );
    }

    private async saveOcaReturn(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        ocaReturnId: number
    ): Promise<boolean> {
        const state = api.getState();
        const ocaReturn = getOcaReturnModified(state, ocaReturnId);

        if (!ocaReturn) {
            // There was nothing to save.
            return true;
        }

        try {
            const updated = await this.handleRequest(api, topgear.updateOcaReturn(orgId, ocaReturn));
            api.dispatch(ocaReturnsActions.save.did({ orgId, ocaReturn: updated }));
            return true;
        } catch (error) {
            api.dispatch(ocaReturnsActions.save.fail({ orgId, ocaReturnId: ocaReturn.id, error }));
            return false;
        }
    }

    private async submitOcaReturn(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        ocaReturnId: number
    ): Promise<boolean> {
        if (getOcaReturnIsDirty(api.getState(), ocaReturnId)) {
            // If form is dirty, attempt save first before submit.
            const success = await this.saveOcaReturn(api, orgId, ocaReturnId);
            if (!success) {
                return false;
            }
        }

        try {
            const ocaReturn = await this.handleRequest(api, topgear.submitOcaReturn(orgId, ocaReturnId));
            api.dispatch(ocaReturnsActions.submit.did({ orgId, ocaReturn }));
            return true;
        } catch (error) {
            api.dispatch(ocaReturnsActions.submit.fail({ orgId, ocaReturnId, error }));
            return false;
        }
    }
}

export default new OcaReturnsMiddleware().middleware;
