import React, { memo } from "react";
import Badge from "../../../shared/components/Badge";
import Segment from "../../../shared/components/Segment";
import SegmentGroup from "../../../shared/components/Segment/SegmentGroup";
import Value from "../../../shared/components/Value";
import { messages } from "../../../shared/messages";
import { getLabel } from "../../models/ComponentType";
import { PartOrderLineItem as PartOrderLineItemModel } from "../../models/PartOrderLineItem";

interface Props {
    lineItem: PartOrderLineItemModel;
}

function PartOrderLineItem({ lineItem }: Props) {
    return (
        <SegmentGroup horizontal className="PartOrderLineItem">
            <Segment
                style={{
                    flexGrow: 3
                }}
            >
                <Value label={messages.labels.partOrder.part()}>
                    <div className="PartOrderLineItem-description">
                        <Badge kind="subdued">{getLabel(lineItem.partDefinition.componentType)}</Badge>
                        <span className="PartOrderLineItem-name">{lineItem.partDefinition.name}</span>
                    </div>
                </Value>
            </Segment>
            <Segment
                style={{
                    flexGrow: 1
                }}
            >
                <Value label={messages.labels.partOrder.quantity()}>
                    <span>{lineItem.partCount}</span>
                </Value>
            </Segment>
        </SegmentGroup>
    );
}

export default memo(PartOrderLineItem);
