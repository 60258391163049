import React, { Component } from "react";
import classnames from "classnames";
import Header from "../Header";

interface Props {
    title: string;
    subtitle?: string;
    className?: string;
}

/**
 * A simple container for partner form content. It has a title, an optional
 * subtitle, and inner content with consistent styling.
 */
class PartnerFormContent extends Component<Props> {
    render() {
        const { title, children, subtitle, className } = this.props;

        return (
            <div className={classnames("PartnerFormContent", className)}>
                <Header as="h3" title={title} fluid>
                    {subtitle && <p className="PartnerFormContent-subtitle">{subtitle}</p>}
                </Header>
                {children}
            </div>
        );
    }
}

export default PartnerFormContent;
