import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { REQUEST_FAILURE, REQUEST_MADE, RESPONSE_FAILURE, RESPONSE_SUCCESS } from "../shared/events";
import logger from "../shared/logger";
import { makeRequestError } from "../utils/requests";
import { AuthService } from "./auth";
import { checkIsMeechumUser, checkIsUser, User } from "./auth/models/User";

export abstract class RequestService {
    protected readonly name: string;
    protected axios: AxiosInstance;
    protected auth: AuthService;

    public static generateBearerTokenHeader(user: User | null): { Authorization: string } | undefined {
        if (!user) {
            return;
        }

        if (checkIsUser(user) && checkIsMeechumUser(user)) {
            return {
                Authorization: `Bearer ${user.accessToken}`
            };
        } else {
            return;
        }
    }

    constructor(name: string, auth: AuthService, axios: AxiosInstance) {
        this.name = name;
        this.auth = auth;
        this.axios = axios;

        this.axios.interceptors.response.use(
            (response) => {
                logger.trackEvent(`${this.name}.${RESPONSE_SUCCESS}`, {
                    url: this.formatRequestUrl(response.config),
                    method: response.config.method
                });
                return response;
            },
            (error: AxiosError) => {
                if (error.response) {
                    // The request was made and the server responded with a
                    // status code that falls out of the range of 2xx.
                    logger.trackEvent(`${this.name}.${RESPONSE_FAILURE}`, {
                        url: this.formatRequestUrl(error.config),
                        method: error.config.method,
                        status: error.response.status,
                        error: error.message,
                        body: error.response.data
                    });

                    return Promise.reject(makeRequestError(error));
                }

                // Either the request was made but received no respones, or an
                // error occurred while setting up the request.
                logger.trackEvent(`${this.name}.${REQUEST_FAILURE}`, {
                    url: this.formatRequestUrl(error.config),
                    method: error.config.method,
                    error: error.message
                });

                return Promise.reject(error);
            }
        );

        this.axios.interceptors.request.use((config) => {
            logger.trackEvent(`${this.name}.${REQUEST_MADE}`, {
                url: this.formatRequestUrl(config),
                method: config.method
            });
            return this.configureRequest({
                ...config,
                headers: {
                    ...config.headers,
                    ...this.auth.authHeaders
                }
            });
        });
    }

    /**
     * Subclasses can override this method to further configure the request.
     */
    protected configureRequest(config: AxiosRequestConfig): AxiosRequestConfig {
        return config;
    }

    /** Tries to return the relative URL if baseURL is defined */
    protected formatRequestUrl(config: AxiosRequestConfig): string | undefined {
        if (config.url) {
            if (config.baseURL) {
                return config.url.replace(config.baseURL, "");
            }

            return config.url;
        }
        return;
    }
}
