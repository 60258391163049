import { messages } from "../../messages";
import { Level, ValidationError } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

/**
 * This is the root export of all Validators.
 */

export * from "./compare";
export * from "./email";
export * from "./integer";
export * from "./ip";
export * from "./length";
export * from "./oneOf";
export * from "./phone";
export * from "./range";
export * from "./regex";
export * from "./required";
export * from "./uuid";
export { makeStrict, all, either, conditional, not };

/**
 * Returns a modified validator that makes all errors Level.Invalid
 */
function makeStrict(validator: Validator): Validator {
    return (value: any, allValues?: any, props?: any, name?: any): ValidationError | undefined => {
        const error = validator(value, allValues, props, name);
        return error ? { ...error, level: Level.Invalid } : undefined;
    };
}

/**
 * Composes a list of validators into a single validator using AND. Order
 * matters. It will return the error of the first validation that fails, if any.
 *
 * @param validators - A list of validators
 *
 *
 * HINT: null is allowed as an item to facilitate using a ternary in the
 * function call.
 */
function all(...validators: (Validator | null)[]): Validator {
    return (value: any, allValues?: any, props?: any, name?: any): ValidationError | undefined => {
        return validators
            .filter((validator): validator is Validator => validator !== null)
            .reduce(
                (error: ValidationError | undefined, validator) => error ?? validator(value, allValues, props, name),
                undefined
            );
    };
}

/**
 * Composes a list of validators into a single validator using OR.
 * @param validators - A list of validators
 */
function either(...validators: Validator[]): Validator {
    return (value: any, allValues?: any, props?: any, name?: any): ValidationError | undefined => {
        const errors: ValidationError[] = [];
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i](value, allValues, props, name);
            if (!error) {
                return;
            } else {
                errors.push(error);
            }
        }

        return errors[errors.length - 1];
    };
}

/**
 * Returns a modified version of the original validator that only runs if
 * certain conditions are met.
 * @param predicate - Whether or not conditions are met.
 * @param validator - The original validator to be made conditional
 */
function conditional<T extends (...args: any[]) => boolean>(predicate: T, validator: Validator): Validator {
    return (value: any, allValues?: any, props?: any, name?: any) => {
        if (predicate(value, allValues, props, name)) {
            return validator(value, allValues, props, name);
        }

        return;
    };
}

/**
 * Returns a validator with inverse validation results of the provided validator
 * @param validator
 * @param message - The error message to use when the provided validator passed.
 */
function not(validator: Validator, message?: string): Validator {
    return (value: any, allValues?: any, props?: any, name?: any) => {
        if (!validator(value, allValues, props, name)) {
            return {
                level: Level.Invalid,
                message: message || messages.forms.validation.invalid(),
            };
        }

        return;
    };
}
