import { Path } from "./Path";

export enum Level {
    /**
     * Incomplete data can be saved (whether locally or on server) to be
     * completed at a later time
     */
    Incomplete = "Incomplete",
    /**
     * Invalid data cannot be saved until it has been corrected.
     */
    Invalid = "Invalid"
}

export interface ValidationError {
    level: Level;
    /** Any meta data that is relevant to the source of the error */
    meta?: any;
    path?: Path;
    /** A user-friendly message regarding the error */
    message: string;
}

const ERROR_LEVEL_ORDERING: Level[] = [Level.Incomplete, Level.Invalid];

/**
 *  Returns the high level of validation error in the provided list of errors
 * @param errors - A list of at least one error(s)
 */
export function getHighestErrorLevel(errors: ValidationError[]): Level {
    if (!errors.length) {
        throw new Error("Attempted to compute highest error level for an empty list of errors");
    }

    const highestError = errors.reduce((highestErrorSoFar, nextError) => {
        if (ERROR_LEVEL_ORDERING.indexOf(highestErrorSoFar.level) >= ERROR_LEVEL_ORDERING.indexOf(nextError.level)) {
            return highestErrorSoFar;
        } else {
            return nextError;
        }
    });

    return highestError.level;
}

export function createValidationError(level: Level, message: string, path?: Path, meta?: any): ValidationError {
    return {
        level,
        message,
        path,
        meta
    };
}
