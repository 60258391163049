import { debounce } from "lodash";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppState } from "../../app/models/AppState";
import topgear from "../../services/topgear";
import { ForceEditableFlag } from "../../shared/models/ForceEditableFlag";
import RequestMiddleware from "../../shared/models/RequestMiddleware";
import { ID_KEY } from "../../shared/models/Resource";
import { VALIDATION_DEBOUNCE_DURATION_MS } from "../../shared/settings";
import { validatePartOrder } from "../../shared/validation/partOrder";
import { matchesAny } from "../../utils/actions";
import { getRemovedLineItems } from "../../utils/partOrder";
import { getResponseErrorsAsMessage } from "../../utils/requests";
import { PARTS_ORDER_LINE_ITEMS_KEY } from "../models/PartOrder";
import { PartOrderReference } from "../models/PartOrderReference";
import { partOrdersActions } from "../reducer";
import { getPartOrder, getPartOrderIsDirty, getPartOrderModified, getQuantityLimits } from "../reducer/selectors";

export class PartOrdersMiddleware extends RequestMiddleware<Dispatch, AppState> {
    constructor() {
        super();

        this.validatePartOrder = debounce(this.validatePartOrder, VALIDATION_DEBOUNCE_DURATION_MS, {
            leading: true,
        });
    }

    get middleware(): Middleware<Record<string, unknown>, AppState> {
        return (api: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: AnyAction) => {
            next(action);

            // Edits
            if (
                matchesAny<PartOrderReference & ForceEditableFlag>(action, [
                    partOrdersActions.addLineItem,
                    partOrdersActions.removeLineItem,
                    partOrdersActions.changeLineItem,
                    partOrdersActions.changeAddress,
                    partOrdersActions.changeContact,
                    partOrdersActions.changeAdditionalRecipients,
                    partOrdersActions.changeNotes,
                    partOrdersActions.selectAddress,
                    partOrdersActions.selectContact,
                    partOrdersActions.changeTaxpayerFields,
                    partOrdersActions.changeEori,
                ])
            ) {
                const { orgId, partOrderId, forceEditable } = action.payload;
                api.dispatch(partOrdersActions.save.reset({ orgId, partOrderId }));
                api.dispatch(partOrdersActions.submit.reset({ orgId, partOrderId }));
                api.dispatch(partOrdersActions.doValidate({ orgId, partOrderId, forceEditable }));
            }

            if (partOrdersActions.fetch.do.match(action)) {
                this.fetchPartOrders(api, action.payload.orgId, action.payload.partOrderIds);
            }

            if (partOrdersActions.save.do.match(action)) {
                this.savePartOrder(api, action.payload.orgId, action.payload.partOrderId);
            }

            if (partOrdersActions.save.did.match(action)) {
                api.dispatch(
                    partOrdersActions.doValidate({
                        orgId: action.payload.orgId,
                        partOrderId: action.payload.partOrder[ID_KEY],
                    })
                );
            }

            if (partOrdersActions.submit.do.match(action)) {
                this.submitPartOrder(api, action.payload.orgId, action.payload.partOrderId);
            }

            if (partOrdersActions.fetchShippingAddressSuggestions.do.match(action)) {
                this.fetchShippingAddressSuggestions(api, action.payload.orgId, action.payload.partOrderId);
            }

            if (partOrdersActions.fetchPartDefinitions.do.match(action)) {
                this.fetchPartDefinitions(api);
            }

            if (partOrdersActions.fetchQuantityLimits.do.match(action)) {
                this.fetchQuantityLimits(api, action.payload.orgId, action.payload.partOrderId);
            }

            if (partOrdersActions.doValidate.match(action)) {
                this.validatePartOrder(
                    api,
                    action.payload.orgId,
                    action.payload.partOrderId,
                    action.payload.forceEditable
                );
            }
        };
    }

    private validatePartOrder(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        partOrderId: number,
        forceEditable = false
    ) {
        const state = api.getState();
        const original = getPartOrder(state, partOrderId);
        const modified = getPartOrderModified(state, partOrderId);
        const quantityLimits = original?.enforceQuantityLimits ? getQuantityLimits(state, partOrderId) : undefined;

        if (!original) {
            return;
        }

        api.dispatch(
            partOrdersActions.didValidate({
                orgId,
                partOrderId,
                errors: validatePartOrder(modified || original, forceEditable, quantityLimits),
            })
        );
    }

    private fetchPartOrders(api: MiddlewareAPI<Dispatch, AppState>, orgId: number, partOrderIds?: number[]) {
        this.handleRequest(api, topgear.fetchPartOrders(orgId, partOrderIds)).then(
            (partOrders) => api.dispatch(partOrdersActions.fetch.did({ orgId, partOrders })),
            (error) => api.dispatch(partOrdersActions.fetch.fail({ orgId, error }))
        );
    }

    private fetchShippingAddressSuggestions(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        partOrderId: number
    ) {
        this.handleRequest(api, topgear.fetchPartOrderShippingAddressSuggestions(orgId, partOrderId)).then(
            (shippingAddresses) =>
                api.dispatch(partOrdersActions.fetchShippingAddressSuggestions.did(shippingAddresses, partOrderId)),
            (error) =>
                api.dispatch(
                    partOrdersActions.fetchShippingAddressSuggestions.fail(
                        {
                            orgId,
                            partOrderId,
                            error,
                        },
                        partOrderId
                    )
                )
        );
    }

    private fetchPartDefinitions(api: MiddlewareAPI<Dispatch, AppState>) {
        this.handleRequest(api, topgear.fetchPartDefinitions()).then(
            (partDefinitions) => api.dispatch(partOrdersActions.fetchPartDefinitions.did(partDefinitions)),
            (error) => api.dispatch(partOrdersActions.fetchPartDefinitions.fail({ error }))
        );
    }

    private fetchQuantityLimits(api: MiddlewareAPI<Dispatch, AppState>, orgId: number, partOrderId: number) {
        this.handleRequest(api, topgear.fetchPartOrderQuantityLimits(orgId, partOrderId)).then(
            (quantityLimits) => api.dispatch(partOrdersActions.fetchQuantityLimits.did(quantityLimits, partOrderId)),
            (error) =>
                api.dispatch(partOrdersActions.fetchQuantityLimits.fail({ orgId, partOrderId, error }, partOrderId))
        );
    }

    private async savePartOrder(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        partOrderId: number
    ): Promise<boolean> {
        const state = api.getState();
        const original = getPartOrder(state, partOrderId);
        const partOrder = getPartOrderModified(state, partOrderId);

        if (!original || !partOrder) {
            // There was nothing to save.
            return true;
        }

        try {
            // Remove deleted line items.
            await Promise.all(
                getRemovedLineItems(
                    original[PARTS_ORDER_LINE_ITEMS_KEY],
                    partOrder[PARTS_ORDER_LINE_ITEMS_KEY]
                ).map((lineItem) => topgear.removePartOrderLineItem(orgId, partOrderId, lineItem.id))
            );
            const updated = await this.handleRequest(api, topgear.updatePartOrder(orgId, partOrder));
            api.dispatch(partOrdersActions.save.did({ orgId, partOrder: updated }));
            return true;
        } catch (e) {
            api.dispatch(
                partOrdersActions.save.fail({
                    orgId,
                    partOrderId,
                    error: getResponseErrorsAsMessage(e) || e,
                })
            );
            return false;
        }
    }

    private async submitPartOrder(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        partOrderId: number
    ): Promise<boolean> {
        if (getPartOrderIsDirty(api.getState(), partOrderId)) {
            // If form is dirty, attempt save first before submit.
            const success = await this.savePartOrder(api, orgId, partOrderId);
            if (!success) {
                return false;
            }
        }

        try {
            const partOrder = await this.handleRequest(api, topgear.submitPartOrder(orgId, partOrderId));
            api.dispatch(partOrdersActions.submit.did({ orgId, partOrder }));
            return true;
        } catch (error) {
            api.dispatch(partOrdersActions.submit.fail({ orgId, partOrderId, error }));
            return false;
        }
    }
}

export default new PartOrdersMiddleware().middleware;
