import { cloneDeep } from "lodash";
import { Change, Operation } from "../shared/models/Change";
import { asArray } from "./arrays";
import { isValidIndexor, stringifyPath } from "./paths";

function abort<T>(resource: T, message: string, silent: boolean) {
    if (!silent) {
        throw new Error(`ChangeError: ${message}`);
    } else {
        return resource;
    }
}

/**
 * Apply a Change to a resource.
 * @param resource - The resource to apply a Change to.
 * @param change - The Change to apply.
 * @param modify - If true, will modify the resource directly. Otherwise, will
 * perform a deep clone and return the clone. You may want to set this to true
 * if you are applying many changes but only want the data cloned once.
 * @param silent - If true, will ignore invalid Changes. Otherwise, throws an
 * error when an invalid change is encountered. Defaults to true.
 */
export function applyChange<T extends any>(resource: T, change: Readonly<Change>, modify = false, silent = true) {
    const modified = modify ? resource : cloneDeep(resource);
    const indexors = asArray(change.path);
    const operation = change.operation || Operation.Replace;

    if (!indexors.length) {
        return abort(modified, "The path is invalid because it is empty", silent);
    }

    const key = indexors[indexors.length - 1];

    let target = modified;
    for (let i = 0; i < indexors.length - 1; i++) {
        const indexor = indexors[i];

        if (!isValidIndexor(indexor)) {
            return abort(modified, `Invalid indexor found ${indexor.toString()}`, silent);
        }

        if (!target[indexor] || typeof target[indexor] !== "object") {
            if (target[indexor] === undefined) {
                if (typeof indexors[i + 1] === "string") {
                    target[indexor] = {};
                } else {
                    target[indexor] = [];
                }
            } else {
                return abort(modified, `The path ${stringifyPath(change.path)} was not found`, silent);
            }
        }
        target = target[indexor];
    }

    if (Array.isArray(target) && typeof key === "string") {
        return abort(
            modified,
            `An array was found at ${stringifyPath(change.path)}, but the key ${key} is a string`,
            silent
        );
    }

    if (typeof target === "object" && !Array.isArray(target) && typeof key === "number") {
        return abort(
            modified,
            `An object was found at ${stringifyPath(
                change.path
            )}, but the key ${key} is a number. If this is intentional, try passing in "${key}" instead.`,
            silent
        );
    }

    switch (operation) {
        case Operation.Remove:
            if (Array.isArray(target)) {
                if (typeof key !== "number") {
                    // Trying to remove a non-number key from an array
                    return abort(
                        modified,
                        `Tried to remove string key ${key.toString()} from an array at ${stringifyPath(change.path)}`,
                        silent
                    );
                }

                if (key > target.length - 1) {
                    // Trying to remove an index that is larger than array length
                    return abort(
                        modified,
                        `Tried a remove index ${key} from array at ${stringifyPath(
                            change.path
                        )} but its length is only ${target.length}!`,
                        silent
                    );
                }
                target.splice(key, 1);
            } else {
                delete target[key];
            }
            break;
        case Operation.Insert:
            if (Array.isArray(target)) {
                if (typeof key !== "number") {
                    // Trying to insert a non-number key into an array
                    return abort(
                        modified,
                        `Tried to insert a value at string key ${key.toString()} to an array at ${stringifyPath(
                            change.path
                        )}`,
                        silent
                    );
                }

                if (key > target.length - 1) {
                    // Trying to insert at an index that is larger than array length
                    return abort(
                        modified,
                        `Tried a insert at index ${key} to array at ${stringifyPath(
                            change.path
                        )} but its length is only ${target.length}!`,
                        silent
                    );
                }

                target.splice(key, 0, cloneDeep(change.value));
            }
            break;
        case Operation.Add:
            if (!Array.isArray(target[key])) {
                // Tried to push to a value that is not an array
                return abort(
                    modified,
                    `Tried to push to ${stringifyPath(change.path)}, but an array was not found!`,
                    silent
                );
            }
            target[key].push(cloneDeep(change.value));
            break;
        default:
            target[key] = cloneDeep(change.value);
            break;
    }

    return modified;
}

export function applyChangeset<T extends Record<string, any>>(
    resource: Readonly<T>,
    changeset?: readonly Change[],
    silent = false
): T {
    if (!changeset) {
        return cloneDeep(resource);
    }

    return changeset.reduce((modified, change) => applyChange(modified, change, true, silent), cloneDeep(resource));
}

export function createChange<T, K extends keyof T = keyof T>(key: K, value: T[K]): Readonly<Change> {
    return {
        path: key,
        value,
    };
}
