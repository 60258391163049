import React, { memo } from "react";
import { messages } from "../../messages";
import Icon from "../Icon";

function DoneIndicator() {
    return (
        <div className="DoneIndicator">
            <Icon icon="check" />
            <span className="DoneIndicator-text">{messages.notice.done()}</span>
        </div>
    );
}

export default memo(DoneIndicator);
