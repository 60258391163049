import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import { authActions } from "../../reducer";
import { Props } from "./Login";

type PropsFromState = Pick<Props, "error" | "isSubmittingLogin">;
type PropsFromDispatch = Pick<Props, "login">;

const mapStateToProps: MapStateToProps<PropsFromState, Record<string, unknown>, AppState> = (state) => {
    return {
        isSubmittingLogin: state.auth.login.pending,
        error: state.auth.login.error,
    };
};

const mapDispatchToProps: MapDispatchToProps<PropsFromDispatch, Record<string, unknown>> = (dispatch) => {
    return {
        login: (email, code) => dispatch(authActions.login.do({ email, code })),
    };
};

export { mapStateToProps, mapDispatchToProps };
