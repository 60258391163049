export interface AppConfig {
    /** "PartnerForms" */
    appName: string;
    /** The package.json version of the app, or "dev" if not available */
    version: string;
    /** `"unitTest"`, `"dev"`, `"test"`, or `"prod"` */
    env: string;
    telemetryApiUrl: string;
    topgearApiBaseUrl: string;
    partnerPortalApiBaseUrl: string;
    partnerPortalHostname: string;
}

const APP_NAME = "PartnerForms";
const ENV = process.env.NODE_ENV === "test" ? "unitTest" : process.env.REACT_APP_ENV ?? "dev";
// REACT_APP_VERSION can be a final semver tag or a commit SHA, which we shorten.
const VERSION = (process.env.REACT_APP_VERSION ?? "dev").substr(0, 7);

const LOG_API_URL = "/";
const TOPGEAR_API_URL = "/topgear";
const PARTNER_PORTAL_API_URL = "/partner";
const PARTNER_PORTAL_HOSTNAME = `//${ENV === "prod" ? "my" : "test"}.oc.netflix.com`;

const config: AppConfig = {
    appName: APP_NAME,
    version: VERSION,
    env: ENV,
    telemetryApiUrl: process.env.REACT_APP_LOG_API_URL ?? LOG_API_URL,
    topgearApiBaseUrl: process.env.REACT_APP_TOPGEAR_API_URL ?? TOPGEAR_API_URL,
    partnerPortalApiBaseUrl: process.env.REACT_APP_PARTNER_PORTAL_API_URL ?? PARTNER_PORTAL_API_URL,
    partnerPortalHostname: PARTNER_PORTAL_HOSTNAME,
};

export default config;
