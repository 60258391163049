import { messages } from "../../messages";
import { createValidationError, Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

/**
 * Returns a validator that ensures the value as a number is between the min and
 * max, inclusive
 * @param range - A min max tuple (inclusive)
 */
export const between = (range: [number, number], message?: string): Validator => (value: string) => {
    const [min, max] = range;

    if (min > max) {
        // invalid configuration.
        return undefined;
    }

    const valueAsNumber = Number(value);
    return !isNaN(valueAsNumber) && valueAsNumber >= min && valueAsNumber <= max
        ? undefined
        : createValidationError(
              Level.Invalid,
              message ||
                  messages.forms.validation.range({
                      MIN: min,
                      MAX: max,
                  })
          );
};
