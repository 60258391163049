import { groupBy, uniqBy } from "lodash";
import { getMessage, messages } from "../shared/messages";
import { ADDITIONAL_RECIPIENTS_KEY } from "../shared/models/AdditionalRecipients";
import { Address } from "../shared/models/Address";
import { AddressFields, ADDRESS_KEYS, DEFAULT_ADDRESS_VALUES } from "../shared/models/AddressFields";
import { ApiRequestState } from "../shared/models/ApiRequestState";
import { Audited, getDateCreated, getLastUpdated } from "../shared/models/Audited";
import { CFDI_FIELDS_KEY } from "../shared/models/CfdiCompliant";
import { Change } from "../shared/models/Change";
import { Contact } from "../shared/models/Contact";
import { ContactFields, CONTACT_FIELDS_KEYS } from "../shared/models/ContactFields";
import { Dictionary } from "../shared/models/Dictionary";
import { Editable } from "../shared/models/Editable";
import { EORI_FIELD_KEY } from "../shared/models/EoriCompliant";
import { Notes } from "../shared/models/Notes";
import { NOTES_KEY } from "../shared/models/NotesFields";
import { Org } from "../shared/models/Org";
import { PartnerForm } from "../shared/models/PartnerForm";
import { PartnerFormKind } from "../shared/models/PartnerFormKind";
import { Submittable } from "../shared/models/Submittable";
import { TAXPAYER_FIELDS_KEY } from "../shared/models/TaxpayerAffiliated";
import { getHighestErrorLevel, Level, ValidationError } from "../shared/models/ValidationError";
import { checkIsEmptyAddress } from "./address";
import { getAddressIdentifier, getContactIdentifier } from "./options";
import { extractCompleteContactsFromOrg } from "./orgs";

/**
 * The time difference threshold between dateCreated and lastUpdated under which
 * the partner form can still be considered "new".
 */
const NEW_PARTNER_FORM_THRESHOLD_MS = 10000;

/**
 * Returns whether or not the form has been submitted. True if any of the following conditions are met:
 *
 * - submittedOn is not undefined
 * - submittedBy is not undefined
 */
export function checkIsSubmitted(partnerForm: Readonly<Submittable>): boolean {
    return partnerForm.submittedBy !== undefined || partnerForm.submittedOn !== undefined;
}

export function checkIsNew(
    partnerForm: Readonly<Audited & Editable & Submittable>,
    forceLastUpdated?: Date,
    forceIsSubmitted?: boolean,
    forceIsEditable?: boolean
): boolean {
    const dateCreated = getDateCreated(partnerForm);
    const lastUpdated = forceLastUpdated ?? getLastUpdated(partnerForm);
    const isSubmitted = forceIsSubmitted ?? checkIsSubmitted(partnerForm);
    const isEditable = forceIsEditable ?? partnerForm.editable;

    // These are legacy entities created using scripts that we should never
    // consider to be "new".
    if (partnerForm.createdBy === "octopgear-api-client") {
        return false;
    }

    if (isSubmitted) {
        return false;
    }

    if (!isEditable) {
        return false;
    }

    return Math.abs(dateCreated.getTime() - lastUpdated.getTime()) < NEW_PARTNER_FORM_THRESHOLD_MS;
}

export function getAllowSave(editable: boolean, isDirty: boolean, isValid: boolean): boolean {
    return editable && isDirty && isValid;
}

export function getAllowSubmit(
    editable: boolean,
    isDirty: boolean,
    isComplete: boolean,
    isSubmitted: boolean,
    allowSave: boolean
): boolean {
    // If the form is dirty, but can be saved, then allow submit. Rely on
    // middleware to perform save and submit in serial.
    return editable && (!isDirty || allowSave) && isComplete && !isSubmitted;
}

export function createContactChanges(contact: Partial<ContactFields>, key: string): readonly Change[] {
    return [
        ...CONTACT_FIELDS_KEYS.map((field) => ({
            path: [key, field],
            value: contact[field] || "",
        })),
    ];
}

export function createAddressChanges<T extends AddressFields>(
    address: Partial<T>,
    key: string,
    fields: Readonly<(keyof T)[]> = ADDRESS_KEYS
): readonly Change[] {
    return [
        ...fields.map((field) => ({
            path: [key, field],
            value: address[field] || (DEFAULT_ADDRESS_VALUES as Readonly<Partial<T>>)[field] || "",
        })),
    ];
}

export function createContactSelectionChanges(
    contact: ContactFields | Contact | undefined,
    key: string
): readonly Change[] {
    const changes: Change[] = [];

    if (!contact) {
        changes.push({
            path: key,
            value: undefined,
        });
    } else {
        changes.push(
            ...CONTACT_FIELDS_KEYS.map((field) => ({
                path: [key, field],
                value: contact[field] || "",
            }))
        );
    }

    return changes;
}

export function createAddressSelectionChanges(
    address: AddressFields | Address | undefined,
    key: string
): readonly Change[] {
    const changes: Change[] = [];

    if (!address) {
        changes.push({
            path: key,
            value: undefined,
        });
    } else {
        changes.push(
            ...ADDRESS_KEYS.map((field) => ({
                path: [key, field],
                value: address[field] || "",
            }))
        );
    }

    return changes;
}

export function createNotesChanges(values: Partial<Notes>, key: string = NOTES_KEY): readonly Change[] {
    const notes = values.notes;

    return [
        {
            path: key,
            value: notes || "",
        },
    ];
}

export function createAdditionalRecipientsChanges(
    recipients: string[],
    key: string = ADDITIONAL_RECIPIENTS_KEY
): readonly Change[] {
    return [
        {
            path: key,
            value: recipients.join(","),
        },
    ];
}

export function createTaxpayerFieldChanges(taxpayerFields: Partial<Record<string, string>>): readonly Change[] {
    return Object.keys(taxpayerFields).map(
        (key): Change => ({
            path: [TAXPAYER_FIELDS_KEY, key],
            value: taxpayerFields[key] ?? "",
        })
    );
}

export function createCfdiFieldChanges(cfdiFields: Partial<Record<string, string | undefined>>): readonly Change[] {
    return Object.keys(cfdiFields).map(
        (key): Change => ({
            path: [CFDI_FIELDS_KEY, key],
            value: cfdiFields[key] ?? "",
        })
    );
}

export function createEoriChange(eori: string | undefined): readonly Change[] {
    return [
        {
            path: EORI_FIELD_KEY,
            value: eori,
        },
    ];
}

/**
 * Returns a rollup from an array of errors. If there are no errors, returns
 * undefined. If there are one or many errors, generates a new error with the
 * error severity matching the highest severity from the list of errors.
 * @param errors - An array of validation errors
 * @param getMessage - A callback that should return a generic message
 * corresponding to the highest error severity.
 */
function rollupErrors(
    errors: ValidationError[] | undefined,
    getMessage: (level: Level) => string
): ValidationError | undefined {
    if (!errors || !errors.length) {
        return;
    }

    const level = getHighestErrorLevel(errors);
    return {
        level,
        message: getMessage(level),
    };
}

export function rollUpErrorsByView(
    errors: ValidationError[],
    grouping: (error: ValidationError) => string | undefined
): Dictionary<ValidationError> {
    const unallocatedKey = "unallocated";
    const grouped = groupBy(errors, (error) => grouping(error) || unallocatedKey);
    return Object.keys(grouped).reduce((errorsByView, view) => {
        // Skip unallocated errors
        if (view === unallocatedKey) {
            return errorsByView;
        }

        errorsByView[view] = rollupErrors(grouped[view], (level) =>
            level === Level.Incomplete
                ? getMessage(() => messages.instructions.complete[view]())
                : getMessage(() => messages.instructions.correct[view]())
        );
        return errorsByView;
    }, {});
}

export function getRecoveryActions<T = string>(errors: Dictionary<ValidationError>): [T, ValidationError][] {
    const actionItems = (Object.entries(errors).filter(([_, error]) => !!error) as any) as [T, ValidationError][];
    return actionItems;
}

export function getProgressionAction(
    kind: PartnerFormKind,
    partnerForm: Submittable & Editable,
    overrides: {
        allowSave?: boolean;
        allowSubmit?: boolean;
        isEditable?: boolean;
        isComplete?: boolean;
        isDirty?: boolean;
        isSubmitted?: boolean;
        isValid?: boolean;
    } = {}
): { message: string; success?: boolean } | undefined {
    const isEditable = overrides.isEditable ?? partnerForm.editable;
    const isDirty = overrides.isDirty ?? false;
    const isValid = overrides.isValid ?? false;
    const isComplete = overrides.isComplete ?? false;
    const isSubmitted = overrides.isSubmitted ?? checkIsSubmitted(partnerForm);
    const allowSave = overrides.allowSave ?? getAllowSave(isEditable, isDirty, isValid);
    const allowSubmit =
        overrides.allowSubmit ?? getAllowSubmit(isEditable, isDirty, isComplete, isSubmitted, allowSave);

    if (allowSubmit) {
        return {
            success: true,
            message: getMessage(() => messages.partnerForm.toDo.submit[kind](null)),
        };
    }

    if (isSubmitted) {
        if (!isEditable) {
            return {
                message: getMessage(() => messages.partnerForm.toDo.submittedAndLocked[kind](null)),
            };
        } else {
            if (allowSave) {
                return {
                    message: getMessage(() => messages.partnerForm.toDo.submittedAndDirty[kind](null)),
                };
            } else {
                return {
                    message: getMessage(() => messages.partnerForm.toDo.submittedAndEditable[kind](null)),
                };
            }
        }
    }

    return;
}

export function getSaveHint(
    kind: PartnerFormKind,
    saveState: ApiRequestState,
    isDirty: boolean,
    allowSave: boolean
): string | undefined {
    if (saveState.error) {
        return messages.partnerForm.saveHint.failed();
    }

    if (saveState.pending) {
        return messages.partnerForm.saveHint.saving();
    }

    if (isDirty) {
        return allowSave ? messages.partnerForm.saveHint.unsaved() : messages.partnerForm.saveHint.hasErrors();
    }

    if (saveState.success) {
        return messages.partnerForm.saveHint.saved();
    }

    return;
}

export function collectContacts(org: Org | undefined): ContactFields[] {
    const contacts: ContactFields[] = [];

    if (org) {
        contacts.push(...extractCompleteContactsFromOrg(org));
    }

    return uniqBy(contacts, (contact) => getContactIdentifier(contact));
}

export function collectAddresses(...addresses: (AddressFields | undefined)[]): AddressFields[] {
    return uniqBy(
        addresses
            .filter((address): address is AddressFields => !!address)
            .filter((address) => !checkIsEmptyAddress(address)),
        (address) => getAddressIdentifier(address)
    );
}

export function extractAdditionalRecipientEmails(partnerForm?: PartnerForm): string[] {
    let results: string[] = [];

    if (partnerForm && partnerForm[ADDITIONAL_RECIPIENTS_KEY]) {
        results = partnerForm[ADDITIONAL_RECIPIENTS_KEY]!.split(",").map((email) => email.trim());
    }

    return results;
}
