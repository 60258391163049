import classnames from "classnames";
import { capitalize } from "lodash";
import React, { memo, useCallback } from "react";
import { Status } from "../../models/Status";
import { Conditional } from "../../props/conditional";
import { RenderProp } from "../../props/render";
import Icon from "../Icon";
import Markdown from "../Markdown";

interface Props extends Conditional {
    /**
     * The type of message, "success", "warning", "error". Affects styling.
     */
    status?: Status;
    /**
     * An optional icon to render to the left of the message
     */
    icon?: RenderProp;
    /**
     * A `string` or an instance of `Error`, in which case `error.message` is
     * rendered. Supports markdown.
     */
    message: Error | string | null | RenderProp;
    /**
     * If defined, the message will render a pointer and have hover styling
     */
    onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    /**
     * If `true`, will render as a block. Defaults to `true`.
     */
    asBlock?: boolean;
    /**
     * If `true`, will apply appropriate styling for lists of messages. Defaults
     * to `false`.
     */
    asGroup?: boolean;
    /**
     * Adds `white-space: nowrap`
     */
    noWrap?: boolean;
    /**
     * Adjusts the message text and background to have higher contrast. Only
     * applies to errors that are configured as blocks.
     */
    highContrast?: boolean;
    onDismiss?: () => void;
    textAlign?: "center" | "default";
}

/**
 * Use this component to display a short message to the user. Supports different
 * intents. To make it more prominent, consider configured it as a block.
 */
function Message({
    asBlock = true,
    asGroup = false,
    status,
    className,
    icon,
    message,
    onClick,
    textAlign = "default",
    when = true,
    highContrast = false,
    noWrap = false,
    onDismiss,
}: Props) {
    const effectiveStatus = message instanceof Error ? "error" : status;

    const renderMessage = useCallback((value: Error | string | RenderProp) => {
        if (typeof value === "function") {
            return value();
        }

        const message = typeof value === "string" ? value : value.message;
        const parts = message.split("\n");

        if (parts.length <= 1) {
            return <Markdown source={message} />;
        }

        return (
            <>
                <Markdown source={parts[0]} />
                <ul className="Message-list">
                    {parts.slice(1).map((part, idx) => (
                        <li key={idx}>
                            <Markdown source={part} />
                        </li>
                    ))}
                </ul>
            </>
        );
    }, []);

    if (!when || !message) {
        return null;
    }

    return (
        <div
            className={classnames("Message", effectiveStatus, className, `align${capitalize(textAlign)}`, {
                clickable: onClick !== undefined,
                block: asBlock,
                group: asGroup,
                highContrast,
                withIcon: icon !== undefined,
                noWrap,
            })}
            onClick={onClick}
        >
            <span className="Message-icon">{icon ? icon() : null}</span>
            <span className="Message-text">{renderMessage(message)}</span>
            {onDismiss && <Icon className="Message-dismiss" icon="times" title="Dismiss" onClick={onDismiss} />}
        </div>
    );
}

export default memo(Message);
