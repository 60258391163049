import { createAction } from "@reduxjs/toolkit";
import { AnyAction, Reducer } from "redux";
import { initAction } from "../../shared/actions";
import { createApiRequestSlice } from "../../utils/reducers";
import { AuthState } from "../models/AuthState";
import { LoginFields } from "../models/LoginFields";

const NAMESPACE = "auth";

// Actions
const logoutAction = createAction(`${NAMESPACE}/logout`);
const unauthorizedAction = createAction(`${NAMESPACE}/unauthorized`);

// Login slice (nested)
const loginSlice = createApiRequestSlice<LoginFields, undefined>(`${NAMESPACE}/login`);

function initialState(): AuthState {
    return {
        login: loginSlice.reducer(undefined, initAction)
    };
}

// Combine reducers
const authReducer: Reducer<AuthState, AnyAction> = (state = initialState(), action) => {
    return {
        login: loginSlice.reducer(state.login, action)
    };
};

const authActions = {
    logout: logoutAction,
    unauthorized: unauthorizedAction,
    login: loginSlice.actions
};

export { authReducer, authActions };
