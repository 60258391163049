import { isEmpty } from "lodash";
import { asArray } from "../../utils/arrays";
import { isValidationError } from "../../utils/guards";
import { concatenatePaths } from "../../utils/paths";
import config from "../config";
import { makeStrict } from "../forms/validators";
import logger from "../logger";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { Path } from "../models/Path";
import { ValidationError } from "../models/ValidationError";

/**
 * Shallowly checks an errors map so that if every property is undefined, or if
 * there are no properties at all, returns undefined.
 * @param errors
 */
export function clean<T>(errors: ModelValidationErrors<T>): ModelValidationErrors<T> | undefined {
    if (isEmpty(errors)) {
        return;
    }

    if (Object.values(errors).every((value) => value === undefined)) {
        return;
    }

    return errors;
}

/**
 * @returns whether or not there are any errors
 */
export function checkIsInvalid(errors: ValidationError | ModelValidationErrors<any> | undefined): boolean {
    if (!errors) {
        return false;
    }

    if (!isValidationError(errors)) {
        return clean(errors) !== undefined;
    } else {
        return true;
    }
}

/**
 * Validates a model.
 * @param model - The object to be validated.
 * @param validations - The set of validations to verify.
 * @param strict - Makes all validation errors `Level.Invalid`
 */
export function validate<T>(
    model: Partial<T>,
    validations: ModelValidator<T>,
    strict = false
): ModelValidationErrors<T> | undefined {
    const errors: ModelValidationErrors<T> = {};

    for (const [key, validator] of validations.entries()) {
        try {
            const error = (strict ? makeStrict(validator) : validator)(model[key], model);
            if (error) {
                error.path = key;
                errors[key] = error;
            }
        } catch (e) {
            if (config.env !== "unitTest") {
                // FIXME: The logger should not be flushing any requests when
                // environment is UnitTest (which it is, note the casing is
                // different between the app's env and the logger's env), but
                // for some reason `logger.trackError` still causes tests to
                // blow up.
                logger.trackError(e, `Error validationg ${key} using ${validator.name}`);
            }
            if (config.env === "dev") {
                console.error(e);
            }
        }
    }

    return clean(errors);
}

/**
 * Returns a list of validation errors from a ModelValidationErrors object.
 */
export function list<T>(validationErrors: ModelValidationErrors<T>): ValidationError[] {
    const errors: ValidationError[] = [];

    Object.entries(validationErrors).forEach(([key, error]: [string, any]) => {
        if (!error) {
            return;
        }

        if (isValidationError(error)) {
            errors.push({
                ...error,
                path: error.path || key,
            });
        } else {
            errors.push(
                ...list(error).map((error) => ({
                    ...error,
                    path: error.path ? concatenatePaths(key, error.path) : key,
                }))
            );
        }
    });

    return errors;
}

/**
 * Given a ModelValidationErrors and a path, returns whatever is found by
 * traversing the path (either undefined, a ValidationError, or nested
 * ModelValidationErrors). Returns undefined if the path is not traversable.
 * @param validationErrors - A ModelValidationErrors object
 * @param path - The Path to the desired value
 */
export function traverse<T>(
    validationErrors: ValidationError | ModelValidationErrors<T> | undefined,
    path?: Path
): ModelValidationErrors<any> | ValidationError | undefined {
    if (!validationErrors) {
        return;
    }

    if (!path) {
        return validationErrors;
    }

    const parts = asArray(path);

    let at = validationErrors;
    for (let i = 0; i < parts.length; i++) {
        // We can traverse no further - exit.
        if (!at || isValidationError(at)) {
            return;
        }

        at = at[parts[i]];
    }

    return at;
}

export function extractValidationError<T>(
    error: ValidationError | ModelValidationErrors<T> | undefined,
    path?: Path
): ValidationError | undefined {
    const errorAtPath = traverse(error, path);
    return errorAtPath && isValidationError<any>(errorAtPath) ? errorAtPath : undefined;
}

/**
 * Extracts the message if a ValidationError is found at the path, otherwise undefined
 */
export function extractMessage<T>(
    error: ValidationError | ModelValidationErrors<T> | undefined,
    path?: Path
): string | undefined {
    const errorAtPath = extractValidationError(error, path);
    return errorAtPath ? errorAtPath.message : undefined;
}
