import React, { Component } from "react";
import MenuItem from "../Menu/MenuItem";
import Icon, { Props as IconProps } from "../Icon";
import { Status } from "../../models/Status";

interface Props {
    status?: Status;
}

class MenuItemWithStatus extends Component<Props> {
    render() {
        const { children, status } = this.props;
        let color: string | undefined, icon: IconProps["icon"] | undefined;

        const styles = window.getComputedStyle(document.documentElement!);

        switch (status) {
            case "warning":
                color = styles.getPropertyValue("--attention");
                icon = "circle";
                break;
            case "error":
                color = styles.getPropertyValue("--error");
                icon = "circle";
                break;
            case "success":
                color = styles.getPropertyValue("--success");
                icon = "circle";
                break;
            default:
                break;
        }

        return (
            <MenuItem className="withStatus">
                {color && icon ? <Icon color={color} icon={icon} size="xxs" /> : null}
                {children}
            </MenuItem>
        );
    }
}

export default MenuItemWithStatus;
