import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { AppState } from "../../../app/models/AppState";
import { getCountries, getSubdivisionsByCountryCode } from "../../../app/reducer/selectors";
import { checkShouldDisallowPsuReplacements } from "../../../utils/partOrder";
import { partOrdersActions } from "../../reducer";
import {
    getAdditionalRecipients,
    getContacts,
    getInitialAdditionalRecipients,
    getPartDefinitions,
    getPartOrderErrors,
    getPartOrderErrorsByView,
    getPartOrderIsComplete,
    getPartOrderIsDirty,
    getPartOrderIsValid,
    getPartOrderModified,
    getPartOrderUIStatesById,
    getSavePartOrdersRequestState,
    getShippingAddresses,
    getSubmitPartOrdersRequestState,
} from "../../reducer/selectors";
import { Props } from "./PartOrder";

type StateProps = Pick<
    Props,
    | "countries"
    | "subdivisionsByCountryCode"
    | "contacts"
    | "errors"
    | "isComplete"
    | "isDirty"
    | "isValid"
    | "modified"
    | "partDefinitions"
    | "saveState"
    | "shippingAddressSuggestions"
    | "submitState"
    | "additionalRecipients"
    | "initialAdditionalRecipients"
    | "rolledUpErrors"
    | "disallowPsuReplacements"
>;
type DispatchProps = Pick<
    Props,
    | "clearSaveAndSubmitErrors"
    | "fetchShippingAddressSuggestions"
    | "fetchQuantityLimits"
    | "onSave"
    | "onSelectContact"
    | "onSelectAddress"
    | "onSubmit"
    | "validate"
    | "reset"
    | "onChangePartOrderLineItem"
    | "onAddPartOrderLineItem"
    | "onRemovePartOrderLineItem"
    | "onChangeAddress"
    | "onChangeContact"
    | "onChangeAdditionalRecipients"
    | "onChangeNotes"
    | "onChangeTaxpayerFields"
    | "onChangeEori"
>;
type OwnProps = Omit<Props, keyof StateProps | keyof DispatchProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
    state,
    { partOrder: { id, hardwareIdentity } }
) => {
    return {
        ...getPartOrderUIStatesById(state)[id],
        countries: getCountries(state),
        subdivisionsByCountryCode: getSubdivisionsByCountryCode(state),
        initialAdditionalRecipients: getInitialAdditionalRecipients(state, id),
        additionalRecipients: getAdditionalRecipients(state, id),
        errors: getPartOrderErrors(state, id),
        contacts: getContacts(state, id),
        isComplete: getPartOrderIsComplete(state, id),
        isDirty: getPartOrderIsDirty(state, id),
        isValid: getPartOrderIsValid(state, id),
        modified: getPartOrderModified(state, id),
        saveState: getSavePartOrdersRequestState(state),
        shippingAddressSuggestions: getShippingAddresses(state, id),
        submitState: getSubmitPartOrdersRequestState(state),
        rolledUpErrors: getPartOrderErrorsByView(state, id),
        partDefinitions: getPartDefinitions(state, id),
        disallowPsuReplacements: checkShouldDisallowPsuReplacements(
            hardwareIdentity.storageClass,
            hardwareIdentity.versionNumber
        ),
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch,
    { orgId, partOrder: { id: partOrderId }, forceEditable }
) => {
    return {
        clearSaveAndSubmitErrors: () => {
            dispatch(partOrdersActions.save.reset({ orgId, partOrderId }));
            dispatch(partOrdersActions.submit.reset({ orgId, partOrderId }));
        },
        fetchShippingAddressSuggestions: () =>
            dispatch(partOrdersActions.fetchShippingAddressSuggestions.do({ orgId, partOrderId }, partOrderId)),
        fetchQuantityLimits: () =>
            dispatch(partOrdersActions.fetchQuantityLimits.do({ orgId, partOrderId }, partOrderId)),
        onChangePartOrderLineItem: (lineItem, at) =>
            dispatch(partOrdersActions.changeLineItem({ orgId, partOrderId, values: lineItem, at, forceEditable })),
        onAddPartOrderLineItem: () => dispatch(partOrdersActions.addLineItem({ orgId, partOrderId, forceEditable })),
        onRemovePartOrderLineItem: (at) =>
            dispatch(partOrdersActions.removeLineItem({ orgId, partOrderId, at, forceEditable })),
        onChangeAddress: (address) =>
            dispatch(partOrdersActions.changeAddress({ orgId, partOrderId, values: address, forceEditable })),
        onChangeContact: (contact) =>
            dispatch(partOrdersActions.changeContact({ orgId, partOrderId, values: contact, forceEditable })),
        onChangeAdditionalRecipients: (recipients) =>
            dispatch(partOrdersActions.changeAdditionalRecipients({ orgId, partOrderId, recipients, forceEditable })),
        onChangeNotes: (notes) =>
            dispatch(partOrdersActions.changeNotes({ orgId, partOrderId, values: notes, forceEditable })),
        onChangeTaxpayerFields: (taxpayerFields) =>
            dispatch(partOrdersActions.changeTaxpayerFields({ orgId, partOrderId, taxpayerFields, forceEditable })),
        onChangeEori: (eori) => dispatch(partOrdersActions.changeEori({ orgId, partOrderId, eori, forceEditable })),
        onSave: () => dispatch(partOrdersActions.save.do({ orgId, partOrderId })),
        onSelectContact: (contact) =>
            dispatch(partOrdersActions.selectContact({ orgId, partOrderId, contact, forceEditable })),
        onSelectAddress: (address) =>
            dispatch(partOrdersActions.selectAddress({ orgId, partOrderId, address, forceEditable })),
        onSubmit: () => dispatch(partOrdersActions.submit.do({ orgId, partOrderId })),
        validate: () => dispatch(partOrdersActions.doValidate({ orgId, partOrderId })),
        reset: () => dispatch(partOrdersActions.reset({ orgId, partOrderId })),
    };
};

export { mapStateToProps, mapDispatchToProps };
