import { isEmpty } from "../../forms/validators/utils";
import { messages } from "../../messages";
import { Level } from "../../models/ValidationError";
import { Validator } from "../../models/Validator";

export const required: Validator = (value?: string) => {
    return isEmpty(value) || value === null
        ? {
              level: Level.Incomplete,
              message: messages.forms.validation.required()
          }
        : undefined;
};
