import classnames from "classnames";
import { flatten } from "lodash";
import React, { Component } from "react";
import { isOptionGroups } from "../../../utils/guards";
import { Option } from "../../models/Option";
import { OptionGroup } from "../../models/OptionGroup";
import { FieldProps } from "../../props/forms";
import DropdownOption from "../Dropdown/DropdownOption";
import Icon from "../Icon";

const PLACEHOLDER_OPTION_VALUE = "__PLACEHOLDER__";
const TRIGGER_OPTION_VALUE = "__TRIGGER__";
const EMPTY_OPTION_VALUE = "__EMPTY__";

interface TriggerOption {
    label: string;
    onClick: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
    /** The position of the trigger option - either first or last in the list */
    position?: "top" | "bottom";
}

export interface Props extends FieldProps, React.SelectHTMLAttributes<HTMLSelectElement> {
    /**
     * If provided, adds a disabled option that acts as the placeholder text. A
     * falsy value or value that does not match any provided options will result
     * in the placeholder being shown.
     */
    placeholder?: string;
    options: Option[] | OptionGroup[];
    showEmptyGroups?: boolean;
    /**
     * If provided, appends an additional option that can trigger a callback
     * when clicked by the user. Clicking this option will not trigger an
     * onChange call.
     */
    trigger?: TriggerOption;
    /**
     * If `true`, a button appears when a selection is made that when clicked
     * will clear the selection. Defaults to `false`.
     */
    clearable?: boolean;
    /**
     * Controls whether the clear icon is disabled. Regardless of this flag, it
     * will only ever be enabled when there is a value. By default, this follows
     * `disable`, but you can use this prop to override it.
     */
    disableClear?: boolean;
}

function PlaceholderOption({ text }: { text: string }) {
    return (
        <DropdownOption disabled value={PLACEHOLDER_OPTION_VALUE}>
            {text}
        </DropdownOption>
    );
}

function Trigger({ text }: { text: string }) {
    return <DropdownOption value={TRIGGER_OPTION_VALUE}>{`-- ${text} --`}</DropdownOption>;
}

function EmptyOption() {
    return (
        <DropdownOption value={EMPTY_OPTION_VALUE} disabled>
            &mdash;
        </DropdownOption>
    );
}

/**
 * A select component that supports:
 * - Option groups
 * - Trigger option (e.g. "Create new...")
 * - Clear selection button
 *
 */
class Dropdown extends Component<Props> {
    private select = React.createRef<HTMLSelectElement>();

    constructor(props: Props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleClearSelection = this.handleClearSelection.bind(this);
    }

    render() {
        const {
            error,
            dirty,
            value,
            placeholder,
            trigger,
            options,
            showEmptyGroups,
            className,
            clearable = false,
            disabled,
            disableClear,
            ...props
        } = this.props;
        const selectedOption = this.selectedOption;
        const { position = "top" } = trigger || {};

        return (
            <div
                className={classnames("Dropdown", className, {
                    disabled,
                    placeholder: !selectedOption,
                    dirty: dirty,
                    error,
                })}
            >
                <select
                    ref={this.select}
                    {...props}
                    disabled={disabled}
                    value={selectedOption ? selectedOption.value : PLACEHOLDER_OPTION_VALUE}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                >
                    {placeholder ? <PlaceholderOption text={placeholder} /> : null}
                    {trigger && position === "top" ? <Trigger text={trigger.label} /> : null}
                    {isOptionGroups(options)
                        ? options.map(({ label, options }) =>
                              showEmptyGroups || options.length > 0 ? (
                                  <optgroup label={label} key={label}>
                                      {this.renderOptions(options, true)}
                                  </optgroup>
                              ) : null
                          )
                        : this.renderOptions(options)}
                    {trigger && position === "bottom" ? <Trigger text={trigger.label} /> : null}
                </select>
                {clearable && (
                    <Icon
                        title="Clear"
                        className={classnames("Dropdown-clearSelection", {
                            show: !!selectedOption,
                        })}
                        icon={["far", "times-circle"]}
                        onClick={this.handleClearSelection}
                    />
                )}
            </div>
        );
    }

    protected renderOptions(options: Option[], renderEmpty = false) {
        if (!options.length && renderEmpty) {
            return <EmptyOption />;
        }

        return options.map(({ key, text, value, disabled }) => (
            <DropdownOption key={key !== undefined ? key : String(value)} value={value} disabled={disabled}>
                {text}
            </DropdownOption>
        ));
    }

    private get selectedOption(): Option | undefined {
        const { value } = this.props;
        let options = this.props.options;

        if (isOptionGroups(options)) {
            options = flatten(options.map((group) => group.options));
        }

        return value ? options.find((option) => option.value === value) : undefined;
    }

    private handleClearSelection() {
        const { disabled, disableClear } = this.props;
        if (!(disableClear ?? disabled)) {
            if (this.select.current) {
                // Hack to trigger React's change event
                const setter = Object.getOwnPropertyDescriptor(HTMLSelectElement.prototype, "value")?.set;
                setter?.call(this.select.current, "");
                const event = new Event("change", { bubbles: true });
                this.select.current.dispatchEvent(event);
            }
        }
    }

    private handleFocus(evt: React.FocusEvent<HTMLSelectElement>) {
        const { onFocus } = this.props;
        onFocus?.(this.prepareEvent(evt));
    }

    private handleBlur(evt: React.FocusEvent<HTMLSelectElement>) {
        const { onBlur } = this.props;
        onBlur?.(this.prepareEvent(evt));
    }

    private handleChange(evt: React.ChangeEvent<HTMLSelectElement>) {
        const { trigger, onChange } = this.props;
        if (trigger && evt.target.value === TRIGGER_OPTION_VALUE) {
            trigger.onClick(evt);
            return;
        }

        onChange?.(this.prepareEvent(evt));
    }

    /**
     * To avoid exposing internal custom values, we bubble up modified events
     * with target.value set to an empty string in the case that the value is
     * the placeholder. A more accurate value would be `undefined`, but that's
     * not accepted by ReactSyntheticEvent).
     */
    private prepareEvent<T extends React.FocusEvent<HTMLSelectElement> | React.ChangeEvent<HTMLSelectElement>>(
        evt: T
    ): T {
        if (evt.target.value === PLACEHOLDER_OPTION_VALUE) {
            evt.target.value = "";
        }

        return evt;
    }
}

export default Dropdown;
