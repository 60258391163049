import { capitalize } from "lodash";

export enum TimeOfDay {
    Any = "ANY",
    Morning = "MORNING",
    Afternoon = "AFTERNOON",
    Evening = "NIGHT"
}

export function getLabel(timeOfDay: TimeOfDay): string {
    return capitalize(timeOfDay);
}
