import React, { StatelessComponent } from "react";
import { Transition } from "react-transition-group";
import { ENTERED, ENTERING, EXITING, EXITED } from "react-transition-group/Transition";

interface Props {
    in?: boolean;
    /** ms */
    duration?: number;
    /** px */
    offset?: number;
    /**
     * Provide the approx height in px for the element for smoother animation.
     */
    approxHeight?: number;
}

function defaultStyles(duration: number) {
    return {
        position: "relative",
        transition: `all ${duration}ms cubic-bezier(.51,.92,.75,.99)`,
        zIndex: 1,
        marginBottom: 0
    };
}

function transitionStyles(offset: number, height: number = offset) {
    return {
        [ENTERING]: {
            top: `-${offset}px`,
            opacity: 0.01,
            marginBottom: `-${height}px`
        },
        [ENTERED]: {
            top: 0,
            opacity: 1,
            marginBottom: 0
        },
        [EXITING]: {
            top: `-${offset}px`,
            opacity: 0.01,
            marginBottom: `-${height}px`
        },
        [EXITED]: {
            top: `-${offset}px`,
            opacity: 0
        }
    };
}

/**
 * A transition wrapper that animates the element sliding down when mounting.
 */
const SlideDown: StatelessComponent<Props> = ({ duration = 120, offset = 16, in: inProp, children, approxHeight }) => (
    <Transition in={inProp} timeout={duration} unmountOnExit>
        {(state: string) => (
            <div style={{ ...defaultStyles(duration), ...transitionStyles(offset, approxHeight)[state] }}>
                {children}
            </div>
        )}
    </Transition>
);

export default SlideDown;
