import React, { Component } from "react";
import classnames from "classnames";

interface Props {
    className?: string;
}

class Body extends Component<Props> {
    render() {
        const { className } = this.props;
        return <div className={classnames("Body", className)}>{this.props.children}</div>;
    }
}

export default Body;
