import { messages } from "../../shared/messages";
import { Audited } from "../../shared/models/Audited";
import { HardwareIdentity } from "../../shared/models/HardwareIdentity";
import { OcaClass } from "../../shared/models/OcaClass";
import { Resource } from "../../shared/models/Resource";
import { Versioned } from "../../shared/models/Versioned";
import { HardwareCategorySummary } from "./HardwareCategorySummary";

export const IPV4_ADDRESS_KEY = "ipv4Address";
export const IPV4_SUBNET_MASK_KEY = "ipv4Netmask";
export const IPV4_GATEWAY_KEY = "ipv4Gateway";
export const IPV4_BGP_PEER_KEY = "ipv4BGPPeer";
export const IPV6_ADDRESS_KEY = "ipv6Address";
export const IPV6_PREFIX_LENGTH_KEY = "ipv6PrefixLength";
export const IPV6_GATEWAY_KEY = "ipv6Gateway";
export const IPV6_BGP_PEER_KEY = "ipv6BGPPeer";
export const BMC_V4_ADDRESS_KEY = "bmcv4Address";
export const BMC_V4_GATEWAY_KEY = "bmcv4Gateway";
export const BMC_V4_NETMASK_KEY = "bmcv4Netmask";
export const BMC_V6_ADDRESS_KEY = "bmcv6Address";
export const BMC_V6_GATEWAY_KEY = "bmcv6Gateway";
export const BMC_V6_PREFIX_LENGTH_KEY = "bmcv6PrefixLength";

export const NETWORK_CONFIG_IPV4_KEYS: (keyof NetworkConfigFields)[] = [
    IPV4_ADDRESS_KEY,
    IPV4_SUBNET_MASK_KEY,
    IPV4_GATEWAY_KEY,
    IPV4_BGP_PEER_KEY,
];
export const NETWORK_CONFIG_IPV6_KEYS: (keyof NetworkConfigFields)[] = [
    IPV6_ADDRESS_KEY,
    IPV6_PREFIX_LENGTH_KEY,
    IPV6_GATEWAY_KEY,
    IPV6_BGP_PEER_KEY,
];
export const NETWORK_CONFIG_BMC_V4_KEYS: (keyof NetworkConfigFields)[] = [
    BMC_V4_ADDRESS_KEY,
    BMC_V4_GATEWAY_KEY,
    BMC_V4_NETMASK_KEY,
];
export const NETWORK_CONFIG_BMC_V6_KEYS: (keyof NetworkConfigFields)[] = [
    BMC_V6_ADDRESS_KEY,
    BMC_V6_GATEWAY_KEY,
    BMC_V6_PREFIX_LENGTH_KEY,
];
export const NETWORK_CONFIG_BMC_KEYS: (keyof NetworkConfigFields)[] = [
    ...NETWORK_CONFIG_BMC_V4_KEYS,
    ...NETWORK_CONFIG_BMC_V6_KEYS,
];
export const NETWORK_CONFIG_KEYS: (keyof NetworkConfigFields)[] = [
    ...NETWORK_CONFIG_IPV4_KEYS,
    ...NETWORK_CONFIG_IPV6_KEYS,
    ...NETWORK_CONFIG_BMC_KEYS,
];

export const COPYABLE_NETWORK_CONFIG_FIELDS: (keyof NetworkConfigFields)[] = [
    IPV4_ADDRESS_KEY,
    IPV4_SUBNET_MASK_KEY,
    IPV4_GATEWAY_KEY,
    IPV4_BGP_PEER_KEY,
    IPV6_ADDRESS_KEY,
    IPV6_PREFIX_LENGTH_KEY,
    IPV6_GATEWAY_KEY,
    IPV6_BGP_PEER_KEY,
];

export interface NetworkConfigFields {
    [IPV4_ADDRESS_KEY]: string;
    [IPV4_SUBNET_MASK_KEY]: string;
    [IPV4_GATEWAY_KEY]: string;
    [IPV4_BGP_PEER_KEY]: string;
    [IPV6_ADDRESS_KEY]?: string;
    [IPV6_PREFIX_LENGTH_KEY]?: string;
    [IPV6_GATEWAY_KEY]?: string;
    [IPV6_BGP_PEER_KEY]?: string;
    [BMC_V4_ADDRESS_KEY]?: string;
    [BMC_V4_GATEWAY_KEY]?: string;
    [BMC_V4_NETMASK_KEY]?: string;
    [BMC_V6_ADDRESS_KEY]?: string;
    [BMC_V6_GATEWAY_KEY]?: string;
    [BMC_V6_PREFIX_LENGTH_KEY]?: string;
}

export interface NetworkConfig extends Resource, Versioned, Audited, NetworkConfigFields {
    hardwareIdentity?: HardwareIdentity;
    hardwareCategory?: HardwareCategorySummary;
    hostname?: string;
    lagg?: boolean;
    laggPortCount?: number;
}

export function hasAnyIpv6Fields(networkConfig: NetworkConfigFields): boolean {
    return NETWORK_CONFIG_IPV6_KEYS.some((key) => networkConfig[key] !== undefined && networkConfig[key] !== "");
}

export function hasAnyBmcIpv4Fields(networkConfig: NetworkConfigFields): boolean {
    return NETWORK_CONFIG_BMC_V4_KEYS.some((key) => networkConfig[key] !== undefined && networkConfig[key] !== "");
}

export function hasAnyBmcIpv6Fields(networkConfig: NetworkConfigFields): boolean {
    return NETWORK_CONFIG_BMC_V6_KEYS.some((key) => networkConfig[key] !== undefined && networkConfig[key] !== "");
}

export function getStorageClass(networkConfig: NetworkConfig): OcaClass | undefined {
    if (networkConfig.hardwareCategory) {
        return networkConfig.hardwareCategory.ocaClass;
    }

    if (networkConfig.hardwareIdentity) {
        return networkConfig.hardwareIdentity.storageClass;
    }

    return;
}

export function getLabel(key: keyof NetworkConfigFields): string {
    try {
        return messages.labels.networkConfig[key]();
    } catch (e) {
        console.warn(e);
        return key;
    }
}
