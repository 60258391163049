import { Subscriber } from "../models/Subscriber";

export default class Publisher<Payload> {
    protected subscribers: Subscriber<Payload>[] = [];

    subscribe(subscriber: Subscriber<Payload>) {
        this.subscribers.push(subscriber);
        return () => this.unsubscribe(subscriber);
    }

    unsubscribe(unsubscriber: Subscriber<Payload>) {
        this.subscribers = this.subscribers.filter(subscriber => subscriber !== unsubscriber);
    }

    protected publish(payload: Payload) {
        this.subscribers.forEach(subscriber => subscriber(payload));
    }
}
