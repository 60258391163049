import React, { PureComponent, ReactNode } from "react";
import classnames from "classnames";
import { Location } from "history";
import Header from "../../../shared/components/Header";
import Link from "../../../shared/components/Link";
import Container from "../../../shared/components/Container";

interface Props {
    title?: string;
    links: readonly {
        name: string;
        to: Location;
    }[];
    accessory?: ReactNode;
    horizontal: boolean;
    className?: string;
}

class Nav extends PureComponent<Props> {
    static defaultProps = {
        horizontal: true,
    };

    render() {
        const { links, title, horizontal, accessory, className } = this.props;
        return (
            <div className={classnames("Nav", className, { horizontal })}>
                <Container fluid>
                    {title && (
                        <Header className="Nav__header" title={title} as="h2">
                            {accessory}
                        </Header>
                    )}
                    <ul className="Nav__list">
                        {links.map(({ to, name }) => (
                            <li key={name} className="Nav__item">
                                <Link to={to} activeClassName="active">
                                    {name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Container>
            </div>
        );
    }
}

export default Nav;
