import { createContext } from "react";

/**
 * Returns whether or not sudo mode is on. It can only ever be on when the user
 * is an Admin (Netflix employee). Some components render differently with relaxed
 * restrictions under `sudo` mode. For example, site surveys become unlocked for
 * edit regardless of the server's editable flags. This stems from a request
 * from Logistics to allow Netflix users to edit properties that ISP partners
 * may have entered incorrectly.
 */
export const SudoContext = createContext<boolean>(false);
