export const MEECHUM_SESSION_REFRESH_ERROR = "Meechum.SessionRefreshError";
export const MEECHUM_SESSION_REFRESH_TIMEOUT = "Meechum.SessionRefreshTimeout";
export const LOGIN_WITH_CODE = "Login.WithCode";
export const LOGIN_WITH_SSO = "Login.WithSSO";
export const NETWORK_CONFIG_COPY = "SiteSurvey.CopyNetworkConfig";
export const NETWORK_CONFIG_PASTE = "SiteSurvey.PasteNetworkConfig";
export const NETWORK_CONFIG_NAVIGATE = "SiteSurvey.SelectNetworkConfig";
export const CLICK_ERROR_ACTION_ITEM = "SiteSurvey.ClickErrorActionItem";
export const TOGGLE_SUDO = "App.SudoToggled";
export const REQUEST_MADE = "RequestMade";
export const REQUEST_FAILURE = "RequestFailure";
export const RESPONSE_FAILURE = "ResponseFailure";
export const RESPONSE_SUCCESS = "ResponseSuccess";
export const SITE_SURVEY_SAVE = "SiteSurvey.Save";
export const COPY_SITE_ADDRESS = "SiteSurvey.CopySiteAddress";
export const BMC_CONFIG_SAVE = "SiteSurvey.BmcConfigSave";
export const BMC_CONFIG_ERROR = "SiteSurvey.BmcConfigError";
