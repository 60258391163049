import { noLaterThanNow } from "../../utils/dates";

/**
 * An interface with attributes for create/update audit log.
 */
export interface Audited {
    readonly dateCreated: string;
    readonly createdBy?: string;
    readonly lastUpdated: string;
    readonly lastUpdatedBy?: string;
}

export function getLastUpdated({ lastUpdated }: Audited): Date {
    return noLaterThanNow(new Date(lastUpdated));
}

export function getDateCreated({ dateCreated }: Audited): Date {
    return new Date(dateCreated);
}
