import { validate } from "../validation";
import { all, length, regex, required } from "../forms/validators";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { FieldMetadata } from "../models/FieldMetadata";
import { EORI_FIELD_KEY } from "../models/EoriCompliant";
import { messages } from "../messages";

const makeEoriFieldValidator = (fieldMetadata: FieldMetadata): ModelValidator<Record<string, string>> =>
    new Map([
        [
            EORI_FIELD_KEY,
            all(
                fieldMetadata.required ? required : null,
                length({ min: 3, message: messages.forms.validation.eoriLength() }),
                fieldMetadata.pattern
                    ? regex(new RegExp(fieldMetadata.pattern), messages.forms.validation.eoriPattern())
                    : null
            ),
        ],
    ]);

export function validateEoriField(
    eori?: string,
    eoriFieldMetadata?: FieldMetadata
): ModelValidationErrors<Record<string, string>> | undefined {
    return eoriFieldMetadata ? validate({ eori }, makeEoriFieldValidator(eoriFieldMetadata)) : undefined;
}
