import { ComponentType, COMPONENT_TYPES } from "../partOrders/models/ComponentType";
import { AllowedParts } from "../shared/models/AllowedParts";
import { OrderDimension } from "../shared/models/OrderDimension";
import { PartDefinitionWithFlags } from "../shared/models/PartDefinition";
import { DatacenterOptions } from "../siteSurveys/models/DatacenterOptions";

const COMPONENT_TYPE_TO_ORDER_DIMENSION: Map<
    ComponentType,
    | OrderDimension.Rack
    | OrderDimension.Optics
    | OrderDimension.PatchCable
    | OrderDimension.PowerCable
    | OrderDimension.Psu
> = new Map([
    [ComponentType.Rack, OrderDimension.Rack],
    [ComponentType.Optics, OrderDimension.Optics],
    [ComponentType.PatchCable, OrderDimension.PatchCable],
    [ComponentType.PowerCable, OrderDimension.PowerCable],
    [ComponentType.Psu, OrderDimension.Psu]
]);

function getDimension(componentType: ComponentType) {
    return COMPONENT_TYPE_TO_ORDER_DIMENSION.get(componentType);
}

export function applyPartsRestrictions(
    datacenterOptions: DatacenterOptions,
    allowedParts: AllowedParts | undefined
): DatacenterOptions {
    if (!allowedParts) {
        return datacenterOptions;
    }

    return COMPONENT_TYPES.reduce((out, componentType) => {
        const partDefinitions = datacenterOptions[componentType];
        const dimension = getDimension(componentType);

        if (!dimension) {
            out[componentType] = partDefinitions;
        } else {
            const allowedIds: Set<number> = new Set(
                allowedParts.dimensionSummaries[dimension]?.allowed.map(({ id }) => id) ?? []
            );
            out[componentType] = partDefinitions.map((partDefinition) => ({
                ...partDefinition,
                restricted: !allowedIds.has(partDefinition.id)
            }));
        }

        return out;
    }, {} as Record<ComponentType, PartDefinitionWithFlags[]>);
}
