import { messages, getMessage } from "../../shared/messages";

export enum SiteSurveyView {
    Site = "site",
    Shipping = "shipping",
    Appliances = "appliances",
    More = "more"
}

export function title(view: SiteSurveyView) {
    return getMessage(() => messages.title.siteSurveyView[view](null), view);
}
