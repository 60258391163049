import React, { useCallback, useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import Collection from "../../../shared/components/Collection";
import Divider from "../../../shared/components/Divider";
import FadeIn from "../../../shared/components/FadeIn";
import Message from "../../../shared/components/Message";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import { messages } from "../../../shared/messages";
import { ModelValidationErrors } from "../../../shared/models/ModelValidationErrors";
import { ID_KEY } from "../../../shared/models/Resource";
import { traverse } from "../../../shared/validation";
import ApplianceBrowser from "../../../siteSurveys/components/ApplianceBrowser";
import BgpConfigForm from "../../../siteSurveys/components/BgpConfigForm";
import NetworkConfigForm from "../../../siteSurveys/components/NetworkConfigForm";
import { NetworkConfig, NetworkConfigFields, NETWORK_CONFIG_BMC_KEYS } from "../../../siteSurveys/models/NetworkConfig";
import { BgpConfig, BgpConfigFields, BGP_ASN_KEY, BGP_MULTIHOP_KEY } from "../../models/BgpConfig";

export interface Props {
    initialBgpAsn: number;
    initialBgpMultihop: number;
    bgpAsn?: number;
    bgpMultihop?: number;
    asns: readonly number[];
    initialNetworkConfigs: readonly NetworkConfig[];
    networkConfigs?: NetworkConfig[];
    copyableNetworkConfigIds: Set<number>;
    onChangeBgpConfig: (values: Partial<BgpConfigFields>) => void;
    onChangeNetworkConfig: (values: Partial<NetworkConfigFields>, index: number) => void;
    onSetActiveNetworkConfigIndex?: (index: number) => void;
    activeIndex?: number;
    /**
     * A list of validation errors related to fields that the appliances
     */
    validationErrors?: ModelValidationErrors<BgpConfig & { networkConfigs: NetworkConfig[] }>;
}

function AppliancesEditor(props: Props) {
    const {
        asns,
        initialBgpAsn,
        bgpAsn,
        initialBgpMultihop,
        bgpMultihop,
        initialNetworkConfigs,
        networkConfigs,
        validationErrors,
        copyableNetworkConfigIds,
        onSetActiveNetworkConfigIndex,
        activeIndex,
        onChangeBgpConfig,
        onChangeNetworkConfig,
    } = props;

    const emptyBmcConfig = useMemo(
        // create empty BMC config with values set to empty strings
        // reason: we can't override previously saved values with undefined using the partial update endpoint
        () => NETWORK_CONFIG_BMC_KEYS.reduce((obj, key) => ({ ...obj, [key]: "" }), {}),
        []
    );

    const networkConfigTitle = useCallback((label?: string) => {
        let title = messages.title.applianceNetworkConfiguration();

        if (label) {
            title += ` ${label}`;
        }

        return title;
    }, []);

    const handleChangeBgpConfig = useCallback((values: Partial<BgpConfigFields>) => onChangeBgpConfig(values), [
        onChangeBgpConfig,
    ]);

    const handleChangeNetworkConfig = useCallback(
        (values: Partial<NetworkConfigFields>, at: number) => onChangeNetworkConfig(values, at),
        [onChangeNetworkConfig]
    );

    const handleResetBmcConfig = useCallback(
        (values: Partial<NetworkConfigFields>, at: number) => {
            onChangeNetworkConfig({ ...values, ...emptyBmcConfig }, at);
        },
        [onChangeNetworkConfig, emptyBmcConfig]
    );

    const handleCopyFromNetworkConfig = useCallback(
        (sourceId: number, destination: NetworkConfig) => {
            const at = initialNetworkConfigs.findIndex(({ id }) => id === destination[ID_KEY]);
            const source =
                networkConfigs?.find(({ id }) => id === sourceId) ??
                initialNetworkConfigs.find(({ id }) => id === sourceId);

            if (source) {
                onChangeNetworkConfig(source, at);
            }
        },
        [initialNetworkConfigs, networkConfigs, onChangeNetworkConfig]
    );

    const getNetworkConfig = useCallback((target: number) => networkConfigs?.find(({ id }) => id === target), [
        networkConfigs,
    ]);

    return (
        <Content title={messages.title.bgpConfiguration()}>
            {asns.length > 0 ? (
                <BgpConfigForm
                    initialValues={{
                        [BGP_ASN_KEY]: initialBgpAsn,
                        [BGP_MULTIHOP_KEY]: initialBgpMultihop,
                    }}
                    values={{
                        [BGP_ASN_KEY]: bgpAsn,
                        [BGP_MULTIHOP_KEY]: bgpMultihop,
                    }}
                    asns={asns}
                    onChange={handleChangeBgpConfig}
                    validationErrors={validationErrors}
                />
            ) : (
                    <>
                        <MissingInfo message={messages.notice.asnsNotRegistered()} />
                        <Message
                            highContrast
                            asBlock
                            status="error"
                            className="AppliancesEditor-errorMessage"
                            message={messages.notice.contactPEMToRegisterASNs()}
                        />
                    </>
                )}
            <Divider />
            <Segment>
                <Collection
                    index={activeIndex}
                    items={initialNetworkConfigs}
                    onSetIndex={onSetActiveNetworkConfigIndex}
                    renderEmpty={() => (
                        <MissingInfo
                            message={messages.notice.missing({
                                ITEMS: messages.entity.networkConfig({ COUNT: 0 }),
                            })}
                        />
                    )}
                >
                    {(initialNetworkConfig, index, onSetIndex, label) => {
                        const networkConfig = getNetworkConfig(initialNetworkConfig.id);
                        return (
                            <Content title={networkConfigTitle(label)}>
                                <ApplianceBrowser
                                    onSetActiveNetworkConfig={onSetIndex}
                                    activeIndex={index}
                                    networkConfigs={initialNetworkConfigs}
                                    copyableNetworkConfigIds={copyableNetworkConfigIds}
                                    onClickCopyNetworkConfig={(sourceId) =>
                                        handleCopyFromNetworkConfig(sourceId, networkConfig ?? initialNetworkConfig)
                                    }
                                />
                                <TransitionGroup component={null}>
                                    <FadeIn key={initialNetworkConfig.id} crossFade>
                                        <NetworkConfigForm
                                            initialValues={initialNetworkConfig}
                                            values={networkConfig}
                                            onChange={(values) => handleChangeNetworkConfig(values, index)}
                                            onToggleBmcConfigType={(values) => handleResetBmcConfig(values, index)}
                                            validationErrors={traverse(validationErrors, ["networkConfigs", index])}
                                        />
                                    </FadeIn>
                                </TransitionGroup>
                            </Content>
                        );
                    }}
                </Collection>
            </Segment>
        </Content>
    );
}

export default AppliancesEditor;
