import { groupBy, mapValues, orderBy, toNumber } from "lodash";
import memoizeOne from "memoize-one";
import React, { memo } from "react";
import Badge from "../../../shared/components/Badge";
import Dropdown from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormField from "../../../shared/components/FormField";
import TextInput from "../../../shared/components/TextInput";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { OptionGroup } from "../../../shared/models/OptionGroup";
import { PartDefinition, PartDefinitionWithFlags } from "../../../shared/models/PartDefinition";
import { FormPropsV2 } from "../../../shared/props/forms";
import { partDefinitionAsOption } from "../../../utils/options";
import { ComponentType, getLabel } from "../../models/ComponentType";
import {
    PartOrderLineItemFields,
    PARTS_ORDER_LINE_ITEM_KEYS,
    PART_KEY,
    QUANTITY_KEY,
} from "../../models/PartOrderLineItemFields";

const DEFAULT_MAX_QUANTITY = 99;
const DEFAULT_MIN_QUANTITY = 1;

export interface Props extends FormPropsV2<PartOrderLineItemFields<number>> {
    chooseFrom: PartDefinitionWithFlags[];
}

/**
 * The list of parts definitions is the same across all line items, so we'll
 * have the same memoized result for each part order while it is in focus.
 *
 * Part definitions may be restricted based on client-side logic. See
 * `checkShouldRestrictPart` in src/utils/partOrder.ts.
 */
const groupPartDefinitionsByType = memoizeOne((partDefinitions: PartDefinition[]): OptionGroup[] =>
    orderBy(
        Object.entries(
            mapValues(groupBy(partDefinitions, "componentType"), (partDefinitions) =>
                partDefinitions.map(partDefinitionAsOption)
            )
        ).map(([type, options]) => ({
            label: getLabel(type as ComponentType),
            options,
        })),
        (o) => o.label
    )
);

function PartOrderLineItemForm(props: Props) {
    const { compact, disabled, onSubmit, chooseFrom: partDefinitions } = props;
    const [{ dirty, errors, values }, { changeHandlers, touchHandlers }] = useForm(
        { keys: PARTS_ORDER_LINE_ITEM_KEYS },
        props
    );
    const selectedPartDefinitionId = values[PART_KEY];
    const selectedPartDefinition = partDefinitions.find(({ id }) => id === selectedPartDefinitionId);
    const selectedComponentType = selectedPartDefinition?.componentType;
    return (
        <Form className="PartOrderLineItemForm" onSubmit={onSubmit} compact={compact} horizontal>
            <FormField
                style={{ flexGrow: 2 }}
                fluid={false}
                name={PART_KEY}
                required
                label={messages.labels.partOrder.part()}
                labelAccessory={
                    selectedComponentType ? (
                        <Badge className="PartOrderLineItemForm-badge" kind="subdued">
                            {getLabel(selectedComponentType)}
                        </Badge>
                    ) : null
                }
                error={errors.get(PART_KEY)}
                disabled={disabled}
            >
                <Dropdown
                    dirty={dirty.has(PART_KEY)}
                    error={errors.has(PART_KEY)}
                    options={groupPartDefinitionsByType(partDefinitions)}
                    placeholder={messages.forms.placeholders.selectPart()}
                    onBlur={() => touchHandlers[PART_KEY]?.()}
                    value={values?.[PART_KEY]}
                    onChange={(evt) => changeHandlers[PART_KEY]?.(toNumber(evt.target.value))}
                />
            </FormField>
            <FormField
                style={{ flexGrow: 1 }}
                fluid={false}
                name={QUANTITY_KEY}
                required
                label={messages.labels.partOrder.quantity()}
                error={errors.get(QUANTITY_KEY)}
                disabled={disabled}
                placeholder={messages.forms.placeholders.quantity()}
            >
                <TextInput
                    dirty={dirty.has(QUANTITY_KEY)}
                    error={errors.has(QUANTITY_KEY)}
                    type="number"
                    min={DEFAULT_MIN_QUANTITY}
                    max={DEFAULT_MAX_QUANTITY}
                    step="1"
                    onBlur={() => touchHandlers[QUANTITY_KEY]?.()}
                    value={values?.[QUANTITY_KEY] ?? ""}
                    onChange={(evt) => changeHandlers[QUANTITY_KEY]?.(toNumber(evt.target.value))}
                />
            </FormField>
        </Form>
    );
}

export default memo(PartOrderLineItemForm);
