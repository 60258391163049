import { Attachment } from "../../shared/models/Attachment";
import { CfdiCompliant } from "../../shared/models/CfdiCompliant";
import { Datacenter } from "../../shared/models/Datacenter";
import { EoriCompliant } from "../../shared/models/EoriCompliant";
import { JiraAssociable } from "../../shared/models/JiraAssociable";
import { OrgSummary } from "../../shared/models/OrgSummary";
import { PartnerForm } from "../../shared/models/PartnerForm";
import { Site } from "../../shared/models/Site";
import { SiteSummary } from "../../shared/models/SiteSummary";
import { TaxpayerAffiliated } from "../../shared/models/TaxpayerAffiliated";
import { BgpConfig } from "./BgpConfig";
import { NetworkConfig } from "./NetworkConfig";
import { SiteSurveyAddresses } from "./SiteSurveyAddresses";
import { SiteSurveyContacts } from "./SiteSurveyContacts";
import { SiteSurveyHardware } from "./SiteSurveyHardware";
import { SiteSurveyProperty } from "./SiteSurveyProperty";
import { SiteSurveyStatus } from "./SiteSurveyStatus";

export const NETWORK_CONFIGS_KEY = "networkConfigs";

export interface SiteSurvey
    extends PartnerForm,
        BgpConfig,
        Datacenter,
        SiteSurveyContacts,
        SiteSurveyAddresses,
        JiraAssociable,
        TaxpayerAffiliated,
        CfdiCompliant,
        EoriCompliant {
    editableProperties: Partial<Record<SiteSurveyProperty, boolean>>;
    applianceCount?: number;
    rma?: boolean;
    newSite: boolean;
    site?: Site | SiteSummary;
    interfaceSpeed?: number;
    name: string;
    [NETWORK_CONFIGS_KEY]: NetworkConfig[];
    org: OrgSummary;
    /**
     * @deprecated
     */
    contentRegion?: string;
    /**
     * ISO-3166 2-letter country code
     */
    countryCode: string;
    contentRegionId: number;
    manifestClusterId?: number;
    expectedInitialTrafficGbps: number;
    partnerRecipients?: string;
    siteSurveyStatus: SiteSurveyStatus;
    siteSurveyHardwares: SiteSurveyHardware[];
    attachments: Attachment[];
}
