import FileSaver from "file-saver";
import { debounce, groupBy } from "lodash";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppState } from "../../app/models/AppState";
import { ComponentType } from "../../partOrders/models/ComponentType";
import topgear from "../../services/topgear";
import { TopgearHelper } from "../../services/topgear/TopgearHelper";
import { SITE_SURVEY_SAVE } from "../../shared/events";
import logger from "../../shared/logger";
import { AddressFields } from "../../shared/models/AddressFields";
import { Attachment } from "../../shared/models/Attachment";
import { CurrentType } from "../../shared/models/CurrentType";
import { POWER_CABLE_TYPE_KEY, PSU_TYPE_KEY } from "../../shared/models/Datacenter";
import { ForceEditableFlag } from "../../shared/models/ForceEditableFlag";
import { PartDefinition } from "../../shared/models/PartDefinition";
import RequestMiddleware from "../../shared/models/RequestMiddleware";
import { ID_KEY } from "../../shared/models/Resource";
import { VALIDATION_DEBOUNCE_DURATION_MS } from "../../shared/settings";
import { validateSiteSurvey } from "../../shared/validation/siteSurvey";
import { matchesAny } from "../../utils/actions";
import { getResponseErrorsAsMessage } from "../../utils/requests";
import { SHIPPING_ADDRESS_KEY } from "../models/SiteSurveyAddresses";
import { SiteSurveyReference } from "../models/SiteSurveyReference";
import { siteSurveysActions } from "../reducer";
import {
    getAddressVerificationsState,
    getAllowedDatacenterOptions,
    getDatacenterOptions,
    getOpenSiteSurveyIds,
    getSiteSurvey,
    getSiteSurveyIsDirty,
    getSiteSurveyModified,
    getSiteSurveyUiState,
} from "../reducer/selectors";

export class SiteSurveysMiddleware extends RequestMiddleware<Dispatch, AppState> {
    constructor() {
        super();

        this.validateSiteSurvey = debounce(this.validateSiteSurvey, VALIDATION_DEBOUNCE_DURATION_MS, {
            leading: true,
        });
    }

    get middleware(): Middleware<Record<string, unknown>, AppState> {
        return (api: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: AnyAction) => {
            next(action);

            if (siteSurveysActions.fetch.do.match(action)) {
                this.fetchSiteSurveys(api, action.payload.orgId, action.payload.siteSurveyIds, action.payload.refresh);
            }

            if (siteSurveysActions.save.do.match(action)) {
                this.saveSiteSurvey(api, action.payload.orgId, action.payload.siteSurveyId);
            }

            if (siteSurveysActions.save.did.match(action)) {
                api.dispatch(
                    siteSurveysActions.doValidate({
                        orgId: action.payload.orgId,
                        siteSurveyId: action.payload.siteSurvey[ID_KEY],
                    })
                );
            }

            if (siteSurveysActions.submit.do.match(action)) {
                this.submitSiteSurvey(api, action.payload.orgId, action.payload.siteSurveyId);
            }

            if (siteSurveysActions.fetchShippingAddressSuggestions.do.match(action)) {
                this.fetchShippingAddressSuggestions(api, action.payload.orgId, action.payload.siteSurveyId);
            }

            if (siteSurveysActions.fetchDatacenterOptions.do.match(action)) {
                this.fetchDatacenterOptions(api);
            }

            if (siteSurveysActions.downloadAttachments.do.match(action)) {
                this.fetchAttachment(api, action.payload.orgId, action.payload.siteSurveyId, action.payload.attachment);
            }

            if (siteSurveysActions.fetchAllowedParts.do.match(action)) {
                this.refreshAllowedParts(api, action.payload.orgId, action.payload.siteSurveyId);
            }

            if (siteSurveysActions.downloadAttachments.did.match(action)) {
                FileSaver.saveAs(action.payload.pdf, action.payload.attachment.filename);
            }

            if (siteSurveysActions.verifyAddress.do.match(action)) {
                this.verifyAddress(api, action.payload.orgId, action.payload.siteSurveyId, action.payload.address);
            }

            if (matchesAny<SiteSurveyReference>(action, [siteSurveysActions.save.did, siteSurveysActions.submit.did])) {
                this.refreshOpenSiteSurveys(api, action.payload.orgId);
            }

            // Edits
            if (
                matchesAny<SiteSurveyReference & ForceEditableFlag>(action, [
                    siteSurveysActions.changeDatacenter,
                    siteSurveysActions.changeAdditionalRecipients,
                    siteSurveysActions.changeSiteAddress,
                    siteSurveysActions.changeShippingAddress,
                    siteSurveysActions.changeContact,
                    siteSurveysActions.changeBgpConfig,
                    siteSurveysActions.changeNotes,
                    siteSurveysActions.changeNetworkConfig,
                    siteSurveysActions.selectContact,
                    siteSurveysActions.selectShippingAddress,
                    siteSurveysActions.changeTaxpayerFields,
                    siteSurveysActions.changeCfdiFields,
                    siteSurveysActions.changeEori,
                ])
            ) {
                const { orgId, siteSurveyId, forceEditable } = action.payload;
                this.autoPickPowerCableIfAppropriate(api, orgId, siteSurveyId);
                // When any changes are made, reset the save/submit error states
                // and trigger validation.
                api.dispatch(siteSurveysActions.save.reset({ orgId, siteSurveyId }));
                api.dispatch(siteSurveysActions.submit.reset({ orgId, siteSurveyId }));
                api.dispatch(
                    siteSurveysActions.doValidate({
                        orgId,
                        siteSurveyId,
                        forceEditable,
                        // Verify shipping address if action involves change to
                        // shipping address.
                        verifyShippingAddress: matchesAny<SiteSurveyReference & ForceEditableFlag>(action, [
                            siteSurveysActions.changeShippingAddress,
                            siteSurveysActions.selectShippingAddress,
                        ]),
                    })
                );
            }

            // Re-fetch allowed parts whenever a part definition edit is made.
            if (siteSurveysActions.changeDatacenter.match(action)) {
                const { orgId, siteSurveyId } = action.payload;
                api.dispatch(siteSurveysActions.fetchAllowedParts.do({ orgId, siteSurveyId }, siteSurveyId));
            }

            // Validate
            if (siteSurveysActions.doValidate.match(action)) {
                this.validateSiteSurvey(
                    api,
                    action.payload.orgId,
                    action.payload.siteSurveyId,
                    action.payload.forceEditable,
                    action.payload.verifyShippingAddress
                );
            }
        };
    }

    /**
     * OCT-6192: If a DC PSU is selected and no power cable has been selected,
     * default to the first DC cable option.
     *
     * @param api
     * @param orgId
     * @param siteSurveyId
     */
    private autoPickPowerCableIfAppropriate(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number
    ) {
        const state = api.getState();
        const uiState = getSiteSurveyUiState(state, siteSurveyId);

        // We only want to auto-pick once; afford user the choice to clear cable
        // selection if desired.
        if (uiState?.didAutoPickPowerCable) {
            return;
        }

        // The reducers have been applied at this point. The modified site
        // survey reflects all form field changes.
        const original = getSiteSurvey(state, siteSurveyId);
        const modified = getSiteSurveyModified(state, siteSurveyId);

        if (!original || !modified) {
            return;
        }

        const datacenterOptions = getAllowedDatacenterOptions(state, siteSurveyId);

        if (!datacenterOptions) {
            return;
        }

        const selectedPsu = datacenterOptions.powerSupply.find(({ id }) => id === modified[PSU_TYPE_KEY]);
        const selectedPowerCable = datacenterOptions.cable.find(({ id }) => id === modified[POWER_CABLE_TYPE_KEY]);
        const originalPowerCable = datacenterOptions.cable.find(({ id }) => id === original[POWER_CABLE_TYPE_KEY]);

        // If a DC power cable was originally set, but user is clearing the
        // selection, don't auto-pick.
        if (originalPowerCable?.currentType === CurrentType.DC && !selectedPowerCable) {
            return;
        }

        // The first valid DC power cable.
        const defaultDcPowerCable = datacenterOptions.cable.find(
            ({ currentType, restricted, retired }) => !restricted && !retired && currentType === CurrentType.DC
        );

        // If DC PSU is selected, and no power cable selection has been made,
        // default to first DC power cable option.
        if (selectedPsu?.currentType === CurrentType.DC && !selectedPowerCable && defaultDcPowerCable) {
            // Update power cable selection. Also set autoPicked flag.
            api.dispatch(
                siteSurveysActions.changeDatacenter({
                    orgId,
                    siteSurveyId,
                    values: {
                        [POWER_CABLE_TYPE_KEY]: defaultDcPowerCable[ID_KEY],
                    },
                })
            );
            api.dispatch(siteSurveysActions.autoPickedPowerCable({ orgId, siteSurveyId }));
        }
    }

    /**
     * @param forceEditable Whether or not the site survey is forced to be
     * editable by Admin user.
     */
    private validateSiteSurvey(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number,
        forceEditable = false,
        verifyShippingAddress = false
    ) {
        const state = api.getState();
        const original = getSiteSurvey(state, siteSurveyId);
        const modified = getSiteSurveyModified(state, siteSurveyId);
        const datacenterOptions = getDatacenterOptions(state);

        if (!original) {
            return;
        }

        const errors = validateSiteSurvey(modified ?? original, datacenterOptions, forceEditable);
        api.dispatch(
            siteSurveysActions.didValidate({
                orgId,
                siteSurveyId,
                errors,
            })
        );

        if (verifyShippingAddress) {
            const shippingAddress = modified?.[SHIPPING_ADDRESS_KEY];
            if (!errors[SHIPPING_ADDRESS_KEY] && shippingAddress) {
                // Verify shipping address if it passes all other validation.
                api.dispatch(
                    siteSurveysActions.verifyAddress.do(
                        {
                            orgId,
                            siteSurveyId,
                            address: shippingAddress,
                        },
                        siteSurveyId
                    )
                );
            } else {
                // Reset address verification status if shipping address has
                // errors or is incomplete.
                api.dispatch(
                    siteSurveysActions.verifyAddress.reset(
                        {
                            orgId,
                            siteSurveyId,
                        },
                        siteSurveyId
                    )
                );
            }
        }
    }

    private fetchSiteSurveys(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyIds: number[] | undefined,
        refresh = false
    ) {
        this.handleRequest(api, topgear.fetchSiteSurveys(orgId, siteSurveyIds)).then(
            (siteSurveys) => api.dispatch(siteSurveysActions.fetch.did({ orgId, siteSurveys, refresh })),
            (error: Error) => api.dispatch(siteSurveysActions.fetch.fail({ orgId, siteSurveyIds, error, refresh }))
        );
    }

    /**
     * If the save request fails, the fail action is dispatched and the returned
     * promise is rejected.
     */
    private async saveSiteSurvey(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number
    ): Promise<boolean> {
        const state = api.getState();
        const siteSurvey = getSiteSurveyModified(state, siteSurveyId);

        if (!siteSurvey) {
            // There was nothing to save.
            return true;
        }

        try {
            const updated = await this.handleRequest(api, topgear.updateSiteSurvey(orgId, siteSurvey));
            api.dispatch(siteSurveysActions.save.did({ orgId, siteSurvey: updated }));

            // Log site survey save event with shipping address verification status.
            const avs = getAddressVerificationsState(state, siteSurveyId);
            logger.trackEvent(SITE_SURVEY_SAVE, {
                shippingAddressVerified: avs?.value ? TopgearHelper.isAddressVerified(avs.value) : undefined,
                orgId,
                siteSurveyId,
            });
            return true;
        } catch (e) {
            api.dispatch(
                siteSurveysActions.save.fail({
                    orgId,
                    siteSurveyId: siteSurvey.id,
                    error: getResponseErrorsAsMessage(e) || e,
                })
            );
            return false;
        }
    }

    private async submitSiteSurvey(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number
    ): Promise<boolean> {
        if (getSiteSurveyIsDirty(api.getState(), siteSurveyId)) {
            // If dirty, attempt to save first befor submitting. If the save
            // request fails, do not attempt submit.
            const success = await this.saveSiteSurvey(api, orgId, siteSurveyId);
            if (!success) {
                return false;
            }
        }

        try {
            const siteSurvey = await this.handleRequest(api, topgear.submitSiteSurvey(orgId, siteSurveyId));
            api.dispatch(siteSurveysActions.submit.did({ orgId, siteSurvey }));
            return true;
        } catch (error) {
            api.dispatch(siteSurveysActions.submit.fail({ orgId, siteSurveyId, error }));
            return false;
        }
    }

    /**
     * A successful save or submit of a site survey can potentially modify
     * sibling site surveys, so we sometimes refresh all site surveys to ensure
     * we have the latest data.
     *
     * NOTE: The refresh intentionally fails silently.
     */
    private refreshOpenSiteSurveys(api: MiddlewareAPI<Dispatch, AppState>, orgId: number) {
        const state = api.getState();
        const siteSurveyIds = getOpenSiteSurveyIds(state);
        api.dispatch(siteSurveysActions.fetch.do({ orgId, siteSurveyIds, refresh: true }));
    }

    private fetchShippingAddressSuggestions(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number
    ) {
        this.handleRequest(api, topgear.fetchSiteSurveyShippingAddressSuggestions(orgId, siteSurveyId)).then(
            (shippingAddresses) =>
                api.dispatch(siteSurveysActions.fetchShippingAddressSuggestions.did(shippingAddresses, siteSurveyId)),
            (error: Error) =>
                api.dispatch(
                    siteSurveysActions.fetchShippingAddressSuggestions.fail(
                        { orgId, siteSurveyId, error },
                        siteSurveyId
                    )
                )
        );
    }

    private fetchDatacenterOptions(api: MiddlewareAPI<Dispatch, AppState>) {
        this.handleRequest(api, topgear.fetchPartDefinitions()).then(
            (partDefinitions) =>
                api.dispatch(
                    siteSurveysActions.fetchDatacenterOptions.did(
                        groupBy(partDefinitions, "componentType") as Record<ComponentType, PartDefinition[]>
                    )
                ),
            (error: Error) => api.dispatch(siteSurveysActions.fetchDatacenterOptions.fail({ error }))
        );
    }

    private fetchAttachment(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number,
        attachment: Attachment
    ) {
        this.handleRequest(api, topgear.downloadSiteSurveyAttachment(orgId, siteSurveyId, attachment.id)).then(
            (pdf) => api.dispatch(siteSurveysActions.downloadAttachments.did({ attachment, pdf }, attachment.id)),
            (error: Error) =>
                api.dispatch(
                    siteSurveysActions.downloadAttachments.fail(
                        { orgId, siteSurveyId, attachment, error },
                        attachment.id
                    )
                )
        );
    }

    private refreshAllowedParts(api: MiddlewareAPI<Dispatch, AppState>, orgId: number, siteSurveyId: number) {
        const siteSurvey = getSiteSurveyModified(api.getState(), siteSurveyId);
        this.handleRequest(api, topgear.fetchAllowedParts(orgId, siteSurveyId, siteSurvey)).then(
            (allowedParts) => api.dispatch(siteSurveysActions.fetchAllowedParts.did(allowedParts, siteSurveyId)),
            (error: Error) =>
                api.dispatch(siteSurveysActions.fetchAllowedParts.fail({ orgId, siteSurveyId, error }, siteSurveyId))
        );
    }

    private verifyAddress(
        api: MiddlewareAPI<Dispatch, AppState>,
        orgId: number,
        siteSurveyId: number,
        address: AddressFields
    ) {
        this.handleRequest(api, topgear.verifyAddress(orgId, address)).then(
            (results) => api.dispatch(siteSurveysActions.verifyAddress.did(results, siteSurveyId)),
            (error: Error) =>
                api.dispatch(
                    siteSurveysActions.verifyAddress.fail({ orgId, siteSurveyId, address, error }, siteSurveyId)
                )
        );
    }
}

export default new SiteSurveysMiddleware().middleware;
