import { MapDispatchToProps } from "react-redux";
import { appActions } from "../../reducer";
import { Props } from "./Authenticated";

export type DispatchProps = Pick<
    Props,
    "resetForNextOrg" | "fetchOrgs" | "fetchCountries" | "fetchUsStates" | "cleanUrl"
>;
export type OwnProps = Omit<Props, keyof DispatchProps>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
    cleanUrl: appActions.cleanUrl,
    resetForNextOrg: appActions.resetForNextOrg,
    fetchOrgs: appActions.fetchOrgs.do,
    fetchCountries: appActions.fetchCountries.do,
    fetchUsStates: appActions.fetchCountrySubdivisions.do,
};

export { mapDispatchToProps };
