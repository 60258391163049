import React, { Component } from "react";
import classnames from "classnames";
import { FontAwesomeIcon, Props as IconProps } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faIdBadge as faIdBadgeRegular,
    faClipboard,
    faCopy,
    faFile,
    faQuestionCircle as faQuestionCircleRegular,
    faTimesCircle,
    faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
    faIdBadge as faIdBadgeSolid,
    faAngleLeft,
    faArrowCircleDown,
    faAsterisk,
    faBolt,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClipboardCheck,
    faClipboardList,
    faDatabase,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faGlobe,
    faLock,
    faLockOpen,
    faPaste,
    faPlus,
    faQuestion,
    faQuestionCircle,
    faSignOutAlt,
    faSpinner,
    faTimes,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

// Build FontAwesome library
library.add(
    faAngleLeft,
    faArrowCircleDown,
    faAsterisk,
    faBolt,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faCopy,
    faDatabase,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFile,
    faGlobe,
    faIdBadgeSolid,
    faIdBadgeRegular,
    faLock,
    faLockOpen,
    faPaste,
    faPlus,
    faQuestion,
    faQuestionCircle,
    faQuestionCircleRegular,
    faSignOutAlt,
    faSpinner,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faUserCircle
);

/**
 * See https://github.com/FortAwesome/react-fontawesome for complete
 * FontAwesomeIcon props.
 *
 * NOTE: To reduce bundle size, we only include icons that we use (see above
 * library.add). Therefore, we don't accept all icon prop values, only specific
 * ones that we have included in the bundle.
 */
export interface Props extends Omit<IconProps, "size"> {
    icon:
        | "angle-left"
        | "arrow-circle-down"
        | "asterisk"
        | "bolt"
        | "caret-down"
        | "check"
        | "chevron-left"
        | "chevron-right"
        | "circle"
        | "check-circle"
        | "clipboard-check"
        | "clipboard-list"
        | "database"
        | "exclamation-circle"
        | "exclamation-triangle"
        | "external-link-alt"
        | "globe"
        | "id-badge"
        | "lock-open"
        | "lock"
        | "paste"
        | "plus"
        | "question"
        | "question-circle"
        | "sign-out-alt"
        | "spinner"
        | "times"
        | "user-circle"
        | ["far", "clipboard"]
        | ["far", "copy"]
        | ["far", "file"]
        | ["far", "id-badge"]
        | ["far", "question-circle"]
        | ["far", "times-circle"]
        | ["far", "trash-alt"];
    color?: string;
    link?: boolean;
    onClick?: () => void;
    size?: "xxs" | IconProps["size"];
    title?: string;
}

class Icon extends Component<Props> {
    computeSize(size: Props["size"]) {
        if (size === "xxs") {
            return "xs";
        }

        return size;
    }

    render() {
        const { className, icon, size, onClick, title, ...props } = this.props;

        return (
            <span
                className={classnames(className, "Icon", size, { clickable: !!onClick })}
                onClick={onClick}
                title={title}
            >
                <FontAwesomeIcon size={this.computeSize(size)} icon={icon} {...props} />
            </span>
        );
    }
}

export default Icon;
