import classnames from "classnames";
import React, { memo } from "react";

interface Props {
    className?: string;
    children: JSX.Element[];
    fluid?: boolean;
}

function ButtonGroup({ children, className, fluid }: Props) {
    return <div className={classnames("ButtonGroup", className, { fluid })}>{children}</div>;
}

export default memo(ButtonGroup);
