import classnames from "classnames";
import React, { memo } from "react";

interface Props {
    size?: "sm" | "md" | "lg";
}

function Spacer({ size = "md" }: Props) {
    return <div className={classnames("Spacer", size)} />;
}

export default memo(Spacer);
