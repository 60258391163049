import { AddressFields, ADDRESS_KEYS } from "../shared/models/AddressFields";
import { checkIsInvalid } from "../shared/validation";
import { validateAddress } from "../shared/validation/address";

export function checkIsEmptyAddress(address: Partial<AddressFields>): boolean {
    return ADDRESS_KEYS.every((key) => !address[key]);
}

export function checkIsValidAddress(address: Partial<AddressFields>): boolean {
    return !checkIsInvalid(validateAddress(address));
}
