import { omit, orderBy, sortBy } from "lodash";
import {
    ADDRESS_KEY as OCA_RETURN_ADDRESS_KEY,
    CONTACT_KEY as OCA_RETURN_CONTACT_KEY,
    OcaReturn,
} from "../../ocaReturns/models/OcaReturn";
import {
    ADDRESS_KEY as PARTS_ORDER_ADDRESS_KEY,
    CONTACT_KEY as PARTS_ORDER_CONTACT_KEY,
    PartOrder,
    PARTS_ORDER_LINE_ITEMS_KEY,
} from "../../partOrders/models/PartOrder";
import { PartOrderLineItem } from "../../partOrders/models/PartOrderLineItem";
import { PART_KEY, QUANTITY_KEY } from "../../partOrders/models/PartOrderLineItemFields";
import { PartOrderLineItemNew } from "../../partOrders/models/PartOrderLineItemNew";
import { PartOrderLineItemPayload } from "../../partOrders/models/PartOrderLineItemPayload";
import { messages } from "../../shared/messages";
import { Address, AddressNew } from "../../shared/models/Address";
import {
    AddressFields,
    ADDRESS_KEYS,
    CITY_KEY,
    COUNTRY_KEY,
    POSTAL_KEY,
    STATE_KEY,
    STREET_1_KEY,
    STREET_2_KEY,
} from "../../shared/models/AddressFields";
import { AllowedParts, DimensionSummary } from "../../shared/models/AllowedParts";
import { Attribution } from "../../shared/models/Attribution";
import { CFDI_FIELDS_KEY, CFDI_FIELD_METADATA_KEY } from "../../shared/models/CfdiCompliant";
import { Constraint } from "../../shared/models/Constraint";
import { Contact, ContactNew } from "../../shared/models/Contact";
import { ContactFields, CONTACT_FIELDS_KEYS } from "../../shared/models/ContactFields";
import {
    Datacenter,
    DATACENTER_KEYS,
    OPTICS_TYPE_KEY,
    PATCH_CABLE_TYPE_KEY,
    POWER_CABLE_TYPE_KEY,
    PSU_TYPE_KEY,
    RACK_TYPE_KEY,
} from "../../shared/models/Datacenter";
import { FieldMetadata } from "../../shared/models/FieldMetadata";
import { NOTES_KEY } from "../../shared/models/NotesFields";
import { getOrderDimension, OrderDimension, OrderDimensionToType } from "../../shared/models/OrderDimension";
import { Org } from "../../shared/models/Org";
import { PartnerForm } from "../../shared/models/PartnerForm";
import { ConflictError } from "../../shared/models/RequestError";
import { ID_KEY } from "../../shared/models/Resource";
import {
    CNPJ_KEY,
    IE_KEY,
    TaxpayerAffiliated,
    TAXPAYER_FIELDS_KEY,
    TAXPAYER_FIELD_METADATA_KEY,
    TAXPAYER_ID_KEY,
    TAX_ID_KEY,
} from "../../shared/models/TaxpayerAffiliated";
import { NetworkConfig, NetworkConfigFields } from "../../siteSurveys/models/NetworkConfig";
import { NETWORK_CONFIGS_KEY, SiteSurvey } from "../../siteSurveys/models/SiteSurvey";
import { SHIPPING_ADDRESS_KEY, SITE_ADDRESS_KEY } from "../../siteSurveys/models/SiteSurveyAddresses";
import { ContactKey, SITE_SURVEY_CONTACT_KEYS } from "../../siteSurveys/models/SiteSurveyContacts";
import { isPartOrderLineItem, isPartOrderLineItemFields } from "../../utils/guards";
import { convertPrefixToSubnetMask } from "../../utils/ip";
import { AddressVerificationResult } from "./models/AddressVerificationResult";
import { OrderParametersPayload } from "./models/OrderParametersPayload";

/**
 * This class contains helpers related to Topgear to massage the
 * request/response payloads between client and server.
 *
 * All the prepare* methods handle client -> server data. All the process*
 * methods handle server -> client data.
 */
export class TopgearHelper {
    public static NOC_LABEL = "NOC";
    public static NOC_FIRST_NAME = TopgearHelper.NOC_LABEL;
    public static NOC_LAST_NAME = "Contact";
    private static CFDI_FISCAL_REGIME_KEY = "cfdiFiscalRegime";
    private static DEFAULT_CFDI_FISCAL_REGIME = "601";

    /**
     * Returns whether or not all user editable fields of the contact are empty.
     * @param contact - The contact object to check.
     */
    public static isContactEmpty(contact: Readonly<ContactFields>): boolean {
        const keys = Array.from(CONTACT_FIELDS_KEYS);
        return keys.every((key) => !contact[key]);
    }

    public static isAddressEmpty<T extends AddressFields>(
        address: Readonly<T>,
        fields: Readonly<(keyof T)[]> = ADDRESS_KEYS
    ): boolean {
        return fields.every((key) => !address[key]);
    }

    public static isAddressVerified(addressVerificationResults: AddressVerificationResult[]) {
        return addressVerificationResults.some((result) => result.verified);
    }

    /**
     * Prepares a contact object for create or update.
     *
     * @param isNoc if `true`, the contact's first and last names will default
     * to dummy values when empty, to ensure we send well-formed contacts to the
     * API. Defaults to `false`.
     */
    public static prepareContact(
        contact: Readonly<Contact | ContactNew | undefined>,
        isNoc = false
    ): Contact | ContactNew | undefined {
        if (!contact || TopgearHelper.isContactEmpty(contact)) {
            return;
        }

        // If it's a new contact, we need to add the `version`.
        const prepared: Contact | ContactNew = {
            version: 0,
            ...contact,
        };

        // NOTE: The first and last name fields are hidden from the contact form
        // since the NOC contact tends to be a department email/phone number as
        // opposed to an individual. Here we fallback to dummy first/last names
        // to ensure that we create well-formed contacts.
        if (isNoc) {
            prepared.nameFirst = prepared.nameFirst || TopgearHelper.NOC_FIRST_NAME;
            prepared.nameLast = prepared.nameLast || TopgearHelper.NOC_LAST_NAME;
        }

        return prepared;
    }

    public static prepareAddress<T extends Address | AddressNew>(
        address: Readonly<T | undefined>,
        country?: string
    ): T | undefined {
        if (!address) {
            return;
        }

        if (TopgearHelper.isAddressEmpty(address)) {
            return;
        }

        // If it's a new address, we need to add `version` and `addressType` fields
        return {
            version: 0,
            ...address,
            country: country ?? address[COUNTRY_KEY],
        };
    }

    public static prepareDatacenter<T extends Partial<Datacenter>>(datacenter: T): T {
        const prepared = { ...(datacenter as any) };

        DATACENTER_KEYS.forEach((key) => {
            if (prepared[key]) {
                prepared[key] = {
                    id: prepared[key],
                };
            }
        });

        return prepared;
    }

    /**
     * Does some patching of the data to _send_ to the API (strip unnecessary
     * fields, reformat data etc.)
     */
    public static prepareSiteSurvey(siteSurvey: Readonly<Partial<SiteSurvey>>): Partial<SiteSurvey> {
        let prepared: Partial<SiteSurvey> = { ...siteSurvey };

        // This is deprecated, but the API still accepts and prioritizes it for
        // backwards compat. Delete to avoid conflict.
        delete prepared[TAXPAYER_ID_KEY];

        if (prepared.networkConfigs) {
            prepared.networkConfigs = prepared.networkConfigs.map((networkConfig) =>
                this.prepareNetworkConfig<NetworkConfig>(networkConfig)
            );
        }

        prepared[SHIPPING_ADDRESS_KEY] = TopgearHelper.prepareAddress(prepared[SHIPPING_ADDRESS_KEY]);

        // If the site survey is not for a new site, do not include siteAddress
        // in the payload since it is not editable.
        prepared[SITE_ADDRESS_KEY] = siteSurvey.newSite
            ? TopgearHelper.prepareAddress(prepared[SITE_ADDRESS_KEY], siteSurvey.countryCode)
            : undefined;

        // API does not like it if this is undefined.
        prepared.partnerRecipients = prepared.partnerRecipients || "";

        SITE_SURVEY_CONTACT_KEYS.forEach((key) => {
            prepared[key] = TopgearHelper.prepareContact(prepared[key], key === ContactKey.NOC);
        });

        prepared = TopgearHelper.prepareDatacenter(prepared);
        prepared = TopgearHelper.prepareTaxFields(prepared);

        return prepared;
    }

    public static prepareNetworkConfig<T extends NetworkConfigFields>(networkConfig: T): T {
        // The API only accepts subnet mask, but we allow the user to
        // enter a subnet mask or prefix. So we do the conversion here
        // of prefixes.
        try {
            return {
                ...networkConfig,
                ipv4Netmask: convertPrefixToSubnetMask(networkConfig.ipv4Netmask),
            };
        } catch (e) {
            return networkConfig;
        }
    }

    public static prepareNetworkConfigs(networkConfigs: NetworkConfig[]): NetworkConfig[] {
        return networkConfigs.map((networkConfig) => this.prepareNetworkConfig<NetworkConfig>(networkConfig));
    }

    public static preparePartOrderLineItems(
        lineItems: (PartOrderLineItem | PartOrderLineItemNew)[]
    ): PartOrderLineItemPayload[] {
        const prepared: PartOrderLineItemPayload[] = [];

        lineItems.forEach((lineItem) => {
            if (isPartOrderLineItem(lineItem)) {
                prepared.push(lineItem);
            } else if (isPartOrderLineItemFields(lineItem)) {
                prepared.push({
                    partDefinition: lineItem[PART_KEY],
                    partCount: lineItem[QUANTITY_KEY],
                });
            }
        });

        return prepared;
    }

    public static preparePartOrder(
        partOrder: Readonly<Partial<PartOrder<PartOrderLineItem | PartOrderLineItemNew>>>
    ): Partial<PartOrder<PartOrderLineItemPayload>> {
        const prepared: Partial<PartOrder<PartOrderLineItemPayload>> = omit(partOrder, PARTS_ORDER_LINE_ITEMS_KEY);
        // This is deprecated, but the API still accepts and prioritizes it for
        // backwards compat. Delete to avoid conflict.
        delete prepared[TAXPAYER_ID_KEY];
        // This flag should never be updated via partner forms.
        delete prepared.enforceQuantityLimits;
        prepared[PARTS_ORDER_ADDRESS_KEY] = TopgearHelper.prepareAddress(prepared[PARTS_ORDER_ADDRESS_KEY]);
        prepared[PARTS_ORDER_CONTACT_KEY] = TopgearHelper.prepareContact(prepared[PARTS_ORDER_CONTACT_KEY]);
        prepared[PARTS_ORDER_LINE_ITEMS_KEY] = TopgearHelper.preparePartOrderLineItems(partOrder.partLineItems || []);
        return prepared;
    }

    public static prepareOcaReturn(ocaReturn: Readonly<Partial<OcaReturn>>): Partial<OcaReturn> {
        const prepared: Partial<OcaReturn> = { ...ocaReturn };

        prepared[OCA_RETURN_ADDRESS_KEY] = TopgearHelper.prepareAddress(prepared[OCA_RETURN_ADDRESS_KEY]);
        prepared[OCA_RETURN_CONTACT_KEY] = TopgearHelper.prepareContact(prepared[OCA_RETURN_CONTACT_KEY]);

        return prepared;
    }

    public static processAddress<T extends Address | AddressNew>(address: T | undefined): T {
        return {
            street1: "",
            city: "",
            country: "",
            state: "",
            postal: "",
            recipient: "",
            ...(address as any),
        };
    }

    public static processContact<T extends Contact | ContactNew>(contact: T | undefined): T {
        return {
            nameFirst: "",
            nameLast: "",
            emailWork: "",
            phoneWork: "",
            ...(contact as any),
        };
    }

    public static processTaxpayerFields<T extends TaxpayerAffiliated>(taxpayerEntity: Readonly<T>): T {
        const processed: T = { ...taxpayerEntity };
        const taxpayerFields = taxpayerEntity[TAXPAYER_FIELDS_KEY];
        processed[TAXPAYER_FIELDS_KEY] = Object.keys(taxpayerFields).reduce((out, key) => {
            // Perhaps due to data migration, a lot of tax ids appear to have
            // trailing whitespace. Trim data coming from API to avoid surfacing
            // this error to partners.
            out[key] = taxpayerFields[key].trim();
            return out;
        }, {});
        // look for Brazilian taxpayer fields and put them in the taxpayerFields map, since that is how we
        // handle editing
        if (processed[IE_KEY]) {
            processed[TAXPAYER_FIELDS_KEY][IE_KEY] = processed[IE_KEY]!;
        }
        const hasCnjp = TopgearHelper.hasCnjp(processed);
        if (hasCnjp && processed[CNPJ_KEY]) {
            // CNPJ field goes in the "taxId" slot
            processed[TAXPAYER_FIELDS_KEY][TAX_ID_KEY] = processed[CNPJ_KEY]!;
        }

        return processed;
    }

    public static prepareTaxFields(taxpayerEntity: Readonly<Partial<TaxpayerAffiliated>>): Partial<TaxpayerAffiliated> {
        // send Brazilian tax fields in both slots for backwards compatibility,
        // both in that we may need to roll back the UI, and the field definitions are already in the taxpayerMetadata
        // array
        const prepared = { ...taxpayerEntity };
        if (prepared[TAXPAYER_FIELDS_KEY]?.[IE_KEY]) {
            prepared[IE_KEY] = prepared![TAXPAYER_FIELDS_KEY]![IE_KEY];
        }
        if (TopgearHelper.hasCnjp(prepared)) {
            // CNPJ field goes in the "taxId" slot
            prepared[CNPJ_KEY] = prepared![TAXPAYER_FIELDS_KEY]![TAX_ID_KEY];
        }
        return prepared;
    }

    private static hasCnjp(entity: Partial<TaxpayerAffiliated>): boolean {
        return (entity[TAXPAYER_FIELD_METADATA_KEY] ?? []).some(
            (entry) => entry.name === TAX_ID_KEY && entry.label === "CNPJ"
        );
    }

    public static processCfdi(
        cfdiFields?: Record<string, string>,
        cfdiFieldMetadata?: readonly FieldMetadata[]
    ): Record<string, string> {
        const out = { ...cfdiFields };

        const cfdiFiscalRegimeMetadata = cfdiFieldMetadata?.find(
            (metadata) => metadata.name === TopgearHelper.CFDI_FISCAL_REGIME_KEY
        );

        // "601" is the default fiscal regime for Mexico (the only country we
        // collect CFDI info for at this time). If CFDI info is blank, and CFDI
        // info is required, default the fiscal regime to the first option that
        // looks like "601". If in the future, we expand this to more countries,
        // this should gracefully become a noop.
        if (cfdiFiscalRegimeMetadata?.required && cfdiFields?.[TopgearHelper.CFDI_FISCAL_REGIME_KEY] === undefined) {
            const defaultFiscalRegime = Object.keys(cfdiFiscalRegimeMetadata.enums ?? {}).find(
                (e) => e === TopgearHelper.DEFAULT_CFDI_FISCAL_REGIME
            );
            if (defaultFiscalRegime) {
                out[TopgearHelper.CFDI_FISCAL_REGIME_KEY] = defaultFiscalRegime;
            }
        }

        return out;
    }

    /**
     * Processes the site survey _retrieved_ from the API to meet specific
     * requirements for the UI to read.
     * @param siteSurvey
     */
    public static processSiteSurvey(siteSurvey: Readonly<SiteSurvey>): SiteSurvey {
        let processed: SiteSurvey = { ...TopgearHelper.processPartnerForm(siteSurvey) };

        processed[NETWORK_CONFIGS_KEY] = orderBy(processed[NETWORK_CONFIGS_KEY], (nc) => nc[ID_KEY]);

        processed[SITE_ADDRESS_KEY] = TopgearHelper.processAddress(processed[SITE_ADDRESS_KEY]);
        processed[SHIPPING_ADDRESS_KEY] = TopgearHelper.processAddress(processed[SHIPPING_ADDRESS_KEY]);
        processed[CFDI_FIELDS_KEY] = TopgearHelper.processCfdi(
            processed[CFDI_FIELDS_KEY],
            siteSurvey[CFDI_FIELD_METADATA_KEY]
        );

        SITE_SURVEY_CONTACT_KEYS.forEach((role) => {
            processed[role] = TopgearHelper.processContact(processed[role]);
        });

        processed = TopgearHelper.processDatacenter(processed);
        processed = TopgearHelper.processTaxpayerFields(processed);

        return processed;
    }

    public static processPartOrder(partOrder: Readonly<PartOrder>): PartOrder {
        let processed = { ...TopgearHelper.processPartnerForm(partOrder) };

        processed[PARTS_ORDER_CONTACT_KEY] = TopgearHelper.processContact(partOrder[PARTS_ORDER_CONTACT_KEY]);
        processed[PARTS_ORDER_ADDRESS_KEY] = TopgearHelper.processAddress(partOrder[PARTS_ORDER_ADDRESS_KEY]);
        processed[PARTS_ORDER_LINE_ITEMS_KEY] = sortBy(processed[PARTS_ORDER_LINE_ITEMS_KEY], "id");
        processed.name = TopgearHelper.generateName(partOrder);
        processed[NOTES_KEY] = processed[NOTES_KEY] || "";

        processed = TopgearHelper.processTaxpayerFields(processed);

        return processed;
    }

    public static processOcaReturn(ocaReturn: Readonly<OcaReturn>): OcaReturn {
        const processed: OcaReturn = { ...TopgearHelper.processPartnerForm(ocaReturn) };

        processed[OCA_RETURN_CONTACT_KEY] = TopgearHelper.processContact(processed[OCA_RETURN_CONTACT_KEY]);
        processed[OCA_RETURN_ADDRESS_KEY] = TopgearHelper.processAddress(processed[OCA_RETURN_ADDRESS_KEY]);

        return processed;
    }

    public static processOrg(org: Readonly<Org>): Org {
        return {
            ...org,
            contacts: orderBy(org.contacts, (c) => c.nameFirstLast),
        };
    }

    public static processNetworkConfigs(networkConfigs: NetworkConfig[]) {
        return orderBy(networkConfigs, (nc) => nc[ID_KEY]);
    }

    /**
     * Tries to display a user-friendly (or at least useful) error message to
     * the user. We don't want to print stacktraces to the user. Full error
     * responses are logged.
     */
    public static processSubmitError(e: Error): Error {
        if (e instanceof ConflictError) {
            e.message = messages.errors.submit.validation();

            // Sometimes 409 Conflict contains useful hints for the user to
            // recover from the error, so even though the format may not be
            // ideal...we choose to display it anyway.
            if (e.constraintViolations) {
                e.message += `:\n${e.constraintViolations.map(({ message }) => message).join("\n")}`;
            }
        } else {
            // Other errors can be very cryptic and poorly formatted and most
            // likely not useful for external users. Prefer to display a generic
            // error message.
            e.message = messages.errors.submit.generic();
        }

        return e;
    }

    public static getOrderParametersFromSiteSurvey(siteSurvey: Partial<SiteSurvey>): Partial<OrderParametersPayload> {
        // Certain parameters are not set for various practical reasons. It
        // makes no difference though because these values are fixed on the
        // server side regardless of what the UI sends.
        // - OCA class: each siteSurveyHardware can technically have different
        //   OCA classes, but today we split site surveys by OCA class such that
        //   each site survey only has one OCA class. This may change in the
        //   near future, so let's not make that assumption in the UI.
        // - destinationCountry: requires conversion from country long name to
        //   codes on site surveys, so let's just wait.
        //   2-letter ISO code. We have plans to incorporate proper country ISO
        // - OCA version: unknown until allocation.
        return {
            [OrderDimension.Optics]: TopgearHelper.createReference(siteSurvey[OPTICS_TYPE_KEY]),
            [OrderDimension.PatchCable]: TopgearHelper.createReference(siteSurvey[PATCH_CABLE_TYPE_KEY]),
            [OrderDimension.Psu]: TopgearHelper.createReference(siteSurvey[PSU_TYPE_KEY]),
            [OrderDimension.PowerCable]: TopgearHelper.createReference(siteSurvey[POWER_CABLE_TYPE_KEY]),
            [OrderDimension.Rack]: TopgearHelper.createReference(siteSurvey[RACK_TYPE_KEY]),
            [OrderDimension.InterfaceSpeed]: siteSurvey.interfaceSpeed,
        };
    }

    /**
     * Maps an instance of `EvaluationResult` proto into `AllowedParts`.
     */
    public static toAllowedParts(data: any): AllowedParts {
        const allowedParts = {
            dimensionSummaries: data.dimensionSummaries.reduce(
                (
                    out: {
                        [P in OrderDimension]: DimensionSummary<OrderDimensionToType[P]>;
                    },
                    summary: any
                ) => {
                    out[getOrderDimension(summary.dimension)!] = {
                        allowed: summary.allowed?.map(TopgearHelper.extractValue) ?? [],
                        disallowed:
                            summary.disallowed?.map((d: any) => {
                                return {
                                    value: TopgearHelper.extractValue(d.dimensionalValue),
                                    constraints: [],
                                    // TODO: Fix after latest CRS is released to prod.
                                    // constraints: d.dimensionConstraints.map(TopgearHelper.toConstraint),
                                };
                            }) ?? [],
                    };
                    return out;
                },
                {}
            ),
        };
        return allowedParts;
    }

    /**
     * Maps proto to TS.
     */
    public static toAttribution(attr: any): Attribution {
        if (attr.knownValue) {
            const knownValue = attr.knownValue;
            const key = Object.keys(knownValue)[0];

            return {
                knownValue: {
                    dimension: getOrderDimension(key)!,
                    value: TopgearHelper.extractValue(knownValue),
                },
            };
        } else {
            return {
                // Extra nesting due to limitation of proto: can't `repeated` in
                // `oneof`. Workaround is to wrap in yet another message.
                constraints: attr.dimensionConstraints?.constriants?.map(TopgearHelper.toConstraint) ?? [],
            };
        }
    }

    /**
     * Maps proto to TS.
     */
    public static toConstraint(dc: any): Constraint {
        return {
            dimension: getOrderDimension(dc.dimension)!,
            rule: dc.rule?.name,
            attributions: dc.attributions.map(TopgearHelper.toAttribution),
        };
    }

    /**
     * Extracts the value from `DimensionalValue`.
     */
    public static extractValue<T>(proto: any): T {
        const value = proto[Object.keys(proto)[0]];

        // Int64Value is serialized into a string due to JS lack of precision.
        if (typeof value?.id === "string") {
            value.id = parseInt(value.id, 10);
        }

        return value;
    }

    public static getAddressVerificationCombinations(address: Readonly<AddressFields>): AddressFields[] {
        return [
            {
                street1: address[STREET_1_KEY],
                street2: address[STREET_2_KEY],
                city: address[CITY_KEY],
                state: address[STATE_KEY],
                postal: address[POSTAL_KEY],
                country: address[COUNTRY_KEY],
            },
            {
                street1: address[STREET_1_KEY],
                city: address[CITY_KEY],
                state: address[STATE_KEY],
                postal: address[POSTAL_KEY],
                country: address[COUNTRY_KEY],
            },
        ];
    }

    private static processPartnerForm<T extends PartnerForm>(partnerForm: T): T {
        const processed: T = { ...(partnerForm as any) };

        processed[NOTES_KEY] = processed[NOTES_KEY] || "";

        return processed;
    }

    private static processDatacenter<T extends Datacenter>(datacenter: T): T {
        const processed: T = { ...(datacenter as any) };

        DATACENTER_KEYS.forEach((key) => {
            const partDefinition = processed[key];
            if (partDefinition) {
                processed[key] = partDefinition[ID_KEY];
            }
        });

        return processed;
    }

    private static createReference(id?: number): { id: number } | undefined {
        return id ? { id } : undefined;
    }

    private static generateName(partOrder: PartOrder): string {
        // eslint-disable-next-line max-len
        return `${partOrder.hardwareIdentity.storageClass} OCA ${partOrder.shortHostname} (SN: ${partOrder.hardwareIdentity.serialNumber})`;
    }
}
