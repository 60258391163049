import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router";
import { Container } from "../../../shared/components/PartnerForm";
import PartnerFormPresenter from "../../../shared/components/PartnerFormPresenter";
import PartnerFormsList from "../../../shared/components/PartnerFormsList";
import SudoToggle from "../../../shared/components/SudoToggle";
import { SudoContext } from "../../../shared/contexts/SudoContext";
import { messages } from "../../../shared/messages";
import { Dictionary } from "../../../shared/models/Dictionary";
import { PATH_PART_ORDERS } from "../../../shared/routes";
import { checkIsCancelled, checkIsNew, checkIsSubmitted, getLastUpdated } from "../../../utils/partOrder";
import { PartOrder as PartOrderModel } from "../../models/PartOrder";
import { partOrdersActions } from "../../reducer";
import PartOrder from "../PartOrder";

export interface Props extends RouteComponentProps<any> {
    orgId: number;
    partOrderIds?: number[];
    isFetching: boolean;
    errorFetching: Error | string | null;
    byId?: Dictionary<PartOrderModel>;
    sorted?: PartOrderModel[];
}

function PartOrders({ byId, orgId, partOrderIds, errorFetching, isFetching, sorted, ...props }: Props) {
    const sudo = useContext(SudoContext);
    const dispatch = useDispatch();
    // NOTE(wtsai): For dispatch effects, we pass in `dispatch` as a dep to shut
    // the linter up, but the `dispatch` reference is stable unless a new store
    // instance is passed to the redux Provider, which should never happen.
    // https://stackoverflow.com/a/56795775/4844715.
    useEffect(() => {
        dispatch(partOrdersActions.fetch.do({ orgId, partOrderIds }));
    }, [dispatch, orgId, partOrderIds]);
    useEffect(() => {
        dispatch(partOrdersActions.fetchPartDefinitions.do());
    }, [dispatch]);

    return (
        <>
            <SudoToggle />
            <Container
                className="PartOrders"
                path={PATH_PART_ORDERS}
                master={() => (
                    <PartnerFormsList<PartOrderModel>
                        isFetching={isFetching}
                        entries={sorted}
                        checkIsNew={checkIsNew}
                        checkIsSubmitted={checkIsSubmitted}
                        checkIsCancelled={checkIsCancelled}
                        lastUpdated={getLastUpdated}
                        emptyMessage={messages.notice.noMatchingPartOrders()}
                        {...props}
                    />
                )}
                detail={() => (
                    <Route
                        render={(props) => (
                            <PartnerFormPresenter<PartOrderModel>
                                {...props}
                                byId={byId}
                                error={errorFetching}
                                doesNotExistMessage={messages.notice.partOrderDoesNotExist()}
                                makeSelectionMessage={messages.notice.selectAPartOrder()}
                                cancelledMessage={messages.notice.partOrderCancelled()}
                                checkIsCancelled={checkIsCancelled}
                            >
                                {(partOrder) => (
                                    <PartOrder {...props} partOrder={partOrder} orgId={orgId} forceEditable={sudo} />
                                )}
                            </PartnerFormPresenter>
                        )}
                    />
                )}
            />
        </>
    );
}

export default PartOrders;
