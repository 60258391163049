import { OcaClass } from "./OcaClass";
import { PartDefinition } from "./PartDefinition";

/**
 * Should match server side `OrderDimension` enum.
 */
export enum OrderDimension {
    InterfaceSpeed = "interfaceSpeed",
    DestinationCountry = "destinationCountry",
    OcaClass = "ocaClass",
    Psu = "psu",
    PowerCable = "powerCable",
    PatchCable = "patchCable",
    Optics = "optics",
    Rack = "rack"
}

/**
 * A map of `OrderDimension` to type of value.
 */
export type OrderDimensionToType = {
    [OrderDimension.InterfaceSpeed]: number;
    [OrderDimension.DestinationCountry]: string;
    [OrderDimension.OcaClass]: OcaClass;
    [OrderDimension.Psu]: PartDefinition;
    [OrderDimension.PowerCable]: PartDefinition;
    [OrderDimension.PatchCable]: PartDefinition;
    [OrderDimension.Rack]: PartDefinition;
    [OrderDimension.Optics]: PartDefinition;
};

/**
 *
 * @param dimension An enum name matching one of `Dimension` proto (upper
 * snake_case) or the field name for one of the dimensions (lower snake_case).
 */
export function getOrderDimension(dimension: string): OrderDimension | undefined {
    switch (dimension.toUpperCase()) {
        case "INTERFACE_SPEED":
            return OrderDimension.InterfaceSpeed;
        case "DESTINATION_COUNTRY":
            return OrderDimension.DestinationCountry;
        case "OCA_CLASS":
            return OrderDimension.OcaClass;
        case "PSU":
            return OrderDimension.Psu;
        case "POWER_CABLE":
            return OrderDimension.PowerCable;
        case "PATCH_CABLE":
            return OrderDimension.PatchCable;
        case "OPTICS":
            return OrderDimension.Optics;
        case "RACK":
            return OrderDimension.Rack;
        default:
            return;
    }
}
