import { Org } from "../shared/models/Org";
import { Contact } from "../shared/models/Contact";
import { isNetflixContact } from "./contact";
import { validateContact } from "../shared/validation/contact";
import { checkIsInvalid } from "../shared/validation";

/**
 * Extracts list of contacts from org that pass all contacts validation.
 *
 * @param org
 * @param excludeNetflix if `true`, excludes any Netflix contacts.
 */
export function extractCompleteContactsFromOrg(org: Org, excludeNetflix = true): Contact[] {
    return org.contacts.filter((contact) => {
        if (excludeNetflix && isNetflixContact(contact)) {
            return false;
        }

        if (checkIsInvalid(validateContact(contact))) {
            return false;
        }

        return true;
    });
}
