import Logger from "@octools/ui-logger";
import config from "../shared/config";

const logger = new Logger({
    url: config.telemetryApiUrl,
    appName: config.appName,
    version: config.version,
    environment: {
        unitTest: "UnitTest",
        prod: "Prod",
        test: "Test",
        dev: "Test"
    }[config.env],
    forceFlush: true,
    outputConsole: false,
    userTitle: "",
    userId: "",
    isNetflixEmployee: true,
});

export default logger;
