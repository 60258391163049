import classnames from "classnames";
import React, { useCallback, memo } from "react";
import Collection from "../Collection";
import Link from "../Link";
import TabPane from "./TabPane";

export interface Pane {
    kind: string;
    menuItem: JSX.Element;
    render: () => JSX.Element;
}

interface Props {
    panes: Pane[];
    /**
     * If this is provided, the Tab component is in a "controlled" state. This
     * should match the `kind` of one of the panes. If non are matched, the
     * first pane is rendered. Use `onSwitchPane` to handle user clicks and
     * provide a new activePane.
     */
    activePane?: string;
    onSwitchPane?: (pane: string) => void;
    /**
     * By default, inactive panes are not unmounted. If you wish to unmount
     * inactive panes, set this to `true`.
     */
    unmountInactivePanes: boolean;
}

function Tab({ panes, activePane, onSwitchPane }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const handleSwitchPane = useCallback((idx: number) => onSwitchPane?.(panes[idx].kind), [panes, onSwitchPane]);

    if (!panes.length) {
        return null;
    }

    let activePaneIndex = panes.findIndex(({ kind }) => kind === activePane);
    if (activePaneIndex === -1) {
        activePaneIndex = 0;
    }

    return (
        <div className="Tab">
            <Collection items={panes} index={activePaneIndex} onSetIndex={handleSwitchPane}>
                {(_pane, idx, onSetIndex) => (
                    <>
                        <div className="Tab-menuList">
                            {panes.map(({ menuItem }, jdx) => (
                                <Link
                                    key={jdx}
                                    className="Tab-menuItem"
                                    onClick={() => onSetIndex(jdx)}
                                    isActive={idx === jdx}
                                >
                                    {menuItem}
                                </Link>
                            ))}
                        </div>
                        <div className="Tab-panes">
                            {panes.map((pane, jdx) => (
                                <TabPane
                                    key={`${pane.kind}.${jdx}`}
                                    className={classnames({
                                        active: idx === jdx,
                                    })}
                                >
                                    {pane.render()}
                                </TabPane>
                            ))}
                        </div>
                    </>
                )}
            </Collection>
        </div>
    );
}

Tab.defaultProps = {
    unmountInactivePanes: false,
};

Tab.Pane = TabPane;

export default memo(Tab);
