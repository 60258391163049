import { startCase, lowerCase } from "lodash";
import { messages } from "../../shared/messages";

export const STREET_1_KEY = "street1";
export const STREET_2_KEY = "street2";
export const CITY_KEY = "city";
export const STATE_KEY = "state";
export const POSTAL_KEY = "postal";
export const COUNTRY_KEY = "country";

export const ADDRESS_KEYS: Readonly<(keyof AddressFields)[]> = [
    COUNTRY_KEY,
    STREET_1_KEY,
    STREET_2_KEY,
    CITY_KEY,
    STATE_KEY,
    POSTAL_KEY
];

export interface AddressFields {
    [STREET_1_KEY]: string;
    [STREET_2_KEY]?: string;
    [CITY_KEY]: string;
    [STATE_KEY]: string;
    [POSTAL_KEY]: string;
    [COUNTRY_KEY]: string;
}

export const DEFAULT_ADDRESS_VALUES = Object.freeze<AddressFields>({
    [STREET_1_KEY]: "",
    [STREET_2_KEY]: "",
    [CITY_KEY]: "",
    [STATE_KEY]: "",
    [POSTAL_KEY]: "",
    [COUNTRY_KEY]: ""
});

export const COPYABLE_ADDRESS_FIELDS: (keyof AddressFields)[] = [
    STREET_1_KEY,
    STREET_2_KEY,
    CITY_KEY,
    STATE_KEY,
    POSTAL_KEY,
    COUNTRY_KEY
];

export function displayLabel(address: AddressFields): string {
    return [
        startCase(lowerCase(address.street1)),
        startCase(lowerCase(address.street2)),
        startCase(lowerCase(address.city)),
        [address.state, address.postal, address.country].join(" ")
    ]
        .filter((part) => !!part)
        .join(", ");
}

export function getLabel(key: keyof AddressFields): string {
    try {
        return messages.labels.addressField[key](null);
    } catch (e) {
        console.warn(e);
        return key;
    }
}
