import { all, conditional, length, required } from "../forms/validators";
import { messages } from "../messages";
import { AddressFields, CITY_KEY, COUNTRY_KEY, STATE_KEY, STREET_1_KEY } from "../models/AddressFields";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { ModelValidator } from "../models/ModelValidator";
import { ValidationError } from "../models/ValidationError";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

const COUNTRIES_WITH_SUBDIVISION_LIST = ["US", "BR"];

/**
 * Returns true if the state field should be required, based on the current
 * values of the address fields.
 */
function shouldRequireState(_: string, allValues?: AddressFields): boolean {
    return allValues ? COUNTRIES_WITH_SUBDIVISION_LIST.includes(allValues[COUNTRY_KEY]) : false;
}

const ADDRESS_VALIDATOR: ModelValidator<AddressFields> = new Map<keyof AddressFields, Validator>([
    [
        STREET_1_KEY,
        all(
            required,
            // Only accept alpha, numeric, and accented characters.
            // https://stackoverflow.com/a/11550799/4844715
            length({
                re: /[a-z0-9\u00C0-\u017F]/gi,
                min: 2,
            }),
            // requires at least some number of alphas (or accented characters).
            // Prevents supplying an IP address as street address (true story).
            length({
                re: /[a-z\u00C0-\u017F]/gi,
                min: 2,
                message: messages.forms.validation.suspiciousStreet(),
            })
        ),
    ],
    [CITY_KEY, required],
    [STATE_KEY, conditional(shouldRequireState, required)],
    [COUNTRY_KEY, required],
]);

/**
 * Validates an address.
 * @param address - The address.
 * @param strict - Whether or not validation should be strict. Defaults to
 * `false`.
 * @param fixedCountryCode - Whether or not the country is fixed. If `true`, it
 * is not validated.
 */
export function validateAddress(
    address: Partial<AddressFields>,
    strict = true,
    fixedCountryCode?: string
): ModelValidationErrors<AddressFields> | ValidationError | undefined {
    const validations = new Map(
        Array.from(ADDRESS_VALIDATOR).filter(([key]) => {
            return key === COUNTRY_KEY ? !fixedCountryCode : true;
        })
    );
    return validate(
        {
            ...address,
            country: fixedCountryCode ?? address.country,
        },
        validations,
        strict
    );
}
