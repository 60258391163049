import React, { memo } from "react";

interface Props {
    textLeft?: string;
    textRight?: string;
}

function Footer({ textLeft, textRight }: Props) {
    return (
        <div className="Footer">
            <span>{textLeft}</span>
            <span>{textRight}</span>
        </div>
    );
}

export default memo(Footer);
