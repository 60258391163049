import { unsetSearchValue, setSearchValue, cleanRedirectPath } from "../../utils/url";
import { QUERY_REDIRECT } from "./query";
import { PATH_SSO, PATH_LOGIN } from "./paths";
import { history } from "../history";

export class RoutesHelper {
    /**
     * Using the current location and search params, returns a new search string.
     * This includes the leading question mark.
     * @param pathname
     * @param search
     */
    public static generateRedirectSearchParamsFromPath(pathname: string, search?: string): string {
        if (pathname === PATH_SSO) {
            return unsetSearchValue(search, QUERY_REDIRECT);
        } else {
            return setSearchValue(search, QUERY_REDIRECT, cleanRedirectPath(pathname));
        }
    }

    public static redirectToSSO() {
        // We want this to reload so that it can trigger Apache/Meechum.
        window.location.assign(`${PATH_SSO}${history.location.search}`);
    }

    public static redirectToLogin(forwardSearch = false) {
        const location = history.location;
        const search = RoutesHelper.generateRedirectSearchParamsFromPath(location.pathname, location.search);
        // As a convenience, we want this to reload to clear the Redux store.
        window.location.assign(`${PATH_LOGIN}${forwardSearch ? search : ""}`);
    }
}
