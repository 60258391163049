import memoizeOne from "memoize-one";
import Mousetrap from "mousetrap";
import React, { Component, ReactNode } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { TransitionGroup } from "react-transition-group";
import { asInt } from "../../../utils/strings";
import { PARTNER_FORM_SAVE } from "../../hotkeys";
import { Dictionary } from "../../models/Dictionary";
import { PartnerForm } from "../../models/PartnerForm";
import { PARAM_ID } from "../../routes/params";
import ErrorBoundary from "../ErrorBoundary";
import FadeIn from "../FadeIn";
import MissingInfo from "../MissingInfo";
import PartnerFormPlaceholder from "../PartnerFormPlaceholder";

export interface Props<T extends PartnerForm> extends RouteComponentProps<any> {
    error?: any;
    byId?: Dictionary<T>;
    children?: (item: T) => ReactNode;
    doesNotExistMessage: string;
    makeSelectionMessage: string;
    /**
     * The custom message displayed for a cancelled form. If not provided,
     * defaults to `doesNotExistMessage`.
     */
    cancelledMessage?: string;
    /**
     * If provided, renders a differentiated message for cancelled forms. Use
     * `cancelledMessage` to customize the message.
     */
    checkIsCancelled?: (item: T) => boolean;
    redirectToLoneItem?: boolean;
}

/**
 * Presents one form out of several forms based on the current path. Has the
 * following niceties:
 *
 * - If there is an error, an error is rendered.
 * - If there is no form selected, renders a message to require a selection
 * - If there is no form selected and there is only 1 form, it will redirect
 * - If the form selected cannot be found, renders a message
 * - If forms are still pending (`undefined`), a placeholder is rendered
 * - If the path matches an existing form, renders that form using render prop
 */
class PartnerFormPresenter<T extends PartnerForm> extends Component<Props<T>> {
    constructor(props: Props<T>) {
        super(props);

        this.asList = memoizeOne(this.asList);
    }

    componentWillUnmount() {
        // Assumes a <PartnerForm/> is rendered as a child, which binds this.
        Mousetrap.unbind(PARTNER_FORM_SAVE);
    }

    render() {
        const {
            error,
            byId,
            redirectToLoneItem = true,
            match,
            location,
            doesNotExistMessage,
            makeSelectionMessage,
            cancelledMessage,
            checkIsCancelled,
            children,
        } = this.props;
        const items = this.asList(byId);

        return (
            <ErrorBoundary padded>
                <Switch>
                    {
                        /*
                         * NOTE: We fail silently if site surveys were fetched successfully
                         * at some point in the past, but an error occurred while trying to refresh.
                         */
                        error && !byId && (
                            <Route
                                render={() => {
                                    throw error;
                                }}
                            />
                        )
                    }

                    {/* If there is only one survey, redirect to the survey instead of the empty state */}
                    {redirectToLoneItem && items && items.length === 1 && (
                        <Redirect
                            from={match.path}
                            exact
                            to={{
                                pathname: `${match.path}/${items[0].id}`,
                                search: location.search,
                            }}
                        />
                    )}

                    <Route
                        path={`${match.path}/:${PARAM_ID}`}
                        render={(props: RouteComponentProps<any>) => (
                            <TransitionGroup component={null}>
                                {/*
                                    This key should match the portion of the URL that should trigger a
                                    transition animation if it changes. In this case, it is /site-surveys/<id>.
                                */}
                                <FadeIn
                                    key={props.match.url}
                                    crossFade
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    <Switch location={props.location}>
                                        <Route
                                            render={() => {
                                                const id = asInt(props.match.params[PARAM_ID]);

                                                if (!byId) {
                                                    return <PartnerFormPlaceholder />;
                                                }

                                                const item = id !== undefined ? byId[id] : undefined;

                                                if (!item) {
                                                    return (
                                                        <MissingInfo
                                                            fluid
                                                            align="center"
                                                            padded
                                                            size="lg"
                                                            message={doesNotExistMessage}
                                                        />
                                                    );
                                                }

                                                if (checkIsCancelled?.(item)) {
                                                    return (
                                                        <MissingInfo
                                                            fluid
                                                            align="center"
                                                            padded
                                                            size="lg"
                                                            message={cancelledMessage ?? doesNotExistMessage}
                                                        />
                                                    );
                                                }

                                                return children?.(item);
                                            }}
                                        />
                                    </Switch>
                                </FadeIn>
                            </TransitionGroup>
                        )}
                    />
                    <Route
                        exact
                        path={match.path}
                        render={() => (
                            <MissingInfo fluid align="center" padded size="lg" message={makeSelectionMessage} />
                        )}
                    />
                </Switch>
            </ErrorBoundary>
        );
    }

    private asList(byId?: Dictionary<T>): T[] | undefined {
        if (!byId) {
            return;
        }

        return Object.values(byId).filter((item): item is T => !!item);
    }
}

export default PartnerFormPresenter;
