import { Address, AddressNew } from "../../shared/models/Address";
import { ShippingAddress, ShippingAddressNew } from "../../shared/models/ShippingAddress";

export const SITE_ADDRESS_KEY = "siteAddress";
export const SHIPPING_ADDRESS_KEY = "shippingAddress";

export interface SiteSurveyAddresses {
    [SITE_ADDRESS_KEY]?: Address | AddressNew;
    [SHIPPING_ADDRESS_KEY]?: ShippingAddress | ShippingAddressNew;
}
