import React, { Component } from "react";
import MenuItem from "../Menu/MenuItem";
import MenuItemWithStatus from "../Menu/MenuItemWithStatus";
export * from "../Menu/MenuItemWithStatus";

class Menu extends Component<React.HTMLAttributes<HTMLDivElement>> {
    static Item = MenuItem;
    static ItemWithStatus = MenuItemWithStatus;

    render() {
        return <div className="Menu">{this.props.children}</div>;
    }
}

export default Menu;
