import classnames from "classnames";
import React, { memo, ReactNode } from "react";
import Meta from "../Meta";

interface Props {
    label?: string;
    inline?: boolean;
    children?: ReactNode;
}

function Value({ label, children, inline = false }: Props) {
    return (
        <div className={classnames("Value", { inline })}>
            {label && <Meta inline={inline}>{label}</Meta>}
            {children}
        </div>
    );
}

export default memo(Value);
