import React, { Component } from "react";

interface Props {}

class PartnerFormContainerDetail extends Component<Props> {
    render() {
        const { children } = this.props;
        return <div className="PartnerFormContainerDetail">{children}</div>;
    }
}

export default PartnerFormContainerDetail;
