import React, { memo } from "react";
import { useForm } from "../../forms/hooks/useForm";
import { messages } from "../../messages";
import { EoriCompliant } from "../../models/EoriCompliant";
import { FieldMetadata } from "../../models/FieldMetadata";
import { ModelValidationErrors } from "../../models/ModelValidationErrors";
import { ValidationError } from "../../models/ValidationError";
import FormField from "../FormField";
import HelpIcon from "../HelpIcon";
import { Content } from "../PartnerForm";
import TextInput from "../TextInput";
import Tooltip from "../Tooltip";

interface Props {
    initialEori?: string;
    eori?: string;
    onChange: (values: Partial<EoriCompliant>) => void;
    validationErrors?: ModelValidationErrors<EoriCompliant> | ValidationError;
    eoriFieldMetadata?: FieldMetadata;
}

function EoriFieldEditor({ eoriFieldMetadata, initialEori, eori, validationErrors, onChange }: Props) {
    const [formState, formActions] = useForm<EoriCompliant>(
        { keys: ["eori"] },
        {
            initialValues: { eori: initialEori },
            values: { eori },
            validationErrors,
            onChange,
        }
    );
    const { errors, dirty, values } = formState;
    const { touchHandlers, changeHandlers } = formActions;

    if (!eoriFieldMetadata) {
        return null;
    }
    const { label, description, required } = eoriFieldMetadata;

    return (
        <Content title={messages.title.eori()}>
            <FormField
                name="eori"
                required={required}
                label={label}
                error={errors.get("eori")}
                labelAccessory={
                    description && (
                        <Tooltip placement="right-end" content={<div>{description}</div>}>
                            <HelpIcon spacedLeft />
                        </Tooltip>
                    )
                }
            >
                <TextInput
                    type="text"
                    autoComplete="off"
                    placeholder={label}
                    value={values?.eori ?? ""}
                    onChange={(evt) => changeHandlers.eori?.(evt.target.value)}
                    onBlur={() => touchHandlers.eori?.()}
                    dirty={dirty.has("eori")}
                    error={errors.has("eori")}
                />
            </FormField>
        </Content>
    );
}

export default memo(EoriFieldEditor);
