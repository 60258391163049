import { AxiosError } from "axios";
import { PartOrderLineItem } from "../partOrders/models/PartOrderLineItem";
import { PartOrderLineItemFields, PART_KEY, QUANTITY_KEY } from "../partOrders/models/PartOrderLineItemFields";
import { PartOrderLineItemNew } from "../partOrders/models/PartOrderLineItemNew";
import { APIKeyCredentials } from "../services/auth/models/APIKeyCredentials";
import { User } from "../services/auth/models/User";
import { Address, AddressNew } from "../shared/models/Address";
import { Audited } from "../shared/models/Audited";
import { Contact, ContactNew } from "../shared/models/Contact";
import { ModelValidationErrors } from "../shared/models/ModelValidationErrors";
import { Option } from "../shared/models/Option";
import { OptionGroup } from "../shared/models/OptionGroup";
import { ID_KEY, Resource } from "../shared/models/Resource";
import { Site } from "../shared/models/Site";
import { SiteSummary } from "../shared/models/SiteSummary";
import { ValidationError } from "../shared/models/ValidationError";
import { Versioned, VERSION_KEY } from "../shared/models/Versioned";

export function isAxiosError(error: Error): error is AxiosError {
    return error.hasOwnProperty("config") && typeof error["config"] === "object";
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
    return obj !== null && typeof obj === "object";
}
export function isString(str: unknown): str is string{
    return str !== null && typeof str === "string";
}

export function isResource(resource: unknown): resource is Resource {
    return isObject(resource) && resource.hasOwnProperty(ID_KEY) && typeof resource[ID_KEY] === "number";
}

export function isAudited(audited: unknown): audited is Audited {
    return isObject(audited) && audited.hasOwnProperty("dateCreated") && audited.hasOwnProperty("lastUpdated");
}

export function isVersioned(versioned: unknown): versioned is Versioned {
    return isObject(versioned) && versioned.hasOwnProperty(VERSION_KEY) && typeof versioned[VERSION_KEY] === "number";
}

export function isSite(site: Site | SiteSummary): site is Site {
    return isResource(site);
}

export function isContact(contact: ContactNew | Contact): contact is Contact {
    return isResource(contact);
}

export function isAddress(address: Address | AddressNew): address is Address {
    return isResource(address);
}

export function isOptionGroup<T>(option: Option<T> | OptionGroup<T>): option is OptionGroup<T> {
    return typeof option["label"] === "string" && Array.isArray(option["options"]);
}

export function isOptionGroups<T>(options: Option<T>[] | OptionGroup<T>[]): options is OptionGroup<T>[] {
    if (!Array.isArray(options)) {
        return false;
    }

    if (!options.length) {
        return true;
    }

    return isOptionGroup(options[0]);
}

export function isValidationError<T>(
    error: ValidationError | ModelValidationErrors<T> | undefined
): error is ValidationError {
    if (error && typeof error === "object" && error["level"] && typeof error["message"] === "string") {
        return true;
    }

    return false;
}

export function isAPIKeyCredentials(user: User | APIKeyCredentials): user is APIKeyCredentials {
    return !user.hasOwnProperty("kind");
}

export function isPartOrderLineItem(lineItem: PartOrderLineItem | PartOrderLineItemNew): lineItem is PartOrderLineItem {
    return (
        typeof lineItem[ID_KEY] === "number" &&
        ["dateCreated", "createdBy", "lastUpdated", "lastUpdatedBy"].some((prop) => lineItem.hasOwnProperty(prop))
    );
}

export function isPartOrderLineItemFields(
    lineItem: Partial<PartOrderLineItemFields>
): lineItem is PartOrderLineItemFields {
    return lineItem[QUANTITY_KEY] !== undefined && lineItem[PART_KEY] !== undefined;
}
