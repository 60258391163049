import { DatacenterOptions } from "../../siteSurveys/models/DatacenterOptions";
import { required } from "../forms/validators";
import { DatacenterFields, DATACENTER_KEYS, OPTICS_TYPE_KEY, PSU_TYPE_KEY, RACK_TYPE_KEY } from "../models/Datacenter";
import { ModelValidator } from "../models/ModelValidator";
import { Validator } from "../models/Validator";
import { validate } from "../validation";

export const REQUIRED_PARTS = new Set([OPTICS_TYPE_KEY, RACK_TYPE_KEY, PSU_TYPE_KEY]);

const makeDatacenterValidator = (_datacenterOptions?: DatacenterOptions): ModelValidator<DatacenterFields> =>
    new Map<keyof DatacenterFields, Validator>(
        DATACENTER_KEYS.filter((key) => REQUIRED_PARTS.has(key)).map((key) => [key, required])
    );

export function validateDatacenterInfo(datacenter: DatacenterFields, datacenterOptions: DatacenterOptions | undefined) {
    return validate(datacenter, makeDatacenterValidator(datacenterOptions));
}
