import { messages } from "../messages";
import { AddressFields, ADDRESS_KEYS, DEFAULT_ADDRESS_VALUES } from "./AddressFields";

export const RECIPIENT_KEY = "recipient";

export const SHIPPING_ADDRESS_KEYS: readonly (keyof ShippingAddressFields)[] = [RECIPIENT_KEY, ...ADDRESS_KEYS];

export const DEFAULT_SHIPPING_ADDRESS_VALUES: ShippingAddressFields = Object.freeze<ShippingAddressFields>({
    [RECIPIENT_KEY]: "",
    ...DEFAULT_ADDRESS_VALUES,
});

export interface ShippingAddressFields extends AddressFields {
    [RECIPIENT_KEY]?: string;
}

export function getLabel(key: keyof ShippingAddressFields): string {
    try {
        return messages.labels.addressField[key](null);
    } catch (e) {
        console.warn(e);
        return key;
    }
}
