import { ADDRESS_KEY, CONTACT_KEY, OcaReturn } from "../ocaReturns/models/OcaReturn";
import {
    OcaReturnPickupPreferences,
    OCA_RETURN_PICKUP_PREFERENCES_KEYS,
    PREFERRED_DAY_OF_WEEK_KEY,
    PREFERRED_TIME_OF_DAY_KEY,
} from "../ocaReturns/models/OcaReturnPickupPreferences";
import {
    BOXED_KEY,
    FREQUENT_SHIPPER_KEY,
    LIFT_GATE_REQUIRED_KEY,
    OcaReturnPickupProperties,
    OCA_RETURN_PICKUP_PROPERTIES_KEYS,
} from "../ocaReturns/models/OcaReturnPickupProperties";
import { OcaReturnView } from "../ocaReturns/models/OcaReturnView";
import { ADDITIONAL_RECIPIENTS_KEY } from "../shared/models/AdditionalRecipients";
import { getLastUpdated as defaultGetLastUpdated } from "../shared/models/Audited";
import { Change } from "../shared/models/Change";
import { getSubmittedOn as defaultGetSubmittedOn } from "../shared/models/Submittable";
import { ValidationError } from "../shared/models/ValidationError";
import { latestOf } from "./dates";
import { getDiffs } from "./diffs";
import { isAddress, isContact } from "./guards";
import { checkIsNew as defaultCheckIsNew, checkIsSubmitted as defaultCheckIsSubmitted } from "./partnerForm";
import { doesPathBeginWith, doesPathBeginWithOneOf } from "./paths";

export function checkIsNew(ocaReturn: OcaReturn): boolean {
    return defaultCheckIsNew(ocaReturn);
}

export function checkIsSubmitted(ocaReturn: OcaReturn): boolean {
    return defaultCheckIsSubmitted(ocaReturn);
}

export function getSubmittedOn(ocaReturn: OcaReturn): Date | undefined {
    return defaultGetSubmittedOn(ocaReturn);
}

export function getLastUpdated(ocaReturn: OcaReturn): Date {
    const candidates: Date[] = [defaultGetLastUpdated(ocaReturn)];

    const contact = ocaReturn[CONTACT_KEY];
    const address = ocaReturn[ADDRESS_KEY];

    if (contact && isContact(contact)) {
        candidates.push(defaultGetLastUpdated(contact));
    }

    if (address && isAddress(address)) {
        candidates.push(defaultGetLastUpdated(address));
    }

    return latestOf(candidates);
}

/**
 * Does a diff of two OCA Returns, ignoring diffs that are inconsequential.
 */
export function checkAreOcaReturnsDifferent(original: OcaReturn | undefined, modified: OcaReturn | undefined): boolean {
    if (!modified) {
        return false;
    }

    if (!original) {
        return true;
    }

    return getDiffs(original, modified, ["orgSummary", "siteSummary", "jiras", "ocaReturnDetails"]).length > 0;
}

export function attributeOcaReturnErrorToView(error: ValidationError): OcaReturnView | undefined {
    if (!error.path) {
        return;
    }

    if (
        doesPathBeginWithOneOf(error.path, [
            ADDRESS_KEY,
            CONTACT_KEY,
            BOXED_KEY,
            LIFT_GATE_REQUIRED_KEY,
            FREQUENT_SHIPPER_KEY,
            PREFERRED_DAY_OF_WEEK_KEY,
            PREFERRED_TIME_OF_DAY_KEY,
        ])
    ) {
        return OcaReturnView.Pickup;
    }

    if (doesPathBeginWith(error.path, ADDITIONAL_RECIPIENTS_KEY)) {
        return OcaReturnView.More;
    }

    return;
}

export function createOcaReturnPickupPropertiesChanges(values: Partial<OcaReturnPickupProperties>): readonly Change[] {
    return OCA_RETURN_PICKUP_PROPERTIES_KEYS.map((key) => ({
        path: key,
        value: values[key],
    }));
}

export function createOcaReturnPickupPreferencesChanges(
    values: Partial<OcaReturnPickupPreferences>
): readonly Change[] {
    return OCA_RETURN_PICKUP_PREFERENCES_KEYS.map((key) => ({
        path: key,
        value: values[key],
    }));
}
