import React, { memo } from "react";
import { useForm } from "../../forms/hooks/useForm";
import { getMessage, messages } from "../../messages";
import {
    ContactFields,
    CONTACT_FIELDS_KEYS,
    getLabel,
    NAME_FIRST_KEY,
    NAME_LAST_KEY,
    ROLES_KEY,
} from "../../models/ContactFields";
import { FormPropsV2 } from "../../props/forms";
import Button from "../Button";
import Form from "../Form";
import FormActions from "../Form/FormActions";
import FormField from "../FormField";
import TextInput from "../TextInput";

export interface Props extends FormPropsV2<ContactFields> {
    /**
     * Only shows one input for "Name". This is passed through the `nameFirst`
     * field.
     */
    combineFirstAndLast?: boolean;
    showLabel?: boolean;
    required?: boolean;
}

/**
 * A form for editing a contact. Does not support editing roles at the moment.
 */
function ContactForm(props: Props) {
    const {
        disabled,
        isSubmitting,
        onSubmit,
        onCancel,
        submitButtonText,
        cancelButtonText,
        omitFields = new Set(),
        showLabel = true,
        compact = false,
        combineFirstAndLast = false,
        required = true,
    } = props;
    const [{ errors, pristine, valid, dirty, values }, { changeHandlers, touchHandlers }] = useForm(
        { keys: CONTACT_FIELDS_KEYS, atomic: true },
        props
    );

    return (
        <Form className="ContactForm" onSubmit={onSubmit} compact={compact}>
            {CONTACT_FIELDS_KEYS.map((field) => {
                let label = getLabel(field);

                if (combineFirstAndLast) {
                    if (field === NAME_FIRST_KEY) {
                        label = messages.labels.contactField.name();
                    }

                    if (field === NAME_LAST_KEY) {
                        return null;
                    }
                }

                if (field === ROLES_KEY) {
                    return null;
                }

                return (
                    <FormField
                        key={field}
                        required={required}
                        disabled={disabled}
                        name={field}
                        label={showLabel ? label : undefined}
                        placeholder={getMessage(() => messages.forms.placeholders[field](null), field)}
                        error={errors.get(field)}
                        hidden={omitFields.has(field)}
                    >
                        <TextInput
                            dirty={dirty.has(field)}
                            error={errors.has(field)}
                            value={values[field] ?? ""}
                            onChange={(evt) => changeHandlers[field]?.(evt.target.value)}
                            onBlur={() => touchHandlers[field]?.()}
                            placeholder={getMessage(() => messages.forms.placeholders[field](null), field)}
                        />
                    </FormField>
                );
            })}
            <FormActions>
                {onSubmit && (
                    <Button disabled={pristine || !valid || isSubmitting} type="submit">
                        {submitButtonText || messages.actions.create()}
                    </Button>
                )}
                {onCancel && (
                    <Button type="button" kind="dismiss" onClick={onCancel}>
                        {cancelButtonText || messages.actions.cancel()}
                    </Button>
                )}
            </FormActions>
        </Form>
    );
}

export default memo(ContactForm);
