import React, { Component } from "react";
import classnames from "classnames";
import GridColumn from "../Grid/GridColumn";

interface Props {
    stackable?: boolean;
    columns?: number;
    className?: string;
}

class Grid extends Component<Props & JSX.ElementChildrenAttribute> {
    static Column = GridColumn;

    render() {
        const { stackable, children, className } = this.props;
        return (
            <div
                className={classnames("Grid", className, {
                    stackable
                })}
            >
                {children}
            </div>
        );
    }
}

export default Grid;
