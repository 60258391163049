import { FieldMetadata } from "./FieldMetadata";

export const TAXPAYER_ID_KEY = "taxpayerId";
export const TAX_ID_KEY = "taxId";
export const TAXPAYER_FIELDS_KEY = "taxpayerFields";
export const TAXPAYER_FIELD_METADATA_KEY = "taxpayerFieldMetadata";

export const IE_KEY = "ie";
export const CNPJ_KEY = "cnpj";

/**
 * Deprecated
 */
export interface TaxpayerId {
    id?: string;
    label?: string;
    /**
     * RegEx
     */
    pattern?: string;
    required: boolean;
}

export interface TaxpayerAffiliated {
    [TAX_ID_KEY]?: string;
    [IE_KEY]?: string;
    [CNPJ_KEY]?: string;
    /**
     * @deprecated
     */
    [TAXPAYER_ID_KEY]?: TaxpayerId;
    [TAXPAYER_FIELDS_KEY]: Record<string, string>;
    [TAXPAYER_FIELD_METADATA_KEY]: FieldMetadata[];
}
