import React, { Component } from "react";

interface Props {}

class PartnerFormContainerMaster extends Component<Props> {
    render() {
        const { children } = this.props;
        return <div className="PartnerFormContainerMaster">{children}</div>;
    }
}

export default PartnerFormContainerMaster;
