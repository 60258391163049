import { diff } from "deep-diff";

/**
 * Given a diff, returns true if it is considered "inconsequential". For
 * example, a field that is undefined vs an empty string is not a difference we
 * care to distinguish.
 * @param diff - A diff
 */
function isInconsequential(diff: deepDiff.IDiff) {
    // New - lhs is undefined
    if (diff.kind === "N" && (diff.rhs === "" || diff.rhs === undefined)) {
        return true;
    }

    // Delete - rhs is undefined
    if (diff.kind === "D" && (diff.lhs === "" || diff.lhs === undefined)) {
        return true;
    }

    // Edit
    if (
        diff.kind === "E" &&
        (diff.lhs === undefined || diff.lhs === "") &&
        (diff.rhs === undefined || diff.rhs === "")
    ) {
        return true;
    }

    return false;
}

/**
 * A wrapper around DeepDiff that excludes certain unimportant diffs. Optionally
 * accepts a simple `ignore` parameter to ignore keys from comparison.
 * @param lhs
 * @param rhs
 * @param ignore
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getDiffs(lhs: Object, rhs: Object, ignore?: string[]): deepDiff.IDiff[] {
    const diffs = diff(lhs, rhs, function prefilter(path: string[], key: string) {
        if (ignore && ignore.includes(key)) {
            return true;
        }

        return false;
    }) as deepDiff.IDiff[] | undefined;

    return diffs ? diffs.filter((d) => !isInconsequential(d)) : [];
}
