import { Shipper } from "../../shared/models/Shipper";

export const BOXED_KEY = "boxed";
export const LIFT_GATE_REQUIRED_KEY = "liftGateRequired";
export const FREQUENT_SHIPPER_KEY = "frequentShipper";

export const OCA_RETURN_PICKUP_PROPERTIES_KEYS: readonly (keyof OcaReturnPickupProperties)[] = [
    BOXED_KEY,
    LIFT_GATE_REQUIRED_KEY,
    FREQUENT_SHIPPER_KEY,
];

export interface OcaReturnPickupProperties {
    [BOXED_KEY]?: boolean;
    [LIFT_GATE_REQUIRED_KEY]?: boolean;
    [FREQUENT_SHIPPER_KEY]?: Shipper;
}
