import { capitalize } from "lodash";

export enum DayOfWeek {
    Any = "ANY",
    Sunday = "SUNDAY",
    Monday = "MONDAY",
    Tuesday = "TUESDAY",
    Wednesday = "WEDNESDAY",
    Thursday = "THURSDAY",
    Friday = "FRIDAY",
    Saturday = "SATURDAY"
}

export function getLabel(dayOfWeek: DayOfWeek): string {
    return capitalize(dayOfWeek);
}
