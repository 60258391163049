import React, { Component } from "react";
import Meta from "../../../shared/components/Meta";
import MissingInfo from "../../../shared/components/MissingInfo";
import { Content } from "../../../shared/components/PartnerForm";
import Segment from "../../../shared/components/Segment";
import { messages } from "../../../shared/messages";
import {
    DatacenterFields,
    DATACENTER_KEYS,
    getLabel as datacenterFieldLabel,
    getComponentType
} from "../../../shared/models/Datacenter";
import { DatacenterOptions } from "../../../siteSurveys/models/DatacenterOptions";
import Loading from "../../../shared/components/Loading";

export interface Props {
    datacenter: DatacenterFields;
    datacenterOptions?: DatacenterOptions;
}

class Datacenter extends Component<Props> {
    public render() {
        const { datacenter, datacenterOptions } = this.props;

        if (!datacenterOptions) {
            return <Loading />;
        }

        return (
            <Content title={messages.title.datacenterAttributes()} className="Datacenter">
                <Segment.Group>
                    {DATACENTER_KEYS.map(key => {
                        const label = datacenterFieldLabel(key);
                        const componentType = getComponentType(key);
                        const partDefinition = datacenterOptions[componentType].find(
                            ({ id }) => id === datacenter[key]
                        );
                        return (
                            <Segment key={key}>
                                <Meta>{label}</Meta>
                                {partDefinition ? (
                                    <span className="Datacenter-selectionName">{partDefinition.name}</span>
                                ) : (
                                    <MissingInfo message="None" />
                                )}
                            </Segment>
                        );
                    })}
                </Segment.Group>
            </Content>
        );
    }
}

export default Datacenter;
