import { ADDRESS_KEY, CONTACT_KEY, OcaReturn } from "../../ocaReturns/models/OcaReturn";
import { messages } from "../messages";
import { Address, AddressNew } from "../models/Address";
import { AddressFields } from "../models/AddressFields";
import { Contact, ContactNew } from "../models/Contact";
import { ContactFields } from "../models/ContactFields";
import { ModelValidationErrors } from "../models/ModelValidationErrors";
import { createValidationError, Level, ValidationError } from "../models/ValidationError";
import { validateAddress } from "../validation/address";
import { validateContact } from "../validation/contact";
import { validateOcaReturnPickupPreferences } from "../validation/ocaReturnPickupPreferences";
import { validateOcaReturnPickupProperties } from "../validation/ocaReturnPickupProperties";
import { TopgearHelper } from "../../services/topgear/TopgearHelper";

export function validateOcaReturnContact(
    contact?: Contact | ContactNew
): ModelValidationErrors<ContactFields> | ValidationError | undefined {
    if (!contact || TopgearHelper.isContactEmpty(contact)) {
        return createValidationError(
            Level.Incomplete,
            messages.forms.validation.missingContact({
                CONTACT_ROLE: messages.entity.contact()
            })
        );
    }

    return validateContact(contact);
}

export function validateOcaReturnAddress(
    address?: Address | AddressNew
): ModelValidationErrors<AddressFields> | ValidationError | undefined {
    if (!address || TopgearHelper.isAddressEmpty(address)) {
        return createValidationError(Level.Incomplete, messages.forms.validation.missingPickupAddress());
    }

    return validateAddress(address);
}

export function validateOcaReturn(ocaReturn: OcaReturn): ModelValidationErrors<OcaReturn> {
    if (!ocaReturn.editable) {
        return {};
    }

    return {
        ...validateOcaReturnPickupProperties(ocaReturn),
        ...validateOcaReturnPickupPreferences(ocaReturn),
        [CONTACT_KEY]: validateOcaReturnContact(ocaReturn[CONTACT_KEY]),
        [ADDRESS_KEY]: validateOcaReturnAddress(ocaReturn[ADDRESS_KEY])
    };
}
