import { connect } from "react-redux";
import { withLogView } from "../../../shared/hoc/withLogView";
import { mapDispatchToProps, mapStateToProps } from "./connector";
import Login from "./Login";
import "./Login.css";

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLogView("Login", Login));
