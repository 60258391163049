import { Address4, Address6 } from "ip-address";
import { padEnd, padStart } from "lodash";
import validator from "validator";

/**
 * e.g. "255.255.255.0"
 */
type SubnetMask = string;
/**
 * e.g. "/24"
 */
type Prefix = string;
type IPv4Address = string;
type IPv6Address = string;

export const RE_IPV4_LOCALHOST = /^127.0.0.1$/;
export const RE_PREFIX_STRING = /^\/\d{1,3}(?=%|$)/;

/**
 * Invalid submask: not a contiguous set of 1s followed by contiguous set of 0s
 * @param subnetMask
 */
export function isSubnetMask(subnetMask: SubnetMask): boolean {
    if (!validator.isIP(subnetMask)) {
        return false;
    }

    const addressInBinary = new Address4(subnetMask).binaryZeroPad();
    return addressInBinary[0] !== "0" && !addressInBinary.includes("01");
}

/**
 * Validates a routing prefix of the format "/24"
 * @param prefix
 */
export function isPrefix(prefix: string): prefix is Prefix {
    return RE_PREFIX_STRING.test(prefix);
}

export function isIpv4(address: string): address is IPv4Address {
    return validator.isIP(address, 4);
}

export function isIpv6(address: string): address is IPv6Address {
    return validator.isIP(address, 6);
}

export function isSubnetMaskOrPrefix(subnetMaskOrPrefix: string): subnetMaskOrPrefix is SubnetMask | Prefix {
    return isSubnetMask(subnetMaskOrPrefix) || isPrefix(subnetMaskOrPrefix);
}

/**
 *
 * @param version If not defined, checks that it is either a valid IPv4 or IPv6
 * address.
 */
function assertIsIp(address: string, version?: 4 | 6): asserts address is IPv4Address | IPv6Address {
    if (!validator.isIP(address, version)) {
        throw new Error(`${address} is not a valid IPv4 or IPv6 address`);
    }
}

function assertIsSubnetMask(subnetMask: string): asserts subnetMask is SubnetMask {
    if (!isSubnetMask(subnetMask)) {
        throw new Error(`${subnetMask} is not a valid subnet mask`);
    }
}

function assertIsPrefix(prefix: string): asserts prefix is Prefix {
    if (!isPrefix(prefix)) {
        throw new Error(`${prefix} is not a valid routing prefix`);
    }
}

/**
 * Gets the prefix length of a valid subnet mask, or throws error if invalid
 * @param subnetMask
 */
function getPrefixLengthFromSubnetMask(subnetMask: SubnetMask): number {
    assertIsSubnetMask(subnetMask);
    const addressInBinary = new Address4(subnetMask).binaryZeroPad();
    if (!addressInBinary.includes("0")) {
        return 32;
    }
    return new Address4(subnetMask).binaryZeroPad().indexOf("0");
}

/**
 * Converts a subnet mask to a CIDR prefix. Example: 255.255.255.0 -> /24
 */
export function convertSubnetMaskToPrefix(subnetMask: SubnetMask) {
    if (isPrefix(subnetMask)) {
        return subnetMask;
    }

    assertIsSubnetMask(subnetMask);
    return `/${getPrefixLengthFromSubnetMask(subnetMask)}`;
}

/**
 * Converts a prefix to a subnet mask. Example: /24 -> 255.255.255.0
 * @param prefix - A prefix (e.g. /24)
 */
export function convertPrefixToSubnetMask(prefix: Prefix): SubnetMask {
    if (isSubnetMask(prefix)) {
        return prefix;
    }

    assertIsPrefix(prefix);

    const length = parseInt(prefix.substring(1), 10);

    return Array.from({ length: 4 }, (v, i) =>
        parseInt(padEnd(padStart("", Math.min(8, Math.max(0, length - i * 8)), "1"), 8, "0"), 2)
    ).join(".");
}

/**
 * Given a valid subnet mask or routing prefix, returns the number of bits (length)
 * @param subnetMaskOrPrefix - A subnet mask of prefix (e.g. /24)
 */
export function getPrefixLength(subnetMaskOrPrefix: SubnetMask | Prefix): number {
    if (isSubnetMask(subnetMaskOrPrefix)) {
        return getPrefixLengthFromSubnetMask(subnetMaskOrPrefix);
    }

    if (isPrefix(subnetMaskOrPrefix)) {
        return parseInt(subnetMaskOrPrefix.substring(1), 10);
    }

    throw new Error(`Expected a valid subnet mask or CIDR prefix, but received ${subnetMaskOrPrefix}`);
}

/**
 * Given an IP address, returns true if it is localhost
 */
export function isLoopbackAddress(address: IPv4Address | IPv6Address) {
    assertIsIp(address);
    if (validator.isIP(address, 4)) {
        return RE_IPV4_LOCALHOST.test(address);
    } else {
        return new Address6(address).isLoopback();
    }
}

export function isIPv4MappedIPv6(value: IPv6Address) {
    assertIsIp(value, 6);
    const address = asAddress(value) as Address6;
    return address.toUnsignedByteArray().length <= 7;
}

/**
 * Returns an Address4 or Address6 instance (see ip-address), or null if it is invalid
 * @param address - An IP address string (v4 or v6)
 */
export function asAddress(value: IPv4Address | IPv6Address): Address4 | Address6 | null {
    try {
        return new Address4(value);
    } catch (e) {
        try {
            return new Address6(value);
        } catch (e) {
            return null;
        }
    }
}

/**
 * Returns true if all addresses are in the same subnet
 * @param subnetMaskOrPrefix - A subnet mask or prefix
 * @param addresses
 */
export function inSameSubnet(subnetMaskOrPrefix: SubnetMask | Prefix, addresses: (IPv4Address | IPv6Address)[]) {
    const networkPrefixes: Set<string> = new Set();
    const prefixLength = getPrefixLength(subnetMaskOrPrefix);

    for (let i = 0; i < addresses.length; i++) {
        const address = asAddress(addresses[i]);
        if (!address) {
            throw new Error(`An invalid address was provided ${addresses[i]}`);
        }

        networkPrefixes.add(address.mask(prefixLength));
    }

    // Should have exactly one entry if all addresses are in the same subnet. If
    // there were no addresses, size may be 0.
    return networkPrefixes.size <= 1;
}

function asAddress4WithSubnet(subnetMaskOrPrefix: SubnetMask | Prefix, address: IPv4Address): Address4 {
    assertIsIp(address, 4);
    return new Address4(`${address}/${getPrefixLength(subnetMaskOrPrefix)}`);
}

function isSameAddress(a: IPv4Address | IPv6Address, b: IPv4Address | IPv6Address) {
    const addressA = asAddress(a);
    const addressB = asAddress(b);

    return addressA && addressB && addressA.correctForm() === addressB.correctForm();
}

export function isBroadcastAddress(subnetMaskOrPrefix: SubnetMask | Prefix | undefined, address: IPv4Address) {
    return (
        subnetMaskOrPrefix !== undefined &&
        getPrefixLength(subnetMaskOrPrefix) < 31 &&
        isSameAddress(asAddress4WithSubnet(subnetMaskOrPrefix, address).endAddress().correctForm(), address)
    );
}

export function isNetworkAddress(subnetMaskOrPrefix: SubnetMask | Prefix | undefined, address: IPv4Address) {
    return (
        subnetMaskOrPrefix !== undefined &&
        getPrefixLength(subnetMaskOrPrefix) < 31 &&
        isSameAddress(asAddress4WithSubnet(subnetMaskOrPrefix, address).startAddress().correctForm(), address)
    );
}
