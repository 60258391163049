import classnames from "classnames";
import React, { memo } from "react";
import { FieldProps } from "../../props/forms";

export interface Props extends FieldProps, React.InputHTMLAttributes<HTMLInputElement> {}

function TextInput({ className, error, dirty, value, ...props }: Props) {
    return (
        <input
            className={classnames("TextInput", className, {
                error,
                dirty,
            })}
            type="text"
            value={value ?? ""}
            {...props}
        />
    );
}

export default memo(TextInput);
