/* eslint-disable */
var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var plural = function(value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  if (key in data) return data[key];
  return data.other;
};

export default {
  actions: {
    add: function(d) { return "Add"; },
    addANewAddress: function(d) { return "Add new address"; },
    addAnother: function(d) { return "Add Another"; },
    cancel: function(d) { return "Cancel"; },
    confirm: function(d) { return "Confirm"; },
    copy: function(d) { return "Copy"; },
    create: function(d) { return "Create"; },
    createANewContact: function(d) { return "Add new contact"; },
    editThisAddress: function(d) { return "Edit this address"; },
    enterNewAddress: function(d) { return "Enter new address"; },
    goBack: function(d) { return "Back"; },
    ok: function(d) { return "Ok"; },
    paste: function(d) { return "Paste"; },
    remove: function(d) { return "Remove"; },
    retry: function(d) { return "Try again"; },
    save: function(d) { return "Save now"; },
    singleSignOn: function(d) { return "Sign In With SSO"; },
    signIn: function(d) { return "Sign In"; },
    signOut: function(d) { return "Log Out"; },
    submit: function(d) { return "Submit"; },
    tokenSignOn: function(d) { return "Sign In With Token"; },
    undo: function(d) { return "Undo"; },
    unlock: function(d) { return "Unlock"; },
    restore: function(d) { return "Restore"; },
    useSiteAddressAsShippingAddress: function(d) { return "Copy site address"; },
    chooseFromSavedAddresses: function(d) { return "Choose from saved addresses..."; },
    chooseFromSavedContacts: function(d) { return "Choose from saved contacts..."; }
  },
  choices: {
    affirmative: function(d) { return "Yes"; },
    all: function(d) { return "All"; },
    both: function(d) { return "Both"; },
    negative: function(d) { return "No"; },
    neither: function(d) { return "Neither"; },
    none: function(d) { return "None"; }
  },
  entity: {
    appliance: function(d) { return plural(d.COUNT, 0, en, { "1": "Appliance", other: "Appliances" }); },
    contact: function(d) { return "Contact"; },
    networkConfig: function(d) { return plural(d.COUNT, 0, en, { "1": "Network Config", other: "Network Configs" }); },
    oca: function(d) { return plural(d.COUNT, 0, en, { "1": "OCA", other: "OCAs" }); },
    twoPostRack: function(d) { return "2-post rack"; },
    fourPostRack: function(d) { return "4-post rack"; }
  },
  errors: {
    generic: function(d) { return "Something went wrong"; },
    login: {
      invalidCode: function(d) { return "The code you entered is invalid. Verify that you have entered the code exactly."; },
      expiredCode: function(d) { return "This code has expired. Please contact your PEM for a new code."; }
    },
    save: {
      nothingToSave: function(d) { return "There is nothing to save"; }
    },
    submit: {
      generic: function(d) { return "Something went wrong. Please try again later."; },
      validation: function(d) { return "Please fix the following errors and try again"; }
    }
  },
  forms: {
    placeholders: {
      bgpAsn: function(d) { return "Choose ASN..."; },
      bgpMultihop: function(d) { return ""; },
      bmcv4Address: function(d) { return ""; },
      bmcv4Gateway: function(d) { return ""; },
      bmcv4Netmask: function(d) { return "Example: 255.255.255.0 or /24"; },
      bmcv6Address: function(d) { return ""; },
      bmcv6Gateway: function(d) { return ""; },
      bmcv6PrefixLength: function(d) { return "Example: /24"; },
      selectOrg: function(d) { return "Select an Org"; },
      selectAddress: function(d) { return "Select an Address"; },
      selectContact: function(d) { return "Select a Contact"; },
      selectOrEnterNewAddress: function(d) { return "Select or Enter an Address"; },
      selectOrEnterNewContact: function(d) { return "Select or Enter a Contact"; },
      selectPart: function(d) { return "Select a Part"; },
      selectState: function(d) { return "Select State"; },
      selectCountry: function(d) { return "Select Country"; },
      city: function(d) { return "City"; },
      code: function(d) { return "Example: 83b616bf-c0ce-40b1-b796-110c5b937160"; },
      country: function(d) { return "Country"; },
      email: function(d) { return "name@company.com"; },
      emailWork: function(d) { return "name@company.com"; },
      ipv4Address: function(d) { return ""; },
      ipv4BGPPeer: function(d) { return ""; },
      ipv4Gateway: function(d) { return ""; },
      ipv4SubnetMask: function(d) { return "Example: 255.255.255.0 or /24"; },
      ipv6Address: function(d) { return ""; },
      ipv6BGPPeer: function(d) { return ""; },
      ipv6Gateway: function(d) { return ""; },
      ipv6Prefix: function(d) { return "Example: /24"; },
      nameFirst: function(d) { return "First Name"; },
      nameLast: function(d) { return "Last Name"; },
      notes: function(d) { return "Your Notes"; },
      phoneWork: function(d) { return "Phone Number"; },
      postal: function(d) { return "Postal Code"; },
      recipient: function(d) { return "Recipient"; },
      quantity: function(d) { return "Enter Quantity"; },
      roles: function(d) { return ""; },
      selectGeneric: function(d) { return "Select..."; },
      state: function(d) { return "State/Province/Region"; },
      street1: function(d) { return "Address Line 1"; },
      street2: function(d) { return "Address Line 2"; }
    },
    validation: {
      currentTypeMismatch: function(d) { return "AC/DC must match that of " + d.FIELD; },
      doesNotMatchPattern: function(d) { return "Does not match the expected pattern"; },
      eoriLength: function(d) { return "EORI must be at least 3 characters long"; },
      eoriPattern: function(d) { return "EORI must start with a 2-letter country code"; },
      invalid: function(d) { return "The value you entered is invalid"; },
      invalidEmail: function(d) { return "Must be a valid email address"; },
      invalidInteger: function(d) { return "This must be a whole number"; },
      invalidPhoneNumber: function(d) { return "Must be a valid phone number"; },
      invalidPrefix: function(d) { return "Must be a valid prefix"; },
      invalidUuid: function(d) { return "Must be a valid UUID key"; },
      ipv4BroadcastAddressNotAllowed: function(d) { return "Broadcast address not allowed"; },
      ipv4InvalidSubnetMask: function(d) { return "Must be a valid subnet mask"; },
      ipv4Malformed: function(d) { return "Must be a valid IPv4 address"; },
      ipv4MappedNotAllowed: function(d) { return "IPv4-mapped IPv6 addresses are not allowed"; },
      ipv4NetworkAddressNotAllowed: function(d) { return "Network address not allowed"; },
      ipv4SubnetMaskOutOfRange: function(d) { return "Subnet mask must be between " + d.MIN + " and " + d.MAX; },
      ipv6Malformed: function(d) { return "Must be a valid IPv6 address"; },
      localhostNotAllowed: function(d) { return "Must not be localhost"; },
      missingContact: function(d) { return "Please provide a " + d.CONTACT_ROLE; },
      missingPickupAddress: function(d) { return "Enter a pickup address"; },
      missingShippingAddress: function(d) { return "Enter a shipping address"; },
      missingSiteAddress: function(d) { return "Enter a site address"; },
      mustBeEqual: function(d) { return "Must be equal to " + d.FIELD; },
      mustBeUnique: function(d) { return "This " + d.FIELD + " is already specified on " + d.LOCATION; },
      mustNotBeEqual: function(d) { return "Must not be equal to " + d.FIELD; },
      netflixEmailNotAllowed: function(d) { return "This should not be a netflix contact"; },
      networkConfig: {
        bmcv4Address: function(d) { return "Remote IPv4 Address"; },
        bmcv4Gateway: function(d) { return "Remote IPv4 Gateway"; },
        bmcv4Netmask: function(d) { return "Remote IPv4 Subnet Mask"; },
        bmcv6Address: function(d) { return "Remote IPv6 Address"; },
        bmcv6Gateway: function(d) { return "Remote IPv6 Gateway"; },
        bmcv6PrefixLength: function(d) { return "Remote IPv6 Prefix"; },
        ipv4Address: function(d) { return "Primary IPv4 Address"; },
        ipv4BGPPeer: function(d) { return "Primary IPv4 BGP Peer Address"; },
        ipv4Gateway: function(d) { return "Primary IPv4 Gateway"; },
        ipv4SubnetMask: function(d) { return "Primary IPv4 Subnet Mask"; },
        ipv6Address: function(d) { return "Primary IPv6 Address"; },
        ipv6BGPPeer: function(d) { return "Primary IPv6 BGP Peer Address"; },
        ipv6Gateway: function(d) { return "Primary IPv6 Gateway"; },
        ipv6Prefix: function(d) { return "Primary IPv6 Prefix"; }
      },
      notInSubnet: function(d) { return "Must be in the same subnet as " + d.OTHER_ADDRESS; },
      prefixOutOfRange: function(d) { return "Prefix must be between " + d.MIN + " and " + d.MAX; },
      range: function(d) { return "Must be a number between " + d.MIN + " and " + d.MAX; },
      rangeCompact: function(d) { return "Must be between " + d.MIN + "-" + d.MAX; },
      greaterThanMax: function(d) { return "Must be at most " + d.MAX; },
      lessThanMin: function(d) { return "Must be at least " + d.MIN; },
      required: function(d) { return "This field is required"; },
      tooLong: function(d) { return "This is too long"; },
      tooShort: function(d) { return "This is too short"; },
      suspiciousStreet: function(d) { return "This doesn't look like a street address"; }
    }
  },
  instructions: {
    complete: {
      appliances: function(d) { return "Finish configuring your Open Connect Appliances"; },
      parts: function(d) { return "Complete parts information"; },
      pickup: function(d) { return "Complete pickup information"; },
      shipping: function(d) { return "Complete shipping and contact information"; },
      site: function(d) { return "Complete site information"; }
    },
    correct: {
      appliances: function(d) { return "Correct your Open Connect Appliance configurations"; },
      parts: function(d) { return "Complete parts information"; },
      pickup: function(d) { return "Correct pickup information"; },
      shipping: function(d) { return "Correct shipping and contact information"; },
      site: function(d) { return "Correct site information"; }
    }
  },
  labels: {
    address: function(d) { return "Address"; },
    addressField: {
      recipient: function(d) { return "Recipient"; },
      city: function(d) { return "City"; },
      country: function(d) { return "Country"; },
      label: function(d) { return "Label"; },
      postal: function(d) { return "Postal Code"; },
      state: function(d) { return "State/Province/Region"; },
      street: function(d) { return "Address"; },
      street1: function(d) { return "Address Line 1"; },
      street2: function(d) { return "Address Line 2"; }
    },
    attachments: function(d) { return "Attachments"; },
    bgpAsn: function(d) { return "Your BGP ASN"; },
    bgpMultihop: function(d) { return "BGP Multihop (1-16, 0=disabled)"; },
    contactField: {
      emailWork: function(d) { return "Email"; },
      name: function(d) { return "Name"; },
      nameFirst: function(d) { return "First Name"; },
      nameLast: function(d) { return "Last Name"; },
      phoneWork: function(d) { return "Phone Number"; },
      role: function(d) { return "Role"; },
      roles: function(d) { return "Roles"; }
    },
    contactType: {
      customs: function(d) { return "Licensed Customs Broker"; },
      generic: function(d) { return "Contact"; },
      noc: function(d) { return "NOC"; },
      shipping: function(d) { return "Shipping Contact"; },
      technical: function(d) { return "Engineering Contact"; },
      logistics: function(d) { return "Logistics/Foreign Trade Contact"; }
    },
    lastReportedHostname: function(d) { return "Last Reported Hostname"; },
    lastReportedIP: function(d) { return "Last Reported IP Address"; },
    hostname: function(d) { return "Hostname"; },
    ip: function(d) { return "IP Address"; },
    lastUpdated: function(d) { return "Last updated"; },
    login: {
      code: function(d) { return "Code"; },
      email: function(d) { return "Email"; }
    },
    networkConfig: {
      bmcv4Address: function(d) { return "IPv4 Address"; },
      bmcv4Gateway: function(d) { return "IPv4 Gateway"; },
      bmcv4Netmask: function(d) { return "IPv4 Subnet Mask"; },
      bmcv6Address: function(d) { return "IPv6 Address"; },
      bmcv6Gateway: function(d) { return "IPv6 Gateway"; },
      bmcv6PrefixLength: function(d) { return "IPv6 Prefix"; },
      ipv4Address: function(d) { return "IPv4 Address"; },
      ipv4BGPPeer: function(d) { return "IPv4 BGP Peer Address"; },
      ipv4Gateway: function(d) { return "IPv4 Gateway"; },
      ipv4SubnetMask: function(d) { return "IPv4 Subnet Mask"; },
      ipv6Address: function(d) { return "IPv6 Address"; },
      ipv6BGPPeer: function(d) { return "IPv6 BGP Peer Address"; },
      ipv6Gateway: function(d) { return "IPv6 Gateway"; },
      ipv6Prefix: function(d) { return "IPv6 Prefix"; }
    },
    noRoles: function(d) { return "Others"; },
    notes: function(d) { return "Notes"; },
    ocaReturn: {
      boxed: function(d) { return "Are the appliance(s) boxed and ready to ship?"; },
      frequentShipper: function(d) { return "Is the pickup site frequently visited by either UPS or Fedex?"; },
      liftGate: function(d) { return "Is a lift gate required to retrieve the appliance(s) from the site?"; },
      preferredDay: function(d) { return "Preferred pickup day"; },
      preferredTime: function(d) { return "Preferred pickup time"; }
    },
    ocaClass: {
      Flash: function(d) { return "Flash"; },
      Global: function(d) { return "Global"; },
      Storage: function(d) { return "Storage"; },
      Unknown: function(d) { return "Unknown"; }
    },
    partOrder: {
      cable: function(d) { return "Power Cable"; },
      optics: function(d) { return "Optics"; },
      part: function(d) { return "Part"; },
      patchCable: function(d) { return "Patch Cable"; },
      powerSupply: function(d) { return "Power Supply"; },
      quantity: function(d) { return "Quantity"; },
      rack: function(d) { return "Rack"; },
      shippingMaterials: function(d) { return "Shipping Materials"; },
      adapter: function(d) { return "Adapter"; }
    },
    rackType: function(d) { return "Rack Type"; },
    serialNumber: function(d) { return "Serial Number"; },
    shippingAddress: function(d) { return "Address"; },
    site: {
      cableTypePartDefinition: function(d) { return "Power Cable"; },
      opticsTypePartDefinition: function(d) { return "Optics Type"; },
      patchCableTypePartDefinition: function(d) { return "Patch Cable Type"; },
      powerTypePartDefinition: function(d) { return "Power Type"; },
      rackTypePartDefinition: function(d) { return "Rail Kit"; }
    },
    storageClass: function(d) { return "Storage Class"; },
    submitted: function(d) { return "Submitted"; },
    toDo: function(d) { return "To Do"; },
    uuid: function(d) { return "Unique ID"; },
    taxId: function(d) { return "Tax ID"; }
  },
  meta: {
    InProgress: function(d) { return "In Progress"; },
    Submitted: function(d) { return "Submitted"; },
    "new": function(d) { return "New"; },
    rma: function(d) { return "RMA"; }
  },
  notes: {
    additionalRecipients: function(d) { return "Please specify any additional recipients for notifications about this survey"; },
    contactDescription: {
      customs: function(d) { return "Your customs agent or a contact at your customs broker."; },
      noc: function(d) { return "Network Operations Center"; },
      ocaReturn: function(d) { return "The person at your company managing this return"; },
      partOrder: function(d) { return "The person at your company who will receive this order"; },
      shipping: function(d) { return "The person at your company who will receive the shipment at the docks."; },
      technical: function(d) { return "The engineering contact at your company."; },
      logistics: function(d) { return "<p>Person with knowledge of the import/export process who can provide Importer of record/Customs broker information.</p><p>This contact will assist in all aspects of the import/export process as needed.</p>"; }
    },
    siteSurveyNotes: function(d) { return "Please specify any additional notes or requirements here"; },
    whereToFindCode: function(d) { return "You can find this in the email"; },
    site: {
      patchCableTypePartDefinition: function(d) { return "If a patch cable is not required, leave this selection blank."; },
      cableTypePartDefinition: function(d) { return "If a power cable is not required, leave this selection blank."; },
      rackTypePartDefinition: function(d) { return "The type of rail kit you need depends on the rack in your datacenter. What kind of rack do you have?"; },
      "rackTypePartDefinition.Flash": function(d) { return "Standard 4-post rail kit (2-post compatible) is included."; }
    },
    partNotAllowed: function(d) { return "This component is not supported for this order."; }
  },
  notice: {
    disableSudo: function(d) { return "You are currently forcing this form to be editable. Restore to discard all unsaved changes."; },
    enableSudo: function(d) { return "You are seeing this Unlock button because you are an Admin user. If necessary, you can unlock the form to make edits that partners are no longer permitted to make. Please proceed carefully. If in doubt, check with [#octools-help](https://netflix.slack.com/archives/C0BT8LMUY)."; },
    asnsNotRegistered: function(d) { return "No ASNs on file"; },
    contactPEMToRegisterASNs: function(d) { return "Please contact your PEM to register your ASNs with Netflix."; },
    done: function(d) { return "Done"; },
    ipv6NotConfigured: function(d) { return "IPv6 not configured"; },
    loading: function(d) { return "Loading..."; },
    locked: function(d) { return "This site survey is no longer editable"; },
    missing: function(d) { return "There are no " + d.ITEMS + " to show"; },
    missingContact: function(d) { return "No contact on file"; },
    missingGeneric: function(d) { return "There is nothing to show"; },
    missingAdditionalRecipients: function(d) { return "No additional recipients have been specified"; },
    missingNotes: function(d) { return "There are no notes"; },
    missingParts: function(d) { return "No parts have been specified. Add a part to get started."; },
    missingShippingAddress: function(d) { return "No shipping address on file"; },
    missingSiteAddress: function(d) { return "No site address on file"; },
    missingTaxId: function(d) { return "No Tax ID on file"; },
    missingValue: function(d) { return "No " + d.VALUE_NAME + " on file"; },
    "new": function(d) { return "New"; },
    noAdditionalRecipients: function(d) { return "No additional recipients"; },
    noMatchingOcaReturns: function(d) { return "No OCA returns in this stage"; },
    noMatchingPartOrders: function(d) { return "No parts orders in this stage"; },
    noMatchingSiteSurveys: function(d) { return "No site surveys in this stage"; },
    notSpecified: function(d) { return "Not specified"; },
    ocaReturnDoesNotExist: function(d) { return "The OCA return form you are looking for does not exist"; },
    partOrderDoesNotExist: function(d) { return "The parts order you are looking for does not exist"; },
    partOrderCancelled: function(d) { return "This part order has been cancelled"; },
    rma: function(d) { return "Return Merchandise Authorization"; },
    remoteManagementNotConfigured: function(d) { return "Remote Management not configured"; },
    saving: function(d) { return "Saving..."; },
    selectAPartOrder: function(d) { return "Select a parts order to get started"; },
    selectASiteSurvey: function(d) { return "Select a site survey to get started"; },
    selectAnOcaReturn: function(d) { return "Select a OCA return form to get started"; },
    siteSurveyDoesNotExist: function(d) { return "The site survey you are looking for does not exist or may have expired"; },
    siteSurveyCancelled: function(d) { return "This site survey has been cancelled"; },
    unsavedChanges: function(d) { return "You have unsaved changes. Are you sure you want to leave?"; },
    partsPossiblyUnavailable: function(d) { return "We no longer stock PSU replacement parts for this version of OCA. If there is an issue with this OCA that requires a new PSU, we will need to initiate an RMA."; },
    remediationOpenATicket: function(d) { return "If you have questions, please [open a ticket](" + d.URL + ")."; },
    remediationAnonymous: function(d) { return "If you have questions, please reach out for assistance."; },
    fixedCountry: function(d) { return "Your PEM has designated this country as the physical location of the site. Please reach out to your PEM if this value needs to be corrected."; },
    addressUnverified: function(d) { return "Unable to verify address"; },
    addressUnverifiedDetails: function(d) { return "We may lack the capability to accurately verify addresses in your country. Please carefully review the address fields for correctness before proceeding."; },
    addressVerified: function(d) { return "Address verified!"; },
    example: function(d) { return "Example: " + d.EXAMPLE; },
    rackType: function(d) { return "Most data centers use a standard rectangular 4-post rack. We will provide a 2-post adapter only if you plan to install your OCAs on a 2 post rack."; },
    datacenterOptions: function(d) { return "Some options may be unavailable based on your OCA's configuration, or incompatible with the other selections made in this form."; }
  },
  partnerForm: {
    saveHint: {
      failed: function(d) { return "Something went wrong..."; },
      hasErrors: function(d) { return "You must fix all errors before saving"; },
      saved: function(d) { return "Saved"; },
      saving: function(d) { return "Saving..."; },
      unsaved: function(d) { return "You have unsaved changes"; }
    },
    toDo: {
      submit: {
        ocaReturn: function(d) { return "Submit the form when you are ready."; },
        partOrder: function(d) { return "Submit the order when you are ready."; },
        siteSurvey: function(d) { return "Submit the survey when you are ready. You can still make changes after submission."; }
      },
      submittedAndDirty: {
        ocaReturn: function(d) { return "Save your changes."; },
        partOrder: function(d) { return "Save your changes."; },
        siteSurvey: function(d) { return "Save your changes."; }
      },
      submittedAndEditable: {
        ocaReturn: function(d) { return "This form has been submitted."; },
        partOrder: function(d) { return "This order has been submitted."; },
        siteSurvey: function(d) { return "This survey is complete! You can still make some changes if necessary."; }
      },
      submittedAndLocked: {
        ocaReturn: function(d) { return "This form has been submitted."; },
        partOrder: function(d) { return "This order has been submitted."; },
        siteSurvey: function(d) { return "This survey is complete! If you require any assistance, please contact your PEM."; }
      }
    }
  },
  title: {
    additionalRecipients: function(d) { return "Additional Recipients"; },
    address: function(d) { return "Address"; },
    app: function(d) { return "Open Connect Partner Forms"; },
    applianceNetworkConfiguration: function(d) { return "Appliance Network Configuration"; },
    bgpConfiguration: function(d) { return "BGP Configuration"; },
    cfdi: function(d) { return "CFDI"; },
    contacts: function(d) { return "Contacts"; },
    datacenterAttributes: function(d) { return "Datacenter Attributes"; },
    editContactInPartnerPortal: function(d) { return "Edit contact in Partner Portal"; },
    eori: function(d) { return "EORI Number"; },
    login: function(d) { return "Sign In to Partner Forms "; },
    enterEmailAndCode: function(d) { return "Use Temporary Code"; },
    enterEmailAndCodeSubtitle: function(d) { return "If you received an email with a link to this form, you can sign in using the temporary code included in the email"; },
    singleSignOn: function(d) { return "Use Single Sign-On"; },
    singleSignOnSubtitle: function(d) { return "Sign in using your Partner Portal credentials"; },
    notes: function(d) { return "Additional Information"; },
    ocaReturnDetails: function(d) { return "You are returning the following " + plural(d.COUNT, 0, en, { "1": "Appliance", other: "Appliances" }); },
    ocaReturnView: {
      more: function(d) { return "More"; },
      pickup: function(d) { return "Pickup"; }
    },
    orgPicker: function(d) { return "View Partner Forms for..."; },
    parts: function(d) { return "Parts"; },
    partOrderView: {
      more: function(d) { return "More"; },
      parts: function(d) { return "Parts"; },
      shipping: function(d) { return "Shipping"; }
    },
    pickupAddress: function(d) { return "Pickup Address"; },
    pickupPreferences: function(d) { return "Preferences"; },
    pickupProperties: function(d) { return "About the Site"; },
    primaryNetworkConfiguration: function(d) { return "Primary Network Configuration"; },
    primaryNetworkConfigurationDescription: function(d) { return "Please provide a publicly-routable IPv4 configuration that will be used to serve Netflix member traffic. An IPv6 configuration is optional."; },
    remoteNetworkConfiguration: function(d) { return "Remote Management Configuration"; },
    remoteNetworkConfigurationDescription: function(d) { return "Please provide an IPv4 or IPv6 configuration that will be used by Netflix for remote management."; },
    shippingAddress: function(d) { return "Shipping Address"; },
    siteSurveyView: {
      appliances: function(d) { return "Appliances"; },
      more: function(d) { return "More"; },
      shipping: function(d) { return "Shipping & Contacts"; },
      site: function(d) { return "Site"; }
    },
    taxInfo: function(d) { return "Tax Info"; }
  },
  alt: {
    twoPostRack: function(d) { return "A rendering of a 2-post rack on a white background"; },
    fourPostRack: function(d) { return "A picture of a 4-post rack on a white background"; }
  }
}