import { Component } from "react";
import { RenderProp } from "../../props/render";

interface Props {
    condition?: boolean;
    children: RenderProp;
}

/**
 * Some sugar that allows a more palatable JSX syntax for conditional rendering,
 * avoiding the common {condition ? component : null} everywhere.
 */
class If extends Component<Props> {
    render() {
        const { condition = true, children } = this.props;

        return condition ? children() : null;
    }
}

export default If;
