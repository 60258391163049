import classnames from "classnames";
import React, { memo } from "react";
import logo from "../../../img/NetflixLogo.png";
import { AuthService } from "../../../services/auth";
import Container from "../../../shared/components/Container";
import Header from "../../../shared/components/Header";
import Icon from "../../../shared/components/Icon";
import { WithUser } from "../../../shared/hoc/withUser";
import { messages } from "../../../shared/messages";

interface Props extends WithUser {
    onClickLogOut: () => void;
    env?: string;
}

function AppHeader({ onClickLogOut, user, env = "dev" }: Props) {
    return (
        <div
            className={classnames("AppHeader", {
                authenticated: !!user,
                dev: env === "dev",
                test: env === "test",
                prod: env === "prod"
            })}
        >
            <Container>
                <img className="AppHeader-logo" src={logo} alt="Netflix logo" />
                <Header
                    justifyContent={user ? "default" : "center"}
                    className="AppHeader-header"
                    title={messages.title.app()}
                    rightAccessory={env !== "prod" ? <span className="AppHeader-env">{env}</span> : null}
                />
                {user ? (
                    <div className="AppHeader-user">
                        <Icon size="lg" icon="user-circle" />
                        <span className="AppHeader-identity">{AuthService.getIdentity(user)}</span>
                        <Icon
                            className="AppHeader-logout"
                            size="lg"
                            icon="sign-out-alt"
                            onClick={onClickLogOut}
                            title={messages.actions.signOut()}
                        />
                    </div>
                ) : (
                    <div className="AppHeader-balance" />
                )}
            </Container>
        </div>
    );
}

export default memo(AppHeader);
