import React, { memo, useMemo } from "react";
import { messages } from "../../messages";
import { FieldMetadata } from "../../models/FieldMetadata";
import MissingInfo from "../MissingInfo";
import { Content } from "../PartnerForm";
import Segment from "../Segment";
import Value from "../Value";

interface Props {
    cfdiFieldMetadata: FieldMetadata[];
    cfdiFields: Record<string, string>;
}

function CfdiFields({ cfdiFieldMetadata, cfdiFields }: Props) {
    const metadataByName = useMemo(() => new Map(cfdiFieldMetadata.map((metadata) => [metadata.name, metadata])), []);
    return (
        <Content title={messages.title.cfdi()}>
            <Segment.Group>
                {Object.entries(cfdiFields).map(([name, value]) => {
                    const metadata = metadataByName.get(name);
                    return (
                        <Segment key={name}>
                            {value ? (
                                <Value label={metadata?.label ?? name}>
                                    <span>{value}</span>
                                </Value>
                            ) : (
                                <MissingInfo
                                    message={messages.notice.missingValue({
                                        VALUE_NAME: metadata?.label ?? name,
                                    })}
                                />
                            )}
                        </Segment>
                    );
                })}
            </Segment.Group>
        </Content>
    );
}

export default memo(CfdiFields);
