import { pick } from "lodash";
import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { AuthService } from "../../../services/auth";
import { AddressVerificationResult } from "../../../services/topgear/models/AddressVerificationResult";
import AdditionalRecipients from "../../../shared/components/AdditionalRecipients";
import AdditionalRecipientsEditor from "../../../shared/components/AdditionalRecipientsEditor";
import AddressEditor from "../../../shared/components/AddressEditor";
import AddressVerificationStatus from "../../../shared/components/AddressVerificationStatus";
import Badge from "../../../shared/components/Badge";
import CfdiFields from "../../../shared/components/CfdiFields";
import CfdiFieldsEditor from "../../../shared/components/CfdiFieldsEditor";
import Contacts from "../../../shared/components/Contacts";
import ContactsEditor from "../../../shared/components/ContactsEditor";
import Divider from "../../../shared/components/Divider";
import EoriField from "../../../shared/components/EoriField/EoriField";
import EoriFieldEditor from "../../../shared/components/EoriFieldEditor/EoriFieldEditor";
import MenuItem from "../../../shared/components/MenuItem";
import Notes from "../../../shared/components/Notes";
import NotesEditor from "../../../shared/components/NotesEditor";
import PartnerForm, { Header, Info } from "../../../shared/components/PartnerForm";
import PartnerFormInstance from "../../../shared/components/PartnerForm/PartnerForm";
import PartnerFormActionItems from "../../../shared/components/PartnerFormActionItems";
import SaveAndSubmit from "../../../shared/components/SaveAndSubmit";
import Segment from "../../../shared/components/Segment";
import ShippingAddressForm from "../../../shared/components/ShippingAddressForm";
import ShippingInfo from "../../../shared/components/ShippingInfo";
import SlideDown from "../../../shared/components/SlideDown";
import Tab, { Pane } from "../../../shared/components/Tab";
import TaxpayerFields from "../../../shared/components/TaxpayerFields";
import TaxpayerFieldsEditor from "../../../shared/components/TaxpayerFieldsEditor";
import { UserContext } from "../../../shared/contexts/UserContext";
import { CLICK_ERROR_ACTION_ITEM } from "../../../shared/events";
import logger from "../../../shared/logger";
import { messages } from "../../../shared/messages";
import { AddressFields, DEFAULT_ADDRESS_VALUES } from "../../../shared/models/AddressFields";
import { ApiRequestCollectionState, ApiRequestState } from "../../../shared/models/ApiRequestState";
import { CFDI_FIELDS_KEY, CFDI_FIELD_METADATA_KEY } from "../../../shared/models/CfdiCompliant";
import { ContactFields, NAME_FIRST_KEY, NAME_LAST_KEY } from "../../../shared/models/ContactFields";
import { ContactRoleDescriptor } from "../../../shared/models/ContactRoleDescriptor";
import { Dictionary } from "../../../shared/models/Dictionary";
import { ModelValidationErrors } from "../../../shared/models/ModelValidationErrors";
import { PartnerFormKind } from "../../../shared/models/PartnerFormKind";
import { DEFAULT_SHIPPING_ADDRESS_VALUES } from "../../../shared/models/ShippingAddressFields";
import { TAXPAYER_FIELDS_KEY, TAXPAYER_FIELD_METADATA_KEY } from "../../../shared/models/TaxpayerAffiliated";
import { ValidationError } from "../../../shared/models/ValidationError";
import {
    ChangeAddress,
    ChangeCfdiFields,
    ChangeContactForRole,
    ChangeEori,
    ChangeShippingAddress,
    ChangeSiteSurveyBgpConfig,
    ChangeSiteSurveyDatacenter,
    ChangeSiteSurveyNetworkConfig,
    ChangeTaxpayerFields,
    DownloadAttachment,
    GetAllowedParts,
    GetShippingAddressSuggestions,
    SelectAddress,
    SelectContactForRole,
    SetActiveNetworkConfigIndex,
} from "../../../shared/props/actions";
import { traverse } from "../../../shared/validation";
import Appliances from "../../../siteSurveys/components/Appliances";
import AppliancesEditor from "../../../siteSurveys/components/AppliancesEditor";
import Datacenter from "../../../siteSurveys/components/Datacenter";
import DatacenterEditor from "../../../siteSurveys/components/DatacenterEditor";
import SiteInfoEditor from "../../../siteSurveys/components/SiteInfoEditor";
import { COPYABLE_NETWORK_CONFIG_FIELDS } from "../../../siteSurveys/models/NetworkConfig";
import { NETWORK_CONFIGS_KEY, SiteSurvey as SiteSurveyModel } from "../../../siteSurveys/models/SiteSurvey";
import { SHIPPING_ADDRESS_KEY, SITE_ADDRESS_KEY } from "../../../siteSurveys/models/SiteSurveyAddresses";
import {
    ContactKey,
    getContactKeyDescription,
    getContactKeyLabel,
    getRequiredContactKeys,
    SITE_SURVEY_CONTACT_KEYS,
} from "../../../siteSurveys/models/SiteSurveyContacts";
import { SiteSurveyView, title } from "../../../siteSurveys/models/SiteSurveyView";
import { checkIsEmptyAddress } from "../../../utils/address";
import { getAllowSave, getAllowSubmit, getSaveHint } from "../../../utils/partnerForm";
import {
    checkIsEditable,
    checkIsNew,
    checkIsSubmitted,
    extractContactsFromSiteSurvey,
    getLastUpdated,
    getSubmittedOn,
} from "../../../utils/siteSurvey";
import { BGP_ASN_KEY, BGP_MULTIHOP_KEY } from "../../models/BgpConfig";
import { PartnerFormProps } from "../../models/PartnerFormProps";
import { SiteSurveyProperty } from "../../models/SiteSurveyProperty";
import { checkIsActive } from "../../models/SiteSurveyStatus";
import SiteInfo from "../SiteInfo";

export interface Props extends RouteComponentProps<Record<string, string>>, PartnerFormProps<SiteSurveyModel> {
    activeNetworkConfigIndex?: number;
    addressVerificationsState?: ApiRequestState<AddressVerificationResult[] | undefined>;
    asns: readonly number[];
    attachmentDownloadStates: ApiRequestCollectionState;
    fetchShippingAddressSuggestions: GetShippingAddressSuggestions;
    fetchAllowedParts: GetAllowedParts;
    onChangeBGPConfig: ChangeSiteSurveyBgpConfig;
    onChangeContactForRole: ChangeContactForRole<ContactKey>;
    onChangeDatacenter: ChangeSiteSurveyDatacenter;
    onChangeNetworkConfig: ChangeSiteSurveyNetworkConfig;
    onChangeShippingAddress: ChangeShippingAddress;
    onChangeSiteAddress: ChangeAddress;
    onChangeTaxpayerFields: ChangeTaxpayerFields;
    onChangeCfdiFields: ChangeCfdiFields;
    onChangeEori: ChangeEori;
    onDownloadAttachment: DownloadAttachment;
    onSelectContactForRole: SelectContactForRole<ContactKey>;
    onSelectShippingAddress: SelectAddress;
    onSetActiveNetworkConfigIndex: SetActiveNetworkConfigIndex;
    shippingAddressSuggestions: AddressFields[];
    siteSurvey: SiteSurveyModel;
}

class SiteSurvey extends PureComponent<Props> {
    private static ROLE_DESCRIPTORS: ContactRoleDescriptor[] = SITE_SURVEY_CONTACT_KEYS.map((key) => ({
        key,
        label: getContactKeyLabel(key),
        description: getContactKeyDescription(key),
    }));

    private static CONTACT_OMIT_FIELDS: Partial<Record<ContactKey, Set<keyof ContactFields>>> = Object.freeze({
        [ContactKey.NOC]: new Set([NAME_FIRST_KEY, NAME_LAST_KEY] as Array<keyof ContactFields>),
    });

    private static getContactsValidationErrors(
        errors: ModelValidationErrors<SiteSurveyModel>
    ): ModelValidationErrors<Dictionary<ContactFields>> {
        return pick(errors, ...SITE_SURVEY_CONTACT_KEYS);
    }

    private partnerForm = React.createRef<PartnerFormInstance>();

    constructor(props: Props) {
        super(props);

        this.handleClickRecoveryAction = this.handleClickRecoveryAction.bind(this);
        this.handleSelectShippingAddress = this.handleSelectShippingAddress.bind(this);
        this.handleSetActiveNetworkConfigIndex = this.handleSetActiveNetworkConfigIndex.bind(this);
    }

    componentDidMount() {
        const { forceEditable, clearSaveAndSubmitErrors, siteSurvey } = this.props;

        clearSaveAndSubmitErrors();

        if (checkIsEditable({ siteSurvey, forceEditable })) {
            this.initializeForms();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { forceEditable, reset } = this.props;

        if (forceEditable !== prevProps.forceEditable) {
            if (forceEditable) {
                // Initialize forms in case they weren't initialized during
                // mount.
                this.initializeForms();
            } else {
                // Reset form to its initial state. All unsaved changes are
                // discarded.
                reset();
            }
        }
    }

    public render() {
        const {
            forceEditable,
            saveState,
            submitState,
            onSave,
            onSubmit,
            siteSurvey,
            isDirty,
            isComplete,
            isValid,
            attachmentDownloadStates,
            clearSaveAndSubmitErrors,
            rolledUpErrors,
            onDownloadAttachment,
            ...props
        } = this.props;
        const isEditable = checkIsEditable({ siteSurvey, forceEditable });
        const isSubmitted = checkIsSubmitted(siteSurvey);
        const isActive = checkIsActive(siteSurvey.siteSurveyStatus);
        const allowSave = getAllowSave(isEditable || isActive, isDirty, isValid);
        const allowSubmit = getAllowSubmit(isEditable, isDirty, isComplete, isSubmitted, allowSave);

        return (
            <PartnerForm
                {...props}
                ref={this.partnerForm}
                hasUnsavedChanges={isDirty}
                onSave={allowSave ? onSave : undefined}
                header={
                    <Header
                        title={this.title}
                        isNew={checkIsNew(siteSurvey)}
                        editable={isEditable}
                        badges={
                            siteSurvey.rma && (
                                <Badge kind="unique" title={messages.notice.rma()}>
                                    {messages.meta.rma()}
                                </Badge>
                            )
                        }
                        accessory={() => (
                            <SaveAndSubmit
                                isSubmitted={isSubmitted}
                                allowSave={allowSave}
                                allowSubmit={allowSubmit}
                                saveState={saveState}
                                submitState={submitState}
                                onSubmit={onSubmit}
                                onSave={onSave}
                                saveHint={getSaveHint(PartnerFormKind.SiteSurvey, saveState, isDirty, allowSave)}
                                onDismiss={clearSaveAndSubmitErrors}
                            />
                        )}
                    />
                }
                info={
                    <Info
                        lastUpdated={getLastUpdated(siteSurvey)}
                        submittedOn={getSubmittedOn(siteSurvey)}
                        accessory={{
                            label: messages.labels.toDo(),
                            render: () => (
                                <PartnerFormActionItems
                                    kind={PartnerFormKind.SiteSurvey}
                                    isEditable={isEditable}
                                    isComplete={isComplete}
                                    isSubmitted={isSubmitted}
                                    allowSave={allowSave}
                                    allowSubmit={allowSubmit}
                                    errors={rolledUpErrors}
                                    partnerForm={siteSurvey}
                                    onClickRecoveryAction={this.handleClickRecoveryAction}
                                />
                            ),
                        }}
                    />
                }
            >
                {(view, onChangeView) => <Tab panes={this.panes} activePane={view} onSwitchPane={onChangeView} />}
            </PartnerForm>
        );
    }

    protected renderTaxInfo(): ReactNode {
        const {
            forceEditable,
            siteSurvey,
            modified,
            onChangeTaxpayerFields,
            onChangeCfdiFields,
            onChangeEori,
            errors,
        } = this.props;

        return (
            <Segment.Group>
                <Segment>
                    {checkIsEditable({ siteSurvey, key: SiteSurveyProperty.Eori, forceEditable }) ? (
                        <EoriFieldEditor
                            initialEori={siteSurvey.eori}
                            eori={modified?.eori}
                            eoriFieldMetadata={siteSurvey.eoriFieldMetadata}
                            onChange={({ eori }) => onChangeEori(eori)}
                            validationErrors={errors}
                        />
                    ) : (
                        <EoriField eori={siteSurvey?.eori} eoriFieldMetadata={siteSurvey.eoriFieldMetadata} />
                    )}
                </Segment>
                <Segment>
                    {checkIsEditable({ siteSurvey, key: SiteSurveyProperty.Taxpayer, forceEditable }) ? (
                        <TaxpayerFieldsEditor
                            initialTaxpayerFields={siteSurvey[TAXPAYER_FIELDS_KEY]}
                            taxpayerFields={modified?.[TAXPAYER_FIELDS_KEY]}
                            taxpayerFieldMetadata={siteSurvey[TAXPAYER_FIELD_METADATA_KEY]}
                            onChange={(taxpayerFields) => onChangeTaxpayerFields(taxpayerFields)}
                            validationErrors={traverse(errors, TAXPAYER_FIELDS_KEY)}
                        />
                    ) : (
                        <TaxpayerFields
                            taxpayerFields={siteSurvey?.[TAXPAYER_FIELDS_KEY]}
                            taxpayerFieldMetadata={siteSurvey[TAXPAYER_FIELD_METADATA_KEY]}
                        />
                    )}
                </Segment>
                <Segment>
                    {checkIsEditable({ siteSurvey, key: SiteSurveyProperty.Cfdi, forceEditable }) ? (
                        <CfdiFieldsEditor
                            initialCfdiFields={siteSurvey[CFDI_FIELDS_KEY]}
                            cfdiFields={modified?.[CFDI_FIELDS_KEY]}
                            cfdiFieldMetadata={siteSurvey[CFDI_FIELD_METADATA_KEY]}
                            onChange={(cfdiFields) => onChangeCfdiFields(cfdiFields)}
                            validationErrors={traverse(errors, CFDI_FIELDS_KEY)}
                        />
                    ) : (
                        <CfdiFields
                            cfdiFields={siteSurvey?.[CFDI_FIELDS_KEY]}
                            cfdiFieldMetadata={siteSurvey[CFDI_FIELD_METADATA_KEY]}
                        />
                    )}
                </Segment>
            </Segment.Group>
        );
    }

    protected renderSiteInfo(): ReactNode {
        const {
            forceEditable,
            siteSurvey,
            countries,
            subdivisionsByCountryCode,
            onChangeSiteAddress,
            errors,
            modified,
        } = this.props;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.SiteAddress, forceEditable }) ? (
            <SiteInfoEditor
                initialSiteAddress={siteSurvey.siteAddress}
                siteAddress={modified?.siteAddress}
                fixedCountryCode={siteSurvey.countryCode}
                countries={countries}
                subdivisionsByCountryCode={subdivisionsByCountryCode}
                onChangeSiteAddress={onChangeSiteAddress}
                validationErrors={traverse(errors, SITE_ADDRESS_KEY)}
            />
        ) : (
            <SiteInfo siteAddress={siteSurvey.siteAddress} />
        );
    }

    protected renderDatacenter(): ReactNode {
        const { forceEditable, siteSurvey, modified, onChangeDatacenter, errors } = this.props;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.PartDefinitions, forceEditable }) ? (
            <DatacenterEditor
                id={siteSurvey.id}
                datacenter={modified}
                initialDatacenter={siteSurvey}
                onChange={onChangeDatacenter}
                validationErrors={errors}
            />
        ) : (
            <Datacenter datacenter={siteSurvey} />
        );
    }

    protected renderShippingInfo(): ReactNode {
        const {
            forceEditable,
            countries,
            subdivisionsByCountryCode,
            shippingAddressSuggestions,
            siteSurvey,
            modified,
            onChangeShippingAddress,
            errors,
            addressVerificationsState,
        } = this.props;
        const copyableSiteAddress = this.copyableSiteAddress;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.ShippingAddress, forceEditable }) ? (
            <>
                <AddressEditor
                    title={messages.title.shippingAddress()}
                    emptyAddress={DEFAULT_SHIPPING_ADDRESS_VALUES}
                    initialAddress={siteSurvey[SHIPPING_ADDRESS_KEY]}
                    address={modified?.[SHIPPING_ADDRESS_KEY]}
                    countries={countries}
                    subdivisionsByCountryCode={subdivisionsByCountryCode}
                    quickAddress={
                        copyableSiteAddress
                            ? {
                                  enabled: !checkIsEmptyAddress(copyableSiteAddress),
                                  label: messages.actions.useSiteAddressAsShippingAddress(),
                                  value: copyableSiteAddress,
                              }
                            : undefined
                    }
                    chooseFrom={shippingAddressSuggestions}
                    onEdit={onChangeShippingAddress}
                    onSelect={this.handleSelectShippingAddress}
                    validationErrors={traverse(errors, SHIPPING_ADDRESS_KEY)}
                    renderAddressForm={(props) => <ShippingAddressForm {...props} />}
                    required
                />
                <SlideDown in={!!addressVerificationsState}>
                    {addressVerificationsState?.value && (
                        <AddressVerificationStatus
                            pending={addressVerificationsState?.pending ?? false}
                            results={addressVerificationsState?.value}
                        />
                    )}
                </SlideDown>
            </>
        ) : (
            <ShippingInfo shippingAddress={siteSurvey.shippingAddress} />
        );
    }

    protected renderContacts(): ReactNode {
        const {
            forceEditable,
            contacts,
            siteSurvey,
            modified,
            onSelectContactForRole,
            onChangeContactForRole,
            errors,
            orgId,
        } = this.props;

        const requiredRoles = getRequiredContactKeys(siteSurvey.countryCode);

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.Contacts, forceEditable }) ? (
            <UserContext.Consumer>
                {(user) => {
                    const isMeechumUser = AuthService.checkIsAdminOrSsoPartner(user);

                    return (
                        <ContactsEditor
                            chooseFrom={contacts}
                            roleDescriptors={SiteSurvey.ROLE_DESCRIPTORS}
                            required={requiredRoles}
                            initialContacts={extractContactsFromSiteSurvey(siteSurvey)}
                            isMeechumUser={isMeechumUser}
                            contacts={modified ? extractContactsFromSiteSurvey(modified) : undefined}
                            onSelectContactForRole={(contact, role) =>
                                onSelectContactForRole(contact, role as ContactKey)
                            }
                            onChangeContactForRole={(contact, role) =>
                                onChangeContactForRole(contact, role as ContactKey)
                            }
                            validationErrors={SiteSurvey.getContactsValidationErrors(errors)}
                            omitFields={SiteSurvey.CONTACT_OMIT_FIELDS}
                            orgId={orgId}
                        />
                    );
                }}
            </UserContext.Consumer>
        ) : (
            <Contacts contacts={extractContactsFromSiteSurvey(siteSurvey)} roles={SiteSurvey.ROLE_DESCRIPTORS} />
        );
    }

    protected renderAppliances() {
        const {
            forceEditable,
            siteSurvey,
            modified,
            asns,
            onChangeBGPConfig,
            onChangeNetworkConfig,
            activeNetworkConfigIndex,
            onSetActiveNetworkConfigIndex,
            errors,
        } = this.props;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.NetworkConfigs, forceEditable }) ? (
            <AppliancesEditor
                asns={asns}
                initialBgpAsn={siteSurvey.bgpAsn}
                initialBgpMultihop={siteSurvey.bgpMultihop}
                initialNetworkConfigs={siteSurvey.networkConfigs}
                bgpAsn={modified?.[BGP_ASN_KEY]}
                bgpMultihop={modified?.[BGP_MULTIHOP_KEY]}
                networkConfigs={modified?.[NETWORK_CONFIGS_KEY]}
                copyableNetworkConfigIds={this.copyableNetworkConfigsIds}
                onChangeBgpConfig={onChangeBGPConfig}
                onChangeNetworkConfig={onChangeNetworkConfig}
                activeIndex={activeNetworkConfigIndex}
                onSetActiveNetworkConfigIndex={this.handleSetActiveNetworkConfigIndex}
                validationErrors={errors}
            />
        ) : (
            <Appliances
                bgpAsn={siteSurvey.bgpAsn}
                bgpMultihop={siteSurvey.bgpMultihop}
                networkConfigs={siteSurvey.networkConfigs}
                onSetActiveNetworkConfigIndex={onSetActiveNetworkConfigIndex}
                activeIndex={activeNetworkConfigIndex}
            />
        );
    }

    protected renderAdditionalRecipients(): ReactNode {
        const {
            forceEditable,
            siteSurvey,
            additionalRecipients,
            initialAdditionalRecipients,
            onChangeAdditionalRecipients,
        } = this.props;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.AdditionalRecipients, forceEditable }) ? (
            <AdditionalRecipientsEditor
                initialRecipients={initialAdditionalRecipients}
                recipients={additionalRecipients}
                onChange={onChangeAdditionalRecipients}
            />
        ) : (
            <AdditionalRecipients recipients={additionalRecipients} />
        );
    }

    protected renderNotes(): ReactNode {
        const { forceEditable, siteSurvey, onChangeNotes, modified } = this.props;

        return checkIsEditable({ siteSurvey, key: SiteSurveyProperty.Notes, forceEditable }) ? (
            <NotesEditor initialNotes={siteSurvey.notes} notes={modified?.notes} onChange={onChangeNotes} />
        ) : (
            <Notes label={messages.labels.notes()} notes={siteSurvey.notes} />
        );
    }

    private get title(): string {
        const { siteSurvey } = this.props;
        return `${siteSurvey.name}${siteSurvey.interfaceSpeed ? ` (${siteSurvey.interfaceSpeed}G)` : ""}`;
    }

    /**
     * Returns the Tab Pane configurations for a site survey. Editable site
     * surveys will render forms. Non-editable site surveys will simply render
     * data. An RMA is different from a site survey only in that site address
     * info is not editable.
     */
    private get panes(): Pane[] {
        const { rolledUpErrors } = this.props;

        return [
            {
                kind: SiteSurveyView.Site,
                menuItem: <MenuItem title={title(SiteSurveyView.Site)} error={rolledUpErrors[SiteSurveyView.Site]} />,
                render: () => (
                    <Segment.Group horizontal>
                        <Segment>{this.renderSiteInfo()}</Segment>
                        <Segment>{this.renderDatacenter()}</Segment>
                    </Segment.Group>
                ),
            },
            {
                kind: SiteSurveyView.Shipping,
                menuItem: (
                    <MenuItem title={title(SiteSurveyView.Shipping)} error={rolledUpErrors[SiteSurveyView.Shipping]} />
                ),
                render: () => (
                    <Segment.Group horizontal>
                        <Segment>
                            <Segment.Group>
                                <Segment>{this.renderTaxInfo()}</Segment>
                                <Segment>{this.renderShippingInfo()}</Segment>
                            </Segment.Group>
                        </Segment>
                        <Segment>{this.renderContacts()}</Segment>
                    </Segment.Group>
                ),
            },
            {
                kind: SiteSurveyView.Appliances,
                menuItem: (
                    <MenuItem
                        title={title(SiteSurveyView.Appliances)}
                        error={rolledUpErrors[SiteSurveyView.Appliances]}
                    />
                ),
                render: () => <Segment>{this.renderAppliances()}</Segment>,
            },
            {
                kind: SiteSurveyView.More,
                menuItem: <MenuItem title={title(SiteSurveyView.More)} error={rolledUpErrors[SiteSurveyView.More]} />,
                render: () => (
                    <Segment.Group>
                        <Segment>{this.renderAdditionalRecipients()}</Segment>
                        <Divider />
                        <Segment>{this.renderNotes()}</Segment>
                    </Segment.Group>
                ),
            },
        ];
    }

    private get copyableSiteAddress(): AddressFields | undefined {
        const { modified, siteSurvey } = this.props;
        return {
            ...DEFAULT_ADDRESS_VALUES,
            ...(modified || siteSurvey).siteAddress,
            country: siteSurvey.countryCode,
        };
    }

    private get copyableNetworkConfigsIds() {
        const { modified, siteSurvey } = this.props;
        return new Set(
            (modified || siteSurvey).networkConfigs
                .filter((networkConfig) => COPYABLE_NETWORK_CONFIG_FIELDS.some((field) => !!networkConfig[field]))
                .map(({ id }) => id)
        );
    }

    private handleClickRecoveryAction(view: SiteSurveyView, validationError: ValidationError) {
        logger.trackEvent(CLICK_ERROR_ACTION_ITEM, validationError);

        if (this.partnerForm.current) {
            this.partnerForm.current.goToView(view);
        }
    }

    private handleSelectShippingAddress(address: AddressFields) {
        const { onSelectShippingAddress } = this.props;
        onSelectShippingAddress(address);
    }

    private handleSetActiveNetworkConfigIndex(index: number) {
        const { onSetActiveNetworkConfigIndex } = this.props;
        onSetActiveNetworkConfigIndex(index);
    }

    private initializeForms() {
        const { fetchShippingAddressSuggestions, fetchAllowedParts, validate } = this.props;
        fetchShippingAddressSuggestions();
        fetchAllowedParts();
        validate();
    }
}

export default SiteSurvey;
