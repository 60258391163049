import React, { Component } from "react";
import { Content } from "../../../shared/components/PartnerForm";
import { messages } from "../../../shared/messages";
import { ID_KEY } from "../../../shared/models/Resource";
import { OcaReturnDetail as OcaReturnDetailModel } from "../../models/OcaReturnDetail";
import OcaReturnDetail from "../OcaReturnDetail";

interface Props {
    ocaReturnDetails: OcaReturnDetailModel[];
}

class OcaReturnDetails extends Component<Props> {
    render() {
        const { ocaReturnDetails } = this.props;
        return (
            <Content
                title={`${messages.title.ocaReturnDetails({ COUNT: ocaReturnDetails.length })}:`}
                className="OcaReturnDetails"
            >
                <div className="OcaReturnDetails-list">
                    {ocaReturnDetails.map(ocaReturnDetail => (
                        <OcaReturnDetail key={ocaReturnDetail[ID_KEY]} ocaReturnDetail={ocaReturnDetail} />
                    ))}
                </div>
            </Content>
        );
    }
}

export default OcaReturnDetails;
