import React, { PureComponent, ReactNode, HTMLAttributes } from "react";
import classnames from "classnames";
import Spacer from "../Spacer";
import { capitalize } from "lodash";

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    subtitle?: string;
    leftAccessory?: ReactNode;
    rightAccessory?: ReactNode;
    size?: string;
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    fluid?: boolean;
    textAlign: "default" | "center";
    justifyContent: "default" | "center";
}

class Header extends PureComponent<Props> {
    static defaultProps = {
        textAlign: "default",
        justifyContent: "default",
    };

    render() {
        const {
            title,
            subtitle,
            children,
            as = "h1",
            leftAccessory,
            rightAccessory,
            className,
            fluid,
            textAlign,
            justifyContent,
            ...props
        } = this.props;

        return (
            <header
                {...props}
                className={classnames(
                    "Header",
                    className,
                    `textAlign${capitalize(textAlign)}`,
                    `justifyContent${capitalize(justifyContent)}`,
                    { fluid }
                )}
            >
                <div className="Header-left">
                    {leftAccessory}
                    {leftAccessory && <Spacer />}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        {React.createElement(as, null, title)}
                        {subtitle && <span className="Header-subtitle">{subtitle}</span>}
                    </div>
                    <Spacer />
                    {rightAccessory}
                </div>
                {children}
            </header>
        );
    }
}

export default Header;
