import classnames from "classnames";
import React, { memo } from "react";
import Icon, { Props as IconProps } from "./Icon";

interface Props extends Omit<IconProps, "icon"> {
    className?: string;
}

function Loading({ size, className, ...props }: Props) {
    return <Icon icon="spinner" className={classnames("fa-spin", className)} size={size} {...props} />;
}

export default memo(Loading);
