import React, { Component } from "react";
import { Content } from "../../../shared/components/PartnerForm";
import { messages } from "../../../shared/messages";
import { isPartOrderLineItem } from "../../../utils/guards";
import PartOrderLineItem from "../PartOrderLineItem";
import { PartOrderLineItem as PartOrderLineItemModel } from "../../models/PartOrderLineItem";
import { PartOrderLineItemNew } from "../../models/PartOrderLineItemNew";
import Segment from "../../../shared/components/Segment";

interface Props {
    lineItems: (PartOrderLineItemModel | PartOrderLineItemNew)[];
}

class PartOrderLineItems extends Component<Props> {
    render() {
        const { lineItems } = this.props;
        return (
            <Content title={messages.title.parts()}>
                <Segment.Group>
                    {lineItems.filter(isPartOrderLineItem).map((lineItem) => (
                        <PartOrderLineItem key={lineItem.id} lineItem={lineItem} />
                    ))}
                </Segment.Group>
            </Content>
        );
    }
}

export default PartOrderLineItems;
