import meechumClient, { MeechumClient, MeechumInfo } from "@octools/meechum-client";
import { MEECHUM_SESSION_REFRESH_ERROR, MEECHUM_SESSION_REFRESH_TIMEOUT } from "../../shared/events";
import { history } from "../../shared/history";
import logger from "../../shared/logger";
import { PartnerFormKind } from "../../shared/models/PartnerFormKind";
import Publisher from "../../shared/models/Publisher";
import { TokenType } from "../../shared/models/TokenType";
import { PATH_LOGIN, QUERY_ORG_ID } from "../../shared/routes";
import { isNetflixEmail } from "../../utils/contact";
import {isObject, isString} from "../../utils/guards";
import { getSearchValue, setSearchValue } from "../../utils/url";
import { APIKeyCredentials } from "../auth/models/APIKeyCredentials";
import { Authorization } from "../auth/models/Authorization";
import { APIKeyAuthResponse } from "../topgear/models/APIKeyAuthResponse";
import {
    ApiKeyUser,
    ApiKeyUserKind,
    checkIsAPIKeyUser,
    checkIsMeechumUser,
    checkIsUser,
    MeechumUser,
    MeechumUserKind,
    User,
} from "./models/User";

/**
 * A singleton that manages the current authenticated user of the app, either
 * via Meechum or API key.
 */
export class AuthService extends Publisher<User | null> {
    public static HEADER_PARTNER_API_KEY = "X-Nflx-Partner-API_Key";
    public static HEADER_PARTNER_EMAIL = "X-Nflx-Partner-Email";

    /**
     * Checks that an APIKeyUser is authorized for the specified resource.
     * @param user - APIKeyUser
     * @param kind - The resource kind (site survey, parts order, OCA return)
     * @param id - The ID of the resource
     */
    public static check(user: ApiKeyUser, kind: PartnerFormKind, id: number): boolean {
        let authorizedFor: number[] | undefined;
        switch (kind) {
            case PartnerFormKind.SiteSurvey:
                authorizedFor = user.siteSurveyIds;
                break;
            case PartnerFormKind.PartOrder:
                authorizedFor = user.partOrderIds;
                break;
            case PartnerFormKind.OcaReturn:
                authorizedFor = user.ocaReturnIds;
                break;
            default:
                return false;
        }
        return authorizedFor !== undefined && authorizedFor.includes(id);
    }

    public static checkIsSsoPartner(user: User | null): boolean {
        return checkIsMeechumUser(user)
            && isObject(user?.userInfo)
            && isString(user?.userInfo?.email)
            && !isNetflixEmail(user?.userInfo?.email);
    }

    public static checkIsAdmin(user: User | null): boolean {
        return checkIsMeechumUser(user)
            && isObject(user?.userInfo)
            && isString(user?.userInfo?.email)
            && isNetflixEmail(user.userInfo?.email);
    }

    public static checkIsAdminOrSsoPartner(user: User | null): boolean {
        return AuthService.checkIsAdmin(user) || AuthService.checkIsSsoPartner(user);
    }

    /**
     * Returns the Authorization of the current user. If it is an API Token
     * user, then it is the scope of the auth key. If it is a meechum user, then
     * an org ID must be specified as the Authorization scope.
     * @param user
     * @param orgId
     */
    public static getAuthorization(user: User | null, orgId?: number): Authorization | undefined {
        if (AuthService.checkIsAdmin(user) || AuthService.checkIsSsoPartner(user)) {
            return {
                orgId,
            };
        }

        if (checkIsAPIKeyUser(user)) {
            return user;
        }

        return;
    }

    public static getIdentity(user: User): string {
        if (user.kind === ApiKeyUserKind) {
            return user.email;
        } else if (user?.userInfo?.email) {
            return user.userInfo.email;
        } else {
            window.location.assign("/meechum?logout=");
            return "";
        }
    }

    // v1 - Deprecated
    private static STORAGE_API_KEY = "apiKey"; // string
    private static STORAGE_EMAIL = "email"; // string

    // v2
    private static STORAGE_AUTH = "auth"; // json

    private static meechumSessionInfoAsUser(info: MeechumInfo): MeechumUser {
        return {
            kind: MeechumUserKind,
            accessToken: info.access_token,
            userInfo: info.userinfo,
        };
    }

    private storage = window.localStorage;
    private _meechumClient: MeechumClient = meechumClient;
    private _user: User | null = null;

    constructor() {
        super();
        this.setupMeechumClient();
    }

    public get user(): User | null {
        return this._user;
    }

    public get meechumClient() {
        return this._meechumClient;
    }

    public set meechumClient(client: MeechumClient) {
        this._meechumClient.stopRefreshTimer();
        this._meechumClient = client;
        this.setupMeechumClient();
    }

    /**
     * Checks local storage for credentials (API key + email), if not found, it
     * tries to kick off the Meechum session (with automatic refresh) to obtain
     * authenticated user. If found, the user is published to subscribers.
     * Otherwise, the user is unauthenticated (`null`).
     */
    public start(): Promise<User | null> {
        return Promise.resolve()
            .then(() => this.checkStorageCredentials())
            .catch(() =>
                this.meechumClient.startRefreshTimer().then((info) => AuthService.meechumSessionInfoAsUser(info))
            )
            .catch(() => null) // ignore
            .then((user) => {
                this.updateUser(user);
                return user;
            });
    }

    public updateApiKeyUser(credentials: APIKeyCredentials, info: APIKeyAuthResponse) {
        const user: ApiKeyUser = {
            kind: ApiKeyUserKind,
            ...credentials,
            orgId: info.orgId,
        };
        switch (info.keyType) {
            case TokenType.SiteSurvey:
                user.siteSurveyIds = info.authorizedIds;
                break;
            case TokenType.PartOrder:
                user.partOrderIds = info.authorizedIds;
                break;
            case TokenType.OcaReturn:
                user.ocaReturnIds = info.authorizedIds;
                break;
            default:
                break;
        }
        this.updateUser(user);
    }

    /**
     * Publish an updated auth. Returns `this` for chaining.
     * @param onFinish - Use this to take some action after the update has been
     * processed. If the return value is truthy, the updated user will be
     * published to subscribers. A falsy return value can be used to suppress an
     * event to avoid unwanted re-renders.
     */
    public updateUser(user: Readonly<User> | null, onFinish?: () => boolean | undefined): AuthService {
        let nextUser = user;
        if (user === null) {
            this.storage.removeItem(AuthService.STORAGE_API_KEY);
            this.storage.removeItem(AuthService.STORAGE_EMAIL);
            this.storage.removeItem(AuthService.STORAGE_AUTH);

            // If user is logged in via meechum, revert to meechum user identity
            if (this.meechumClient.accessToken !== undefined) {
                nextUser = {
                    kind: MeechumUserKind,
                    accessToken: this.meechumClient.accessToken!,
                    userInfo: this.meechumClient.userInfo!,
                };
            }
        } else if (checkIsAPIKeyUser(user)) {
            this.storage.setItem(AuthService.STORAGE_AUTH, JSON.stringify(user));
        }

        // Publish event to all subscribers if user has changed
        if (this._user !== nextUser) {
            this._user = nextUser;
            if (onFinish?.() ?? true) {
                this.publish(this._user);
            }
        }
        return this;
    }

    public get authHeaders() {
        if (!this.user) {
            return undefined;
        }

        if (checkIsUser(this.user) && checkIsMeechumUser(this.user)) {
            return AuthService.generateBearerTokenHeader(this.user);
        } else {
            return AuthService.generateApiKeyAuthHeaders(this.user);
        }
    }

    public signOut() {
        this.updateUser(null, () => false);
        // NOTE(wtsai): From my observation, it appears that only the first
        // query param is carried over through Meechum's logout endpoint. Opt to
        // keep the org ID.
        const location = history.location;
        const orgId = getSearchValue(location.search, QUERY_ORG_ID);
        const search = setSearchValue(undefined, QUERY_ORG_ID, orgId);
        window.location.assign(`/meechum?logout=${PATH_LOGIN}${search}`);
    }

    public static generateBearerTokenHeader(user: MeechumUser) {
        return {
            Authorization: `Bearer ${user.accessToken}`,
        };
    }

    public static generateApiKeyAuthHeaders(user: APIKeyCredentials | ApiKeyUser) {
        return {
            [AuthService.HEADER_PARTNER_API_KEY]: user.apiKey,
            [AuthService.HEADER_PARTNER_EMAIL]: user.email,
        };
    }

    /**
     * Set Meechum Client event handlers
     */
    private setupMeechumClient() {
        // NOTE(wtsai): Even though we kick off the meechum client timer, the
        // user is not necessarily authenticated via Meechum. Therefore, don't
        // redirect to login or refresh the page as a result of a session
        // refresh timeout/error. Instead, rely on the `RequestMiddleware` or
        // the `<Redirect/>` component to redirect when the user is not properly
        // authenticated, as those mechanisms are both Meechum & API Key aware.
        this.meechumClient.errorHandler = (error) => {
            logger.trackEvent(MEECHUM_SESSION_REFRESH_ERROR, {
                message: error.message,
            });
            logger.trackError(error, error.message, {
                from: "sessionErrorHandler",
            });
        };
        this.meechumClient.sessionTimeoutHandler = () => {
            logger.trackEvent(MEECHUM_SESSION_REFRESH_TIMEOUT);
        };
        this.meechumClient.refreshHandler = (info) => this.updateUser(AuthService.meechumSessionInfoAsUser(info));
    }

    private checkStorageCredentials(): Promise<ApiKeyUser> {
        const str = this.storage.getItem(AuthService.STORAGE_AUTH);
        const user = str ? (JSON.parse(str) as ApiKeyUser) : undefined;

        if (user) {
            this.updateUser(user);
            return Promise.resolve(user);
        } else {
            return Promise.reject(new Error("Authenticated User not found."));
        }
    }
}

export default new AuthService();
