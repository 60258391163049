import React, { HTMLAttributes, memo } from "react";
import Dropdown from "../../../shared/components/Dropdown";
import Form from "../../../shared/components/Form";
import FormField, { Props as FormFieldProps } from "../../../shared/components/FormField";
import OptionBar from "../../../shared/components/OptionBar";
import { useForm } from "../../../shared/forms/hooks/useForm";
import { messages } from "../../../shared/messages";
import { Shipper } from "../../../shared/models/Shipper";
import { FieldProps, FormPropsV2 } from "../../../shared/props/forms";
import { shipperAsOption } from "../../../utils/options";
import {
    BOXED_KEY,
    FREQUENT_SHIPPER_KEY,
    LIFT_GATE_REQUIRED_KEY,
    OcaReturnPickupProperties,
    OCA_RETURN_PICKUP_PROPERTIES_KEYS,
} from "../../models/OcaReturnPickupProperties";

export interface Props extends FormPropsV2<OcaReturnPickupProperties> {}

const SHIPPER_OPTIONS = Object.values(Shipper).map(shipperAsOption);

function OcaReturnPickupPropertiesForm(props: Props) {
    const [{ dirty, errors, values }, { touchHandlers, changeHandlers }] = useForm(
        { keys: OCA_RETURN_PICKUP_PROPERTIES_KEYS },
        props
    );

    function getFormFieldProps<K extends keyof OcaReturnPickupProperties>(
        key: K
    ): Pick<FormFieldProps, "name" | "required" | "horizontal" | "error"> {
        return {
            name: key,
            required: true,
            horizontal: true,
            error: errors.get(key),
        };
    }

    function getInputProps<K extends keyof OcaReturnPickupProperties, T>(
        key: K
    ): Pick<FieldProps & HTMLAttributes<T>, "dirty" | "error" | "onBlur"> & { value: any } {
        return {
            value: values?.[key],
            dirty: dirty.has(key),
            error: errors.has(key),
            onBlur: () => touchHandlers[key]?.(),
        };
    }

    return (
        <Form>
            <FormField {...getFormFieldProps(BOXED_KEY)} label={messages.labels.ocaReturn.boxed()}>
                <OptionBar
                    {...getInputProps(BOXED_KEY)}
                    onChange={changeHandlers[BOXED_KEY]}
                    options={[
                        {
                            text: messages.choices.affirmative(),
                            value: true,
                        },
                        {
                            text: messages.choices.negative(),
                            value: false,
                        },
                    ]}
                />
            </FormField>
            <FormField {...getFormFieldProps(LIFT_GATE_REQUIRED_KEY)} label={messages.labels.ocaReturn.liftGate()}>
                <OptionBar
                    {...getInputProps(LIFT_GATE_REQUIRED_KEY)}
                    onChange={changeHandlers[LIFT_GATE_REQUIRED_KEY]}
                    options={[
                        {
                            text: messages.choices.affirmative(),
                            value: true,
                        },
                        {
                            text: messages.choices.negative(),
                            value: false,
                        },
                    ]}
                />
            </FormField>
            <FormField {...getFormFieldProps(FREQUENT_SHIPPER_KEY)} label={messages.labels.ocaReturn.frequentShipper()}>
                <Dropdown
                    {...getInputProps(FREQUENT_SHIPPER_KEY)}
                    onChange={(evt) => changeHandlers[FREQUENT_SHIPPER_KEY]?.(evt.target.value as Shipper)}
                    placeholder={messages.forms.placeholders.selectGeneric()}
                    options={SHIPPER_OPTIONS}
                />
            </FormField>
        </Form>
    );
}

export default memo(OcaReturnPickupPropertiesForm);
