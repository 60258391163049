import React, { Component } from "react";
import classnames from "classnames";
import Icon from "../Icon";
import SlideDown from "../SlideDown";
import Button from "./Button";

interface Props {
    disabled?: boolean;
    component: () => JSX.Element;
    icon?: JSX.Element;
    onDismiss?: () => void;
    dismissOnOutsideClick?: boolean;
}

interface State {
    isOpen: boolean;
}

/**
 * A button accessory that maintains its own state. Renders the component in an
 * absolutely positioned popup when clicked. Detects clicks outside of the popup
 * to dismiss.
 */
class ButtonAccessoryLauncher extends Component<Props, State> {
    state: State = {
        isOpen: false
    };

    private wrapper = React.createRef<HTMLDivElement>();
    private accessory = React.createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        this.handleClickAccessoryLauncher = this.handleClickAccessoryLauncher.bind(this);
        this.handleGlobalClickEvent = this.handleGlobalClickEvent.bind(this);
    }

    componentDidMount() {
        window.addEventListener("click", this.handleGlobalClickEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleGlobalClickEvent);
    }

    /**
     * Toggle the open state of the accessory launcher.
     * @param toOpenState - If provided, will force open (true) or closed (false)
     */
    public toggle(toOpenState = !this.state.isOpen) {
        this.setState({
            isOpen: toOpenState
        });
    }

    render() {
        const { icon, disabled, component } = this.props;
        const { isOpen } = this.state;

        return (
            <div ref={this.wrapper} className={classnames("ButtonAccessoryLauncher", { disabled })}>
                <Button
                    className="ButtonAccessoryLauncher-button"
                    kind="action"
                    onClick={this.handleClickAccessoryLauncher}
                    rounded={false}
                >
                    {icon || <Icon size="lg" icon="caret-down" />}
                </Button>
                <SlideDown in={isOpen}>
                    <div ref={this.accessory} className={classnames("ButtonAccessory")}>
                        {component()}
                    </div>
                </SlideDown>
            </div>
        );
    }

    /**
     * Component click event handler. If click is on the launcher, then toggle
     * the open state.
     * @param evt
     */
    private handleClickAccessoryLauncher(evt: React.MouseEvent<HTMLButtonElement>) {
        const { onDismiss } = this.props;

        // Click is inside accessory, do nothing
        if (this.accessory.current && this.accessory.current.contains(evt.target as Element)) {
            return;
        }

        const toOpenState = !this.state.isOpen;

        this.setState({
            isOpen: toOpenState
        });

        if (!toOpenState && onDismiss) {
            onDismiss();
        }
    }

    /**
     * Global click event handler. Detects if clicks are outside of this
     * component, and closes the accessory dialog if so.
     * @param event
     */
    private handleGlobalClickEvent(event: MouseEvent) {
        const { onDismiss, dismissOnOutsideClick = true } = this.props;
        const { isOpen } = this.state;

        // Configured to ignore global click events
        if (!dismissOnOutsideClick) {
            return;
        }

        // Accessory is not open - nothing to do
        if (!this.wrapper.current || !isOpen) {
            return;
        }

        // This is an _inside_ click
        if (this.wrapper.current.contains(event.target as Element)) {
            return;
        }

        this.setState({
            isOpen: false
        });

        if (onDismiss) {
            onDismiss();
        }
    }
}

export default ButtonAccessoryLauncher;
