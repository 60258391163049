import { configureStore } from "@reduxjs/toolkit";
import { AnyAction, Middleware } from "redux";
import appMiddleware from "./app/middleware";
import { AppState } from "./app/models/AppState";
import { appReducer } from "./app/reducer";
import authMiddleware from "./auth/middleware";
import ocaReturnsMiddleware from "./ocaReturns/middleware";
import partOrdersMiddleware from "./partOrders/middleware";
import config from "./shared/config";
import siteSurveysMiddleware from "./siteSurveys/middleware";

export function configurePfStore(preloadedState?: DeepPartial<AppState>) {
    return configureStore<AppState, AnyAction, Middleware<Record<string, unknown>, AppState>[]>({
        reducer: appReducer,
        middleware: [appMiddleware, authMiddleware, siteSurveysMiddleware, partOrdersMiddleware, ocaReturnsMiddleware],
        preloadedState,
        devTools: config.env === "dev",
    });
}
